import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListSubheader,
  ListItemButton,
  ListItemText,
} from '@mui/material';
// utils
import moment from 'moment';
import { fToNow } from '../../../utils/formatTime';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { Api } from '../../../webservices';

export default function NotificationsPopover() {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([]);
  const [notificationsUnred, setNotificationsUnred] = useState([]);
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const location = useLocation()
  useEffect(() => {
    // if(location.pathname === ''){
      getNotificationLst()
      getSkills()
    // } else {
    //   getNotificationLst();
    // }
  }, []);

  useEffect(()=>{
    getNotificationLst()
    // alert("h")
  }, [location])

  const getNotificationLst = () => {
    const token = localStorage.getItem('token');
    Api('common/notificationList', 'GET', '', token)
      .then((response) => {
        if (response.status === 200 || response.data.code === 200) {
          setNotifications(response.data.result);
          setNotificationsUnred(response.data.unreadNotification)
          setLoading(false);
        } else {
          setLoading(false);
          setError('No data found');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError('Failed to fetch notifications');
      });
  };

  const readAll = () => {
    const token = localStorage.getItem('token');
    Api('common/readAllNotification', 'GET', '', token)
      .then((response) => {
        if (response.status === 200 || response.data.code === 200) {
          getNotificationLst()
          // setNotifications(response.data.result);
          // setNotificationsUnred(response.data.unreadNotification)
          // setLoading(false);
        } else {
          // setLoading(false);
          // setError('No data found');
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const getNotificationId = (id) => {
    const token = localStorage.getItem('token');
    Api(`common/readNotification/${id}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200 || response.data.code === 200) {
          // setNotifications(response.data.result);
          // setLoading(false);
          // alert("j")
          getNotificationLst()
        } else {
          // setLoading(false);
          // setError('No data found');
        }
      })
      .catch((err) => {
        console.error(err);
        // setLoading(false);
        // setError('Failed to fetch notifications');
      });
  };

  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);
  const[value, setValue] = useState("")

  
  const [addedSkill, setAddedSkill] = useState([])
  const [tempskill, setTempskill] = useState([]);
  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data?.result?.map((item) => {
              return tempskill?.push({ title: item?.skillName });
            });
            // setSkill(tempskill);
            setAddedSkill(tempskill)
            // console.log('===value skilll========', JSON.stringify(skill));
          } else {
            // setSkill([{ title: '' }]);
          }
        } else {
          // toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  return (
    <>
      <IconButton
        color={'primary'}
        // color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ml: -4,
          mt: 0.2,
          backgroundColor: 'white',
          borderRadius: '50%', 
          padding: 0, 
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <Badge 
        badgeContent={notificationsUnred} 
        color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
  open={Boolean(open)}
  anchorEl={open}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  PaperProps={{ sx: { ml: -4, width: 360, border: "2px solid rgb(164 176 191)", borderRadius: "10px",
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '81.5vh',
    minHeight:'81.5vh'
  } }}
 
>
  <Box sx={{ alignItems: 'center', py: 2, px: 2.5, flexShrink: 0, }}>
    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
      <Typography variant="subtitle1" fontWeight={"bold"} color={'text.secondary'}>Notifications</Typography>
    </Box>
  </Box>

  <Divider sx={{ 
    borderStyle: 'dashed',
    // border: "2px solid rgb(164 176 191)"
    }} />

<Box sx={{ 
    flexGrow: 1, 
    overflowY: 'auto',
    maxHeight: 'calc(100% - 120px)',
    '&:hover': {
      overflowY: 'scroll',
    }
  }}>
  <Scrollbar sx={{ height: "100%" }}> 
    <List disablePadding>
      {notifications.map((notification) => (
        <NotificationItem key={notification._id} notification={notification} 
        getNotificationId={getNotificationId}
        navigate={navigate}
        value={value}
        handleClose={handleClose}
        addedSkills={addedSkill}
        />
      ))}
    </List>
  </Scrollbar>
  </Box>
  <Divider/>
  <Button color="custom" variant="contained" size="small"
                  sx={{ mb:1, mt:1 , width:"26%", ml:"39%"}}
                  onClick={()=>readAll()}
                  >
                  Clear All
                </Button>
</Popover>

    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    readStatus: PropTypes.bool,
  }).isRequired,
};

function NotificationItem({ notification, getNotificationId, navigate, handleClose, addedSkills}) {
  const [hovered, setHovered] = useState(false); 

  const handleLinkClick = () => {
    if (notification.subject === "Job Updated" || notification.subject === "Job Added") {
      // navigate("/jobs");
      navigate("/jobDetail", {state:{_id:notification.link, success:"success"}});
      handleClose()
    } 
    // else if (notification.subject === "New Client Updated" || notification.subject === "New Client Added") {
    //   navigate("/clients");
      
    // } 
    // else if (notification.subject === "Bench Submission" || notification.subject === "Bench Submission Update") {
    //   navigate("/benchworksheet");
    // } 
    else if (notification.subject === "Resume Submitted" || notification.subject === "Submission Update" 
      || notification.subject === "Interview Update" || notification.subject === "Placement Update" ||
      notification.subject === "Project Update"
    ) {
      // navigate("/worksheet");
      navigate('/resumeDetail', {
        state: {
          row: notification,
          fTab: "submitted",
          mtab: 'Resume',
        },
      });
      handleClose()
    }
    else if (notification.subject === "Bench Placement Update") {
      navigate('/benchresumedetails', {
        state: {
          row: notification,
          // fTab: "submitted",
          addedSkills,
          mtab: 'Resume',
        },
      });
      handleClose()
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const tablebg = 'rgba(82, 130, 190, 0.08)';
  return (
    <ListItemButton
    sx={{
      mt: "1.7px", 
      // mb: "0.5px", 
      paddingTop: 1, 
      paddingBottom: 1, 
    // overflow: 'hidden', 
      minHeight:"80px !important",
      height:"auto !important",
      ...(notification?.readStatus === false && { bgcolor: tablebg }), 
      // sx={[
      //   { background: index % 2 === 0 ? 'text.background' : tablebg },
      //   { '&:last-child td, &:last-child th': { border: 0 } },
      //   { '&:hover': { boxShadow: 5 } },
      // ]}
    }}
      onClick={() => getNotificationId(notification._id)}
    >
      <ListItemText 
        primary={<Typography fontWeight={"bold"} 
        // color={'text.secondary'}
        color={notification.colorKey?notification.colorKey:'text.secondary'}
        >
        {notification.subject}
        </Typography>}
        secondary={
          <>
           <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.9)" }}>
  {
    notification.message.split(notification.link).map((part, index) => (
        <span key={index}>
          {part}
          {index !== notification.message.split(notification.link).length - 1 && (
            <span
              style={{
                color: hovered ? "red" : "#4f85a2", 
                cursor: 'pointer',
                transition: "color 0.3s ease",
                textDecoration: 'underline'
              }}
              role="button"
              tabIndex={0}
              onClick={() => handleLinkClick()}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleLinkClick();
                }
              }}
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
            >
              {notification.link}
            </span>
          )}
        </span>
      ))
  }
</Typography>


            <Typography variant="caption" sx={{ mt: 0.5, display: 'flex', alignItems: 'center', color: 'text.disabled' }}>
              <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
              {/* {(new Date(notification.createdAt).getHours())}:{(new Date(notification.createdAt).getMinutes())} */}
              {fToNow(new Date(notification.createdAt))}
              {/* {new Date(notification.createdAt).toLocaleString('en-US', {
    month: 'short', 
    day: 'numeric', 
    year: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false,
  })} */}
            </Typography>
           {/* <Typography variant="caption" sx={{ mt: 0.5, display: 'flex', alignItems: 'center', color: 'text.disabled' }}>
  <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
  {(new Date() - new Date(notification.createdAt)) / (1000 * 60 * 60) >= 24
    ? `${Math.floor((new Date() - new Date(notification.createdAt)) / (1000 * 60 * 60 * 24))} day${Math.floor((new Date() - new Date(notification.createdAt)) / (1000 * 60 * 60 * 24)) > 1 ? 's' : ''} ago`
    : `${new Date(notification.createdAt).getHours().toString().padStart(2, '0')}:${new Date(notification.createdAt).getMinutes().toString().padStart(2, '0')}`}
</Typography> */}

          </> 
        }
      />
    </ListItemButton>
  );
}




// import PropTypes from 'prop-types';
// import { set, sub } from 'date-fns';
// import { noCase } from 'change-case';
// import { faker } from '@faker-js/faker';
// import { useState, useEffect} from 'react';
// // @mui
// import {
//   Box,
//   List,
//   Badge,
//   Button,
//   Avatar,
//   Tooltip,
//   Divider,
//   Popover,
//   Typography, 
//   IconButton,
//   ListItemText,
//   ListSubheader,
//   ListItemAvatar,
//   ListItemButton,
// } from '@mui/material';
// // utils
// import { fToNow } from '../../../utils/formatTime';
// // components
// import Iconify from '../../../components/iconify';
// import Scrollbar from '../../../components/scrollbar';
// import { Api } from '../../../webservices';
// // ----------------------------------------------------------------------

// const NOTIFICATIONS = [
//   {
//     id: faker.datatype.uuid(),
//     title: 'Your order is placed',
//     description: 'waiting for shipping',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: faker.name.fullName(),
//     description: 'answered to your comment on the Foxearch',
//     avatar: '/assets/images/avatars/avatar_2.jpg',
//     type: 'friend_interactive',
//     createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new message',
//     description: '5 unread messages',
//     avatar: null,
//     type: 'chat_message',
//     createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new mail',
//     description: 'sent from Guido Padberg',
//     avatar: null,
//     type: 'mail',
//     createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'Delivery processing',
//     description: 'Your order is being shipped',
//     avatar: null,
//     type: 'order_shipped',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
// ];

// export default function NotificationsPopover() {
//   const [notifications, setNotifications] = useState(NOTIFICATIONS);

//   const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

//   const [open, setOpen] = useState(null);

//   const handleOpen = (event) => {
//     setOpen(event.currentTarget);
//   };

//   const handleClose = () => {
//     setOpen(null);
//   };

//   const handleMarkAllAsRead = () => {
//     setNotifications(
//       notifications.map((notification) => ({
//         ...notification,
//         isUnRead: false,
//       }))
//     );
//   };

//   const [notifications1, setNotifications1] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//       getNotificationLst();
//   }, []);

//   const getNotificationLst = () => {
//       const token = localStorage.getItem('token');
//       Api(`common/notificationList`, 'GET', '', token)
//           .then((response) => {
//               if (response.status === 200 && response.data.code === 200) {
//                   setNotifications1(response.data.notifications); 
//                   setLoading(false);
//               } else {
//                   setLoading(false);
//                   setError('No data found');
//               }
//           })
//           .catch((err) => {
//               console.error(err);
//               setLoading(false);
//               setError('Failed to fetch notifications');
//           });
//   };

//   return (
//     <>
//       {/* <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} 
//       sx={{ width: 40, height: 40, ml:-4, mt:0.5 }}
//       >
//         <Badge badgeContent={totalUnRead} color="error">
//           <Iconify icon="eva:bell-fill" />
//         </Badge>
//       </IconButton> */}

// <IconButton
//   color={open ? 'primary' : 'default'}
//   onClick={handleOpen}
//   sx={{
//     width: 40,
//     height: 40,
//     ml: -4,
//     mt: 0.2,
//     backgroundColor: 'lightgrey',
//     borderRadius: '50%', 
//     padding: 0, 
//     '&:hover': {
//       backgroundColor: 'grey',
//     },
//   }}
// >
//   <Badge badgeContent={totalUnRead} color="error">
//     <Iconify icon="eva:bell-fill" />
//   </Badge>
// </IconButton>

//       <Popover
//         open={Boolean(open)}
//         anchorEl={open}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//         PaperProps={{
//           sx: {
//             mt: 1,
//             ml: -6,
//             width: 360,
//           },
//         }}
//       >
//         <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
//           <Box sx={{ flexGrow: 1 }}>
//             <Typography variant="subtitle1">Notifications</Typography>
//             <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//               You have {totalUnRead} unread messages
//             </Typography>
//           </Box>

//           {totalUnRead > 0 && (
//             <Tooltip title=" Mark all as read">
//               <IconButton color="primary" onClick={handleMarkAllAsRead}>
//                 <Iconify icon="eva:done-all-fill" />
//               </IconButton>
//             </Tooltip>
//           )}
//         </Box>

//         <Divider sx={{ borderStyle: 'dashed' }} />

//         <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
//           <List
//             disablePadding
//             subheader={
//               <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
//                 New
//               </ListSubheader>
//             }
//           >
//             {notifications.slice(0, 2).map((notification) => (
//               <NotificationItem key={notification.id} notification={notification} />
//             ))}
//           </List>

//           <List
//             disablePadding
//             subheader={
//               <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
//                 Before that
//               </ListSubheader>
//             }
//           >
//             {notifications.slice(2, 5).map((notification) => (
//               <NotificationItem key={notification.id} notification={notification} />
//             ))}
//           </List>
//         </Scrollbar>

//         <Divider sx={{ borderStyle: 'dashed' }} />

//         <Box sx={{ p: 1 }}>
//           <Button fullWidth 
//           // disableRipple
//           >
//             View All
//           </Button>
//         </Box>
//       </Popover>
//     </>
//   );
// }

// // ----------------------------------------------------------------------

// NotificationItem.propTypes = {
//   notification: PropTypes.shape({
//     createdAt: PropTypes.instanceOf(Date),
//     id: PropTypes.string,
//     isUnRead: PropTypes.bool,
//     title: PropTypes.string,
//     description: PropTypes.string,
//     type: PropTypes.string,
//     avatar: PropTypes.any,
//   }),
// };

// function NotificationItem({ notification }) {
//   const { avatar, title } = renderContent(notification);

//   return (
//     <ListItemButton
//       sx={{
//         py: 1.5,
//         px: 2.5,
//         mt: '1px',
//         ...(notification.isUnRead && {
//           bgcolor: 'action.selected',
//         }),
//       }}
//     >
//       <ListItemAvatar>
//         <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
//       </ListItemAvatar>
//       <ListItemText
//         primary={title}
//         secondary={
//           <Typography
//             variant="caption"
//             sx={{
//               mt: 0.5,
//               display: 'flex',
//               alignItems: 'center',
//               color: 'text.disabled',
//             }}
//           >
//             <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
//             {fToNow(notification.createdAt)}
//           </Typography>
//         }
//       />
//     </ListItemButton>
//   );
// }

// // ----------------------------------------------------------------------

// function renderContent(notification) {
//   const title = (
//     <Typography variant="subtitle2">
//       {notification.title}
//       <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
//         &nbsp; {noCase(notification.description)}
//       </Typography>
//     </Typography>
//   );

//   if (notification.type === 'order_placed') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'order_shipped') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'mail') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'chat_message') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
//       title,
//     };
//   }
//   return {
//     avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
//     title,
//   };
// }
