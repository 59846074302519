import React from 'react';
import { Card, CardContent, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

// const useStyles = styled({
//   content: {
//     padding: '40px',
    
//   }
// });

const MainDashboard = () => {

  // const classes = useStyles();

  // return (
  //   <Stack spacing={1} alignItems="center">
  //   {/* <Typography variant="h6" sx={{ bgcolor: '#1E74B3', color: 'white', padding: '5px', borderRadius: '4px', width: 'fit-content' }}>Dashboard</Typography> */}
  //   <Card sx={{ backgroundColor: 'white', color: 'white', padding: '10px', alignItems: 'center', justifyContent: 'center' }}>
  //     <Grid container spacing={2} justifyContent="center">
  //       <Grid item>
  //         <Card sx={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           width: '230px',
  //           height: '130px',
  //           // textAlign: 'center',
  //           paddingTop: '5px',
  //           boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  //         }}>
  //           <Typography style={{ fontWeight: 'bold', fontSize: '15px',textAlign:'center' }}>Recruitment</Typography>
  //           <Typography style={{paddingLeft:'6px'}}> Total Active Users</Typography>
  //         </Card>
  //       </Grid>
  //       <Grid item>
  //         <Card sx={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           width: '230px',
  //           height: '130px',
  //           // textAlign: 'center',
  //           paddingTop: '5px',
  //           boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  //         }}>
  //           <Typography style={{ fontWeight: 'bold', fontSize: '15px',textAlign:'center' }}>Sales</Typography>
  //           <Typography style={{paddingLeft:'6px'}}> Total Installed</Typography>
  //         </Card>
  //       </Grid>
  //       <Grid item>
  //         <Card sx={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           width: '230px',
  //           height: '130px',
  //           // textAlign: 'center',
  //           paddingTop: '5px',
  //           boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  //         }}>
  //           <Typography style={{ fontWeight: 'bold', fontSize: '15px',textAlign:'center' }}>Revenue</Typography>
  //           <Typography style={{paddingLeft:'6px'}}> Total Downloads</Typography>
  //         </Card>
  //       </Grid>
  //       <Grid item>
  //         <Card sx={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           width: '230px',
  //           height: '130px',
  //           // textAlign: 'center',
  //           paddingTop: '5px',
  //           boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  //         }}>
  //           <Typography style={{ fontWeight: 'bold', fontSize: '15px',textAlign:'center' }}>Clients</Typography>
  //           <Typography style={{paddingLeft:'6px'}}> Total Clients</Typography>
  //         </Card>
  //       </Grid>
  //       <Grid item>
  //         <Card sx={{
  //           backgroundColor: 'white',
  //           color: 'black',
  //           width: '230px',
  //           height: '130px',
  //           // textAlign: 'center',
  //           paddingTop: '5px',
  //           boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  //         }}>
  //           <Typography style={{ fontWeight: 'bold', fontSize: '15px',textAlign:'center' }}>Jobs</Typography>
  //           <Typography style={{paddingLeft:'6px'}}> Total Jobs</Typography>
  //         </Card>
  //       </Grid>
  //     </Grid>
  //   </Card>
  // </Stack>
  // );
};

export default MainDashboard;
