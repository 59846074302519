import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '50%', xs: '95%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: '3px solid #3C6BA5',
  borderRadius: 3,
};

function Admins() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const CompanyName = location.state.cname;
  const [showPassword, setShowPassword] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const [role, setRole] = useState('Admin');
  const [editTrue, setEditTrue] = useState(true);

  useEffect(() => {
    getAdmin();
    // alert(CompanyName)
  }, []);

  // modal 1
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [companyName, setCompanyName] = useState('');

  // modal 2
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [tableData, setTableData] = useState([]);
  const tableLabel = [
    { id: 1, label: 'Company Name' },
    { id: 2, label: 'User Id' },
    { id: 3, label: 'Role' },
    { id: 4, label: 'Name' },
    { id: 5, label: 'Phone' },
    { id: 6, label: 'Email' },
    { id: 7, label: 'Password' },
    { id: 8, label: 'Action' },
  ];

  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: 'admin',
    email: '',
    domain: '',
    ContactPersonName: '',
    contactNumber: '',
    password: '',
    address: '',
  });

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        });
      }
    } else {
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      });
    }
  };

  const getAdmin = async () => {
    const token = localStorage.getItem('token');
    Api(`company/getadmins/${id}`, 'GET', '', token)
      .then((response) => {
        console.log('=========get Admins=====>>>>>>>', response);
        if (response.status === 200) {
          // setCompanyName(response.data.company.companyName);
          // toast.success(response.data.company.companyName);
          if (response.data.code === 200 && response.data.data.length) {
            setTableData(response.data.data);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const addCompany = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const body = {
      email: formValuesAddComapnny.email.toLowerCase(),
      role: 'admin',
      password: formValuesAddComapnny.password,
      contactPersonName: formValuesAddComapnny.ContactPersonName,
      contactNo: formValuesAddComapnny.contactNumber,
      companyId: id,
    };
    Api(`company/add_admin`, 'POST', body, token)
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          handleClose2();
          toast.success(response.data.message, +'!');
          getAdmin();
          setFormValuesAddCompany({
            ...formValuesAddComapnny,
            cName: '',
            // role: '',
            email: '',
            domain: '',
            ContactPersonName: '',
            contactNumber: '',
            password: '',
            address: '',
          });
          // setTableData(response.data.result)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })

      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const funclick = () => {
    getAdmin();
  };
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Stack flexDirection={'row'} gap={1} alignItems={'center'} color={'text.secondary'}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
          <Typography variant="h5" color={'text.secondary'}>
            Admins Management
          </Typography>
        </Stack>
        <Stack flexDirection={'row'} gap={1}>
          <Button color="custom" variant="contained" size="small" onClick={handleOpen2}>
            Add Admin
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'text.secondary' }}>
              {tableLabel.map((item) => {
                return (
                  <TableCell sx={{ p: 1, py: 0.5, color: 'white' }} key={item.id}>
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <ManagementRow key={row._id} data={row} fun={funclick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={(e) => addCompany(e)}>
            <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
              <Grid
                display={'grid'}
                py={1}
                pr={{ xs: 1 }}
                gap={2}
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
              >
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                  disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    placeholder="Role"
                    value={formValuesAddComapnny.role}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        role: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                disabled
                  id="outlined-basic"
                  name="Company Name"
                  label="Company Name"
                  placeholder="Company Name"
                  variant="outlined"
                  value={companyName}
               
                /> */}
                <TextField
                  id="outlined-basic"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={formValuesAddComapnny.email}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      email: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  name="domain"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  variant="outlined"
                  value={formValuesAddComapnny.ContactPersonName}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      ContactPersonName: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-basic"
                  name="Contact Number"
                  label="Contact Number"
                  placeholder="Contact Number"
                  variant="outlined"
                  value={formValuesAddComapnny.contactNumber}
                  // type="number"
                  onChange={(e) => setContact(e.target.value)}
                />
                <Stack className="passwordIcon">
                  <TextField
                    id="outlined-basic"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    value={formValuesAddComapnny.password}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        password: e.target.value,
                      })
                    }
                  />
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </Stack>
              </Grid>
            </Scrollbar>
            <Stack flexDirection={'row'} gap={1} mt={2}>
              <Button color="custom" type="submit" variant="contained">
                Add
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

function ManagementRow({ data, fun }) {
  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showPassword, setShowPassword] = useState(true);
  const [del, setDel] = useState(false);
  const opendel = () => setDel(true);
  const closedel = () => setDel(false);
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: data.role,
    email: data.email,
    domain: data.officialDomain,
    contactPersonName: data.contactPersonName,
    contactNumber: data.contactNo,
    password: data.password,
    address: data.companyAddress,
  });
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();

  const [access, setAccess] = useState({ rworksheet: '', client: '', lead: '', jobs: '', placeAndDatabase: '' });

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        });
      }
    } else {
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      });
    }
  };
  async function editFun(data) {
    const token = await localStorage.getItem('token');
    if (edit === false) {
      setEdit(true);
    } else if (edit === true) {
      const body = {
        adminId: data._id,
        companyId: data.companyId,
        email: formValuesAddComapnny.email ? formValuesAddComapnny.email : data.email,
        password: formValuesAddComapnny.password ? formValuesAddComapnny.password : data.password,
        contactPersonName: formValuesAddComapnny.contactPersonName
          ? formValuesAddComapnny.contactPersonName
          : data.contactPersonName,
        contactNo: formValuesAddComapnny.contactNumber ? formValuesAddComapnny.contactNumber : data.contactNo,
      };
      Api(`company/edit_admin`, 'POST', body, token).then((Response) => {
        console.log('======edit_company_details======>', JSON.stringify(Response));
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            setEdit(false);
            fun();
          } else {
            toast.error(Response.data.message, +'!');
          }
        } else {
          toast.error('Internal error !');
        }
      });
    }
  }

  async function statuss(data) {
    const token = await localStorage.getItem('token');
    let sts = '';
    if (data.status === 'active') {
      sts = 'inactive';
    } else {
      sts = 'active';
    }
    const body = {
      adminId: data._id,
      status: sts,
    };
    Api(`company/change_status`, 'POST', body, token).then((Response) => {
      console.log('======getUser==response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          fun();
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
        }
      }
    });
  }

  const delAdmin = async (id) => {
    const token = await localStorage.getItem('token');
    const body = {
      adminId: id,
    };
    Api(`company/del_admin`, 'POST', body, token)
      .then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            fun();
          } else {
            toast.error(Response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
      });
  };
  return (
    <>
      <TableRow key={data._id}>
        <TableCell sx={{ pl: 1 }}>
          <Typography color={'text.secondary'} sx={{ cursor: 'pointer' }} onClick={() => handleOpen()}>
            {data.companyName}
          </Typography>
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography sx={{ maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.USERID}</Typography>
            <CopyToClipboard text={data.USERID}>
              <Iconify icon="radix-icons:copy" color={'text.textG'} sx={{ height: 17, width: 17, cursor: 'pointer',ml:1 }} />
            </CopyToClipboard>
          </Stack>
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          <Typography>{data.role}</Typography>
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          {edit === true ? (
            <TextField
              id="outlined-basic"
              label="Contact Person Name"
              placeholder="Contact Person Name"
              variant="outlined"
              value={formValuesAddComapnny.contactPersonName}
              onChange={(e) =>
                setFormValuesAddCompany({
                  ...formValuesAddComapnny,
                  contactPersonName: e.target.value,
                })
              }
            />
          ) : (
            <Typography>{data.contactPersonName}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          {edit === true ? (
            <TextField
              id="outlined-basic"
              label="Phone"
              placeholder="Phone"
              variant="outlined"
              value={formValuesAddComapnny.contactNumber}
              onChange={(e) =>
                setFormValuesAddCompany({
                  ...formValuesAddComapnny,
                  contactNumber: e.target.value,
                })
              }
            />
          ) : (
            <Typography>{data.contactNo}</Typography>
          )}
        </TableCell>

        <TableCell sx={{ pl: 2 }}>
          {edit === true ? (
            <TextField
              id="outlined-basic"
              label="Email"
              placeholder="Email"
              variant="outlined"
              value={formValuesAddComapnny.email}
              onChange={(e) =>
                setFormValuesAddCompany({
                  ...formValuesAddComapnny,
                  email: e.target.value,
                })
              }
            />
          ) : (
            <Typography sx={{textTransform:'lowercase'}}>{data.email}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 2 }}>
          {edit === true ? (
            <TextField
              id="outlined-basic"
              label="Password"
              placeholder="Password"
              variant="outlined"
              value={formValuesAddComapnny.password}
              onChange={(e) =>
                setFormValuesAddCompany({
                  ...formValuesAddComapnny,
                  password: e.target.value,
                })
              }
            />
          ) : (
            <Stack sx={{ flexDirection: 'row',alignContent:'center' }}>
              {showPassword ? (
                <>
                  <Typography sx={{ maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  *****************************
                  </Typography>
                  <Iconify
                    icon="mdi:eye"
                    sx={{ color: 'text.secondary', width: 20, height: 20,mt:-0.5,ml:0.5 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowPassword(false) }
                  />
                </>
              ) : (
                <>
                  <Typography sx={{ maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data.password}
                  </Typography>
                  <Iconify
                    icon="el:eye-close"
                    sx={{ color: 'text.secondary', width: 20, height: 20 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowPassword(true) }
                  />
                </>
              )}
            </Stack>
          )}
        </TableCell>
        <TableCell sx={{ pl: 2 }}>
          <Stack flexDirection={'row'} gap={1}>
            <Button color="custom" type="submit" variant="contained" size="small" onClick={() => editFun(data)}>
              {edit === false ? 'edit' : 'save'}
            </Button>
            <Button color="custom" type="submit" variant="contained" size="small" onClick={() => statuss(data)}>
              {data.status}
            </Button>
            <Button color="custom" type="submit" variant="contained" size="small" onClick={() => opendel(data._id)}>
              Delete
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: 400 }}>
            <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
              Access Management
            </Typography>
            <Divider />
            <Stack gap={2} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Recruiter Worksheet</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={access.rworksheet}
                  label="Recruiter Worksheet"
                  onChange={(e) => setAccess({ ...access, rworksheet: e.target.value })}
                >
                  <MenuItem value={'noaccess'}>No Access</MenuItem>
                  <MenuItem value={'limited'}>Limited Access</MenuItem>
                  <MenuItem value={'fullaccess'}>Full Access</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={access.client}
                  label="Client"
                  onChange={(e) => setAccess({ ...access, client: e.target.value })}
                >
                  <MenuItem value={'noaccess'}>No Access</MenuItem>
                  <MenuItem value={'limited'}>Limited Access</MenuItem>
                  <MenuItem value={'fullaccess'}>Full Access</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Lead</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={access.lead}
                  label="Lead"
                  onChange={(e) => setAccess({ ...access, lead: e.target.value })}
                >
                  <MenuItem value={'noaccess'}>No Access</MenuItem>
                  <MenuItem value={'limited'}>Limited Access</MenuItem>
                  <MenuItem value={'fullaccess'}>Full Access</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jobs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={access.jobs}
                  label="Jobs"
                  onChange={(e) => setAccess({ ...access, jobs: e.target.value })}
                >
                  <MenuItem value={'noaccess'}>No Access</MenuItem>
                  <MenuItem value={'limited'}>Limited Access</MenuItem>
                  <MenuItem value={'fullaccess'}>Full Access</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Placement & Database</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={access.placeAndDatabase}
                  label="Placement & Database"
                  onChange={(e) => setAccess({ ...access, placeAndDatabase: e.target.value })}
                >
                  <MenuItem value={'noaccess'}>No Access</MenuItem>
                  <MenuItem value={'limited'}>Limited Access</MenuItem>
                  <MenuItem value={'fullaccess'}>Full Access</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Scrollbar>
          <Stack sx={{ alignItems: 'center' }} gap={1} mt={2}>
            <Button color="custom" variant="contained" sx={{ width: 100 }} onClick={handleOpen}>
              save
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal open={del} onClose={closedel} style={{ borderRadius: 10 }}>
        <Box sx={[style, { width: '20%' }]}>
          <Stack sx={{ justifyContent: 'center', alignContent: 'center', width: '100%' }}>
            <Stack sx={{ width: '100%' }}>
              <Typography sx={{}} variant="h6" textAlign={'center'} color={'red'}>
                Would You want to Delete this admin ?
              </Typography>
            </Stack>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between', width: '25%', mt: 3, alignSelf: 'center' }}
            >
              <Button
                color="custom"
                sx={{}}
                variant="contained"
                size="small"
                onClick={() => {
                  closedel();
                  delAdmin(data._id);
                }}
              >
                Yes
              </Button>
              <Button color="custom" sx={{}} variant="contained" size="small" onClick={closedel}>
                No
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default Admins;
