import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
// import { styled } from '@mui/material/styles';
// import Iconify from '../../../components/iconify';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Icon } from '@iconify/react';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import moment from 'moment';
import {
  Input,
  Slide,
  IconButton,
  ClickAwayListener,
  InputBase,
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  useTheme,
  Switch,
  Checkbox,
} from '@mui/material';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomComplete from '../components/customComplete/CustomComplete';
import CustomCompleteWorksheet from '../components/customComplete/CustomCompleteWorksheet';
import Iconify from '../components/iconify';
// import Upload from '../components/upload';   
import Scrollbar from '../components/scrollbar/Scrollbar';

import { Api, UploadFile } from '../webservices';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf_viewer.js.map';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const tablebg = 'rgba(82, 130, 190, 0.08)';
const filter = createFilterOptions();
function BenchWorksheet(props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  function MyFormHelperText() {
    const { onFilled } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      if (onFilled) {
        return 'This field is being onFilled';
      }

      return 'Helper text11';
    }, [onFilled]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [askill, setAskill] = React.useState(null);
  const [finallst, setFinallst] = React.useState([]);
  const [tableData, setTableData] = useState([]);

  console.log("=====tableData===", tableData)

  const [row, setRow] = useState([]);
  console.log("!!!!!!!!!!!!!!!!!!!!========", row)
  const [resumee, setResumee] = useState([])
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [evalid, setEvalid] = useState(true);
  const [dupemail, setDupemail] = useState(false);
  const [tempskill, setTempskill] = useState([]);
  const [cityerr, setCityerr] = useState(false);
  const [joblst, setJoblst] = useState([]);
  console.log("joblst", joblst)
  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  const [parse, setParse] = useState(false);
  const parseOpen = () => setParse(true);
  const parseClose = () => setParse(false);
  const [tempList, setTempList] = useState([])

  const [emails, getEmails] = useState([]);
  console.log("=============emails", emails)
  console.log("resumee===>>>", resumee)

  const [emailDet, setEmailDet] = useState('')

  const detailByEmail = () => {
    const body = {
      "email":value.email,
    }
    const token = localStorage.getItem('token');
    Api('bench/prospectDataBasedOnMails', 'POST', body, token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          setEmailDet(response.data.result)
          const result = response.data.result
          setPdffile(result.resumeUrl)
          setPdffileDL(result.DL_File)
          setPdffileVisa(result.visaFile)
          setPdffileOtherDoc(result.otherFile)
          setPdffile94(result.i94_File)
          const skills = result?.skill?.map((item) => item.trim());
      setFinallst(skills);
      setFormValues1({...formValues1, dateofbirth:result.DOB})
      setFormValues({...formValues, validity:result.validity})
          setValue((prevValue) => ({
            ...prevValue,
            name: result?.fullName || '',
            jobtitle: result?.jobTitle || '',
            currCompany: result?.currentCompany || '',
            phone: result?.phone || '',
            visa: result?.visaType,
    tax: result?.taxTerm,
    linkedinUrl: result?.linkedinUrl,
    comType: result?.chargeType,
    expectedCom: result?.expectedCompensation,
    jobid:'',
    city: result?.cityOrZipcode,
    zip: result?.zipcode,
    reloc: result?.reallocate,
    notes: result?.note,
    fcity: '',
    scode: '',
    country: result?.country,
    userStatus: result?.userStatus,
    visaTrn:result?.visaTransfer,
    summary:result?.summary,
    yearExp:result?.experienceInYear

          }));
          if (response.data.code === 200) {
            // setTableData(response.data.data || 0)
            // // setRow(response.data.data[0])
            // hidePep()
            // setResour('')
            // setResourData(response.data.data);
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  const [value, setValue] = useState({
    email: '',
    name: '',
    jobtitle: '',
    currCompany: '',
    phone: '',
    visa: '',
    tax: '',
    linkedinUrl: '',
    comType: '',
    expectedCom: '',
    jobid: '',
    city: '',
    zip: '',
    reloc: '',
    notes: '',
    fcity: '',
    scode: '',
    country: 'USA',
    userStatus: 'active',
    visaTrn:'',
    summary:'',
    yearExp:''
    
  });

  useEffect(()=>{
    detailByEmail()
  },[value.email])

  console.log("-====finallst", finallst)

  const [newcv, setNewcv] = useState('no')
  const [cityy, setCityy] = useState('');
  const [statee, setStatee] = useState('');
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  const [pdffile, setPdffile] = useState(null);
  const [pdffileDL, setPdffileDL] = useState(null);
  const [pdffileVisa, setPdffileVisa] = useState(null);
  const [pdffileOtherDoc, setPdffileOtherDoc] = useState(null);
  const [pdffile94, setPdffile94] = useState(null);

  // const [pdffile, setPdffile] = useState(null);
  const [docfile, setDocfile] = useState('');
  const [docfile1, setDocfile1] = useState('');
  const [docfile2, setDocfile2] = useState('');
  const [docfile3, setDocfile3] = useState('');
  const [docfile4, setDocfile4] = useState('');
  const [docval, setDocval] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [choosefile, setChoosefile] = useState(false);
  const [choosefile1, setChoosefile1] = useState(false);
  const [choosefile2, setChoosefile2] = useState(false);
  const [choosefile3, setChoosefile3] = useState(false);
  const [choosefile4, setChoosefile4] = useState(false);
  const [searchResult, setSearchResult] = React.useState();
  
  const [mainTab, setMainTab] = useState('recruiter');
  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
    { label: 'Permanent Resident' },
  ];

  const [emailerr, setEmailerr] = useState(false);
  const [nameerr, setNameerr] = useState(false);
  const [jobterr, setJobterr] = useState(false);

  const handlePage = (event, value) => {
    console.log(value)

    setPageNumber(value);
    if (valuee === 'worksheet') {
      getResume(value)
    } else if (valuee === 'favourites') {
      getFav(value)
    } else {
      handleChange(valuee, value)
    }
  };

  const [valuee, setValuee] = React.useState('worksheet');

  useLayoutEffect(() => {
    // console.log('dependency', location.state);
    if (location.state) {
      // setValuee(location.state);
      handleChange(location.state);
    } else {
      setValuee('worksheet');
      getResume();
    }
  }, [location.state]);

  const getTemplList = () => {
    const token = localStorage.getItem('token');
    // console.log("token ===>>>>> ",token)
    Api('email/getEmailTemplateList', 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            //  console.log("hhh")

          } else {
            console.log('data:', response.data.message);
          }
        }
        setTempList(response.data.result);
        // setUserId(response.data.result.userId)
        // setCompanyId(response.data.result.companyId)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    // setValuee('worksheet')
    // setValuee('worksheet');
    // getResume();
    getTemplList()
    getSkills();
    GetJobId();  
    
  }, []);

  const [tableCount, setTableCount] = useState([])

  console.log("tableCount", tableCount)

  const handleChange = (newValue, value) => {
    setValuee(newValue);
    setTableData([]);
    setTable11([]);
    setLoading("Loading...")
    setToggleButton(false)
    setTableCount([])
    const body = {
      "status": newValue,
      "page": value || 1,
      "limit": "35"
    }
    const token = localStorage.getItem('token');
    // Api(`resume/resume_by_status`, 'POST', body, token)
    Api(`bench/getBenchDataByStatus`, 'POST', body, token)
      .then((response) => {
        // console.log('=========getCompany======>>>>>>>', response.data.message)
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableCount(response.data.count)
            
            if (response.data.data.length !== 0) {
              // setRow(response.data.data[0]);
              setTableData(response.data.data || 0);
              setTable11(response.data.data || 0);
              setLoading("")

              const totalPage = Math.ceil(response.data.count / 35)
              setPages(totalPage)
              console.log('======resume_by_status======>>>>>>>', response.data.data);
            } else {
              setLoading("No Data Found")
              setTableData([]);
              setLoading("")
            }
          } else {
            setTableData([]);
            setLoading("")
            setLoading("No Data Found")
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  // useEffect(()=>{
  //   getInactiveList()
  // }, [])

  const getInactiveList = () => {
    setValuee("inactive")
    setTable11([]);
    setLoading("Loading...")
    const token = localStorage.getItem('token');
    // console.log("token ===>>>>> ",token)
    Api('bench/InactiveProspectList', 'GET', '', token)
      .then((response) => {
        console.log('Response inact:---------', response);
        if (response.status === 200) {
          if(response.data.result?.length !== 0) {
             setTable11(response.data.result);
            //  alert("if")
             setLoading("")
           } else {
            // alert("else")
            setLoading("No Data Found")
           }
          // if(response.data.result?.length === ""){
            
            // setLoading("No Data Found")
          // }
          // setValuee("inactive")
          if (response.data.code === 200) {
            //  console.log("hhh")

          } else {
            console.log('data:', response.data.message);
            
          }
        } else {
          
          setLoading("No Data Found")
        }
        // setUserId(response.data.result.userId)
        // setCompanyId(response.data.result.companyId)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const adminrole = useSelector((state) => state.userData?.data?.role);
  const cities = [];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    // width: { xs: '85%', sm: '60%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    // backgroundColor: 'secondary',
    ...theme.typography.button,
    padding: theme.spacing(0.5),
    // textAlign: 'center',
    color: 'white',
  }));

  const delfile = () => {
    console.log('doc file', docfile);
    setDocfile('');
    // setDocfile('')
    setPdffile('');
    console.log('doc file------', docfile);
    console.log('doc value------', docval);
  };
  
   const delfileDL = () => {
    console.log('doc file', docfile);
    setDocfile1('');
    // setDocfile('')
    setPdffileDL('');
    // console.log('doc file------', docfile);
    // console.log('doc value------', docval);
  };
   const delfileVisa = () => {
    console.log('doc file', docfile);
    setDocfile2('');
    // setDocfile('')
    setPdffileVisa('');
    // console.log('doc file------', docfile);
    // console.log('doc value------', docval);
  };
   const delfileOtherDoc = () => {
    console.log('doc file', docfile);
    setDocfile3('');
    // setDocfile('')
    setPdffileOtherDoc('');
    // console.log('doc file------', docfile);
    // console.log('doc value------', docval);
  };
   const delfile94 = () => {
    console.log('doc file', docfile);
    setDocfile4('');
    // setDocfile('')
    setPdffile94('');
    // console.log('doc file------', docfile);
    // console.log('doc value------', docval);
  };

  const GetJobId = () => {
    const id = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    Api(`company/active_jobs`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };
  //  const [candiadteId, setCandidateId] = useState()

  const [table11, setTable11] = useState([])
  console.log("===========table11", table11?.length)


  const getResume = async (value) => {
    // setTableData([])
    setToggleButton(false)
    setTableCount([])
    setTable11([]);
    // setLoading("Loading...")
    setLoading("Loading...")
    const token = localStorage.getItem('token');
    const body = {
      "page": value || 1,
      "limit": "35"
    }
    
    // Api(`resume/get_all_resumes`, 'POST', body, token)
    
    Api(`bench/getBenchWorksheetList`, 'POST', body, token)
      .then((response) => {
        // alert("h")
        // console.log('=========get all resume======>>>>>>>', response);
      
        if (response.status === 200) {
          // alert("g")
          // setTableData("response.data.result");
          setTable11(response.data.result);
          setLoading('')
          setTableCount(response.data.count)
          const totalPage = Math.ceil(response.data.count / 35)
          setPages(totalPage)
          // alert("ggg")
          if (response.data.code === 200) {
            setTableCount(response.data.count)
            if (response.data.result.length !== 0) {
              // toast.success(response.data.message, +'!');
              // setTableData(response.data.result || 0);
              setLoading('')
              // setRow(response.data.result[0]);
              // const Dataa = response.data.result;
              // const allAdditionalResumes = Dataa.map((item) => {
              //   return (
              //     {item.additionalResumes}
              //   )                                         
              // })   
              // setResumee(allAdditionalResumes);
              const totalPage = Math.ceil(response.data.count / 35)
              setPages(totalPage)
              // setValuee('worksheet')
              console.log('============>>>>>>>', Math.ceil(response.data.count / 30));
            } else {
              // setLoading('No Data Found')
              // setTableData([]);
              // toast.warn('Data Not Found');
            }
          } else {
            // setTableData([]);
            // setLoading('No Data Found')
            toast.warn(response.data.message);
          }
        } else {
          toast.warn(response.data.message, +'!');
          setLoading('No Data Found')
          // alert("hello")
        }
        // if(response.status !== 200){
          // setLoading('No Data Found')
          // alert("hello")
        // }
      })
     
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
        
      });
      // alert("h")
// if(response.status !== 200){
//   setLoading('No Data Found')
// }
  };

  const getFav = async () => {
    setToggleButton(false)
    setTableCount([])
    setTableData([])
    setLoading("Loading...")
    const token = localStorage.getItem('token');
    const body = {
      "page": pageNumber,
      "limit": "35"
    }
    Api(`resume/get_favourites`, 'POST', body, token)
      .then((response) => {
        console.log('=========get all resume======>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableCount(response.data.count)
            if (response.data.result.length !== 0) {
              // toast.success(response.data.message, +'!');
              setTableData(response.data.result || 0);
              setLoading("")
              // setRow(response.data.result[0]);
              const totalPage = Math.ceil(response.data.count / 35)
              setPages(totalPage)
              // setValuee('worksheet')
              console.log('============>>>>>>>', response);
            } else {
              setLoading("No Data Found")
              setTableData([]);
              // toast.warn('Data Not Found');
            }
          } else {
            setTableData([]);
            toast.warn(response.data.message);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const pdfUpload = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setNewcv('yes')
          setChoosefile(true);
          setDocfile('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  const pdfUploadDL = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'benchDocuments');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile1(true);
          setDocfile1('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileDL(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  const pdfUploadVisa = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'benchDocuments');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile2(true);
          setDocfile2('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileVisa(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  const pdfUploadOtherDoc = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'benchDocuments');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile3(true);
          setDocfile3('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileOtherDoc(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  const pdfUpload94 = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'benchDocuments');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile4(true);
          setDocfile4('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile94(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  const addcv = async (e) => {
    // alert("gg")
    const skillLst = [];
    finallst?.map((item) => {
      return skillLst?.push(item.title?.toLowerCase());
    });

  //   if (value.email !== '') {
  //     toast.error("Email is Required");
  //     return;
  // }
  //   if (value.name !== '') {
  //     toast.error("Full name is Required");
  //     return;}

  //     if (value.jobtitle !== '') {
  //       toast.error("Job title is Required");
  //       return;
  //     }

    // alert("gg")
    // console.log('lst', skillLst);
    if (value.email !== '') {
      if (value.name !== '') {
        if (value.jobtitle !== '') {
          const token = localStorage.getItem('token');
          const body = {
            // "newCV": newcv,
            email: value.email,
            fullName: value.name,
            jobTitle: value.jobtitle,
            phone: value.phone,
            currentCompany: value.currCompany,
            skill: skillLst,
            visaType: value.visa,
            visaTransfer: value.visaTrn,
            taxTerm: value.tax,
            chargeType: value.comType,
            expectedCompensation: value.expectedCom,
            cityOrZipcode: cityandstate.city,
            linkedinUrl: value.linkedinUrl,
            reallocate: value.reloc,
            zipcode: value.zip,
            // stateCode: value.scode,
            stateCode: cityandstate.stateCode,
            resumeUrl: pdffile,
            state:cityandstate.state,
            visaFile:pdffileVisa,
            otherFile:pdffileOtherDoc,
     i94_File:pdffile94,
     DL_File:pdffileDL, 
            // jobId: value.jobid,
            note: value.notes,
            // status: 'prospect',
            userStatus: value.userStatus,
            country: value.country,
            validity: formValues.validity,
            DOB:formValues.dateofbirth,
            summary:value.summary,
            experienceInYear:value.yearExp
          };
          // console.log('=====create_resume==========>>>>>>>', finallst);
          // Api(`resume/create_resume`, 'POST', body, token)
          Api(`bench/addNewBenchProspect`, 'POST', body, token)
            .then((response) => {
              console.log('=====create_resume==========>>>>>>>', response);
              if (response.status === 200) {
                if (response.data.code === 200) {
                  setChoosefile(false);
                  handleClose1();
                  toast.success(response.data.message, +'!');
                  // setValuee('worksheet');
                  getResume();
                  setValue({
                    email: '',
                    name: '',
                    jobtitle: '',
                    currCompany: '',
                    phone: '',
                    visa: '',
                    tax: '',
                    comType: '',
                    expectedCom: '',
                    jobid: '',
                    city: '',
                    reloc: '',
                    notes: '',
                    zip: '',
                    validity: '',
                    visaTransfer:"",
            DOB:'',
            summary:'',
            yearExp:'',
                  });
                  getEmails([]);
                  setFinallst([]);
                  setCityandstate('');
                  setCityAndZip([]);
                  setDocfile();
                  setDocfile1();
                  setDocfile2();
                  setDocfile3();
                  setDocfile4();

                  setPdffile('');
                  setPdffileDL('');
                  setPdffileVisa('');
                  setPdffileOtherDoc('');
                  setPdffile94('');

                  // getResume();
                  // setcityData([]);
                  setCityy('');
                  // getCompanyDetail()
                  // setTableData(response.data.data)
                } else if (response.data.code === 400) {
                  setChoosefile(false);
                  toast.warn(response.data.error, +'!');
                  // setValuee('worksheet');
                  // toast.success(response.data.message, +'!');
                  handleClose1();
                  getResume();
                  setValue({
                    email: '',
                    name: '',
                    jobtitle: '',
                    currCompany: '',
                    phone: '',
                    visa: '',
                    tax: '',
                    comType: '',
                    expectedCom: '',
                    jobid: '',
                    city: '',
                    reloc: '',
                    notes: '',
                    zip: '',
                  });
                  getEmails([]);
                  setFinallst([]);
                  setCityandstate('');
                  setCityAndZip([]);
                  setDocfile();
                  setDocfile1();
                  setDocfile2();
                  setDocfile3();
                  setDocfile4();

                  setPdffile('');
                  setPdffileDL('');
                  setPdffileVisa('');
                  setPdffileOtherDoc('');
                  setPdffile94('');
                }
              } else {
                toast.warn(response.data.message, +'!');
              }
            })
            .catch((err) => {
              console.log('===========', err);
            });
        } else {
          setJobterr('true');
          toast.error("Job Title is Required");
        }
      } else {
        setNameerr('true');
        toast.error("Full name is Required");
      }
    } else {
      setEmailerr('true');
      toast.error("Email is Required");
    }
  };
  
  const funclick = () => {
    getResume();
  };

  const funclick22 = () => {
    if(valuee === "submitted"){
      handleChange('submitted');
      setValuee('submitted');
    } else if (valuee === "interview"){
        handleChange('interview'); 
        setValuee('interview');
    } else if (valuee === "placement"){
        handleChange('placement');
        setValuee('placement');
    } else if (valuee === "backout"){
        handleChange('backout');
        setValuee('backout');
    } else if (valuee === "inactive"){
        handleChange('inactive');
        setValuee('inactive');
    }
  };
  
  const funclick2 = () => {
    setValuee('submitted');
    // setValuee('placement');
    handleChange('submitted');
    // handleChange('placement');
  };

  const fun3 = () => {
    setValuee('favourites');
    // getFav();

  };

  const getInactiveList1 = () => {
    getInactiveList()
    setValuee('inactive');
  }

  const getActiveList = () => {
    getResume()
    setValuee('worksheet');
  }

  const handleEmail = (e) => {
    console.log('======================true=====1=============>>>>>>>>>', evalid);
    emails?.forEach((item) => {
      if (item === e) {
        getDataByEmail(item);
      }
    });
    setValue({ ...value, email: e });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e));
    // setTimeout(() => {
    if (evalid === true) {
      setEmailerr(false);
      console.log('=======email valid', evalid);
    } else {
      console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    console.log('======================true==================>>>>>>>>>', evalid);
  };

  const [addedSkill, setAddedSkill] = useState([])
  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data?.result?.map((item) => {
              return tempskill?.push({ title: item?.skillName });
            });
            setSkill(tempskill);
            setAddedSkill(tempskill)
            console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill([{ title: '' }]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getCity = async (val) => {
    // console.log("value.country===>>", value.country)
    setCityAndZip([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            // setCityandstate(response.data.data[0]);

            // if (response.data.data[0].zip) {
            //   setValue({ ...value, zip: response.data.data[0].zip, scode: response.data.data[0].stateCode });
            // } else {
            //   setValue({ ...value, zip: '' });
            // }
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            toast.error(response.data.message)
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getDataByEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`resume/resume_by_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('getDataByemail========', response.data);
            // const abc = response.data.result.city
            // getCity(abc)
            setValue({
              ...value,
              city: response.data.result.city,
              notes: '',
              jobid: response.data.result.jobId,
              reloc: response.data.result.reallocate,
              expectedCom: response.data.result.expectedCompensation,
              tax: response.data.result.taxTerm,
              visa: response.data.result.visaType,
              currCompany: response.data.result.currentCompany,
              phone: response.data.result.phone,
              jobtitle: response.data.result.jobTitle,
              email: response.data.result.email,
              name: response.data.result.fullName,
              zip: response.data.result.zipcode,
              linkedinUrl: response.data.result.linkedinUrl,
              country: response.data.result.country,
              userStatus: response.data.result.userStatus,
            });
            setCityy(response.data.result.city);
            setPdffile(response.data.result.resumeUrl);
            setCityandstate({ ...cityandstate, city: response.data.result.city, state: response.data.result.state });
            const arr = [];
            response.data.result.skill.forEach((item) => {
              arr.push({ title: item });
            });
            setFinallst(arr);
            if (response.data.result.resumeUrl) {
              setChoosefile(true);
            }
          } else {
            console.log('getDataByemail========', response.data);
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getEmail = async (val) => {
    console.log("val============", val)
    // const body = {
    //   email:val
    // }
    const token = localStorage.getItem('token');
    // Api(`bench/getBenchDetailsByEmail`, 'POST', body, token)
    // Api(`resume/check_existing_mail/${val}`, 'GET', '', token)
    Api(`bench/prospectCheckExistingMails/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('city========', JSON.stringify(response.data));
            getEmails(response.data.data);
          } else {
            getEmails(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };


  const childFuc = (e) => {
    // setCityy(e);
    setCityandstate({ ...cityandstate, city: e });

    if (e.length >= 3) {
      getCity(e);
    } else if (e.length === 0) {
      setCityy('');
      setCityAndZip([]);
      setCityandstate({});
    }
  };
  // const[postal, setPostal] = useState('')
  const fullVal = (val) => {
    // setPostal(val.postal)
    console.log("################===========", val.postal);
    setCityandstate(val);
    setCityy(val.city);
    setValue({ ...value, zip: val.zip || val.postal });
    // setValue({ ...value, city: val.city });
    // setCityy(val.city);
  };

  const childFucEmail = (e) => {
    // alert("j")
    handleEmail(e);
    if (e.length >= 3) getEmail(e);
  };

  const fullEmail = (val) => {
    console.log(val);
    handleEmail(val);

    // setCityy(val.city);
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setValue({
          ...value,
          phone: e,
        });
      }
    } else {
      setValue({
        ...value,
        phone: e,
      });
    }
  };
  const [rotate, setRotate] = useState()
  const [inputExcel, setInputExcel] = useState([]);
  const [fileInputFiles, setFileInputFiles] = useState([]);
  const [dragDropFiles, setDragDropFiles] = useState([]);
  // const Rotate = () =>{
  //   return (
  //     // alert("hhhh")
  //     <IconButton>
  //      <Iconify icon="eos-icons:arrow-rotate" width="3rem" color="red"/>
  //      </IconButton>
  //   )
  // } 
  const [circle, setCircle] = useState(false)

  const circleFn = () => {
    setCircle(true)
  }

  const circleFn1 = () => {
    setCircle(false)
  }

  const handleFileSelect = (e) => {
    const files = e.target.files;
    const fileList = Array.from(files);
    console.log('files browse', fileList)

    parseClose()
    circleFn()
    // Rotate()
    // toast.info('Resume is parsing...');
    setFileInputFiles(fileList);
    fileList?.map((item) => uploadMulti(item))
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const fileList = Array.from(files);
    console.log('files drop', fileList)

    setFileInputFiles(fileList);
    fileList?.map((item) => uploadMulti(item))
  };

  const [pinned, setPinned] = useState(false)
  const searchDataFun = (data) => {
    setTableData(null)
    setTable11(null)
    if (valuee !== 'worksheet') {
      setValuee('worksheet')
    }
    setTable11(data || 0)
    setSearchResult(data?.length)
    // setPinned(true)
  }

  const [parseres, setParseRes] = useState('')

  const uploadMulti = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          parseClose()
          circleFn()
          // setParseRes(Response.data.filePath)
          ParseRes(Response.data.filePath)
          console.log('=====404=gst===', Response.data.filePath);

        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  console.log("filepath ======>>>>>>", parseres)

  const [cityStateStyling, setCityStateStyling] = useState(true)

  const ParseRes = (parseres) => {

    const body = {
      "resumeUrl": parseres
    }
    const token = localStorage.getItem("token")
    Api('common/cv/data', 'POST', body, token).then((Response) => {
      console.log("Response", Response)
      if (Response.status === 200) {

        if (Response.data.code === 403) {
          // setCityStateStyling(true)
          setRow(Response.data.result);
          getResume();
          circleFn1();
          navigate('/benchresumedetails', {
            state: {
              addedSkill,
              cityStateStyling,
              row: Response.data.result,
              kTab: "Hide",
              mtab: 'Detail',
              fTab: "submitted",
            },
          });
        } else if (Response.data.code === 200) {
          setRow(Response.data.result);
          getResume();
          circleFn1();
          navigate('/benchresumedetails', {
            state: {
              addedSkill,
              row: Response.data.result,
              kTab: "Hide",
              mtab: 'Detail',
              fTab: "submitted",
            },
          });
        } else {
          circleFn1();
          // toast.warn("A resume with the same email already exists");
          toast.warn(Response.data.error);
          getResume();
        }
      } else {
        toast.warn('Error!');
        circleFn1();
      }
    })

  }

  const handleFileSelectExcel = (e) => {
    const files = e.target.files;
    const fileList = Array.from(files);
    console.log('files browse', fileList)

    setInputExcel(fileList);
    fileList.map((item) => uploadCSV(item))
  };

  const uploadCSV = async (csvFile) => {
    // alert("jjj")
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('directoryName', 'csv');

    try {
      const response = await UploadFile('resume/add_resumes/byfile', formData, token);
      // alert("uuu")
      if (response.status === 200) {
        if (response.data.code === 200) {
          setCityStateStyling(true)
          getResume();
          parseClose();
          // circleFn()
          setRow(response.data.result)
          // setTimeout(() => {
          navigate('/benchresumedetails', {
            state: {
              addedSkill,
              cityStateStyling,
              row: response.data.result, 
              mtab: 'Detail',
              kTab: "Hide",
              fTab: "submitted",

            },
          })
          // }, 500);
        } else {
          toast.warn(response.data.error);
          parseClose();
        }
      } else {
        toast.warn('Error!');
      }
    } catch (error) {
      console.error('Upload failed:', error);
      // toast.warn('Upload failed!');
    }
  };


  const resetDataFun = () => {
    getResume();
    setPinned(false)
    // setToggleButton(false)
  }

  const [toggleButton, setToggleButton] = useState(false)
  const resetDataFun1 = () => {
    // getResume();
    // setPinned(false)
    // if(valuee === "worksheet"){
    //   getResume()
    // }
    // if(valuee === "favourites"){
    //   getFav();
    // }
    if (valuee === "placement") {
      handleChange("placement")
    }
    else if (valuee === "interview") {
      handleChange("interview")
    }
    else if (valuee === "submitted") {
      handleChange("submitted")
    }
    else if (valuee === "backout") {
      handleChange("backout")
    }
    setToggleButton(false)
  } 

  console.log("fileInputFiles", fileInputFiles)
  console.log("inputExcel", inputExcel)

  const [tableShow, setTableShow] = useState(true)

  const hidePage = () => {
    // alert('hello')
    setTableShow(false)
  }

  const showtable = () => {
    setTableShow(true)
  }

  const [loading, setLoading] = useState("")

  useEffect(() => {
    allUsers()

  }, [loading]);

  const [pepShow, setPepShow] = useState(false)

  const hidePep = () => {
    // alert('hello')
    setPepShow(false)
  }

  const showPep = () => {
    setPepShow(true)
  }

  const [resour, setResour] = useState('')
  const [resourData, setResourData] = useState([])

  const allUsers = () => {
    const token = localStorage.getItem('token');
    Api('common/users/RCO', 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setResourData(response.data.data);
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handleChangeRes = (e) => {
    const resourceId = e.target.value;
    setResour(resourceId);
    setToggleButton(true)
    setDataaa(false)
  };

  const searchReso = (resourceId) => {
    const body = {
      "status": valuee,
      "resource": resourceId
    }
    const token = localStorage.getItem('token');
    Api('common/search/submissions', 'POST', body, token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableData(response.data.data || 0)
            // setRow(response.data.data[0])
            hidePep()
            setResour('')
            // setResourData(response.data.data);
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (resour !== '') {
      searchReso(resour);
    }
  }, [resour]);


  const [data, setData] = useState(null);
  const [dataaa, setDataaa] = useState(true);

  console.log("-----------data", data)

  const handleDataFromChild = (childData) => {
    setData(() => childData);
    if (data) {
      setDataaa(false)
    }
  };

  const callReceivedFunction = () => {
    if (data) {
      data();
    }
  };

  const [formValues, setFormValues] = useState({
    validity: "",
    
  });

  const [formValues1, setFormValues1] = useState({
    dateofbirth: "",
  });

  // console.log("formValues.fromDate", formValues.validity)
  // console.log("formValues.fromDate 1", formValues.dateofbirth)

  const handleDateChange = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setFormValues((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  const handleDateChange1 = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setFormValues1((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  useEffect(()=>{
    recAndCompList()
  },[])

  const [dropComp1, setDropComp1] = useState([])
  const recAndCompList = () => {  
    // alert(term)
    // const body = {
      //    "recruitorName":rec,
      //    "recruitorCompany":comp
      // }
    const token = localStorage.getItem('token');
    Api(`bench/recruitorDropDownList`, 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) { 
          setDropComp1(response.data.result);
          // setDropRec(response.data.result);
          if (response.data.code === 200) {
            console.log('response.data.result', response.data.result)
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error); 
      });
  };

  return (
    <>
      <Search searchData={searchDataFun} func={resetDataFun} funcc={hidePage} funnc={showtable}
        onData={handleDataFromChild} resetSearch={()=>setSearchResult('')} />
      {toggleButton && (
        <Button color="custom" variant="contained" size="small"
          style={{ marginLeft: '275px', marginTop: "12px", position: "absolute" }}
          onClick={resetDataFun1}
        >
          Close
        </Button>

      )}
      <Modal
        open={circle}
        // onClose={circleFn1}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          textAlign: 'center'
        }}>
          <h2 id="simple-modal-title">Parsing resume, Please wait...</h2>
          {/* <p id="simple-modal-description">Modal content goes here.</p> */}
          <Iconify icon="eos-icons:arrow-rotate" width="5rem" color="green" />
          {/* <CircularProgress style={{ display: open ? 'block' : 'none', marginTop: '20px' }} /> */}
        </div>
      </Modal>
      <Modal
        open={pepShow}
        onClose={hidePep}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '33%',
          left: '35%',
          width: "290px",
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          // textAlign: 'center',
          border: " 2.5px solid #1E74B3"
        }}>
          {/* <Grid item xs={4}> */}
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel >Resource</InputLabel>
            <Select
              // labelId="resource-label"
              label="Resource"
              value={resour}
              // onChange={(e) => {setResour(e.target.value); searchReso()}}
              onChange={handleChangeRes}

            >
              {resourData?.map((item, index) => (
                <MenuItem key={index} value={item._id}>
                  {item.contactPersonName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* </Grid> */}

        </div>
      </Modal>
      <ToastContainer />
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Typography variant="h5" color="textSecondary">
          Bench Worksheet
          {valuee !== "worksheet" && valuee !== "favourites" && (
            <IconButton sx={{ marginLeft: "5px", marginTop: "-1px" }} onClick={showPep}>
              {/* <Iconify icon="pepicons-pencil:people" width="1.8rem" color="#1E74B3"/> */}
              <Iconify
                icon="bxs:down-arrow"
                color="#1E74B3"
              />
            </IconButton>
          )}

        </Typography>

        <Stack direction="row" spacing={1}>
          <Button color="custom" variant="contained" size="small" onClick={handleOpen1}>
            Add New Prospect
          </Button>
          <Button color="custom" variant="contained" size="small" 
          // onClick={parseOpen}
          onClick={()=>navigate('/benchmanagement')}
          >
            Add Recruiters
          </Button>
        </Stack>
      </Stack>
      {!tableShow && (

<Typography sx={{float: "right", mt:0.5}} fontSize={"0.85rem"}color={'text.secondary'}>
  Total: {searchResult || "0"}
</Typography>
)}
      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignItems: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row', alignItems: 'center' },
            valuee === 'worksheet' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            // navigate('/worksheet');
            navigate('/benchworksheet');
            setPageNumber(1)
            getResume();
            setValuee('worksheet');
            // setLoading(true)
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={17} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Prospects
          </Typography>
        </Stack>
        {/* <Stack
          sx={[
            { flexDirection: 'row', alignItems: 'center' },
            valuee === 'favourites' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            // navigate('/worksheet',{ state: 'favourites' })
            setPageNumber(1);
            getFav();
            setValuee('favourites');
            // setLoading(true)
          }}
        >
          <Iconify
            icon="ph:heart"
            fontSize={17}
            color={valuee === 'favourites' ? { color: '#CB4335' } : { color: 'text.textG' }}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Favourites
          </Typography>
        </Stack> */}
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'submitted' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          // onClick={() => handleChange('submitted')}
          onClick={() => { navigate('/benchworksheet', { state: 'submitted' }); setPageNumber(1) }}
        >
          <Iconify
            icon="iconamoon:star-light"
            fontSize={17}
            color={valuee === 'submitted' ? '#B7950B' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Submissions
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'interview' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { navigate('/benchworksheet', { state: 'interview' }); setPageNumber(1) }}
        >
          <Iconify icon="ps:check-box" fontSize={17} 
          color={valuee === 'interview' ? '#F4D03F' : 'text.textG'} 
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Interviews
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'placement' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { navigate('/benchworksheet', { state: 'placement' }); setPageNumber(1) }}
        >
          <Iconify
            icon="mingcute:target-line"
            sx={{ transform: 'rotate(270deg)' }}
            fontSize={17}
            color={valuee === 'placement' ? '#2ECC71' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Placements
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'backout' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { navigate('/benchworksheet', { state: 'backout' }); setPageNumber(1) }}
        >
          <Iconify icon="bx:error" fontSize={17} 
          color={valuee === 'backout' ? '#CB4335' : 'text.textG'} 
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Backouts
          </Typography>
        </Stack>

        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'inactive' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => getInactiveList()
            // navigate('/benchworksheet', { state: 'inactive' }); setPageNumber(1) 
          }
        >
          <Iconify icon="icomoon-free:blocked" width="1rem"
          
          color={valuee === 'inactive' ? '#CB4335' : 'text.textG'}
           />
         
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Inactives
          </Typography>
        </Stack> 

      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
              { 
  (valuee === "submitted" || valuee === "placement" || valuee === "interview" || valuee === 'backout') 
    ? "Candidate Details" 
    : "Detail" 
}
  </TableCell>

                <TableCell 
                sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}
                >
                  {
                (valuee === "submitted" || valuee === "placement" || valuee === "interview" || valuee === 'backout') 
  
                ?"Summary":"Contact Details" }
                
                </TableCell>
              {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Contact Details</TableCell> */}
              {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Skills</TableCell> */}
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                {
                 (valuee === "submitted" || valuee === "placement" || valuee === "interview" || valuee === 'backout') 
  
                ?"Client Details":"Skills" }
                
                </TableCell>
                <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                {
                (valuee === "submitted" || valuee === "placement" || valuee === "interview" || valuee === 'backout') 
  
                ?"Job Info":"Visa & Terms" }
                
                </TableCell>
              {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Visa & Terms</TableCell> */}
              <TableCell sx={{ p: 1, py: 0.5,color: 'white', width: '12%' }}>
                {
                (valuee === "submitted" || valuee === "placement" || valuee === "interview" || valuee === 'backout') 
  
                ?"Recruiter's Details":"Summary" }
                 
                </TableCell>
              {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Summary</TableCell> */}
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Status</TableCell>
              {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Job Info</TableCell> */}
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {tableData.map((item, index) => ( */}
            {table11?.map((item, index) => (
              <ResumeRow
                key={item._id}
                index={index}
                row={item}
                fun={funclick}
                fun22={funclick22}
                fun2={funclick2}
                skillsett={skill}
                joblst1={joblst}
                filterTab={valuee}
                fun3={fun3}
                getInactiveList1={getInactiveList1}
                getActiveList={getActiveList}
                pinned={pinned}
                tempList={tempList}
                addedSkills={addedSkill}
                // Edit={Edit}
                favFun={getFav}
                dropComp={dropComp1}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {loading && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'blue' }}>Loading...</Typography>
        </Stack>
      )} */}
      {loading !== "" && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>{loading}</Typography>
        </Stack>
      )}

      {/* {tableData.length < 1 && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )} */}

      {/* {tableData.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage} />
        </Stack>
      ) : null} */}
      {tableShow && dataaa && (
        <>
          {tableCount > 35 ? (
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2
              }}
            >
              {/* {pageNumber !== 1 && ( */}
              <Button
                variant="outlined"
                sx={{
                  minWidth: 16, height: 32, borderRadius: '5px',
                  mt: 2,
                  ml: 0.5, cursor: 'pointer'
                }}
                onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
              >
                {"<"}
              </Button>
              {/* )} */}

              <Pagination
                variant="outlined"
                shape="rounded"
                color="secondary"
                hideNextButton
                hidePrevButton
                count={pages}
                page={pageNumber}
                onChange={handlePage}
                sx={{ cursor: 'pointer', mb: (pageNumber !== 1) ? -4 : -2 }}
              />
              {/* {pageNumber !== pages && ( */}
              <Button
                variant="outlined"
                sx={{
                  minWidth: 16, height: 32, borderRadius: '5px', mr: 1,
                  mt: -2,
                  cursor: 'pointer'
                }}
                onClick={() => setPageNumber(prev => Math.min(prev + 1, pages))}
              >
                {">"}
              </Button>
              {/* )} */}
            </Stack>

          ) : null}
        </>
      )}

      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={style}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            New Candidate
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider sx={{ mb: 2 }} />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <CustomComplete
                  err={emailerr}
                  req={'true'}
                  placeholder={'Email'}
                  option={emails}

                  propVal={value?.email}
                  childFuc={childFucEmail}
                  fullVal={fullEmail}
                  fetch={'email'}
                  // onChange={()=>alert("hi")}
                />
                <FormControl fullWidth>
                  {/* <TextField
                    required
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                    onChange={(e) => handleEmail(e)}
                    onFocus={(e) => setEvalid(false)}
                    onBlur={(e) => (evalid === true ? chkmail() : null)}
                    // onChange={(e) => setValue({...value, email:e.target.value})  }
                  /> */}
                  {evalid === false ? (
                    <Stack sx={{ ml: 1 }}>
                      <Typography color={'red'} variant="body">
                        This is not vaild mail
                      </Typography>
                    </Stack>
                  ) : null}
                  {dupemail === true ? (
                    <Stack sx={{ ml: 1, mt: 1, flexDirection: 'row' }}>
                      <Typography color={'red'} variant="body">
                        Duplicate Record Found
                      </Typography>
                      <Typography color={'red'} variant="body" sx={{ ml: 2 }}>
                        -
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        variant="body"
                        sx={{ ml: 2, cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Click here to view
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl>

                <TextField
                  id="outlined-basic"
                  required
                  // size='small'
                  label="Full Name"
                  placeholder="Full Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.name}
                  onChange={(e) => {
                    setValue({ ...value, name: e.target.value });
                    if (e.target.value.length) {
                      setNameerr(false);
                    } else {
                      setNameerr('true');
                    }
                  }}
                />
                <TextField
                  required
                  id="outlined-basic"
                  label="Job title"
                  placeholder="Job title"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.jobtitle}
                  onChange={(e) => {
                    setValue({ ...value, jobtitle: e.target.value });
                    if (e.target.value.length) {
                      setNameerr(false);
                    } else {
                      setNameerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Current Company"
                  placeholder="Current Company"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.currCompany}
                  onChange={(e) => setValue({ ...value, currCompany: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.phone}
                  onChange={(e) => setContact(e.target.value)}
                // type="text"
                // inputProps={{
                //   maxLength: 20,
                // }}
                // type="number"
                // onChange={(e) => setValue({ ...value, phone: e.target.value })}
                />

                <Autocomplete
                  disablePortal
                  // id="combo-box-demo"
                  options={visalist}
                  value={value?.visa}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setValue({ ...value, visa: e.target.innerText });
                    // console.log(e.target.innerText);
                  }}
                  renderInput={(params) => <TextField {...params} label="Visa" />}
                />

{!['USC', 'GC', 'GCEAD', 'TN', 'Canadian citizen', 'Permanent Resident'].includes(value?.visa) 
                && (
 
<FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Visa Transfer</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="yes"
                    name="radio-buttons-group"
                    value={value.visaTrn}
                    onChange={(e) => setValue({ ...value, visaTrn: e.target.value })}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    {/* <FormControlLabel value="1099" control={<Radio />} label="1099" />
                    <FormControlLabel value="T4" control={<Radio />} label="T4" />
                    <FormControlLabel value="INC" control={<Radio />} label="INC" />
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" /> */}
                  </RadioGroup>
                </FormControl>
)}
                {!['USC', 'GC', 'GCEAD', 'TN', 'Canadian citizen', 'Permanent Resident'].includes(value?.visa) 
                && (
 
                <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Validity"
                      // slotProps={{ textField: { size: "small" } }}
                      value={formValues.validity ? dayjs(formValues.validity) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange(date, 'validity')}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
)}

                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Contract (Tax Term) / Fulltime</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="c2c"
                    name="radio-buttons-group"
                    value={value.tax}
                    onChange={(e) => setValue({ ...value, tax: e.target.value,
                      comType: e.target.value === "fulltime"?"Annually":"hourly"
                     })
                    
                  }

                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >
                    <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                    <FormControlLabel value="c2h" control={<Radio />} label="C2H" />
                    {/* <FormControlLabel value="w2" control={<Radio />} label="W2" />
                    <FormControlLabel value="1099" control={<Radio />} label="1099" />
                    <FormControlLabel value="T4" control={<Radio />} label="T4" />
                    <FormControlLabel value="INC" control={<Radio />} label="INC" /> */}
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled
                    value={value.comType}
                    label="Compensation Type"
                    onChange={(e) => setValue({ ...value, comType: e.target.value })}
                  >
                    <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                    <MenuItem value={'Annually'}>Annual Compensation</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Expected Compensation( In Dollars )*"
                  placeholder="Expected Compensation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.expectedCom}
                  type="text"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: value.comType && (
                      <InputAdornment position="end">{value.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                    ),
                  }}
                  // onChange={(e) => setValue({ ...value, expectedCom: e.target.value })}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^\d*\.?\d*$/.test(newValue)) {
                      setValue({ ...value, expectedCom: newValue });
                    }
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.userStatus}
                    label="Change Status"
                    onChange={(e) => setValue({ ...value, userStatus: e.target.value })}
                  >
                    {/* <MenuItem value={'fake'}>Fake</MenuItem> */}
                    {/* <MenuItem value={'blacklisted'}>Blacklisted</MenuItem> */}
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                  </Select>
                </FormControl>

                <Grid item xs={6} sx={{ }}>
                <FormControl fullWidth size="medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      // slotProps={{ textField: { size: "small" } }}
                      value={formValues1.dateofbirth ? dayjs(formValues1.dateofbirth) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange1(date, 'dateofbirth')}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Grid>
              </Stack>
              <Stack gap={2}>
                <FormControl fullWidth sx={{}}>
                  <TextField
                    id="outlined-basic"
                    label="Linkedin URL"
                    placeholder="Linkedin URL"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.linkedinUrl}
                    onChange={(e) => {
                      let updatedUrl = e.target.value.trim();
                      updatedUrl = updatedUrl.replace(/^(https?:\/\/)?(www\.)?/i, '');

                      if (updatedUrl.startsWith('www')) {
                        updatedUrl = `https://${updatedUrl}`;
                      } else if (updatedUrl !== '') {
                        updatedUrl = `https://www.${updatedUrl}`;
                      }

                      setValue({ ...value, linkedinUrl: updatedUrl });
                    }}
                  />
                </FormControl>
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.jobid}
                    label="Job Id"
                    onChange={(e) => setValue({ ...value, jobid: e.target.value })}
                  >
                    <MenuItem value={'NA'}>NA</MenuItem>
                    {joblst.map((item, index) => {
                      return <MenuItem key={index} value={item.jobId}>{item.jobId}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"CANADA"}>CANADA</MenuItem>
                  </Select>
                </FormControl>

                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <FormControl fullWidth>
                  {cityandstate ? (
                    <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                      <Typography color={'text.textG'} variant="body">
                        {cityandstate?.city},{" "}{cityandstate?.state}
                      </Typography>
                      {/* <Typography color={'text.textG'} variant="body" sx={{ ml: 1 }}>
                        {cityandstate.state}
                      </Typography> */}
                    </Stack>
                  ) : null}
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Zip code"
                      placeholder="Zip code"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={value.zip}
                      // type="number"
                      onChange={(e) => setValue({ ...value, zip: e.target.value })}
                    />
                  </FormControl>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">Can Relocate </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes "
                    name="radio-buttons-group"
                    value={value.reloc}
                    onChange={(e) => setValue({ ...value, reloc: e.target.value })}
                    sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <Stack sx={{ flexDirection: 'row'}}>
                {/* <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Summary
                </Typography> */}
                <TextField
                    id="outlined-multiline-static"
                  label="Summary"
                  placeholder="Summary..."
                  multiline
                  rows={4}
                    sx={{ width: '100%', py: 0.5 }}
                    value={value.summary}
                  
                    onChange={(e) => setValue({ ...value, summary: e.target.value })}
                  />
</Stack>

<FormControl fullWidth sx={{ }}>
      <InputLabel id="years-of-experience-label">Years of Experience</InputLabel>
      <Select
        labelId="years-of-experience-label"
        id="years-of-experience"
       
        label="Years of Experience"
        // onChange={handleChange}
        value={value.yearExp}
        // type="number"
        onChange={(e) => setValue({ ...value,  yearExp: e.target.value })}
      >

<MenuItem value={"1"}>1</MenuItem>
<MenuItem value={"2"}>2</MenuItem>
<MenuItem value={"3"}>3</MenuItem>
<MenuItem value={"4"}>4</MenuItem>
<MenuItem value={"5"}>5</MenuItem>
<MenuItem value={"6"}>6</MenuItem>
<MenuItem value={"7"}>7</MenuItem>
<MenuItem value={"8"}>8</MenuItem>
<MenuItem value={"9"}>9</MenuItem>
<MenuItem value={"10"}>10+</MenuItem>
<MenuItem value={"15"}>15+</MenuItem>
<MenuItem value={"20"}>20+</MenuItem>
<MenuItem value={"25"}>25+</MenuItem>
<MenuItem value={"30"}>30+</MenuItem>
<MenuItem value={"35"}>35+</MenuItem>
<MenuItem value={"40"}>40+</MenuItem>
<MenuItem value={"45"}>45+</MenuItem>

        {/* {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year === 0 ? 'Less than 1 year' : `${year} ${year === 1 ? 'year' : 'years'}`}
          </MenuItem>
        ))} */}
      </Select>
    </FormControl>

                <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setFinallst([...newValue]);
                  }}
                  options={skill}
                  getOptionLabel={(option) => option?.title}
                  // value={finallst?.map((item) => item)}
                  value={finallst.map((item) => (typeof item === 'string' ? { title: item } : item))} 
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options?.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Skills" placeholder="Skills" />}
                />
                {/* <Stack sx={{ position: 'relative', width: '100%' }}>
                  <Autocomplete
                    value={askill}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // setAskill({title: newValue });
                        addskill(newValue);
                        // console.log('first----- ', newValue);
                        setAskill('');
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // setAskill({title: newValue.inputValue});
                        // console.log('second------ ', newValue.inputValue);
                        addskill(newValue.inputValue);
                        setAskill('');
                      } else {
                        // console.log('third----- ', newValue.title);
                        setAskill('');
                        addskill(newValue.title);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    id="free-solo-with-text-demo"
                    options={skill}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        // console.log('re 1-----', option);
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        // console.log('re 2-----', option.inputValue);
                        return option.inputValue;
                      }
                      // Regular option

                      // console.log('re 3-----', option.title);
                      return option.title;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    sx={{}}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Skill" />}
                  />

                  <Stack sx={{ marginTop: 1 }}>
                    <Grid container spacing={2}>
                      {finallst.map((item) => {
                        return (
                          <Grid item>
                            <Item>
                              {item}
                              <Iconify
                                icon="basil:cross-outline"
                                sx={{ color: 'white', width: 20, height: 20 }}
                                style={{ cursor: 'pointer' }}
                                onClick={() => del(item)}
                              />
                            </Item>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Stack>*} */}
                <Stack sx={{flexDirection:"row"}}>
                <Stack sx={{ flexDirection: 'column' }}> 
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1">Upload Resume*</Typography>

    <Stack sx={{ flexDirection: 'row', ml: 2 }}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          sx={{
            cursor: 'pointer',
            m: 1,
            border: 1,
            p: 0.5,
            borderRadius: 2,
            width: 80,
            borderColor: 'text.secondary',
            ml:-1,
          }}
          component="label"
        >
          <Typography sx={{ textAlign: 'center'}} color={'text.secondary'}>
            choose file
          </Typography>
          <input
            style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%' }}
            type="file"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => {
              setDocfile(e.target.files[0]);
              setChoosefile(false);
              pdfUpload(e.target.files[0]);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>

  {docfile && (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mt: 1,
      }}
    >
     
      <Typography variant="body2" sx={{ width: 200 }}>
        {docfile?.name}
      </Typography>

      <Iconify
        icon="basil:cross-outline"
        sx={{ color: 'text.secondary', width: 30, height: 30, ml: -10 }} 
        style={{ cursor: 'pointer' }}
        onClick={() => delfile()}
      />
    </Stack>
  )}

  {/* Success Message */}
  <Stack sx={{flexDirection:"row"}}>
  {choosefile === true ? (
    <Typography color={'green'} sx={{ mt: 1 }}>
      Success
    </Typography>
  ) : null}

  {pdffile && (
    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center',ml:8 }}>
      <a href={pdffile} target="_foxearch">
        <Button color="custom" variant="outlined">
          View File
        </Button>
      </a>
    </Stack>
  )}
  </Stack> 
</Stack>


<Stack sx={{ flexDirection: 'column' }}>
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1" sx={{ml:5.8}}>Upload DL</Typography>

    <Stack sx={{ flexDirection: 'row', ml: 2 }}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          sx={{
            cursor: 'pointer',
            m: 1,
            border: 1,
            p: 0.5,
            borderRadius: 2,
            width: 80,
            borderColor: 'text.secondary',
            ml:-0.3
          }}
          component="label"
        >
          <Typography sx={{ textAlign: 'center' }} color={'text.secondary'}>
            choose file
          </Typography>
          <input
            style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%' }}
            type="file"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => {
              setDocfile1(e.target.files[0]);
              setChoosefile1(false);
              pdfUploadDL(e.target.files[0]);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>

 {docfile1 && (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mt: 1, 
      }}
    >
      <Typography variant="body2" sx={{ width: 200, ml:6 }}>
        {docfile1.name}
      </Typography>

      <Iconify
        icon="basil:cross-outline"
        sx={{ color: 'text.secondary', width: 30, height: 30, ml: -10 }}
        style={{ cursor: 'pointer' }}
        onClick={() => delfileDL()}
      />
    </Stack>
  )}

  <Stack sx={{flexDirection:"row"}}>
  {choosefile1 === true ? (
    <Typography color={'green'} sx={{ mt: 1 , ml:5.7}}>
      Success
    </Typography>
  ) : null}

  {pdffileDL && (
    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center',ml:3.9 }}>
      <a href={pdffileDL} target="_foxearch">
        <Button color="custom" variant="outlined">
          View File
        </Button>
      </a>
    </Stack>
  )}
  </Stack> 
</Stack>

</Stack>

<Stack sx={{flexDirection:"row"}}>
                <Stack sx={{ flexDirection: 'column' }}> 
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1">Upload Visa*</Typography>

    <Stack sx={{ flexDirection: 'row'}}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          sx={{
            cursor: 'pointer',
            m: 1,
            border: 1,
            p: 0.5,
            borderRadius: 2,
            width: 80,
            borderColor: 'text.secondary',
            ml: 4
          }}
          component="label"
        >
          <Typography sx={{ textAlign: 'center'}} color={'text.secondary'}>
            choose file
          </Typography>
          <input
            style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%' }}
            type="file"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => {
              setDocfile2(e.target.files[0]);
              setChoosefile2(false);
              pdfUploadVisa(e.target.files[0]);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>

  {docfile2 && (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mt: 1,
      }}
    >
     
      <Typography variant="body2" sx={{ width: 200 }}>
        {docfile2.name}
      </Typography>

      <Iconify
        icon="basil:cross-outline"
        sx={{ color: 'text.secondary', width: 30, height: 30, ml: -10 }} 
        style={{ cursor: 'pointer' }}
        onClick={() => delfileVisa()}
      />
    </Stack>
  )}

  {/* Success Message */}
  <Stack sx={{flexDirection:"row"}}>
  {choosefile2 === true ? (
    <Typography color={'green'} sx={{ mt: 1 }}>
      Success
    </Typography>
  ) : null}

  {pdffileVisa && (
    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center', ml: 8 }}>
      <a href={pdffileVisa} target="_foxearch">
        <Button color="custom" variant="outlined">
          View File
        </Button>
      </a>
    </Stack>
  )}
  </Stack> 
</Stack>


<Stack sx={{ flexDirection: 'column' }}>
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1" sx={{ml:6.3}}>Upload | 94</Typography>

    <Stack sx={{ flexDirection: 'row', ml: 2 }}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          sx={{
            cursor: 'pointer',
            m: 1,
            border: 1,
            p: 0.5,
            borderRadius: 2,
            width: 80,
            borderColor: 'text.secondary',
            ml:-1
          }}
          component="label"
        >
          <Typography sx={{ textAlign: 'center' }} color={'text.secondary'}>
            choose file
          </Typography>
          <input
            style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%' }}
            type="file"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => {
              setDocfile4(e.target.files[0]);
              setChoosefile4(false);
              pdfUpload94(e.target.files[0]);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>

 {docfile4 && (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mt: 1, 
      }}
    >
      <Typography variant="body2" sx={{ width: 200, ml:9.1 }}>
        {docfile4.name}
      </Typography>

      <Iconify
        icon="basil:cross-outline"
        sx={{ color: 'text.secondary', width: 30, height: 30, ml: -10 }}
        style={{ cursor: 'pointer' }}
        onClick={() => delfile94()}
      />
    </Stack>
  )}

  <Stack sx={{flexDirection:"row"}}>
  {choosefile4 === true ? (
    <Typography color={'green'} sx={{ mt: 1 , ml:6.3}}>
      Success
    </Typography>
  ) : null} 

  {pdffile94 && (
    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center',ml:3.9 }}>
      <a href={pdffile94} target="_foxearch">
        <Button color="custom" variant="outlined">
          View File
        </Button>
      </a>
    </Stack>
  )}
  </Stack> 
</Stack>

</Stack>

<Stack sx={{ flexDirection: 'column' }}> 
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1">Other Doc*</Typography>

    <Stack sx={{ flexDirection: 'row', ml: 2 }}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          sx={{
            cursor: 'pointer',
            m: 1,
            border: 1,
            p: 0.5,
            borderRadius: 2,
            width: 80,
            borderColor: 'text.secondary',
            ml:3.6,
          }}
          component="label"
        >
          <Typography sx={{ textAlign: 'center'}} color={'text.secondary'}>
            choose file
          </Typography>
          <input
            style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%' }}
            type="file"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => {
              setDocfile3(e.target.files[0]);
              setChoosefile3(false);
              pdfUploadOtherDoc(e.target.files[0]);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>

  {docfile3 && (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mt: 1,
      }}
    >
     
      <Typography variant="body2" sx={{ width: 200 }}>
        {docfile3.name}
      </Typography>

      <Iconify
        icon="basil:cross-outline"
        sx={{ color: 'text.secondary', width: 30, height: 30, ml: -10 }} 
        style={{ cursor: 'pointer' }}
        onClick={() => delfileOtherDoc()}
      />
    </Stack>
  )}

  {/* Success Message */}
  <Stack sx={{flexDirection:"row"}}>
  {choosefile3 === true ? (
    <Typography color={'green'} sx={{ mt: 1 }}>
      Success
    </Typography>
  ) : null}

  {pdffileOtherDoc && (
    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center',ml:8.1 }}>
      <a href={pdffileOtherDoc} target="_foxearch">
        <Button color="custom" variant="outlined">
          View File
        </Button>
      </a>
    </Stack>
  )}
  </Stack> 
</Stack>

                <TextField
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={4}
                  value={value.notes}
                  onChange={(e) => setValue({ ...value, notes: e.target.value })}
                />
                {/* <Stack sx={{ width: 500 }}>
                  <CKEditor
                    editor={ClassicEditor}
                    // data="<p>Typing&nbsp;5!<br><br></p>"
                    // onReady={ editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log( 'Editor is ready to use!', editor );
                    // } }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue({ ...value, notes: data });
                      console.log('ckeditor------data', data);
                    }}
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
                  />
                </Stack> */}
              </Stack>
            </Grid>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => addcv(e)}>
                Add CV
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={parse} onClose={parseClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: 'white',
            border: '4px solid #1E74B3',
            borderRadius: '13px',
            padding: '1rem',
            outline: 'none',
            height: 'auto'
          }}

        >
          <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
            <div
              style={{
                border: '3px dashed #1E74B3',
                padding: '10px',
                borderRadius: '15px',
                margin: '1rem',
                textAlign: 'center',
                color: '#1E74B3',
                // width: '85%',
                alignContent: 'center',
                marginLeft: '20px'
              }}
            >
              <Typography variant="body1" fontWeight="bold" mb={1}>
                Browse File
              </Typography>
              <input
                id="file-upload"
                type="file"
                accept=".doc,.docx, application/pdf"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                multiple
              />
            </div>
          </label>
          {/* {fileInputFiles.length > 0 && (
            <div>
              {fileInputFiles.map((file, index) => (
                <Typography key={index} variant="body1" sx={{ marginLeft: '20px' }}>
                  {file.name}
                </Typography>
              ))}
            </div>

          )} */}
          <div>
            <Typography
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{
                textAlign: 'center',
                color: '#1E74B3',
              }}
            >
              OR
            </Typography>

            <div
              style={{
                border: '3px solid #1E74B3',
                padding: '10px',
                borderRadius: '15px',
                margin: '1rem',
                textAlign: 'center',
                height: '200px',
                color: '#1E74B3',
                alignContent: 'center',

              }}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              Drag & Drop to Upload File
            </div>
          </div>


          {fileInputFiles.length > 0 && (
            <div>
              {/* {/ <Typography variant="body1">Uploaded Files</Typography> /} */}
              {fileInputFiles?.map((file, index) => (
                <Typography key={index} variant="body1" sx={{ marginLeft: '20px' }}>{file.name}</Typography>
              ))}
            </div>
          )}

          <label htmlFor="file-upload-excel" style={{ cursor: 'pointer' }}>
            <div
              style={{
                border: '3px dashed #1E74B3',
                padding: '10px',
                borderRadius: '15px',
                margin: '1rem',
                // justifyContent: 'center',
                // alignItems: 'center',
                textAlign: 'center',
                color: '#1E74B3',
                // width: '330px',
                marginLeft: '25',
                alignContent: 'center',
              }}
            >
              <Typography variant="body1" fontWeight="bold" mb={1}>
                Browse Excel File
              </Typography>
              <input
                id="file-upload-excel"
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileSelectExcel}
                style={{ display: 'none' }}
                multiple
              />

            </div>
          </label>
          {inputExcel.length > 0 && (
            <div>
              {/* {/ <Typography variant="body1">Uploaded Files</Typography> /} */}
              {inputExcel?.map((file, index) => (
                <Typography key={index} variant="body1" sx={{ marginLeft: '20px' }}>{file.name}</Typography>
              ))}
            </div>
          )}
          <Typography color="custom" variant="body1" sx={{ marginLeft: "30%" }}>
            <a href="/assets/Parsing-Candidate.xlsx" download style={{ textDecoration: 'underline', color: '#1E74B3' }}>
              Download Excel Format
            </a>
          </Typography>

        </Box>
      </Modal>
    </>
  );
}

export default BenchWorksheet;

function ResumeRow({ row, fun, fun2, fun3, fun22, skillsett, joblst1, filterTab, index, pinned, tempList, 
  addedSkills, favFun, getInactiveList1, getActiveList, dropComp }) {

  //  ==============>>>>>>>> email popup

  const [temp, setTemp] = useState('Template');
  // const [subject, setSubject] = useState('');
  const [editor, setEditor] = useState('');

  const [date, setDate] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')
  const [openModal1, setOpenModal1] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)

  const [candidateId, setCandidateId] = useState('')
  const [selectedCandidateId, setSelectedCandidateId] = useState('');
  const [base64, setBase64] = useState([])
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState('')


  // console.log("candidateId ====>>>>",candidateId )
  // console.log("selectedCandidateId ====>>>>", selectedCandidateId)

  const [selectedFile, setSelectedFile] = useState(null);

  const FileChange = (event) => {
    const file = event.target.files[0];
    // console.log("file---", event.target.files)
    if (file) {
      setFileName(file.name)
      setFileType(file.type)
      convertFileToBase64(file);
    } else {
      toast.warn('No file selected!');
    }
  };
  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64(reader.result);
      // console.log("base64",reader.result)
    };
    reader.readAsDataURL(file);
  };

  const [greeting, setGreeting] = useState('')
  const [recipientEmail, setRecipientEmail] = useState(filterTab === "worksheet" ? row?.email: row?.emailId);
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [bccEmail, setBccEmail] = useState([]);
  const [ccEmail, setCcEmail] = useState([]);
  const [uploadfilee, setUploadFile] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [sub1, setSub1] = useState('')
  const [sub2, setSub2] = useState('')
  const [sub3, setSub3] = useState('')
  const [body1, setBody1] = useState('')
  const [body2, setBody2] = useState('')
  const [body3, setBody3] = useState('')
  const [time1, setTime1] = useState(dayjs())
  const [time2, setTime2] = useState(dayjs())
  const [time3, setTime3] = useState(dayjs())
  const [date3, setDate3] = useState('')
  const [checked, setChecked] = useState(false);
  const [checkedEmail1, setCheckedEmail1] = useState(false);
  const [checkedEmail2, setCheckedEmail2] = useState(false);
  const [checkedEmail3, setCheckedEmail3] = useState(false);
  const sign = localStorage.getItem('sign')

  const SendEmail = () => {
    // const ebody = `<p>${greeting}${' '}${row.fullName}</p>${emailBody}`
    const token = localStorage.getItem('token')
    const bcc = [];
    const cc = [];
    bccEmail?.map((item) => {
      return bcc.push(item.title);
    });
    ccEmail?.map((item) => {
      return cc.push(item.title);
    });
    const body =
    {
      "subject": subject,
      'emailBody': `<p>${greeting}${' '}${row.fullName}</p>${emailBody}${sign}`,
      "recipientEmail": recipientEmail,
      "bccEmail": bcc,
      "ccEmail": cc,
      "base64File": base64,
      "fileType": fileType,
      "fileName": fileName
    }
    Api('email/mail/instant', 'POST', body, token)
      .then(response => {
        // console.log("emailData ====>>>>>>>>>", response.data);
        if (response.status === 200) {
          if (response.data.code === 200) {
            handleCloseModal()
            toast.success(response.data.message)
            // alert('Email sent successfully!');
            if (checked && (checkedEmail1 || checkedEmail2 || checkedEmail3)) {
              scheduleEmail();
            } else if (checked === true) {
              scheduleEmail()
              // alert("all checked")
            }
          } else {
            toast.error(response.data.message)
            // alert('Failed to send email.');
          }
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
        // alert('Error sending email. Please try again.');
      });
  };
  const scheduleEmail = () => {
    // alert("===>>>>scheduled")
    const token = localStorage.getItem('token')
    const bcc = [];
    const cc = [];
    bccEmail?.map((item) => {
      return bcc.push(item.title);
    });
    ccEmail?.map((item) => {
      return cc.push(item.title);
    });
    const body = {
      "candidateId": selectedCandidateId,
      "templates": [
        {
          "date": date1,
          // "time": dayjs(time1).format('LT'),
          "time": dayjs(time1).format('HH:mm'),
          "subject": sub1,
          "emailBody": `<p>${greeting}${' '}${row.fullName}</p>${body1}${sign}`,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        },
        {
          "date": date2,
          // "time": dayjs(time2).format('LT'),
          "time": dayjs(time2).format('HH:mm'),
          "subject": sub2,
          "emailBody": `<p>${greeting}${' '}${row.fullName}</p>${body2}${sign}`,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        },
        {
          "date": date3,
          // "time": dayjs(time3).format('LT'),
          "time": dayjs(time3).format('HH:mm'),
          "subject": sub3,
          "emailBody": `<p>${greeting}${' '}${row.fullName}</p>${body3}${sign}`,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        }
      ]
    }
    Api('email/mail/schedule', 'POST', body, token)
      .then(response => {
        try {
          // console.log("emailData ====>>>>>>>>>", response.data);
          if (response.status === 200) {
            if (response.data.code === 200) {
              toast.success(response.data.message);
            } else if (response.data.code === 400 && response.data.message === "Emails already scheduled") {
              toast.warn(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          } else {
            toast.error('Emails already scheduled.');
          }
        } catch (error) {
          console.error('Error handling response:', error);
          toast.error('Error handling response. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
        toast.error('Error sending email. Please try again.');
      });

  };

  // const uploadFile = async (pdf) => {
  //   const token = localStorage.getItem('token');
  //   const formData = new FormData();
  //   formData.append('document', pdf);
  //   formData.append('directoryName', 'resume');
  //   UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
  //     if (Response.status === 200) {
  //       if (Response.data.code === 200) {
  //         setUploadFile(Response.data.filePath)
  //         console.log('=====404=gst===', Response.data.filePath);
  //       } else {
  //         console.log('=====404=gst===', Response.data.message);
  //         toast.warn(Response.data.status, +'!');
  //       }
  //     } else {
  //       toast.warn('Error !');
  //     }
  //   });
  // };

  const handleTemplateSelect = (item) => {
    // const selectedTemplate = tempList.find((item) => item.templateName === templateName);
    setSelectedTemplate(item)


    if (item && item.template && item.template.length > 0) {

      setGreeting(item.template[0].greeting)
      // console.log("selectedTemplate ====>>>>>>>", item.template[0].greeting);
      setSubject(item.template[0].subject)
      setEmailBody(item.template[0].emailBody)

      setSub1(item.template[1].subject)
      setBody1(item.template[1].emailBody)

      setSub2(item.template[2].subject)
      setBody2(item.template[2].emailBody)

      setSub3(item.template[3].subject)
      setBody3(item.template[3].emailBody)


    }
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }
  const [email, setEmail] = useState("")
  // const [email, setEmail] = useState(getTomorrowDate())

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDateSelect = (selectedDate) => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setDate1(formattedDate);
    setOpenModal(false);
  };

  const getDateFromThreeDaysLater = () => {
    const threeDaysLater = new Date();
    threeDaysLater.setDate(threeDaysLater.getDate() + 4);
    return threeDaysLater.toISOString().split('T')[0];
  }
  const [email1, setEmail1] = useState('')
  // const [email1, setEmail1] = useState(getDateFromThreeDaysLater())
  const handleModalOpen1 = () => {
    setOpenModal1(true);
  };

  const handleModalClose1 = () => {
    setOpenModal1(false);
  };

  const handleDateSelect1 = (selectedDate) => {
    // console.log(dayjs(selectedDate).format("YYYY-MM-DD"))
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    setDate1(formattedDate);
    setOpenModal(false);
  };

  const getDateFromOneWeekLater = () => {
    const OneWeekLater = new Date();
    OneWeekLater.setDate(OneWeekLater.getDate() + 9);
    return OneWeekLater.toISOString().split('T')[0];
  }
  const [email2, setEmail2] = useState('')
  // const [email2, setEmail2] = useState(getDateFromOneWeekLater())
  const handleModalOpen2 = () => {
    setOpenModal2(true);
  };

  const handleModalClose2 = () => {
    setOpenModal2(false);
  };


  const handleDateSelect2 = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    // const formattedDate = selectedDate.toISOString().replace('T', ' ').split('.')[0];
    setDate2(formattedDate);
    // console.log("====>>>>>", formattedDate);
    handleModalClose1(false)
  };
  const handleDateSelect3 = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    // const formattedDate = selectedDate.toISOString().replace('T', ' ').split('.')[0];
    setDate3(formattedDate);
    // console.log("====>>>>>", formattedDate);
    setOpenModal2(false);
  };

  const [modalOpen, setModalOpen] = useState(false);

  // const handleOpenModal = () => {
  //   // alert('hello')
  //   getCandidateId()
  //   setModalOpen(true);

  // };

  const handleOpenModal = (id) => {
    setSelectedCandidateId(id);
    setModalOpen(true);
    // alert(`Selected Candidate ID: ${id}`);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // const getTemplList = () => {
  //   const token = localStorage.getItem('token');
  //   // console.log("token ===>>>>> ",token)
  //   Api('email/getEmailTemplateList', 'GET', '', token)
  //     .then((response) => {
  //       console.log('Response:---------', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           //  console.log("hhh")
  //           // if (response.data.result.length > 0) {
  //           //   const firstTemplate = response.data.result;
  //           //   setUserId(firstTemplate.userId);
  //           //   setCompanyId(firstTemplate.companyId);
  //           // }
  //           const temp = response.data.result;
  //           const extractedData = temp.map((item) => {
  //             return {
  //               companyId: item.companyId,
  //               userId: item.userId
  //             };
  //           });
  //           // console.log('Extracted Data:', extractedData);

  //           if (extractedData.length > 0) {
  //             const firstItem = extractedData;
  //             // setCompanyId(firstItem.companyId); 
  //             // setUserId(firstItem.userId); 
  //           }

  //         } else {
  //           console.log('data:', response.data.message);
  //         }
  //       }
  //       setTempList(response.data.result);
  //       // setUserId(response.data.result.userId)
  //       // setCompanyId(response.data.result.companyId)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  useEffect(() => {

    // console.log('=================date===>>>', filterTab);
    if (skillsett.length > 0) {
      setSkillset(skillsett);
    }
    if (row.skill) {

      row?.skill?.map((item) => temparr?.push({ title: item }))
    }
  }, []);

  const navigate = useNavigate();
  const [tmpskl, setTmpskl] = useState([]);

  const [status, setStatus] = useState('Pending');
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openpdf, setOpenpdf] = useState(false);

  const [openskill, setOpenskill] = useState(true);
  const skillclose = () => setOpenskill(false);
  const [open1, setOpen1] = useState(false);
  const handleEdit = () => {
    setOpen1(true);
  };
  const handleCloseEdit = () => setOpen1(false);
const [recName, setRecName] = useState("")
  const [esub, setSub] = useState(false);
  const openEsub = (row) => setSub(true);
  // setRecName(row.)
  const closeEsub = () => setSub(false);

  
  const [subto, setSubto] = useState(row.type);
//   const [esubdata, setEsubdata] = useState({
//     subjobid: row.jobId,
//     expectedCom: row.expectedCompensation,
//     comType: row.chargeType,
//     pname: row.positionName,
//     cname: row.clientName,
//     tax: row.taxTerm,
//     country: row.country,
//     jobType:row.jobType,
//     recruitorName:row.recruitorName,
//     recruitorCompany: row.recruitorCompany,
//     automailer: row.automailer,
//     emailSubject: row.emailSubject,
//     phoneNumber: row.phoneNumber,
//     emailId:row.emailId,
// state:row.cityy,
// zipCode:value.zip,
// note:row.note

//   });
  const [clientlist, setClientlst] = useState([])
  const [eplace, setEplace] = useState(false);
  const openEplace = () => setEplace(true);
  const closeEplace = () => setEplace(false);
  const [eplacedata, setEplacedata] = useState({
    placestatus: "placed",
    jobType: row.jobType,
    offeredJob: row.offeredJob,
    AnnualSalary: row.annualSalary,
    JoiningBonus: row.joiningBonus,
    AnnualBonus: row.annualBonus,
    ReloAssistance: row.relocationAssistance,
    hro: row.hourlyRateRateOffered,
    TaxTerm: row.taxTerm,
    OvertimeExpense: row.overTimeExpence,
    Additional: row.additionals,
    placenote: ""
  });
  const [tsd, setTsd] = React.useState(dayjs(row.tentativeStartDate));
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [totalDays, setTotalDays] = useState(0);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);
const [actId, setActId] = useState('')
const [actSts, setActSts] = useState('')

  const [opens1, setOpens1] = useState(false);
  const handleOpens1 = (Id, status) => {setOpens1(true);setActId(Id);setActSts(status);
    console.log("KKKKKKKKKK===============", Id)}
  const handleCloses1 = () => setOpens1(false);

  const [values, setValues] = useState({
    status:actSts,
    notes:""
  })

  const [addSkill, setAddSkill] = useState(false);
  const handleSkillOpen = () => setAddSkill(true);
  const handleSkillClose = () => setAddSkill(false);

  const [openn, setOpenn] = useState(false);
  const [his, setHis] = useState([]);
  const handleOpenn = () => {
    setOpenn(true);
    getHis();
  };

  const changeStatus = async () => {
    const token = localStorage.getItem('token');
    const body = {
       "bench_id":actId,
       "status":values.status || actSts,
      "note":values.notes,
    };
    console.log("========values.status", values.status)
    Api(`bench/InactiveProspect`, 'POST', body, token)
      .then((response) => {
        console.log('==========sts=====>>>', response);
        if (response.status === 200) {
          handleCloses1()
          toast.success(response.data.resmessage);
          if(values.status === "active"){
            console.log("========values.status", values.status)
            getActiveList()
          } else {
            getInactiveList1()
          }
          if (response.data.code === 200) {
            toast.success(response.data.message);
            console.log(values.status);
            handleCloses1()
            // fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };

  const EditSubmitted = async () => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      // resumeId: row.resumeId,
    "bench_id": row.benchId,
    "userId": row.userId,
      // type: subto,
      // jobId: esubdata.subjobid,
      "expectedCompensation": esubdata.expectedCom,
      "chargeType": esubdata.comType,
      "positionName": esubdata.pname,
      "clientName": esubdata.cname,
      "taxTerm": esubdata.tax,
      "submitStatus": "submitted", 
      "submitTo": "",
      "emailId": esubdata.emailId,
      // "status": "Active",
    "recruitorName": esubdata.recruitorName,
    "recruitorCompany": esubdata.recruitorCompany,
    "automailer":esubdata.automailer,
    "emailSubject":esubdata.emailSubject,
    "companyID": row.companyID,
    "phoneNumber": esubdata.phoneNumber,
    "jobType":esubdata.jobType,
    "country":esubdata.country,
    "zipCode":esubdata.zipCode || zipcode,
    "state":esubdata.state,
"note":esubdata.note,
"stateCode":cityandstate.stateCode
    };
    // Api(`resume/update_any_step`, 'POST', body, token)
    Api(`bench/editSubmission`, 'POST', body, token)
      .then((response) => {
        // console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // setLevelM(false);
            toast.success(response.data.message);
            // console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };
  const EditPlacement = async () => {
    const token = localStorage.getItem('token');
    const body = {
      // submissionId: row._id,
      // resumeId: row.resumeId,
      submissionId: row._id,
      bench_id: row.benchId,
      status: eplacedata.placestatus === 'placed' ? 'placement' : 'prospect',
      result: eplacedata.placestatus,
      note: eplacedata.placenote,
      jobType: eplacedata.jobType,
      offeredJob: eplacedata.offeredJob,
      chargeType: eplacedata.offeredJob === 'fulltime' ? 'K' : 'hourly',
      annualSalary: eplacedata.AnnualSalary,
      joiningBonus: eplacedata.JoiningBonus,
      annualBonus: eplacedata.AnnualBonus,
      relocationAssistance: eplacedata.ReloAssistance,
      additionals: eplacedata.Additional,
      tentativeStartDate: tsd,
      taxTerm: eplacedata.TaxTerm,
      hourlyRateRateOffered: eplacedata.hro,
      overTimeExpence: eplacedata.OvertimeExpense,
      startStatus: '',
    };
    // Api(`resume/update_any_step`, 'POST', body, token)
    Api(`bench/BenchPlacementStep`, 'POST', body, token)
      .then((response) => {
        // console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // setLevelM(false);
            toast.success(response.data.message);
            // console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };

  console.log(" ================filterTab", filterTab)

  const getHis = () => {
    const token = localStorage.getItem('token');
    let id;
    if (filterTab === "worksheet"){
id = row._id
    } else {
      id = row.benchId
    }
    console.log("==========id==========", id)
    const body = {
      "bench_id":id,

    }
        Api(`bench/getBenchActivityList`, 'POST', body, token)
    // Api(`resume/get_history/${filterTab === 'worksheet' ? row._id : row.resumeId}`, 'GET', '', token)
      .then((response) => {
        // console.log('==========get_history=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setHis(response.data.data);
            // setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };
  const handleClosen = () => setOpenn(false);

  const [openinterview, setOpeninterview] = useState(false);
  const handleopeninterview = () => setOpeninterview(true);
  const handlecloseinterview = () => setOpeninterview(false);

  const [levelM, setLevelM] = useState(false);

  const [openclient, setOpenclient] = useState(false);
  const handleOpenclient = () => setOpenclient(true);
  const handleCloseclient = () => setOpenclient(false);

  const [openclient1, setOpenclient1] = useState(false);
  const [EditSumId, setEditSumId] = useState()

  const [enableEditSum, setEnableEditSum] = useState(false)
  const handleOpenclient1 = (row) => 
    {setUpdateSummary(row.summary);
    setOpenclient1(true)
    if(filterTab === "worksheet"){
      setEditSumId(row._id)
    } else {
      setEditSumId(row.benchId)
    }
  }
const [loadingSum, setLoadingSum] = useState(false)
  const handleCloseclient1 = () => setOpenclient1(false);
const [updateSummary, setUpdateSummary] = useState('')

  const handleOpenpdf = () => setOpenpdf(true);
  const Closepdf = () => setOpenpdf(false);
  const [pdffile, setPdffile] = useState(row.resumeUrl);
  const [docfile, setDocfile] = useState();

  const [pdffileDL, setPdffileDL] = useState(row.DL_File);
  const [docfile1, setDocfile1] = useState();

  const [pdffileVisa, setPdffileVisa] = useState(row.visaFile);
  const [docfile2, setDocfile2] = useState();

  const [pdffileOtherDoc, setPdffileOtherDoc] = useState(row.otherFile);
  const [docfile3, setDocfile3] = useState();

  const [pdffile92, setPdffile92] = useState(row.i94_File);
  const [docfile4, setDocfile4] = useState();

  const [commentt, setCommentt] = useState('');
  const [statusNote, setStatusNote] = useState('')

  const editSummary = async (val) => {
    setLoadingSum(true)
    const token = localStorage.getItem('token');
    const body = {
        "bench_id":EditSumId,
        "summary":updateSummary,
    };
    Api(`bench/editSummary`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          setEnableEditSum(false)
          if(filterTab === "worksheet"){
            fun()
          } else {
            fun22()
          }
          setLoadingSum(false)
          // console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            // setCityandstate(response.data.data[0]);
          } else {
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  
  const [value, setValue] = useState({
    email: row.email,
    name: row.fullName,
    jobtitle: row.jobTitle,
    currCompany: row.currentCompany,
    phone: row.phone,
    visa: row.visaType,
    tax: row.taxTerm,
    expectedCom: row.expectedCompensation,
    jobid: row.jobId,
    city: filterTab === 'worksheet' ? row.city : row.cityOrZipcode,
    reloc: row.reallocate,
    notes: row.note,
    comType: row.chargeType,
    fcity: '',
    scode: row.stateCode,
    zip: row.zipcode,
    status: row.status,
    country: row.country,
    userStatus: row.userStatus,
    linkedinUrl: row.linkedinUrl,
    companyID:row.companyID,
    updatedAt:row.updatedAt,
  bench_id:row._id,
  note:row.note,
   summary:row.summary,
     experienceInYear:row.experienceInYear,
     otherFile:row.otherFile,
     i94_File:row.i94_File,
     DL_File:row.DL_File,
     validity:row.validity,
     dob:row.DOB,
     visaTransfer:row.visaTransfer
  });
  const [tempskill, setTempskill] = useState([]);
  const temparr = [];
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  // console.log('new skill========>', row.skill);


  const [finallst, setFinallst] = useState(temparr);
  const [evalid, setEvalid] = useState(true);
  // const [cityy, setCityy] = useState(filterTab === 'worksheet' ? row.city : row.cityOrZipcode || '');
  const [cityy, setCityy] = useState(row.cityOrZipcode || '');
  console.log("======cityy=====", cityy)
  const [cityData, setcityData] = useState([]);
  // const [ city , setCity] = useState('')
  const [askill, setAskill] = React.useState(null);
  const [statee, setStatee] = useState('');
  const cities = [];
  const [skillset, setSkillset] = useState([{ title: 'item.skillName' }]);
  const [client, setClients] = useState([]);
  console.log("client", client)
  const [cityandstate, setCityandstate] = useState({
    city: row.cityOrZipcode,
    state: row.stateCode,
  });
  // const [cityandstate, setCityandstate] = useState({
  //   city: filterTab === 'worksheet' ? row.city : row.cityOrZipcode,
  //   state: row.stateCode,
  // });
  const [choosefile, setChoosefile] = useState(false);
  const [choosefile1, setChoosefile1] = useState(false);
  const [choosefile2, setChoosefile2] = useState(false);
  const [choosefile3, setChoosefile3] = useState(false);
  const [choosefile4, setChoosefile4] = useState(false);
  const [leveltype, setLeveltype] = useState('');
  const [leveltimez, setLeveltimez] = useState('');
  const [leveltime, setLeveltime] = React.useState(dayjs());
  const [leveldate, setLeveldate] = React.useState(dayjs());
  const [subStatus, setSubStatus] = useState('');
  const [phoneCopy, setPhoneCopy] = useState(false);
  const [emailCopy, setEmailCopy] = useState(false);
  const [newcv, setNewcv] = useState('no')

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '500px',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // flexDirection: 'row',
    display: 'flex',
    gap: 2,
  };
  const style2 = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  };
  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: 'white',
  }));
  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
    { label: 'Permanent Resident' },
  ];
  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data?.result?.map((item) => {
              return tempskill?.push({ title: item?.skillName });
            });
            setSkill(tempskill);
            // console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill('');
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  function fixCity(e) {
    setValue({ ...value, city: e.target.value });
    if (e.target.value.length > 2) {
      getCity();
      // console.log('city set');
    }
  }
  const getCity = async (val) => {
    setcityData([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            // console.log('city========', JSON.stringify(response.data));
            setcityData(response.data.data);
            // setCityandstate(response.data.data[0]);
            // if (response.data.data[0].zip) {
            //   setValue({ ...value, zip: response.data.data[0].zip, scode: response.data.data[0].stateCode });
            // } else {
            //   setValue({ ...value, zip: '' });
            // }
          } else {
            toast.error(response.data.message)
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  
  const fileInputRefResume = useRef(null);
  const fileInputRefDL = useRef(null);
  const fileInputRefOther = useRef(null);
  const fileInputRefVisa = useRef(null);
  const fileInputRefi92 = useRef(null);

  const getDate = async (file) => {
    const token = localStorage.getItem('token');
    if (!file) {
      console.warn('No file provided.');
      return;
    }
    const body = {
      bench_id: row._id,
    };
  
    if (fileInputRefResume.current) {
      body.resumeFile = { resumeFileUrl: file };
    } else if (fileInputRefOther.current) {
      body.otherFile = { otherFileUrl: file };
    } else if (fileInputRefDL.current) {
      body.DL_File = { DL_FileUrl: file };
    } else if (fileInputRefVisa.current) {
      body.visaFile = { visaFileUrl: file };
    } else if (fileInputRefi92.current) {
      body.i94_File = { i94_FileUrl: file };
    } else {
      console.warn('No valid input reference found.');
      return; 
    }
    console.log('=====body=add===>>>', body);
    Api(`bench/editProspectFile`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_start=====>>>', response);
        if (response.status === 200) {
          // getResumes()
          // fun()
          if (response.data.code === 200) {
            // setUpload(true)
            // toast.success(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };

  const pdfUpload = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "resume");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setNewcv('yes')
          setChoosefile(true);
          setDocfile('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile(Response.data.filePath); 
          getDate(Response.data.filePath)
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  
  const pdfUploadDL = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "benchDocuments");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile1(true);
          setDocfile1('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileDL(Response.data.filePath);
          getDate(Response.data.filePath)
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const pdfUploadVisa = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "benchDocuments");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile2(true);
          setDocfile2('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileVisa(Response.data.filePath);
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const pdfUploadOtherDoc = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "benchDocuments");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile3(true);
          setDocfile3('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffileOtherDoc(Response.data.filePath);
          getDate(Response.data.filePath)
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const pdfUpload92 = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "benchDocuments");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setNewcv('yes')
          setChoosefile4(true);
          setDocfile4('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile92(Response.data.filePath);
          getDate(Response.data.filePath)
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setValue({
          ...value,
          phone: e,
        });
      }
    } else {
      setValue({
        ...value,
        phone: e,
      });
    }
  };

  // const [stateCode1, setStateCode1] = useState()
  // useState(() => {
  //   if (cityandstate) {
  //     setStateCode1(cityandstate.state);
  //   }
  // }, [cityandstate]);


  const editResume = async (row) => {
    // console.log('update skill', finallst);
    const skillLst = [];
    finallst?.map((item) => {
      return skillLst.push(item.title);
    });
    // console.log('after map skill', skillLst);
    const id = row._id;
    const token = localStorage.getItem('token');
    if (!value.name) {
      toast.error("Full Name is Required");
      return;
    }
    if (!value.jobtitle) {
      toast.error("Job Title is Required");
      return;
    }
    if (!value.email) {
      toast.error("Email is Required");
      return;
    }
    const body = {
      // "newCV": newcv,
      // DL_File:row.DL_File,
      companyID:value.companyID,
      bench_id: id,
      fullName: value.name,
      email: value.email,
      jobTitle: value.jobtitle,
      phone: value.phone,
      currentCompany: value.currCompany,
      skill: skillLst,
      visaType: value.visa,
      taxTerm: value.tax,
      expectedCompensation: value.expectedCom,
      cityOrZipcode: cityy,
      zipcode: value.zip,
      // stateCode: cityandstate.stateCode,
      stateCode: stateCodee,
      // stateCode:stateCode1,
      // cityOrZipcode: "90291",
      reallocate: value.reloc,
      resumeUrl: pdffile,
      // jobId: value.jobid,
      note: value.notes,
      status: value.status,
      chargeType: value.comType,
      userStatus: value.userStatus,
      country: value.country,
      linkedinUrl: value.linkedinUrl,
      summary:value.summary,
      experienceInYear:value.experienceInYear,
      otherFile:pdffileOtherDoc,
      i94_File:pdffile92,
      DL_File:pdffileDL,
visaFile:pdffileVisa,
      validity:value.validity,
      updatedAt:value.updatedAt,
      visaTransfer:value.visaTransfer,
      DOB:value.dob
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        // console.log('======getUser==response=====>', Response);
        if (Response.status === 200) { 
          // alert("hhh")
          toast.success(Response.data.resmessage);
          // if (Response.data.code === 200) {
            // alert("kk")
            // setValue({...value,
            //   email: "",
            //   name: "", 
            //   jobtitle: "",
            //   currCompany: "",
            //   phone: "",
            //   visa: "",
            //   tax: "",
            //   expectedCom: "",
            //   jobid:"" ,
            //   city: "",
            //   reloc: "",
            //   notes: "",
            //   comType: "",
            //   fcity: '',
            //   scode: '',
            //   zip: "",
            //   status: "",
            //   country: "",
            //   userStatus: "",
            // })
            handleCloseEdit();
            toast.success(Response.data.message);
            fun();
            setcityData([]);
            // setFinallst([]);
            setDocfile();
            setChoosefile(false);
            setDocfile1();
            setChoosefile1(false);
            setDocfile2();
            setChoosefile2(false);
            setDocfile3();
            setChoosefile3(false);
            setDocfile4();
            setChoosefile4(false);
            // console.log('======getUser===data.data pdata====>', Response);
          // } else {
            // console.log('======getUser=======>', +Response);
            // toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          // } 
        } else {
          console.log('======getUser=======>', +Response);
          // toast.warn(Response.data.message);
          // enqueueSnackbar(Response.data.message);
        } 
      })
      .catch((err) => {
        toast.error(err, +'!');
        // console.log('===========', err);
      });
  };

  const delfile = () => {
    // console.log('doc file', docfile);
    setDocfile(null);
    // setDocfile('')
    setPdffile('');
    // console.log('doc file------', docfile);
  };

  const delfileDL = () => {
    // console.log('doc file', docfile);
    setDocfile1(null);
    // setDocfile('')
    setPdffileDL('');
    // console.log('doc file------', docfile);
  };
  const delfileVisa = () => {
    // console.log('doc file', docfile);
    setDocfile2(null);
    // setDocfile('')
    setPdffileVisa('');
    // console.log('doc file------', docfile);
  };
  const delfileOtherDoc = () => {
    // console.log('doc file', docfile);
    setDocfile3(null);
    // setDocfile('')
    setPdffileOtherDoc('');
    // console.log('doc file------', docfile);
  };
  const delfile92 = () => {
    // console.log('doc file', docfile);
    setDocfile4(null);
    // setDocfile('')
    setPdffile92('');
    // console.log('doc file------', docfile);
  };
  const handleEmail = (e) => {
    // console.log('======================true=====1=============>>>>>>>>>', evalid);

    setValue({ ...value, email: e.target.value });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e.target.value));
    // setTimeout(() => {
    if (evalid === true) {
      // console.log('=======email valid', evalid);
    } else {
      // console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    // console.log('======================true==================>>>>>>>>>', evalid);
  };
  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getClients = async () => {
    const token = localStorage.getItem('token');
    // let id;
    // if (filterTab === 'worksheet' || filterTab === 'favourites') {
    //   id = row._id
    // }
    // else {
    //   id = row.resumeId
    // }
    // console.log("==========id", id)
let id;
    if (filterTab === "worksheet"){
id = row._id
    } else {
      id = row.benchId
    }
    console.log("==========id==========", id)
    const body = {
      "bench_id":id,
    }
    // Api(`resume/get_history/${id}`, 'GET', '', token)
    Api(`bench/getBenchActivityList`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data.result);
          if (response.data.code === 200) {
            setClients(response.data.result);
            handleOpenclient();
          } else {
            handleOpenclient();
            // toast.error(response.data.message, +'!');
          }
        } handleOpenclient();
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getClientSub = async () => {
    const token = localStorage.getItem('token');
    Api(`company/active_clients`, 'GET', '', token)
      .then((response) => {
        // console.log('==========resume_submission=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setClientlst(response.data.data);
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  // const getClients = async () => {
  //   const token = localStorage.getItem('token');
  //   Api(`resume/get_client_for_resume/${row._id}`, 'GET', '', token)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log('=========get_client_for_resume======>>>>>>>', response);
  //         if (response.data.code === 200) {
  //           setClients(response.data.data);
  //           console.log('client========', JSON.stringify(response.data));
  //           handleOpenclient();
  //         } else {
  //           // toast.error(response.data.message, +'!');
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //       // toast.error(err, +'!');
  //     });
  // };
  const styleSkill = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleSkillset11 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p:3,
    // paddingTop:3,
    // paddingBottom:3,
    borderRadius: 3,
  };


  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
   p:3,
    borderRadius: 3,
  };

  const styleSkillset1 = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '30%', sm: '50%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleSkillset2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '25%', sm: '40%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleClients1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '40%', sm: '60%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const childFuc = (e) => {
    setCityy(e);
    if (e.length >= 3) {
      getCity(e);
    } else if (e.length === 0) {
      setCityy(e);
      setcityData([]);
      setCityandstate({});
    }
  };

const [zipcode, setZipcode] = useState(row.zipCode)
const [stateCodee, setStateCodee] = useState('')
const [value1, setValue1] = useState({zip:row.zipCode})
  const fullVal = (val) => {
    console.log("====val", val);
    setCityandstate(val);
    setStateCodee(val.stateCode)
    setCityy(val.city);
    setValue({ ...value, zip: val.zip || val.postal });
    setValue1({ ...value1, zip: val.zip || val.postal });
    console.log("=====value.zip", value.zip)
    setZipcode(val.zip)
    // setValue1({ ...value1, zip: val.zip || val.postal});
  };

  // console.log("=====zipCode", zipCode)
  const [esubdata, setEsubdata] = useState({
    subjobid: row.jobId,
    expectedCom: row.expectedCompensation,
    comType: row.chargeType, 
    pname: row.positionName,
    cname: row.clientName,
    tax: row.taxTerm,
    country: row.country,
    jobType:row.jobType,
    recruitorName:row.recruitorName,
    recruitorCompany: row.recruitorCompany,
    automailer: row.automailer,
    emailSubject: row.emailSubject,
    phoneNumber: row.phoneNumber,
    emailId:row.emailId,
state:'',
city:cityy,
zipCode:zipcode,
note:row.note
 
  });
  const setLevel = (subId) => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: subId,
      level: leveltype,
      timeZone: leveltimez,
      date: leveldate,
      time: leveltime,
    };
    console.log('body', body);

    Api(`resume/set_level`, 'POST', body, token)
      .then((response) => {
        console.log('====update Notes====', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun2();
            setLevelM(false);
            toast.success(response.data.message);
            // setEditable(!editable);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
      });
  };

  const Submitted = async (row) => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      // resumeId: row.resumeId,
      status: subStatus,
      note: `Marked ${subStatus} ${statusNote}`,
      // stepWiseStatus: subStatus,
      bench_id:row.benchId,
    };
    // Api(`resume/update_sub_status`, 'POST', body, token)
    Api(`bench/updateSubmitSubStatus`, 'POST', body, token)
      .then((response) => {
        console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setLevelM(false);
            toast.success(response.data.message);
            console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };
  const setFav = async (data) => {
    // alert(data)
    const token = localStorage.getItem('token');
    const body = {
      resumeId: row._id,
      // favStatus: data,
    };
    Api(`resume/favourite`, 'POST', body, token)
      .then((response) => {
        console.log('==========resume/favourite=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun3();
            // toast.success(response.data.message);
            //  if(data === 'favourite'){
            //    fun3()
            //  }
            favFun()
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };
  const setUnFav = async (data) => {
    // alert(data)
    const token = localStorage.getItem('token');
    const body = {
      resumeId: row._id,
      // favStatus: data,
    };
    Api(`resume/unfavourite`, 'POST', body, token)
      .then((response) => {
        console.log('==========resume/favourite=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun3();
            // toast.success(response.data.message);
            //  if(data === 'favourite'){
            //    fun3()
            //  }
            favFun()
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };

  const handleCopy = () => {
    toast.success('Copied to clipboard!', {
      position: toast.POSITION.TOP_CENTER
    });
  };


  // const pinData = () => {
  //   alert("pinned")

  // }
  // const [pinned ,setPinned] = useState(false)

  const pinData = async (row) => {
    // const skillLst = [];
    // finallst.map((item) => {
    //   return skillLst.push(item.title);
    // });
    // setPinned(true)
    const skillLst = [];
    finallst?.map((item) => {
      return skillLst?.push(item?.title);
    });
    console.log('lst', skillLst);

    console.log("row", row);

    // console.log('lst', skillLst);

    const token = localStorage.getItem('token');
    const body = {
      "newCV": "no",
      email: row.email,
      fullName: row.fullName,
      jobTitle: row.jobTitle,
      phone: row.phone,
      currentCompany: row.currCompany,
      skill: skillLst,
      visaType: row.visa,
      taxTerm: row.taxTerm,
      chargeType: row.chargeType,
      expectedCompensation: row.expectedCompensation,
      // cityOrZipcode: row.cityOrZipcode,
      linkedinUrl: row.linkedinUrl,
      reallocate: row.reallocate,
      // zipcode: row.zipcode,
      // stateCode: row.stateCode,
      resumeUrl: row.resumeUrl,
      jobId: row.jobid,
      note: row.notes,
      status: row.status,
      userStatus: row.userStatus,
      // country: row.country,
    };
    console.log('=====create_resume==========>>>>>>>', finallst);
    Api(`resume/create_resume`, 'POST', body, token)
      .then((response) => {
        console.log('=====create_resume==========>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            console.log('success')
          }
          toast.success('Data successfully pinned!');
        }
        else {

          toast.error("Email, fullName, and jobTitle are required fields");
        }

      })
  };

  const [autoMail, setAutoMail] = useState(false);

  const handleChangebtn = (event) => {
    setChecked(event.target.checked);
    console.log('=========mail chk=====>>>', event.target.checked);
    setCheckedEmail1(event.target.checked);
    console.log('checkboxEmail 1=====>>>', event.target.checked);
    setCheckedEmail2(event.target.checked);
    console.log('checkboxEmail 2=====>>>', event.target.checked);
    setCheckedEmail3(event.target.checked);
    console.log('checkboxEmail 3=====>>>', event.target.checked);
    // handleChangeEmail1()
    // handleChangeEmail2()
    // handleChangeEmail3()
  };

  const handleChangeEmail1 = (event) => {
    console.log('checkboxEmail 1=====>>>', event.target.checked);
    setCheckedEmail1(event.target.checked);
  };


  const handleChangeEmail2 = (event) => {
    console.log('checkboxEmail 2=====>>>', event.target.checked);
    setCheckedEmail2(event.target.checked);
  };

  const handleChangeEmail3 = (event) => {
    console.log('checkboxEmail 3=====>>>', event.target.checked);
    setCheckedEmail3(event.target.checked);
  };
  // const [autoEmail1, setAutoEmail1] = useState(false);

  // const toggleEmail1 = () => {
  //   setAutoEmail1(!autoEmail1);
  // };

  // const [autoEmail2, setAutoEmail2] = useState(false);

  // const toggleEmail2 = () => {
  //   setAutoEmail2(!autoEmail2);
  // };

  // const [autoEmail3, setAutoEmail3] = useState(false);

  // const toggleEmail3 = () => {
  //   setAutoEmail3(!autoEmail3);
  // };

  console.log(" rec row====>>>+++", row)
  console.log("row.resumeUrl[0]===>>>>>>>", row.additionalResumes)

  const [newSkill, setNewSkill] = useState([])
  const [details1, setDetails1] = useState([])
  console.log("details1", details1)

  const [details2, setDetails2] = useState([])
  console.log("details2", details2)

  const [details3, setDetails3] = useState([])
  console.log("details3", details3)

  const [details4, setDetails4] = useState([])
  console.log("details4", details4)

  console.log("<<<<<<<< >>>>> newSkill", newSkill)

  const [skillss, setSkillss] = useState([])
  console.log("skillss", skillss)

  const validSkills = row.skill.filter(item => item !== '' && item !== null);
  const validSkills1 = newSkill.filter(item => item !== '' && item !== null);

  const [hideButton, setHideButton] = useState(true)
  const [hideCross, setHideCross] = useState(true)

  const handleDeleteSkill = (index) => {
    console.log("===============indx", index)
    console.log("====validSkills", validSkills)

    const arrr = validSkills.filter((_, i) => i !== index);
    const arrr1 = validSkills1.filter((_, i) => i !== index);
    console.log("arrr", arrr1)
    setNewSkill(arrr1)
    // setSkill(arrr)  
    // setSkillss(arrr); 
  }

  const [skillLst1, setSkillLst1] = useState([]);
  // const [finallst, setFinallst] = useState([]);

  // useEffect(() => {
  //   getSkills1();
  // }, []);

  // const getSkills1 = async () => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const response = await Api(`resume/get_skills`, 'GET', '', token);

  //     if (response.data.code === 200) {
  //       const skillsData = response.data.result;
  //       const updatedSkills = skillsData.map((item) => ({ title: item.skillName }));
  //       setSkillLst1(updatedSkills);
  //       console.log('Updated Skills:', updatedSkills);
  //     } else {
  //       setSkillLst1([{ title: '' }]);
  //       toast.warn(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching skills:', error);
  //     toast.error('Failed to fetch skills!');
  //   }
  // };
  const [loading12, setLoading12] = useState(false);

  const deleteSkill = async () => {
    setLoading12(true)
    console.log('===========update', newSkill);
    const updatedSkill = [];
    newSkill?.map((item) => {
      return updatedSkill.push(item);
    });
    console.log('=======after map skill', updatedSkill);

    const token = localStorage.getItem('token');
    const body = {
      // "newCV": "no",
      // resumeId: details4,
      bench_id: details4,
      fullName: details1,
      email: details2,
      jobTitle: details3,
      skill: updatedSkill,
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          toast.success(Response.data.message);
          fun();
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            fun();
            setLoading12(false)
            // console.log('=====>', Response);
            setHideButton(true)
            setHideCross(true)
            console.log('=====>', Response);
          } else {
            console.log('+++======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const [loading11, setLoading11] = useState(false);
  
  const AdddSkill = async () => {
     setLoading11(true)
    console.log('===========update', skillss);
    const updatedSkill = [];
    
    skillss?.forEach((item) => {
      const lowerCaseSkill = item.title.toLowerCase();
      if (!newSkill.map(skill => skill.toLowerCase()).includes(lowerCaseSkill)) {
        updatedSkill.push(item.title); 
      }
    });
  
    const combinedSkills = [...newSkill, ...updatedSkill];
    const uniqueSkills = [...new Set(combinedSkills.map(skill => skill.toLowerCase()))];  
  
    const token = localStorage.getItem('token');
    const body = {
      // "newCV": "no",
      bench_id: details4,
      fullName: details1,
      email: details2,
      jobTitle: details3,
      skill: uniqueSkills,
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          toast.success(Response.data.message);
          fun();
          handleCloses()
          setLoading11(false)
          handleSkillClose();
                // handleCloses()
                setSkillss([]);
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            fun();
            handleCloses()
            setLoading11(false)
          handleSkillClose();
                // handleCloses()
                setSkillss([]);
            console.log('=====>', Response);
          } else {
            console.log('+++======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const handleDateChange = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setValue((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  const handleDateChange1 = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setValue((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  // console.log("=======value=====", value.validity)

  const resumeProspect = async () => {
    const token = localStorage.getItem('token');
    const body = {
      "bench_id":deleteId,
    }
    console.log('=====body====>>>', body);
    Api(`bench/deleteprospect`, 'POST', body, token).then((response) => {
      console.log('==========props=====>>>', response);
      if (response.status === 200) {
        fun()
        handleCloseDeleteModal()
        toast.success(response.data.resmessage);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          // getResumes()
          // fun()
          // handleCloseDeleteModal()
        }
      }
    })
      .catch((err) => { });
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('')
  const handleOpenDeleteModal = (id) => {
    console.log("=============================id ", id)
    setDeleteId(id)
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  
  const [dropRec, setDropRec] = useState([])
  
  // useEffect(()=> {
  //   recAndCompList()
  // },[])
  
  // const [dropComp, setDropComp] = useState([])
  // const recAndCompList = () => {  
  //   // alert(term)
  //   // const body = {
  //     //    "recruitorName":rec,
  //     //    "recruitorCompany":comp
  //     // }
  //   const token = localStorage.getItem('token');
  //   Api(`bench/recruitorDropDownList`, 'GET', '', token)
  //     .then((response) => {
  //       console.log('Response:---------', response);
  //       if (response.status === 200) { 
  //         setDropComp(response.data.result);
  //         // setDropRec(response.data.result);
  //         if (response.data.code === 200) {
  //           console.log('response.data.result', response.data.result)
  //         } else {
  //           console.log('Error:', response.data.message);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error); 
  //     });
  // };

  // useEffect(()=> {
  //   recList()
  // },[esubdata.recruitorCompany])

  const recList = () => {  
    // alert(term)
    const body = {
      //  "recruitorName":rec,
       "recruitorCompany":esubdata.recruitorCompany
    }
    const token = localStorage.getItem('token');
    Api(`bench/recruitorNameDropDownList`, 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) { 
          // setDropComp(response.data.result);
          setDropRec(response.data.result);
          const resResult = response.data.result;
          const recResult1 = resResult.map((item)=>(item))
          setEsubdata({ ...esubdata, recruitorName: recResult1[0].recruitorName,
            emailId:recResult1[0].email,
              phoneNumber:recResult1[0].phone
          });  
          if (response.data.code === 200) {
            console.log('response.data.result', response.data.result)
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error); 
      });
  };

   const [val, setVal] = useState({
    recruitorName: '',
    recruitorCompany: '',
    phoneNumber: '',
    emailId: '',
    submitTo: ''
  })

  const [filtered, setFiltered] = useState(dropComp || dropRec);

  const handleInputChangeComp = (event, newValue) => {
    const inputValue = newValue || event?.target.value || '';
    setEsubdata({ ...esubdata, recruitorCompany: inputValue,  }); 
    // recAndCompList('', inputValue); 
    const filtered = dropComp?.filter(city =>
      city?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFiltered(filtered);
  };

  const handleInputChangeRec = (event, newValue) => {
    const inputValue = newValue || event?.target.value || '';
    setEsubdata({ ...esubdata, recruitorName: inputValue }); 
    // recAndCompList(inputValue, ''); 
    const filtered = dropRec?.filter(city =>
      city?.recruitorName?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFiltered(filtered);
  };
    
  const handleChangeComp11 = (event, newValue) => {
    if (newValue) {
        setEsubdata({ ...esubdata, recruitorCompany: newValue });
        const Recruitor = dropComp.find(city => city === newValue);
        if (Recruitor) {
            setEsubdata({ ...esubdata, recruitorName: Recruitor.recruitorName,
              emailId:Recruitor.email,
              phoneNumber:Recruitor.phone
             }); 
        } 
    } else {
        setEsubdata({ ...esubdata, recruitorCompany: '', recruitorName: '' }); 
    }
};

const handleChangeRec = (e) => {
  const selectedRecruiter = dropRec.find(item => item.recruitorName === e.target.value);
  if (selectedRecruiter) {
    setEsubdata({
      ...esubdata,
      recruitorName: selectedRecruiter.recruitorName,
      emailId:selectedRecruiter.email, 
      phoneNumber: selectedRecruiter.phone
    });
  } else {
    setEsubdata({ ...esubdata, recruitorName: '', emailId: '', phoneNumber: '' });
  }
};

const handleChangeComp = (e) => {
  setEsubdata({...esubdata, recruitorCompany:e.target.value})
  recList()
};

const [showHide, setShowHide] = useState(true)
const [showHide1, setShowHide1] = useState(true)

const optionsDate = { 
  year: 'numeric', 
  month: 'short', 
  day: 'numeric', 
  timeZone: 'UTC'
};

const optionsTime = { 
  hour: '2-digit', 
  minute: '2-digit', 
  hour12: false,
  timeZone: 'UTC'
};

  return (

    <>
      <TableRow

        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        {filterTab === 'worksheet' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{}} >
              {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
              {/* Upd - {moment(row.updatedAt).format('lll')} */}
              Up - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
            </Typography>
            <Typography sx={{}} >
              {/* Cr - {moment(row.createdAt).format('lll')} */}
              Cr - {`${new Date(row?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
            </Typography>
            <Stack sx={{ flexDirection: 'row' }}>
           
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEdit()}
              >
                Edit
              </Typography>
           
              {/* {row.favouriteStatus === true ? (

                <Iconify
                  icon="ph:heart-fill"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => setUnFav()}
                />
              ) : (
                <Iconify
                  icon="ph:heart"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => {
                    // setTimeout(() => {
                    // favFun();
                    setFav('favourite');
                    // }, 1000);
                  }}
                />
              )} */}
              {/* {pinned && (
                <Iconify
                  icon="icon-park-solid:pin"
                  color='#2ECC71'
                  sx={{ m: 0.3, ml: 8, fontSize: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => pinData(row)}
                />
              )} */}

            </Stack>
          </TableCell>
        )}

{filterTab === "inactive" && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{}} >
              {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
              {/* Upd - {moment(row.updatedAt).format('lll')} */}
              Up - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
            </Typography>
            <Typography sx={{}} >
              {/* Cr- {moment(row.createdAt).format('lll')} */}
              Cr- {`${new Date(row?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
            </Typography>
            <Stack sx={{ flexDirection: 'row' }}>
           <Typography>-</Typography>
              {/* <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEdit()}
              >
                Edit
              </Typography>
            */} 
              {/* {row.favouriteStatus === true ? (

                <Iconify
                  icon="ph:heart-fill"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => setUnFav()}
                />
              ) : (
                <Iconify
                  icon="ph:heart"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => {
                    // setTimeout(() => {
                    // favFun();
                    setFav('favourite');
                    // }, 1000);
                  }}
                />
              )} */}
              {/* {pinned && (
                <Iconify
                  icon="icon-park-solid:pin"
                  color='#2ECC71'
                  sx={{ m: 0.3, ml: 8, fontSize: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => pinData(row)}
                />
              )} */}

            </Stack>
          </TableCell>
        )}

        {/* {filterTab === 'favourites' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ marginTop: "-21px" }} >
              Updated - {moment(row.updatedAt).format('ll')}
            </Typography>
            <Typography sx={{}}>
             
            </Typography>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEdit()}
              >
                Edit
              </Typography>

              <Iconify
                icon="ph:heart-fill"
                color={'text.secondary'}
                sx={{ m: 0.3, ml: 1, height: 15, width: 15, cursor: 'pointer' }}
                hover
                onClick={() => setUnFav()}
              />

            </Stack>
          </TableCell>
        )} */}
        {filterTab === 'submitted' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ marginTop: "-21px" }}  >
              {/* {moment(`${row.createdAt}`).format('lll')} */}
              {`${new Date(row?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
            </Typography>
            <Typography sx={{}} >
              {/* Since {moment().diff(row.createdAt, 'months')} months */}
              {/* Created At - {moment(row.createdAt).format('ll')} */}

            </Typography>
            {row.status === "submitted" && (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => { getClientSub(); openEsub(row) }}
            >
              Edit
            </Typography>
              )}
          </TableCell>
        )}
        {filterTab === 'interview' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ color: 'text.secondary', cursor: 'pointer', marginTop: "-21px" }} onClick={handleopeninterview}>
              {/* {moment(`${row.interviewDate}`).format('lll')} */}
              {`${new Date(row?.interviewDate).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.interviewDate).getHours()}`}:{`${new Date(row?.interviewDate).getMinutes()}`}
            </Typography>
            <Typography sx={{}} >
              {/* Since {moment().diff(row.createdAt, 'months')} months */}
              {/* Created At - {moment(row.createdAt).format('ll')} */}

            </Typography>
            <Typography>&nbsp;</Typography>
            {/* <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer', }}
            onClick={handleOpen1}
          >
            Edit
          </Typography> */}
          </TableCell>
        )}
        {filterTab === 'placement' && (
          <TableCell sx={{ py: 0 }}>
            <Typography
              sx={{ cursor: 'pointer', marginTop: "-21px" }}
            //  onClick={handleopeninterview}
            >
              {/* {row.startStatus === ''
                ? moment(`${row.tentativeStartDate}`).format('ll')
                : moment(`${row.joiningDate}`).format('ll')} */}
                {/* {moment(`${row.tentativeStartDate}`).format('lll')} */}
                {`${new Date(row?.tentativeStartDate).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.tentativeStartDate).getHours()}`}:{`${new Date(row?.tentativeStartDate).getMinutes()}`}
            </Typography>
            {row.terminatedAt === null ? (
              <Typography sx={{}} >
                {/* Since {moment().diff(row.createdAt, 'months')} months */}
                {/* Created At - {moment(row.createdAt).format('ll')} */}

              </Typography>
            ) : (
              <Typography sx={{}} >
                To
              </Typography>
            )}
            {row.terminatedAt !== null ? (
              <Typography sx={{ marginBottom: "-25px" }}>
                {/* {moment(`${row.terminatedAt}`).format('lll')} */}
                {`${new Date(row?.terminatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.terminatedAt).getHours()}`}:{`${new Date(row?.terminatedAt).getMinutes()}`}
                </Typography>
            ) : (
              <Typography sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openEplace()}>edit</Typography>
            )}
          </TableCell>
        )}
        {filterTab === 'backout' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ color: 'text.secondary', cursor: 'pointer', marginTop: "-21px" }} onClick={handleopeninterview}>
              {/* {moment(`${row.updatedAt}`).format('lll')} */}
              {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
            </Typography>
            <Typography sx={{}} >
              {/* Since {moment().diff(row.createdAt, 'months')} months */}
              {/* Created At - {moment(row.createdAt).format('ll')} */}

            </Typography>
            <Typography>&nbsp;</Typography>
            {/* <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer', }}
            onClick={handleOpen1}
          >
            Edit
          </Typography> */}
          </TableCell>
        )}
        {filterTab === 'placement' ? (
          <TableCell sx={{ py: 0, maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Stack sx={{ flexDirection: 'row' }}>
              {/* {row.userStatus === 'fake' || row.userStatus === 'blacklisted' ? ( */}
              {row.activeStatus === 'inactive' || row.status === 'backout' ? (
                <Typography
                  sx={{ fontWeight: '600', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  color={'red'}
                  onClick={() =>
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills,
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >
                 
                  {row.fullName}
                </Typography>
              ) : (filterTab === 'placement' && row.status === 'placement' && row.startStatus === 'started')
                 ?(
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color={'#2ECC71'}
                  onClick={() =>
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills, 
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color={'text.secondary'}
                  onClick={() =>
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills, 
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              )}
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                // color={row.linkedinUrl ? 'text.textG' : 'text.disabled'}
                sx={{
                  ml: 1, height: 18, width: 18,
                  cursor: 'pointer'
                  // opacity: row.linkedinUrl ? 1 : 0.5,
                }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              // onClick={() => {
              //   if (row.linkedinUrl) {
              //     window.open(row.linkedinUrl, '_blank');
              //   }
              // }}
              />
            </Stack>
            {/* <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
              {row.jobTitlee}
            </Typography>
            <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
              {row.currentCompanyy ? row.currentCompanyy : '-'}
            </Typography> */}

{(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
      <Stack sx={{flexDirection:"row"}}>
      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 160 }}>
        
        {/* {row?.phone ? row?.phone:"-"} */}
        {/* {row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim() ? row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim() :"-" } */}
        {
    row?.phone?.trim()
    ? row?.phone?.trim()
        ?.replace(/\s+/g, '')
        ?.replace(/^(\+?\d{1,3})?(\d{10,20})$/, (match, p1, p2) => {
          if (p1) {
            const countryCode = p1;
            const lastDigits = p2?.slice(-10);
            return `${countryCode} ${lastDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
          }
          return p2?.replace(/(\d{3})(\d{3})(\d{4})(\d{3,4})?/, '$1 $2 $3 $4')?.trim();
        })
    : ''
  }
      </Typography>
      {row?.phone && (

      <CopyToClipboard text= {row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
      )}
       </Stack>
              <Stack sx={{flexDirection:"row"}}>
      <Typography sx={{ maxWidth: 140,  overflow: 'hidden', textOverflow: 'ellipsis',textTransform: 'none' }} color={'text.textG'}>
        {row.email ? row.email : '-'}
      </Typography>
      {row.email && (

      <CopyToClipboard text={row.email} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
      )}
              </Stack> 
    </>
  ) : ( 
    <>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row?.jobTitle ? row?.jobTitle:'-'}
      </Typography>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {row.currentCompany ? row.currentCompany : '-'}
      </Typography>
    </>
)}
          </TableCell>
        ) : (
          <TableCell sx={{ py: 0 }}>
            <Stack sx={{ flexDirection: 'row' }}>
            {row.activeStatus === 'inactive' || row.status === 'backout' ? ( 
                <Typography
                  sx={{ fontWeight: '600', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.9rem' }}
                  color={'red'}
                  onClick={() =>
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills,
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >

                  {row.fullName}
                </Typography>
              ) : (filterTab === 'worksheet' && row.status === 'started') || row.status === 'placement' ? (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '0.9rem'
                  }}
                  color={'#2ECC71'}
                  onClick={() =>
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills,
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '0.9rem'
                  }}
                  color={'text.secondary'}
                  onClick={() =>
                    // navigate('/resumeDetail', {
                    navigate('/benchresumedetails', {
                      state: {
                        addedSkills,
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' || filterTab === 'favourites'
                              ? 'submitted'
                              : '' || filterTab === 'interview'
                                ? 'placement'
                                : '' || filterTab === 'placement'
                                  ? 'start'
                                  : '',
                      },
                    })
                  }
                >
                  {(row.fullName) ? row.fullName : "#"}
                </Typography>
              )}
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                // color={row.linkedinUrl ? 'text.textG' : 'text.disabled'}

                sx={{
                  m: 0.3, height: 15, width: 15, cursor: 'pointer',
                }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              // onClick={() => {
              //   if (row.linkedinUrl) {
              //     window.open(row.linkedinUrl, '_blank');
              //   }
              // }}
              />
            </Stack>
            {(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
    <Stack sx={{flexDirection:"row"}}>
      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 160 }}>
        
        {/* {row?.phone ? row?.phone:"-"} */}
        {/* {row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim() ? row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim():'-'} */}
        {
    row?.phone?.trim()
    ? row?.phone?.trim()
        ?.replace(/\s+/g, '')
        ?.replace(/^(\+?\d{1,3})?(\d{10,20})$/, (match, p1, p2) => {
          if (p1) {
            const countryCode = p1;
            const lastDigits = p2?.slice(-10);
            return `${countryCode} ${lastDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
          }
          return p2?.replace(/(\d{3})(\d{3})(\d{4})(\d{3,4})?/, '$1 $2 $3 $4')?.trim();
        })
    : ''
  }
      </Typography>
      {row?.phone && (

      <CopyToClipboard text={row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
      )}
       </Stack>
              <Stack sx={{flexDirection:"row"}}>
      <Typography sx={{ maxWidth: 140,  overflow: 'hidden', textOverflow: 'ellipsis',textTransform: 'none' }} color={'text.textG'}>
        {row.email ? row.email : '-'}
      </Typography>
      {row.email && (

      <CopyToClipboard text={row.email} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
      )}
              </Stack>
    </>
  ) : (
    <>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row?.jobTitle? row?.jobTitle:'-'}
      </Typography>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {row.currentCompany ? row.currentCompany : '-'}
      </Typography>
    </>
)}
          </TableCell>
        )}

        <TableCell sx={{ py: 0 }}>
          {/* {row.phone ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ flexDirection: 'row', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.phone}
              </Typography>
              <CopyToClipboard text={row.phone} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
          {row.email ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
                sx={{ textTransform: 'lowercase', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {row.email}
              </Typography>
              <CopyToClipboard text={row.email} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography>-</Typography>
          )} */}
          {(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
      {/* <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row?.summary ? row?.summary :'-'}
      </Typography> */}
       <Typography sx={{ color: 'text.secondary', textDecoration:'underline' ,cursor: 'pointer'  }} 
   onClick={()=>handleOpenclient1(row)}>
  {/* {row.summary && row.summary.length > 10 
    ? `${row.summary.substring(0, 10)}...` 
    : row.summary || '-'} */}
    Summary
</Typography>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {row.visaType ? row.visaType : '-'}
      </Typography>
      {/* <Typography>-</Typography> */}
      {row.country ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography sx={{}} color={'text.textG'}>
                {/* {row.cityOrZipcode ? row.cityOrZipcode: '-'} */}
                {row.cityOrZipcode ? row.cityOrZipcode: '-'}
              </Typography>
              <Typography sx={{}} color={'text.textG'}>
                {/* {", "}{row?.zipCode ? row.zipCode: ''} */}
                {", "}{row?.stateCode ? row.stateCode: ''}
              </Typography>
              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : ''}
            </Stack>
          ) : (
            <Typography color={'text.textG'}>-</Typography>
          )}
    </>  
  ) : (
    <>
      {row.phone ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ flexDirection: 'row', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {/* {row.phone} */}
                {/* {row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} */}
                {
    row?.phone?.trim()
    ? row?.phone?.trim()
        ?.replace(/\s+/g, '')
        ?.replace(/^(\+?\d{1,3})?(\d{10,20})$/, (match, p1, p2) => {
          if (p1) {
            const countryCode = p1;
            const lastDigits = p2?.slice(-10);
            return `${countryCode} ${lastDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
          }
          return p2?.replace(/(\d{3})(\d{3})(\d{4})(\d{3,4})?/, '$1 $2 $3 $4')?.trim();
        })
    : ''
  }
              </Typography>
              {row.phone && (

              <CopyToClipboard text={row?.phone?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
              )}
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
     {row.email ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
                sx={{ maxWidth: 140, overflow: 'hidden', textOverflow: 'ellipsis',textTransform: 'none' }}
              >
                {row.email}
              </Typography>
              {row.email && (

              <CopyToClipboard text={row.email} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
              )}
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}

{row.country ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography sx={{}} color={'text.textG'}>
                {row.cityOrZipcode ? row.cityOrZipcode: '-'}
              </Typography>
              <Typography sx={{}} color={'text.textG'}>
                {", "}{row.stateCode ? row.stateCode: ''}
              </Typography>
              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : ''}
            </Stack>
          ) : (
            <Typography color={'text.textG'}>-</Typography>
          )}
    </>
)}
          {/* {row.city || row.stateCode || row.reallocate === 'yes' ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography sx={{}} color={'text.textG'}>
                {row.city ? row.city : null}
              </Typography>
              <Typography sx={{}} color={'text.textG'}>
                {row.stateCode ? row.stateCode : null}
              </Typography>

              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : null}
            </Stack>
          ) : (
            <Typography color={'text.textG'}>-</Typography>
          )} */}
        </TableCell>

        {(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
     <TableCell sx={{  width: 170 }}>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis', 
        textTransform: "capitalize"}}>
        {row?.clientName? row?.clientName: '-'}
      </Typography> 
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {row.positionName ? row.positionName : '-'}
      </Typography>
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {/* {row.clientCity ? row.clientCity : '-'}{", "}{row.clientAddress ? row.clientAddress : ''} */}
        {row.clientCity ? row.clientCity : '-'}{", "}{row.clientState ? row.clientState : ''}
      </Typography>
      </TableCell>
    </>
  ) : (
    <>
       <TableCell sx={{  width: 170 }}>
          {row.skill && row.skill.length > 0 ? (
            <Grid container sx={{ maxWidth: 160, height: 44, overflow: 'hidden', textOverflow: 'ellipsis', gap: 0.3 }}>
              {row.skill?.map((item, index) => (
                item && (
                  <Typography key={index} sx={{}}>
                    {item}
                    {index < row.skill?.filter(skill => skill).length - 1 && ' | '}
                  </Typography>
                )
              ))}
            </Grid>
          ) : (
            <Typography>-</Typography>
          )}
          {row.skill && row.skill.length > 0 ? (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                handleOpens(); setNewSkill(row.skill); setDetails1(row.fullName);
                setDetails2(row.email); setDetails3(row.jobTitle); setDetails4(row._id)
              }}

            >
              view all
            </Typography>
          ) : null}
        </TableCell>
    </>
)}
        {/* <TableCell sx={{ py: 0, width: 170 }}>
          {row.skill && row.skill.length > 0 ? (
            <Grid container sx={{ maxWidth: 160, height: 44, overflow: 'hidden', textOverflow: 'ellipsis', gap: 0.3 }}>
              {row.skill.map((item, index) => (
                item && (
                  <Typography key={index} sx={{}}>
                    {item}
                    {index < row.skill.filter(skill => skill).length - 1 && ' | '}
                  </Typography>
                )
              ))}
            </Grid>
          ) : (
            <Typography>-</Typography>
          )}
          {row.skill && row.skill.length > 0 ? (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                handleOpens(); setNewSkill(row.skill); setDetails1(row.fullName);
                setDetails2(row.email); setDetails3(row.jobTitle); setDetails4(row._id)
              }}

            >
              view all
            </Typography>
          ) : null}
        </TableCell> */}

        <TableCell sx={{ py: 0, width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>

        {(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
     {filterTab === 'placement' ? (
  <Typography sx={{}}>
    {row.offeredJob && (row.annualSalary || row.hourlyRateRateOffered) && (
      <>
        ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
        {row.chargeType === 'hourly' ? '/Hour' : 'K'}
      </>
    )}
  </Typography>
) : (
  <Typography sx={{}}>
    {row.expectedCompensation && (
      <>
        ${row.expectedCompensation}
        {row.chargeType === 'hourly' ? '/Hour' : 'K'}
      </>
    )}
  </Typography>
)}

          <Typography sx={{ textTransform: "capitalize" }} color={'text.textG'}>
            {row.taxTerm ? row.taxTerm : '-'}
          </Typography>
          
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {row.jobType ? row.jobType : '-'}
      </Typography>
    </>
  ) : ( <>    
  <Box sx={{ display: 'flex', alignItems: 'center', mt: -0.6, color: 'text.secondary' }}>
  <Typography sx={{ paddingRight: 1 }}>
    {row.visaType ? row.visaType : '-'}
  </Typography>
  {row.visaTransfer === "yes" ? (
    <Iconify icon="fluent:arrow-swap-16-filled" width="1.1rem"/>
  ):null}
</Box>
{/* {row.visaType !== 'USC' && row.visaType !== 'GC' && row.visaType !== 'GCEAD' && 
row.visaType !== 'TN' && row.visaType !== 'Canadian citizen' && row.visaType !== 'Permanent Resident'?( */}

          <Typography sx={{}}>
            {/* {row.validity ? dayjs(row.validity).format('D MMM YYYY') : '-'} */}
            {row.validity ? (
              <>
Validity - {moment(row.validity).format('ll')}
</>
            ):(
"-"
            )}
            
            </Typography>
{/* ):(
  "-" */}
{/* ) } */}
            {filterTab === 'placement' ? (
  <Typography sx={{}}>
    {row.offeredJob && (row.annualSalary || row.hourlyRateRateOffered) ? (
      <>
        ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
        {row.chargeType === 'hourly' ? '/Hour' : 'K'}
      </>
    ) : (
      '-'
    )}
  </Typography>
) : (
  <Stack sx={{ flexDirection: 'row' }}>
    <Typography sx={{}}>
      {row.expectedCompensation ? (
        <>
          ${row.expectedCompensation}
          {row.chargeType === 'hourly' ? '/Hour' : 'K'}
        </>
      ) : (
        '-'
      )}
    </Typography>
    <Typography sx={{ ml: 0.5, textTransform: "capitalize"}} color={'text.textG'}>
      {row.taxTerm ? row.taxTerm : ''}
    </Typography>
  </Stack>
)}

          {/* {filterTab === 'placement' ? (
            <Typography sx={{}} >
              ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
              {row.chargeType === 'hourly' ? '/Hour' : 'K'}
            </Typography>
          ) : (
            
            <Stack sx={{flexDirection:"row"}}>
            <Typography sx={{}} >
              ${row.expectedCompensation}
              {row.chargeType === 'hourly' ? '/Hour' : 'K'} 
            </Typography>

<Typography sx={{ ml:0.5 }} color={'text.textG'}>
{row.taxTerm ? row.taxTerm : '-'}
</Typography>
</Stack>
          )} */}
          {/* <Typography sx={{ textTransform: 'uppercase' }} color={'text.textG'}>
            {row.taxTerm ? row.taxTerm : '-'}
          </Typography> */}
       </> 
)}

        {/* <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1, color: 'text.secondary' }}>
  <Typography sx={{ paddingRight: 1 }}>
    {row.visaType ? row.visaType : '-'}
  </Typography>
  <Iconify icon="fluent:arrow-swap-16-filled" width="1.4rem"/>
</Box>
          <Typography sx={{}}>{row.validity ? dayjs(row.validity).format('D MMM YYYY') : '-'}</Typography>
          
          {filterTab === 'placement' ? (
            <Typography sx={{}} >
              ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
              {row.chargeType === 'hourly' ? '/Hour' : 'K'}
            </Typography>
          ) : (
            <Typography sx={{}} >
              ${row.expectedCompensation}
              {row.chargeType === 'hourly' ? '/Hour' : 'K'} 
            </Typography>
          )}
          <Typography sx={{ textTransform: 'uppercase' }} color={'text.textG'}>
            {row.taxTerm ? row.taxTerm : '-'}
          </Typography> */}
        </TableCell>
        <TableCell>

        {(filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
  filterTab === "backout") ? (
    <>
    
      <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row?.recruitorName ? row?.recruitorName:"-"}
      </Typography>
      <Stack sx={{ flexDirection: 'row' }}>
      <Typography sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
        {/* {row.phoneNumber ? row.phoneNumber : '-'} */}
        {/* {row?.phoneNumber?.replace(/(\d{3})(?=\d)/g, '$1 ').trim() ? row?.phoneNumber?.replace(/(\d{3})(?=\d)/g, '$1 ').trim():"-"} */}
        {
    row?.phone?.trim()
    ? row?.phone?.trim()
        ?.replace(/\s+/g, '')
        ?.replace(/^(\+?\d{1,3})?(\d{10,20})$/, (match, p1, p2) => {
          if (p1) {
            const countryCode = p1;
            const lastDigits = p2?.slice(-10);
            return `${countryCode} ${lastDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
          }
          return p2?.replace(/(\d{3})(\d{3})(\d{4})(\d{3,4})?/, '$1 $2 $3 $4')?.trim();
        })
    : ''
  }
      </Typography>
      {row?.phoneNumber && (

      <CopyToClipboard text= {row?.phoneNumber?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
      )}
              </Stack>
              <Stack sx={{ flexDirection: 'row' }}>
      <Typography sx={{ maxWidth: 140, overflow: 'hidden', textOverflow: 'ellipsis',textTransform: 'none' }} color={'text.textG'}>
        {row.emailId ? row.emailId : '-'}
      </Typography>
      {row?.emailId && (

      <CopyToClipboard text={row.emailId} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
      )}
              </Stack> 
    </>
  ) : ( <>    
  {/* handleCloseclient1 */}
   {/* <Typography sx={{color: 'text.secondary'}}>{row.summary ? row.summary:'-'}</Typography> */}
   <Typography sx={{ color: 'text.secondary', textDecoration: 'underline',cursor: 'pointer', mt:-0.6  }} 
   onClick={()=>handleOpenclient1(row)}>
  {/* {row.summary && row.summary.length > 10 
    ? `${row.summary.substring(0, 10)}...` 
    : row.summary || '-'} */}
    Summary
</Typography>

        <Typography>{"Exp - "}{row.experienceInYear}{" Yrs"}</Typography>
        <Typography sx={{color: 'text.secondary'}}>
          {/* {dayjs(row.DOB).format('MMM/DD/YYYY')} */}
          {row.DOB ? (
  <>
    D.O.B - {moment(row.DOB).format('ll')}
  </>
) : (
  '-'
)}
</Typography>
      </> 
)}

        {/* <Typography sx={{color: 'text.secondary'}}>{row.summary ? row.summary:'-'}</Typography>
        <Typography>{"Exp - "}{row.experienceInYear}{" Yrs"}</Typography>
        <Typography sx={{color: 'text.secondary'}}>{dayjs(row.DOB).format('MMM/DD/YYYY')}
        </Typography> */}
       {/* <Typography>-</Typography> */}
        </TableCell>
        <TableCell sx={{ py: 0, width: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {filterTab === 'interview' && (
            <Typography
              sx={{
                color: 'text.secondary',
                textDecoration: (row.status === 'interview' || row.status !== 'interview') ? 'underline' : 'none',
                // cursor: (row.status === 'interview' || row.stepWiseStatus === "l1" === "l2" === "l3") ? 'pointer' : '',
                // cursor: (row.status === 'interview' || ['l1', 'l2', 'l3, l4, l5'].includes(row.stepWiseStatus)) ? 'pointer' : '',
                cursor: (row.status === 'interview' || ['l1', 'l2', 'l3, l4, l5'].includes(row.interviewSubStatus)) ? 'pointer' : '',

              }}
              onClick={() => {
                if (row.status === 'interview') {
                  navigate('/benchresumedetails', {
                    state: {
                      addedSkills,
                      row: {
                        _id: row._id,
                        resumeId: row.resumeId,
                        status: 'submitted',
                        fullName: row.fullName,
                        resumeUrl: row.resumeUrl,
                        clientName: row.clientName,
                        interviewStatus: row.interviewSubStatus,
                        // interviewStatus: row.stepWiseStatus,
                      },
                      fTab: 'interview',
                    },
                  });
                }
              }}
            >

              {row.status === 'interview' && (
                // <span>{row.stepWiseStatus} </span>
                <span>{row.interviewSubStatus} </span>
              )}

              <span
                style={{
                  color: (row.status === 'interview' || row.status !== 'interview') ? 'inherit' : 'gray',
                }}
              >
                {row.status}
              </span>
            </Typography>
          )}

          {filterTab === 'placement' ? (
            <Typography sx={{}}>{row.startStatus !== '' ? row.startStatus : row.status} </Typography>
          ) : null}
          {filterTab === 'worksheet' ? (
            // <Typography sx={{}}>{row.status}</Typography>
            <Typography sx={{color: 'text.secondary', fontWeight:"bold", cursor: 'pointer'}}
            onClick={()=>{handleOpens1(row._id, row.userStatus)}}>
            {row.userStatus}</Typography> 
          ) : null}

{filterTab === 'inactive' ? (
            // <Typography sx={{}}>{row.status}</Typography>
            <Typography sx={{color: 'text.secondary', fontWeight:"bold", cursor: 'pointer'}}
            onClick={()=>{handleOpens1(row._id, row.userStatus)}}>
            {row.activeStatus}</Typography> 
          ) : null}
{filterTab === 'backout' ? (
            // <Typography sx={{}}>{row.status}</Typography>
            <Typography sx={{color: 'text.secondary', fontWeight:"bold"}}
           >
            {row.status}</Typography> 
          ) : null}

          {filterTab === 'submitted' ? (
            <Typography sx={{ color: 'text.secondary', 
              textDecoration: row.status === "submitted"?'underline':'',  mt:-0.8,
              cursor: 'pointer' }} onClick={() => {
              // if (row.submitStatus === "submitted") {
              if (row.status === "submitted") {
                setLevelM(true)
              }
            }}>
              {/* {row.stepWiseStatus} */}
              {row.submitSubStatus !== "" && row.status === "submitted" ? row.submitSubStatus : row.status}
              {/* {row.status} */}
            </Typography>
          ) : null}

          {/* <Typography>jjj</Typography> */}
          {filterTab === 'worksheet' || filterTab === 'favourites' || filterTab === 'submitted' ||
            filterTab === 'placement' || filterTab === 'interview' || filterTab === 'backout' ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>
              <Typography sx={{}} >
                {/* {row.jobId} */}
                {/* {row.status} */}
              </Typography>
              <Stack sx={{ flexDirection: 'row' }} onClick={(e) => getClients()}>
                <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
                <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                 
                  {row.clients ? `+${row.clients}` : ''}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography sx={{}}>
              {row.jobId ? row.jobId : '-'}
            </Typography>
          )}

          <Typography sx={{}} color={'text.textG'}>
            {row.contactPersonName ? row.contactPersonName : '-'}
          </Typography>
          {/* <Typography>-</Typography> */}
        </TableCell>
        {/* <TableCell sx={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}> */}
        {/* <TableCell sx={{ width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}> */}
          {/* <Typography sx={{}} > */}
            {/* {row.clientName ? row.clientName : row.submitTo} */}
            {/* {row.clientName || '-'} */}
          {/* </Typography> */}
          {/* {filterTab === 'worksheet' || filterTab === 'favourites' ? (
            <Typography sx={{}} >
              {row.jobIdTitle}
            </Typography>
          ) : (
            <Typography sx={{}} >
              {row.type === 'position' ? row.jobIdTitle : row.positionName}
            </Typography>
          )}
    */}
          {/* {filterTab === 'worksheet' || filterTab === 'favourites' ? (
            <Typography sx={{}}>
              {row.jobIdTitle?.length > 15
                ? `${row.jobIdTitle.slice(0, 15)}...`
                : row.jobIdTitle}
            </Typography>
          ) : (
            <Typography sx={{}}>
              {row.type === 'position' ? (
                row.jobIdTitle?.length > 15
                  ? `${row.jobIdTitle.slice(0, 15)}...`
                  : row.jobIdTitle
              ) : (
                row.positionName?.length > 15
                  ? `${row.positionName.slice(0, 15)}...`
                  : row.positionName
              )}
            </Typography>
          )} */}

          {/* <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleOpenn}
          >
            notes
          </Typography> */}
        {/* </TableCell> */}
        <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {/* {filterTab === "submitted"? */}
          { 
            (filterTab === "submitted" || filterTab === "placement" || filterTab === "interview" || 
              filterTab === "backout") 
              ?
           <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
            <Iconify icon="bx:support" fontSize={10} />
            <Typography sx={{}}>Contact</Typography>
          </Stack> 
          :
          <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
          {/* <Iconify icon="bx:support" fontSize={10} /> */}
          <Iconify icon="bx:error" fontSize={17} 
         color={'text.secondary'} 
          />
          <Typography sx={{color:"red"}} onClick={()=>handleOpenDeleteModal(row._id)}>Delete</Typography> 
        </Stack>
          }
         
          <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
            <Iconify icon="ic:baseline-email" fontSize={10} />
            {/* <Typography sx={{}} onClick={() => { navigate('/Email') }}>Email</Typography> */}
            <Typography sx={{}} onClick={() => handleOpenModal((row._id))}>Email</Typography>
          </Stack>
          {/* <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
            <Iconify icon="bx:support" fontSize={10} />
            <Typography sx={{}}>Contact</Typography>
          </Stack> */}
          {row.userStatus !== 'fake' && row.userStatus !== 'blacklisted' ? (
            <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
              {row.startStatus !== 'quit' && row.startStatus !== 'terminated' && row.startStatus !== 'project ended' ? (
                <Iconify icon="icon-park-solid:check-one" fontSize={10} />
              ) : (
                <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
              )}
              {filterTab === 'worksheet' ? (
                <Typography onClick={() => navigate('/benchresumedetails', {
                  state: {
                    addedSkills, row, fTab: 'submitted',
                    ...(row.status === 'placement' && { openModal: "editOrNot" })
                  }
                })}>
                  {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                </Typography>
              ) : null}
              {filterTab === 'favourites' ? (
                <Typography onClick={() => navigate('/benchresumedetails', { state: { row, addedSkills, fTab: 'submitted' } })}>
                  {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                </Typography>
              ) : null}
              {filterTab === 'submitted' ? (
                <Typography onClick={() => navigate('/benchresumedetails', { state: { row, addedSkills, fTab: 'interview' } })}>
                  {row.status === 'submitted' ? 'Interview' : ''}
                  {/* Interview */}
                </Typography>
              ) : null}
              {filterTab === 'interview' ? (
                <Typography onClick={() => navigate('/benchresumedetails', { state: { row, addedSkills, fTab: 'placement' } })}>
                  {row.status === 'interview' ? 'Offer/Reject' : ''}
                </Typography>
              ) : null}
              {filterTab === 'placement' ? (
                <>
                  <Typography
                    onClick={() =>
                      navigate('/benchresumedetails', {
                        state: { row, addedSkills, fTab: 'start', q: row.startStatus === 'started' ? 'quit' : '' },
                      })
                    }
                  >
                    {row.startStatus === '' && row.status === 'placement' ? 'Start/Backout' : ''}
                  </Typography>
                  <Typography
                    onClick={() =>
                      navigate('/benchresumedetails', {
                        state: { row, addedSkills, fTab: 'start', q: row.startStatus === '' ? 'quit' : '' },
                      })
                    }
                  >
                    {row.placementStatus === 'placement' && row.status === 'started' ? 'Quit/Pro.End' : ''}
                  </Typography>
                </>
              ) : null}
              {filterTab === 'backout' ? (
                <Typography onClick={() => navigate('/benchresumedetails', { state: { addedSkills, row, fTab: 'submitted' } })}>
                  {row.status === 'backout' ? 'Resubmit' : ''}
                </Typography>
              ) : null}
            </Stack>
          ) : (
            <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
          )}
        </TableCell>
      </TableRow>
      
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "35%",
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}>
          <Typography variant="h6" color={'#3C6BA5'} textAlign={'center'}>
            Are you sure you want to delete this prospect?
          </Typography>
          <Stack flexDirection={'row'} gap={2} mt={2} sx={{ justifyContent: 'center' }}>
            <Button color="custom" variant="contained" onClick={()=>resumeProspect()}>
              Yes
            </Button>
            <Button color="custom" variant="contained" onClick={handleCloseDeleteModal}>
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal open={opens1} onClose={handleCloses1} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset2]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid container sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} gap={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select
                  // disabled={convertedDis}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Status"
                  InputLabelProps={{ shrink: true }}
                  value={values.status}
                  onChange={(e) => setValues({...values, status:e.target.value})}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'Inactive'}>Inactive</MenuItem>
                 
                </Select>
              </FormControl>
             
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Note... (Max 2000 char)"
                    placeholder="Note..."
                    multiline
                    rows={5}
                    value={values.notes}
                    onChange={(e) => setValues({...values, notes:e.target.value})}
                  />
                </FormControl>
              
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} 
              onClick={(e) => changeStatus()}
              >
                Save
              </Button>

            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={open1} onClose={handleCloseEdit} style={{ borderRadius: 10 }}>
        <Box sx={styleSkill}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            Edit Candidate
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                  />
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  placeholder="Full Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.name}
                  onChange={(e) => setValue({ ...value, name: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Job title"
                  placeholder="Job title"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.jobtitle}
                  onChange={(e) => setValue({ ...value, jobtitle: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Current Company"
                  placeholder="Current Company"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.currCompany}
                  onChange={(e) => setValue({ ...value, currCompany: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.phone}
                  onChange={(e) => setContact(e.target.value)}
                // type="text"
                // inputProps={{
                //   maxLength: 20,
                // }}
                // onChange={(e) => setValue({ ...value, phone: e.target.value })}
                />
                <Autocomplete
                  disablePortal
                  // id="combo-box-demo"
                  options={visalist}
                  value={value.visa}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setValue({ ...value, visa: e.target.innerText });
                    console.log(e.target.innerText);
                  }}
                  renderInput={(params) => <TextField {...params} label="Visa" />}
                />

{!['USC', 'GC', 'GCEAD', 'TN', 'Canadian citizen', 'Permanent Resident'].includes(value?.visa) 
                && (
<FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Visa Transfer</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="yes"
                    name="radio-buttons-group"
                    value={value.visaTransfer}
                    onChange={(e) => setValue({ ...value, visaTransfer: e.target.value })}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >    
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    {/* <FormControlLabel value="1099" control={<Radio />} label="1099" />
                    <FormControlLabel value="T4" control={<Radio />} label="T4" />
                    <FormControlLabel value="INC" control={<Radio />} label="INC" />
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" /> */}
                  </RadioGroup>
                </FormControl>
                )}
                {!['USC', 'GC', 'GCEAD', 'TN', 'Canadian citizen', 'Permanent Resident'].includes(value?.visa) 
                && (
                <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Validity"
                      // slotProps={{ textField: { size: "small" } }}
                      value={value.validity ? dayjs(value.validity) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange(date, 'validity')}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
)}
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Contract (Tax Term) / Fulltime</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="c2c"
                    
                    name="radio-buttons-group"
                    value={value.tax}
                    onChange={(e) => setValue({ ...value, tax: e.target.value,
                         comType: e.target.value === "fulltime"?"Annually":"hourly"
                     })}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >
                    <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                    <FormControlLabel value="c2h" control={<Radio />} label="C2H" />
                    {/* <FormControlLabel value="1099" control={<Radio />} label="1099" /> */}
                    {/* <FormControlLabel value="T4" control={<Radio />} label="T4" /> */}
                    {/* <FormControlLabel value="INC" control={<Radio />} label="INC" /> */}
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                  <Select
                  disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.comType}
                    label="Compensation Type"
                    onChange={(e) => setValue({ ...value, comType: e.target.value })}
                  >
                    <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                    <MenuItem value={'Annually'}>Annual Compensation</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Expected Compensation( In Dollars )*"
                  placeholder="Expected Compensation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.expectedCom}
                  type="text"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: value.comType && (
                      <InputAdornment position="end">{value.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                    ),
                  }}
                  // onChange={(e) => setValue({ ...value, expectedCom: e.target.value })}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^\d*\.?\d*$/.test(newValue)) {
                      setValue({ ...value, expectedCom: newValue });
                    }
                  }}
                />
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.jobid}
                    label="Job Id"
                    onChange={(e) => setValue({ ...value, jobid: e.target.value })}
                  >
                    <MenuItem value={''}>NA</MenuItem>
                    {joblst1.map((item, index) => {
                      return <MenuItem key={index} value={item.jobId}>{item.jobId}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}

<FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.userStatus}
                    label="Change Status"
                    onChange={(e) => setValue({ ...value, userStatus: e.target.value })}
                  >
                    {/* <MenuItem value={'fake'}>Fake</MenuItem> */}
                    {/* <MenuItem value={'blacklisted'}>Blacklisted</MenuItem> */}
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                  </Select>
                </FormControl>

                <Grid item xs={6} sx={{ }}>
                <FormControl fullWidth size="medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      // slotProps={{ textField: { size: "small" } }}
                      value={value.dob ? dayjs(value.dob) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange1(date, 'dateofbirth')}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Grid>
              <FormControl fullWidth sx={{}}>
                  <TextField
                    id="outlined-basic"
                    label="Linkedin URL"
                    placeholder="Linkedin URL"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.linkedinUrl}
                    // type='number'
                    onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}

                  />
                </FormControl>
              </Stack>
              <Stack gap={2}>
               
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"CANADA"}>CANADA</MenuItem>
                  </Select>
                </FormControl>

                <CustomComplete
                  placeholder={'City & Pincode'}
                  option={cityData}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <FormControl>
                  {cityandstate ? (
                    <Stack sx={{ ml: 1, mt: -1, flexDirection: 'row' }}>
                      <Typography color={'text.textG'} variant="body">
                        {cityandstate.city},
                      </Typography>
                      <Typography color={'text.textG'} variant="body" sx={{ ml: 1 }}>
                        {cityandstate.state}
                        {/* {console.log("=====cityandstate.state===>>>", cityandstate.state)} */}
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Zip code"
                    placeholder="Zip code"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.zip}
                    // type="number"
                    onChange={(e) => setValue({ ...value, zip: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">Can Relocate </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes "
                    name="radio-buttons-group"
                    value={value.reloc}
                    onChange={(e) => setValue({ ...value, reloc: e.target.value })}
                    sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}
                  // defaultValue={row}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                {/* </FormControl> */}
                <Stack sx={{ flexDirection: 'row'}}>
                {/* <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Summary
                </Typography> */}
                <TextField
                    id="outlined-multiline-static"
                  label="Summary"
                  placeholder="Summary..."
                  multiline
                  rows={4}
                    sx={{ width: '100%', py: 0.5 }}
                    value={value.summary}
                  
                    onChange={(e) => setValue({ ...value, summary: e.target.value })}
                  />
</Stack>

<FormControl fullWidth sx={{ }}>
      <InputLabel id="years-of-experience-label">Years of Experience</InputLabel>
      <Select
        labelId="years-of-experience-label"
        id="years-of-experience"
       
        label="Years of Experience"
        // onChange={handleChange}
        value={value.experienceInYear}
        // type="number"
        onChange={(e) => setValue({ ...value, experienceInYear: e.target.value })}
      >

<MenuItem value={"1"}>1</MenuItem>
<MenuItem value={"2"}>2</MenuItem>
<MenuItem value={"3"}>3</MenuItem>
<MenuItem value={"4"}>4</MenuItem>
<MenuItem value={"5"}>5</MenuItem>
<MenuItem value={"6"}>6</MenuItem>
<MenuItem value={"7"}>7</MenuItem>
<MenuItem value={"8"}>8</MenuItem>
<MenuItem value={"9"}>9</MenuItem>
<MenuItem value={"10"}>10+</MenuItem>
<MenuItem value={"15"}>15+</MenuItem>
<MenuItem value={"20"}>20+</MenuItem>
<MenuItem value={"25"}>25+</MenuItem>
<MenuItem value={"30"}>30+</MenuItem>
<MenuItem value={"35"}>35+</MenuItem>
<MenuItem value={"40"}>40+</MenuItem>
<MenuItem value={"45"}>45+</MenuItem>

        {/* {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year === 0 ? 'Less than 1 year' : `${year} ${year === 1 ? 'year' : 'years'}`}
          </MenuItem>
        ))} */}
      </Select>
    </FormControl>

                <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setFinallst([...newValue]);
                  }}
                  options={addedSkills || skill}
                  getOptionLabel={(option) => option.title}
                  value={finallst?.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Skills" placeholder="Skills" />}
                />
                {/* <Stack sx={{ position: 'relative', width: '100%' }}>
                  <Autocomplete
                    value={askill}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        addskill(newValue);
                        console.log('first----- ', newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // setAskill({title: newValue.inputValue});
                        console.log('second------ ', newValue.inputValue);
                        addskill(newValue.inputValue);
                      } else {
                        console.log('third----- ', newValue.title);
                        addskill(newValue.title);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={skillset}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        // console.log("re 1-----",option);
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    sx={{}}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Skill" />}
                  />

                  {finallst.length ? (
                    <Stack sx={{ marginTop: 1 }}>
                      <Grid container spacing={2}>
                        {finallst.map((item) => {
                          return (
                            <Grid item>
                              <Item>
                                {item}
                                <Iconify
                                  icon="basil:cross-outline"
                                  sx={{ color: 'white', width: 20, height: 20 }}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => del(item)}
                                />
                              </Item>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Stack>
                  ) : null}
                </Stack> */}
                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload Resume*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                            ml:2.7
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          ref={fileInputRefResume}
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            setDocfile(e.target.files[0]);
                            setChoosefile(false);
                            pdfUpload(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {pdffile && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffile} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfile()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload DL*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                            ml:7
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          ref={fileInputRefDL}
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            setDocfile1(e.target.files[0]);
                            setChoosefile1(false);
                            pdfUploadDL(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {pdffileDL && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffileDL} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile1 ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile1.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile1 ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfileDL()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile1 === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload Visa*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                            ml:6
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          ref={fileInputRefVisa}
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            setDocfile2(e.target.files[0]);
                            setChoosefile2(false);
                            pdfUploadVisa(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {pdffileVisa && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffileVisa} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile2 ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile2.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile2 ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfileVisa()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile2 === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload Other Doc*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                            ml:1.7
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          ref={fileInputRefOther}
                          onChange={(e) => {
                            setDocfile3(e.target.files[0]);
                            setChoosefile3(false);
                            pdfUploadOtherDoc(e.target.files[0]);

                          }}
                        />
                      </Stack>
                      {pdffileOtherDoc && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffileOtherDoc} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile3 ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile3.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile3 ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfileOtherDoc()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile3 === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>

                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload | 92*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                            ml:6.8
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          ref={fileInputRefi92}
                          onChange={(e) => {
                            setDocfile4(e.target.files[0]);
                            setChoosefile4(false);
                            pdfUpload92(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {pdffile92 && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffile92} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile4 ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile4.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile4 ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfile92()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile4 === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.userStatus}
                    label="Change Status"
                    onChange={(e) => setValue({ ...value, userStatus: e.target.value })}
                  >
                    <MenuItem value={'fake'}>Fake</MenuItem>
                    <MenuItem value={'blacklisted'}>Blacklisted</MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>;
                  </Select>
                </FormControl> */}
                <TextField
                  sx={{}}
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={4}
                  value={value.notes}
                  onChange={(e) => setValue({ ...value, notes: e.target.value })}
                // defaultValue="Default Value"
                />
                {/* <Stack sx={{ width: 500 }}>
                  <CKEditor
                    editor={ClassicEditor}
                    // data="<p>{value.notes}<br><br></p>"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue({ ...value, notes: data });
                      console.log('ckeditor------data', { event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                </Stack> */}
              </Stack>
            </Grid>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => editResume(row)}>
                Save CV
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={opens} onClose={handleCloses} style={{ borderRadius: 10 }}>
        <Box sx={styleSkillset11}>
        <Stack sx={{ float:"right" }} direction="row" spacing={1.9}>
          <Button color="custom" variant="contained"  onClick={handleSkillOpen}>Add</Button>
          {hideButton && (

            <Button color="custom" variant="contained"  onClick={() => { setHideButton(!hideButton); setHideCross(false) }}>Delete
            </Button>
          )} 
          {!hideButton && (

            <Button color="custom" variant="contained" onClick={() => 
              { deleteSkill() }}
              disabled={loading12}
              >
                {/* Save */}
                  {loading12 ? <CircularProgress size={24} /> : 'Save'}
                </Button>
          )}
</Stack>
       <Typography variant="h5" color={'#3C6BA5'} sx={{ml:"46%"}}>
            Skills

          </Typography>

          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
          <Divider sx={{ mt: 0.5, borderWidth: 1.7, borderColor: '#3C6BA5'}} />
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 0.5,


                flexWrap: 'wrap'
              }}
            >
              {/* {row.skill?.map((item, index) => (
                item && (
                  <Typography
                    key={index}
                    variant="h6"
                    color="text.textG"
                    textAlign="center"
                    sx={{ mt: 2 }}
                  >
                    {item}
                    {index < row.skill.filter(skill => skill).length - 1 && ' | '}
                  </Typography>
                )
              ))} */}

              {/* {row.skill?.map((item, index) => ( */}
              {newSkill?.filter(item => item !== '' && item != null).map((item, index) => (

                item && (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="text.textG"
                      textAlign="center"
                       textTransform= "capitalize"
                      sx={{ paddingRight: '2px', position: 'relative', paddingTop: '-3px' }}
                    >
                      {item} {index < row.skill.length - 1 && '|'}

                      {!hideCross && (
                        <Iconify
                          icon="system-uicons:cross-circle"
                          width="1.45rem"
                          height="1.45rem"
                          sx={{
                            position: 'absolute',
                            top: '-15px',
                            right: '1px',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          onClick={() => handleDeleteSkill(index)}
                        />
                      )}

                    </Typography>
                  </Box>
                )
              ))}

            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={addSkill} onClose={handleSkillClose} sx={{ borderRadius: 10 }}>
        <Box sx={styleSkillset1}>
          <Stack spacing={3}>
            <Autocomplete
              multiple
              id="tags-outlined"
              onChange={(event, newValue) => {
                setSkillss([...newValue]);
              }}
              options={addedSkills}
              getOptionLabel={(option) => option.title}
              value={skillss}
              filterSelectedOptions
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${inputValue}`,
                  });
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Skills" placeholder="Enter Skill" autoFocus />
              )}
            />
            <Stack direction="row" justifyContent="center" spacing={3}>
            <Button
                color="custom"
                variant="contained"
                onClick={() => {
                  // handleSkillClose();
                  // handleCloses()
                  // setSkillss([]);
                  AdddSkill()
                }}
                disabled={loading11}
              >
                {/* Add Skill */}
                {loading11 ? <CircularProgress size={24} /> : 'Add Skill'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>


      <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Button
              // sx={{width:'13%'}}
              color="custom"
              variant="contained"
              size="small"
              onClick={() =>
                navigate('/benchresumedetails', {
                  state: {
                    addedSkills,
                    row,
                    mtab: 'Resume',
                    fTab:
                      filterTab === 'worksheet'
                        ? 'submitted'
                        : '' || filterTab === 'favourites'
                          ? 'submitted'
                          : '' || filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                              ? 'start'
                              : '',
                  },
                })
              }
            >
              Add Note
              {/* <Iconify icon="simple-line-icons:note" sx={{ml:1}} fontSize={22} fontWeight={'bold'} /> */}
            </Button>
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ textTransform: 'capitalize', mt: -3 }} textAlign={'center'}>
            {/* {row.fullName.split(" ",1)}'s Notes */}
            {/* {row.fullName}'s Notes */}
            {/* {/\s/.test(row.fullName) ? row.fullName.split(" ", 1) : row.fullName}'s  */}
            Notes
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ backgroundColor: 'text.secondary', flexDirection: 'row', p: 1, py: 0.5, borderRadius: 1 }}>
              <Typography color={'white'} sx={{ width: '11%', fontSize: '0.976rem', fontWeight: '600' }}>
                Date
              </Typography>
              <Typography color={'white'} sx={{ width: '10%', fontSize: '0.976rem', fontWeight: '600' }}>
                User
              </Typography>
              <Typography color={'white'} sx={{ width: '80%', fontSize: '0.976rem', fontWeight: '600' }}>
                Note
              </Typography>
            </Stack>
            {his.length
              ? his.map((item, index) => {
                return (
                  <Stack
                    sx={[
                      { background: index % 2 === 0 ? 'text.background' : tablebg },
                      { flexDirection: 'row', p: 1 },
                    ]}
                  >
                    <Typography sx={{ width: '11%' }}>
                      {/* {moment(`${item.createdAt}`).format('lll')} */}
                      {`${new Date(item?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(item?.createdAt).getHours()}`}:{`${new Date(item?.createdAt).getMinutes()}`}
                      </Typography>
                    <Typography sx={{ width: '10%', textTransform: 'capitalize', fontWeight: '800', color: 'text.secondary' }}>{item.actionBy}</Typography>
                    <Typography sx={{ width: '80%' }}>
                      {item.note}
                      {/* <div dangerouslySetInnerHTML={{ __html: item.note }} /> */}
                    </Typography>
                  </Stack>
                );
              })
              : null}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={levelM} onClose={() => setLevelM(false)} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>
          <Divider />
          <Stack sx={{ justifyContent: 'center', gap: 2, mb: 2, mt: 2, width: '100%' }}>
            <Stack sx={{ alignSelf: 'center', gap: 3, width: '60%' }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  InputLabelProps={{ shrink: true }}
                  label="Select New Status"
                  placeholder="Select New Status"
                  value={subStatus}
                  onChange={(e) => setSubStatus(e.target.value)}
                >
                  <MenuItem value={'client Submission'}>Client Submission</MenuItem>
                  <MenuItem value={'unqualified'}>Unqualified</MenuItem>
                  <MenuItem value={'shortlisted'}>Shortlisted</MenuItem>
                  <MenuItem value={'job On Hold'}>Job On Hold</MenuItem>
                  <MenuItem value={'out Of Market'}>Out of Market</MenuItem>
                  <MenuItem value={'Duplicate Submission'}>Duplicate Submission</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{}}>
                <TextField
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={statusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                />
              </FormControl>
              <Button
                color="custom"
                variant="contained"
                sx={{ width: '15%', alignSelf: 'center' }}
                onClick={(e) => Submitted(row)}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Modal open={openinterview} onClose={handlecloseinterview} style={{ borderRadius: 10 }}>
        <Box sx={styleSkillset}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'} sx={{ textTransform: 'capitalize' }}>
            {row.fullName} Interview Schedule
          </Typography>
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: 'text.secondary',
                borderRadius: 1,
                p: 0.5
              }}
            >
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Date
              </Typography>
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Time
              </Typography>
              {/* {row?.hiringManagerName && ( */}

              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Hiring Manager
              </Typography>
              {/* )} */}
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Mode
              </Typography>
            </Stack>
            {filterTab === 'interview' ? (
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', p: 1 }}>
                <Typography sx={{ width: '30%' }}>
                {/* {moment(row.interviewDate).format('lll')} */}
                 {`${new Date(row?.interviewDate).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.interviewDate).getHours()}`}:{`${new Date(row?.interviewDate).getMinutes()}`}
                </Typography>
                <Typography sx={{ width: '30%', textTransform: 'uppercase' }}>
                  {moment(row.interviewStartTime).format('LT')} - {moment(row.interviewEndTime).format('LT')}{' '}
                  {row.timezone}
                </Typography>

                <Typography sx={{ width: '30%' }}>

                  {row?.hiringManagerName}
                  {row.interviewMode !== 'phoneic' && (
                    row?.contactNo ? ` - (${row?.contactNo})` : ''
                  )}
                </Typography>

                <Stack sx={{ flexDirection: 'row', width: '30%', textTransform: 'capitalize', overflow: 'hidden' }}>
                  {row.interviewMode === 'phoneic' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize', marginLeft: "1%" }}>
                        Phone{row?.contactNo ? ` - (${row?.contactNo})` : ''}{" "}</Typography>
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('lll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                     
                          Mode: ${'Phone'} - ${row.contactNo
                          },                                                              
                          `}
                      >
                        {row?.contactNo && (

                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                        )}
                      </CopyToClipboard>
                    </>
                  )}
                  {row.interviewMode === 'video' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize', marginLeft: "1%" }}>
                        {/* {(row?.hiringManagerName)? `${row?.hiringManagerName} - `: ''} */}
                        {/* {row?.contactNo ? `(${row?.contactNo}) - ` : ''}{" "} */}
                        {(row.videoPlatform) ? `${row.videoPlatform} - ` : "Video Conference - "}
                        <Typography
                          sx={{ textTransform: 'capitalize', color: 'text.secondary', cursor: 'pointer', display: 'inline' }}
                          onClick={() => window.open(row.meetingLink, '_blank')}
                        >
                          {" "} Meeting url {" "}
                        </Typography>
                      </Typography>

                      {/* <a href={row.meetingLink} target="_blank" rel="noreferrer">
                        &nbsp; Meeting link
                      </a> */}
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('lll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                     
                          Mode: ${row.videoPlatform} - ${row.meetingLink},
                                                                                         
                          `}
                      >
                        {row.videoPlatform && (

                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                        )}
                      </CopyToClipboard>
                    </>
                  )}
                  {row.interviewMode === 'onsite' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize', maxWidth: '90%', marginLeft: "1%" }}>
                        {/* {row?.hiringManagerName?`${row?.hiringManagerName} - `:''}  {row?.contactNo ? `(${row?.contactNo}) - ` : ''} */}
                        face to face{(row?.companyAddress) ? ` - (${row?.companyAddress})` : ''}
                      </Typography>
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('lll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                       
                          Mode: ${'Face To Face'} - ${row.companyAddress
                          }                                                              
                          `}
                      >
                       { row.timezone && (

                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                       )}
                      </CopyToClipboard>
                    </>
                  )}
                </Stack>{' '}
              </Stack>
            ) : null}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openclient} onClose={handleCloseclient} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                <Stack
                  sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ width: '22%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    User Name
                  </Typography>
                  {/* <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job ID
                  </Typography> */}
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Client
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job Title
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Notes
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                </Stack>
              </Stack>

              {client
                ? client.map((item, index) => {
                  console.log('client list----------------------', item);
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                      ]}
                    >
                      <Typography sx={{ width: '21%' }}>
                        {/* {moment(`${item.createdAt}`).format('lll')} */}
                        {`${new Date(item?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(item?.createdAt).getHours()}`}:{`${new Date(item?.createdAt).getMinutes()}`}
                      </Typography>
                      <Typography sx={{ width: '21%', textTransform: 'capitalize', fontWeight: '800', color: 'text.secondary' }}>
                        {item.actionBy}
                      </Typography>
                      {/* <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.jobId ? item.jobId : '-'}
                      </Typography>  */}
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.clientName ? item.clientName : '-'}
                      </Typography>
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.jobIdTitle || item.jobTitle}
                      </Typography>
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.note}
                      </Typography>
                      <Typography
                        sx={[
                          {
                            color:
                              item.actionTo === 'started' || item.actionTo === 'placement'
                                ? 'green'
                                : item.actionTo === 'Backout from Job offer'
                                  ? 'red'
                                  : '',
                          },
                          { width: '20%', textTransform: 'capitalize' },
                        ]}
                      >
                        {item.actionTo}
                      </Typography>
                    </Stack>
                  );
                })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openclient1} onClose={handleCloseclient1} style={{ borderRadius: 10 }}>
        <Box sx={styleClients1}>
        <Stack
  direction="row" 
  justifyContent="space-between"
  alignItems="center"
  sx={{ width: '100%' }}
>
  <Typography
    variant="h5"
    color={'#3C6BA5'}
    textAlign={'center'}
    sx={{ flexGrow: 1, textAlign: 'center' }}
  >
    {/* {row.fullName}'s  */}
    Summary
  </Typography>
  {!enableEditSum && (

  <Button color="custom" variant="contained" onClick={(e)=>setEnableEditSum(true)}>
    Edit
  </Button>
  )} 
 {enableEditSum && (
  <Button color="custom" variant="contained" size="small" disabled={loadingSum}
  onClick={(e)=>editSummary()}>
    {loadingSum ? <CircularProgress size={22} /> : 'Save'}
    
  </Button>
 )
 }
</Stack>

          <Divider sx={{ mt: 1, mb:1, borderWidth: 1.7, borderColor: '#3C6BA5'}} />
          {!enableEditSum && (

                  <Typography sx={{ width: '100%', fontSize: '0.976rem', fontWeight: '100' }}>
                    {row.summary}
                  </Typography>
          )} 
                  {enableEditSum && (

                    <TextField
                    sx={{}}
                    id="outlined-multiline-static"
                    label="Summary"
                    multiline
                    rows={4}
                    width="100%" 
                    fullWidth
                    value={updateSummary}
                    onChange={(e) => setUpdateSummary(e.target.value)}
                  
                  />
                  )}
        </Box>
      </Modal> 

      <Modal open={esub} onClose={closeEsub} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '80%', sm: '70%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Submission
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack gap={2}>
              {/* <FormControl>
                <Typography fontWeight={500}>Submit to</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="position"
                  name="radio-buttons-group"
                  value={subto}
                  onChange={(e) => setSubto(e.target.value)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: 1,
                  }}
                >
                  <FormControlLabel value="position" control={<Radio />} label="Position" />
                  <FormControlLabel value="client" control={<Radio />} label="Client" />
                </RadioGroup>
              </FormControl> */}
              {/* {subto === 'position' && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={esubdata.subjobid}
                    label="Job Id"
                    onChange={(e) => setEsubdata({ ...esubdata, subjobid: e.target.value })}
                  // onChange={(e) => alert(e.target.value)}
                  >
                    <MenuItem value={''}>NA</MenuItem>
                    {joblst1?.map((item, index) => {
                      return <MenuItem key={index} value={item?.jobId}>{item?.jobId}: {item?.jobTitle}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              )} */}
              {/* {subto === 'client' && (
                <> */}
                <Stack sx={{ flexDirection: "row", mt: 2 }}>
  <FormControl sx={{ flex: 1, mr: 1 }}> 
    {/* <InputLabel >Select Client</InputLabel> */}
    <TextField
     variant="outlined"
     InputLabelProps={{ shrink: true }}
      placeholder="Select Client"
      value={esubdata.cname}
      label="Select Client"
      onChange={(e) => setEsubdata({ ...esubdata, cname: e.target.value })}
    >
      {/* {clientlist?.map((item) => (
        <MenuItem key={item.companyName} value={item.companyName}>
          {item.companyName}
        </MenuItem>
      ))} */}
    </TextField>
  </FormControl>
  <FormControl sx={{ flex: 1, ml: 1 }}> 
    <TextField
      id="outlined-basic"
      label="Position Name"
      placeholder="Position Name"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      value={esubdata.pname}
      onChange={(e) => setEsubdata({ ...esubdata, pname: e.target.value })}
    />
  </FormControl>
</Stack>

<Stack sx={{ flexDirection: 'row', mt: 2 }}>
      <Grid container spacing={2}>
       
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <FormControl variant="outlined">
              <InputLabel id="select-country-label">Select Country</InputLabel>
              <Select
                labelId="select-country-label"
                id="select-country"
                value={esubdata.country}
                label="Select Country"
                onChange={(e) => setEsubdata({...esubdata, country:e.target.value})}
              >
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="CANADA">CANADA</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small">
              <CustomComplete
                placeholder={'City & Pincode'}
  option={cityData}
  propVal={cityy}
  childFuc={childFuc}
  fullVal={fullVal}
  fetch={'state'}
              />
            </FormControl>

            <FormControl size="small">
              {cityandstate ? (
                <Typography color={'text.textG'} variant="body" sx={{mt:1, mb:1}}>
                  {cityandstate?.city}, {cityandstate?.state}
                </Typography>
              ) : null}
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{mt:5}}>
              <TextField
                
  value={value1?.zip}
  onChange={(e) => setValue1({ ...value1, zip: e.target.value })}
              />
            </FormControl>

            <FormControl>
                    <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Job Type"
                      value={esubdata.jobType}
                      onChange={(e) => setEsubdata({ ...esubdata, jobType: e.target.value })}
                    >
                      <MenuItem value="remote">Remote Job</MenuItem>
                      <MenuItem value="onsite">Onsite Job</MenuItem>
                      <MenuItem value="hybrid">Hybrid Job</MenuItem>
                      {/* <MenuItem value="wfh">WFH</MenuItem> */}
                    </Select>
                  </FormControl>
          </Stack>

         

          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{mt:1}}fontWeight={500}>Need to send Automailer to client</Typography>
                    <Switch checked={checked} onChange={handleChangebtn} inputProps={{ 'aria-label': 'controlled' }} />
                  </Stack>
                  {checked === true && (
                    <Stack sx={{mt:1}}>
                      <TextField
                        id="outlined-basic"
                        label="Subject"
                        placeholder="Subject"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={esubdata.emailSubject}
                        onChange={(e) => setEsubdata({ ...esubdata, emailSubject: e.target.value })}
                      />
                     
                    </Stack>
                  
                  )}
                   <TextField
                    sx={{mt:3, width:"100%"}}
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    value={esubdata.note}
                    onChange={(e) =>  setEsubdata({ ...esubdata, note: e.target.value })}
                    
                  // defaultValue="Default Value"
                  />
        </Grid> 

        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="select-compensation-type-label">Compensation Type</InputLabel>
              <Select
                labelId="select-compensation-type-label"
                id="select-compensation-type"
                value={esubdata.comType}
                label="Compensation Type"
                onChange={(e) => setEsubdata({ ...esubdata, comType: e.target.value })}
                //  setEsubdata({...esubdata, tax: ""})}}
              >
                <MenuItem value={'hourly'}>Per Hour Rate</MenuItem>
                <MenuItem value={'Annually'}>Annual Compensation</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                id="expected-compensation"
                label="Expected Compensation (In Dollars)*"
                placeholder="Expected Compensation"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={esubdata.expectedCom}
                 type="text"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: esubdata.comType && (
                    <InputAdornment position="end">
                      {esubdata.comType === 'hourly' ? 'per/hour' : 'K'}
                    </InputAdornment>
                  ),
                }}
                // onChange={(e) => setEsubdata({ ...esubdata, expectedCom: e.target.value })}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(newValue)) {
                    setEsubdata({ ...esubdata, expectedCom: newValue });
                  }
                }}
              />
            </FormControl>

            <FormControl sx={{mt:-1}}>
              <RadioGroup
                aria-labelledby="tax-term-radio-group-label"
                defaultValue="c2c"
                name="tax-buttons-group"
                value={esubdata.tax}
                onChange={(e) => setEsubdata({ ...esubdata, tax: e.target.value })}
                sx={{ display: 'flex', flexDirection: 'row', mt:-1 }}
              >
                <FormControlLabel value="c2c"  disabled={esubdata.comType !== 'hourly'} control={<Radio />} label="C2C" />
                      <FormControlLabel value="cth"  disabled={esubdata.comType !== 'hourly'}control={<Radio />} label="CTH" />
                      <FormControlLabel value="fulltime"  disabled={esubdata.comType !== 'Annually'} control={<Radio />} label="Full-Time" />
                 
              </RadioGroup>
            </FormControl>
         

            {/* <Autocomplete
  options={filtered.length > 0 ? filtered : [{ recruitorCompanyName: esubdata.recruitorCompany }]}
  getOptionLabel={(option) => option.recruitorCompanyName || ''}
  value={filtered.find(city => city.recruitorCompanyName === esubdata.recruitorCompany) || null} 
  onInputChange={handleInputChangeComp}
  onChange={(event, newValue) => {
    if (newValue) {
      setEsubdata({ ...esubdata, recruitorCompany: newValue.recruitorCompanyName });
    } else {
      setEsubdata({ ...esubdata, recruitorCompany: '' }); 
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Recruiter Company Name"
      placeholder="Recruiter Company Name"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  )}
  freeSolo 
/> */}

{showHide && (
<FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Recruiter Company Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      label="Recruiter Company Name"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Recruiter Company Name"
                      value={esubdata.recruitorCompany}
                      onChange={(e) => handleChangeComp(e)
                        // setEsubdata({...esubdata, recruitorCompany:e.target.value})
                      }
                    >
  <Button
  sx={{
    mb: 0.5,
    width: "100%",
    backgroundColor: 'text.secondary',
    color: "white",
    '&:hover': {
      backgroundColor: 'text.secondary', 
    }
  }}
  onClick={() => {setShowHide(false); setShowHide1(false);
    setEsubdata({...esubdata, recruitorCompany:'', recruitorName:"", emailId:"", phoneNumber:"" })}}
>
  Add New
</Button>

{/* <MenuItem value="" disabled>
  Select an option
</MenuItem> */}
  {dropComp
  ?.filter(item => item !== '' && item !== null && item !== undefined)
  .map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
))}

                    </Select>
                  </FormControl>
)}
{!showHide && (
  <TextField
                    id="outlined-basic"
                    label="Recruiter Company Name"
                    placeholder="Recruiter Company Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={esubdata.recruitorCompany}
                      onChange={(e) => setEsubdata({...esubdata, recruitorCompany:e.target.value})}
                  />
                  )}


{showHide1 && (
<FormControl sx={{ width: '100%'}}>
                    <InputLabel id="demo-simple-select-autowidth-label">Recruiter Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      label="Recruiter Name"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Recruiter Name"
                      value={esubdata.recruitorName}
                      onChange={(e) => handleChangeRec(e)}
                    >

  {dropRec
  ?.filter(item => item !== '' && item !== null && item !== undefined)
  .map((item, index) => (
    <MenuItem key={index} value={item.recruitorName}>
      {item.recruitorName}
    </MenuItem>
))}
<Button
  sx={{
    mb: 0.5,
    width: "100%",
    backgroundColor: 'text.secondary',
    color: "white",
    '&:hover': {
      backgroundColor: 'text.secondary', 
    }
  }}
  onClick={() => {setShowHide1(false);
    setEsubdata({...esubdata, recruitorName:"", emailId:"", phoneNumber:"" })}}
>
  Add New
</Button>

                    </Select>
                  </FormControl>
)}


{!showHide1 && (
  <TextField
                    id="outlined-basic"
                    label="Recruiter Name"
                    placeholder="Recruiter Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={esubdata.recruitorName}
                      onChange={(e) => setEsubdata({esubdata, recruitorName:e.target.value})}
                  />
                  )}
           {/* <Autocomplete
    options={filtered.length > 0 ? filtered : [esubdata.recruitorCompany]}
    getOptionLabel={(option) => option || ''}
    value={filtered.find(city => city === esubdata.recruitorCompany) || null}
    onInputChange={handleInputChangeComp}
    onChange={handleChangeComp}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Recruiter Company Name"
            placeholder="Recruiter Company Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
        />
    )}
    freeSolo
/>
      
<Autocomplete
  options={filtered.length > 0 ? filtered : dropRec}
  getOptionLabel={(option) => option.recruitorName || ''}
  value={filtered.find(city => city.recruitorName === esubdata.recruitorName) || null} 
  onInputChange={handleInputChangeRec}
  onChange={handleChangeComp1}
  onFocus={recList}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Recruiter Name"
      placeholder="Recruiter Name"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  )}
  freeSolo 
/> */}

                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={esubdata.emailId}
                    onChange={(e) =>setEsubdata({ ...esubdata, emailId: e.target.value })}
                  />
                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Phone"
                    placeholder="Phone"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={esubdata.phoneNumber}
                    onChange={(e) => setEsubdata({ ...esubdata, phoneNumber: e.target.value })}
                  />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
              <Button
                type="submit"
                variant="contained"
                color="custom"
                size="small"
                sx={{ width: 'fit-content', mb: 2, alignSelf: 'center' }}
                onClick={() => EditSubmitted()}
              >
                Submit
              </Button>

            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={eplace} onClose={closeEplace} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Placement
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{}}>
              <FormControl fullWidth>
                <Typography fontWeight={500} sx={{ my: 2 }}>
                  Placement Status
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="c2c"
                  name="radio-buttons-group"
                  value={eplacedata.placestatus}
                  onChange={(e) => setEplacedata({ ...eplacedata, placestatus: e.target.value })}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: 1,
                  }}
                >
                  <FormControlLabel value="placed" control={<Radio />} label="Placed" />
                  <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                </RadioGroup>
              </FormControl>
              {eplacedata.placestatus === 'placed' && (
                <Stack gap={2}>
                  <FormControl sx={{}}>
                    <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Job Type"
                      value={eplacedata.jobType}
                      onChange={(e) => setEplacedata({ ...eplacedata, jobType: e.target.value })}
                    >
                      <MenuItem value="onsite">On Site</MenuItem>
                      <MenuItem value="hybrid">Hybrid</MenuItem>
                      <MenuItem value="wfh">WFH</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{}}>
                    <InputLabel id="demo-simple-select-label">Offered Job</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Offered Job"
                      value={eplacedata.offeredJob}
                      onChange={(e) => setEplacedata({ ...eplacedata, offeredJob: e.target.value })}
                    >
                      <MenuItem value="fulltime">Fulltime</MenuItem>
                      <MenuItem value="contract">Contract</MenuItem>
                      <MenuItem value="cth">CTH</MenuItem>
                    </Select>
                  </FormControl>
                  {eplacedata.offeredJob === 'fulltime' ? (
                    <>
                      <FormControl sx={{}}>
                        <TextField
                          sx={{}}
                          id="outlined-multiline-static"
                          label="Annual Salary"
                          value={eplacedata.AnnualSalary}
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="end">K</InputAdornment>
                          }}
                          onChange={(e) => setEplacedata({ ...eplacedata, AnnualSalary: e.target.value })}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          id="outlined-multiline-static"
                          label="Joining Bonus"
                          value={eplacedata.JoiningBonus}
                          onChange={(e) => setEplacedata({ ...eplacedata, JoiningBonus: e.target.value })}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          id="outlined-multiline-static"
                          label="Annual Bonus"
                          value={eplacedata.AnnualBonus}
                          onChange={(e) => setEplacedata({ ...eplacedata, AnnualBonus: e.target.value })}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          id="outlined-multiline-static"
                          label="Relocation Assistance"
                          value={eplacedata.ReloAssistance}
                          onChange={(e) => setEplacedata({ ...eplacedata, ReloAssistance: e.target.value })}
                        />
                      </FormControl>
                    </>
                  ) : null}

                  {eplacedata.offeredJob !== 'fulltime' ? (
                    <>
                      <FormControl>
                        <TextField
                          sx={{}}
                          id="outlined-multiline-static"
                          label="Hourly Rate Offered"
                          value={eplacedata.hro}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="end">per/hour</InputAdornment>
                          }}
                          onChange={(e) => setEplacedata({ ...eplacedata, hro: e.target.value })}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          id="outlined-multiline-static"
                          label="Tax Term"
                          value={eplacedata.TaxTerm}
                          onChange={(e) => setEplacedata({ ...eplacedata, TaxTerm: e.target.value })}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          id="outlined-multiline-static"
                          label="Overtime Expense"
                          value={eplacedata.OvertimeExpense}
                          onChange={(e) => setEplacedata({ ...eplacedata, OvertimeExpense: e.target.value })}
                        />
                      </FormControl>
                    </>
                  ) : null}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ mt: -0.8 }} components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        disablePast
                        label="Tentative Start Date"
                        value={tsd}
                        onChange={(newValue) => setTsd(newValue)}
                        slotProps={{
                          textField: {
                            readOnly: true,
                          },
                        }}
                      // console.log("uncontrol date",newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <TextField
                    id="outlined-multiline-static"
                    label="Additional"
                    value={eplacedata.Additional}
                    onChange={(e) => setEplacedata({ ...eplacedata, Additional: e.target.value })}
                  />
                </Stack>
              )}

              <TextField
                sx={{ mt: 2 }}
                id="outlined-multiline-static"
                label="Notes"
                multiline
                value={eplacedata.placenote}
                rows={4}
                onChange={(e) => setEplacedata({ ...eplacedata, placenote: e.target.value })}
              // defaultValue="Default Value"
              />

              <Button
                type="submit"
                variant="contained"
                color="custom"
                size="small"
                sx={{ width: 230, mt: 3 }}
                onClick={() => EditPlacement()}
              >
                Update Placement
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={modalOpen} onClose={handleCloseModal} style={{ borderRadius: 10 }}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '70%' },
          // width: { xs: '85%', sm: '60%' },
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}
        >
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Send Email
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            {/* <Divider sx={{ mb: 2 }} /> */}
            <Grid container spacing={1} mt={1}>
              <Grid item xs={5.9}>
                <TextField
                  fullWidth
                  required
                  label="To"
                  variant="outlined"
                  size="small"
                  // value={to}
                  value={recipientEmail}
                  // onChange={(e) => setTo(e.target.value)}
                  onChange={(e) => setRecipientEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={5.9}>
                <Autocomplete
                  size='small'
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setCcEmail([...newValue]);
                  }}
                  options={[{ title: '' }]}
                  getOptionLabel={(option) => option.title}
                  value={ccEmail?.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Cc" placeholder="Cc"

                    size="small"
                  />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={5.9}>
                <Autocomplete
                  size='small'
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setBccEmail([...newValue]);
                  }}
                  options={[{ title: '' }]}
                  getOptionLabel={(option) => option.title}
                  value={bccEmail?.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="BCc" placeholder="BCc"

                    size="small"
                  />}
                />

              </Grid>

              <Grid item xs={5} sx={{}}>
                <FormControl variant="outlined" fullWidth size="small" >
                  <InputLabel >Select Template</InputLabel>
                  <Select
                    label="Select Template"
                    // value={formValues.resource}
                    onChange={(e) => handleTemplateSelect(e.target.value)}

                  >
                    {tempList?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Typography
                // component={Button}
                // onClick={() => { navigate('/Email'),{ state: { undid:"undid" } } }}
                onClick={() => navigate('/Email', { state: { undid: "undid" } })}

                sx={{
                  color: '#23A6F0',
                  fontWeight: 'bold',
                  fontSize: '17px',
                  marginLeft: '30px',
                  marginTop: '18px',
                  cursor: 'pointer',
                }}
              >
                Edit
              </Typography>
            </Grid>
            <Grid container alignItems="center" style={{ flexDirection: 'row' }}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ml: 1, color: '#1E74B3' }}>Attachment:</Typography>
                <input type="file" onChange={FileChange} style={{ marginLeft: '9px' }} />
                {/* <Button variant="contained" onClick={UploadFile}>
        Upload
    </Button> */}
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={11.8}>
                <TextField
                  label='Sub'
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>
              <Grid item xs={11.8}>
                <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                  {/* <TextField
                  label='Greeting'
                  variant="outlined"
                  sx={{width:'20%'}}
                  size="small"
                  value={greeting}
                  onChange={(e) => setGreeting(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                /> */}
                  <Typography> {greeting} {row.fullName}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={11.8}>
                <CKEditor
                  data={emailBody}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log("body", data)
                    setEmailBody(data);
                  }}
                  editor={ClassicEditor}
                />
              </Grid>
            </Grid>
            <Stack>

              <div dangerouslySetInnerHTML={{ __html: localStorage.getItem('sign') }} />
            </Stack>

            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
              <Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Automailer</Typography>
                <Switch checked={checked} onChange={handleChangebtn} inputProps={{ 'aria-label': 'controlled' }} />
              </Stack>
              {checked && (<Typography sx={{ fontWeight: 'bold', color: '#2ECC71' }}>Automailer Activated !</Typography>)}
            </Stack>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail1}
                  onChange={handleChangeEmail1}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  disabled
                  label="Email 1"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub1}
                  InputProps={{ style: { borderRadius: '10px' } }}
                />
              </Grid>
              {/* <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      slotProps={{ textField: { size: 'small' } }}
                      value={time1}
                      onChange={(e) => setTime1(e)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid> */}
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time1}
                      onChange={(newValue) => setTime1(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date1}
                  onChange={(e) => setDate1(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                />
              </Grid>
              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>
            </Grid>

            <Modal
              open={openModal}
              onClose={handleModalClose}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box sx={{
                width: '24%',
                height: '53%',
                overflowY: 'auto',
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px solid #1E74B3'
              }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    disablePast
                    date={dayjs(date1)}
                    onChange={handleDateSelect1}
                  />
                </LocalizationProvider>
              </Box>
            </Modal>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail2}
                  onChange={handleChangeEmail2}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8} >
                <TextField
                  disabled
                  label="Email 2"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub2}
                  InputProps={{ style: { borderRadius: '10px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>
              {/* <Grid item xs={2}>
                <FormControl sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <TimePicker
                        slotProps={{ textField: { size: 'small' } }}
                        value={time2}
                        onChange={(e) => {
                          setTime2(e);
                          // setItime({ ...itime, hrs: e.$H, min: e.$m });
                          // console.log('===============time===', e.$H, ':', e.$m);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Grid> */}
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time2}
                      onChange={(newValue) => setTime2(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>

                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date2}
                  onChange={(e) => setDate2(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>

              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen1}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>

              <Modal
                open={openModal1}
                onClose={handleModalClose1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{
                  width: '24%',
                  height: '53%',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '2px solid #1E74B3'

                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      disablePast
                      date={dayjs(date2)}
                      onChange={handleDateSelect2}
                    />
                  </LocalizationProvider>

                </Box>
              </Modal>

            </Grid>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail3}
                  onChange={handleChangeEmail3}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8}>

                <TextField
                  disabled
                  label="Email 3"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub3}
                  // onChange={(e) => setEmail2(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time3}
                      onChange={(newValue) => setTime3(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date3}
                  onChange={(e) => setDate3(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>

              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen2}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>

              <Modal
                open={openModal2}
                onClose={handleModalClose2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{
                  width: '24%',
                  height: '53%',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '2px solid #1E74B3',

                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      disablePast
                      date={dayjs(date3)}
                      onChange={handleDateSelect3}
                    />
                  </LocalizationProvider>
                </Box>
              </Modal>

            </Grid>
            {/* {recipientEmail!=='' && subject!==""?(
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={SendEmail}>
                Send
              </Button>
            </Stack>
):null} */}

            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button
                color="custom"
                variant="contained"
                onClick={SendEmail}
                disabled={recipientEmail === '' || subject === ''}
              >
                Send
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

    </>
  );
}


const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const HEADER_MOBILE1 = 150;
const HEADER_DESKTOP1 = 300;

const StyledSearchbar = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ModalContent = styled('div')(({ theme }) => ({
  marginTop: '220px',
  width: '100%',
  display: 'flex',
  marginRight: '50px',

  height: HEADER_MOBILE1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP1,
    padding: theme.spacing(0, 5),
  },
}));

const StyledIconButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
});

function Search({ searchData, func, funcc, funnc, onData, resetSearch }) {
  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
    { label: 'Permanent Resident' },
  ];

  const [open, setOpen] = useState(false);
  // const [unit, setUnit] = useState();
  // const [amount, setAmount] = useState();
  // const [isVisible, setIsVisible] = useState(true);
  // const [city, setCity] = useState('')
  // const [state, setState] = useState('')

  const [formValues, setFormValues] = useState({
    // fullName: "",
    // email: "",
    // phone: "",
    commonSearch: "",
    jobTitle: [],
    skills: [],
    relocate: "",
    visaType: "",
    // location: "",
    // city: "",
    // state: "",
    // updatedAt: {
    //   from: "",
    //   till: ""
    // }
    // updated:"",
    duration: "",
    // distance: "",
    resource: ""
  });
  const [formValues1, setFormValues1] = useState({ commonSearch: "" });
  const [userdata, setUserdata] = useState([])

  console.log("userdata ====>>>>>>", userdata)
  useEffect(() => {
    allUsers()
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleSkillsChange = (e) => {

  //   const skillsArray = e.target.value.split(',').map((skill) => skill.trim());
  //   setFormValues({ ...formValues, skills: skillsArray });
  // };

  const toggleVisibility = () => {
    // setIsVisible(!isVisible); 
    resetSearch()
    func()
    setFormValues1({ ...formValues1, commonSearch: '' });
    setFormValues({
      ...formValues,
      commonSearch: '',
      jobTitle: [],
      skills: [],
      relocate: '',
      visaType: '',
      // city: '',
      // state: '',
      // duration: { unit: "", amount: "1" },
      duration: "",
      // distance: '',
      resource: ''
    });
    setCityQuery('')
    setStateQuery('')
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
    // setFormValues({ ...formValues, commonSearch: '' });
  };

  const fn = () => {
    setLoading(true);

    const token = localStorage.getItem('token');
    const body = {
      "commonSearch": formValues.commonSearch,
      "jobTitle": formValues.jobTitle,
      "skills": formValues.skills,
      "relocate": formValues.relocate,
      "visaType": formValues.visaType,
      // "location": formValues.location,
      // "city": formValues.city,
      // "state": formValues.state,
      "city": cityQuery,
      // "state": stateQuery,
      ...(stateQuery && !cityQuery ? { "state": stateQuery } : {}),
      // "updatedAt": {
      //   // "from":fromDate,
      //   // "till": tillDate 
      //   "from": fromDate,
      //   "till": tillDate,
      // } 
      // "updated":formValues.updated,
      "stateCode": stateCode || stateId,
      "duration": {
        "unit": formValues.duration,
        "amount": "1",
      },

      // "distance": formValues.distance,
      "resource": formValues.resource,
    }
    // Api('common/search/cv', 'POST', body, token)
    Api('bench/searchBenchWorksheetData', 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            searchData(response.data.data)
            setLoading(false);
            console.log("response.data.data", response.data.data)
          } else {
            console.log('Error:', response.data.message);
          }
          // handleClose()
          // func()
          handleSearchClose()
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUnitChange = (e) => {
    const selectedUnit = e.target.value;
    setFormValues({
      ...formValues,
      duration: {
        ...formValues.duration,
        unit: selectedUnit
      }
    });
  };

  const [skillLst, setSkillLst] = useState([]);
  // const [finallst, setFinallst] = useState([]);

  useEffect(() => {
    getSkills();
  }, []);

  const getSkills = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await Api(`resume/get_skills`, 'GET', '', token);

      if (response.data.code === 200) {
        const skillsData = response.data.result;
        const updatedSkills = skillsData?.map((item) => ({ title: item?.skillName }));
        setSkillLst(updatedSkills);
        console.log('Updated Skills:', updatedSkills);
      } else {
        setSkillLst([{ title: '' }]);
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching skills:', error);
      toast.error('Failed to fetch skills!');
    }
  };

  const [loading1, setLoading1] = useState(false);

  console.log("skillLst", skillLst)
  const searchfn = () => {
    setLoading1(true)
    const body = {
      "commonSearch": formValues1.commonSearch,
    }
    const token = localStorage.getItem('token');
    // Api('common/search/cv', 'POST', body, token)
    Api('bench/searchBenchWorksheetData', 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            searchData(response.data.data)
            setLoading1(false)
          } else {
            console.log('Error:', response.data.message);
          }
          handleClose()
          // func()
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const allUsers = () => {
    const token = localStorage.getItem('token');
    Api('common/users/RC', 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setUserdata(response.data.data);
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const defaultRelocateValue ='yes'
  const handleJobTitleChange = (event, newValue) => {
    let updatedJobTitles = [];

    if (newValue && newValue.length > 0) {
      const lastItem = newValue[newValue.length - 1];
      if (typeof lastItem === 'string') {
        updatedJobTitles = [...newValue.slice(0, -1), lastItem];
      } else {
        updatedJobTitles = newValue;
      }
    }

    setFormValues({ ...formValues, jobTitle: updatedJobTitles });
  };

  const isAnyFieldFilled = (
    // formValues1.commonSearch || 
    formValues.commonSearch ||
    formValues.jobTitle ||
    formValues.skills ||
    formValues.relocate ||
    formValues.visaType ||
    // formValues.city ||
    // formValues.state ||
    // formValues.duration || 
    // formValues.distance ||
    formValues.resource
  );

  // const isDisabled = 
  //   !formValues.commonSearch ||
  //   !formValues1.commonSearch ||
  //   !formValues.jobTitle ||
  //   !formValues.skills ||
  //   !formValues.relocate ||
  //   !formValues.visaType ||
  //   !formValues.city ||
  //   !formValues.state ||
  //   !formValues.duration ||
  //   !formValues.distance ||
  //   !formValues.resource;


  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const [dist, setDistance] = useState('')
  const [latt, setLatt] = useState('')
  const [long, setLong] = useState('')
  const [valuee, setValue] = useState({ country: 'USA' });
  const [cityData, setCityData] = useState([]);
  const [cityQuery, setCityQuery] = useState("");
  const [cityAndState, setCityAndState] = useState(null);
  const [stateCode, setStateCode] = useState('')

  console.log("cityData", cityData)
  console.log("cityQuery===>>>", cityQuery)
  console.log("cityAndState", cityAndState)

  const handleChange = (e) => {
    setValue({ country: e.target.value });
  };

  // const handleCityChange = async (val) => {
  //   setCityData([])
  //   console.log("val==", val)
  //   setCityQuery(val);
  //   // await getCity(val);
  //   if (val.length >= 3) { getCity(val);
  //   }
  // }; 

  const getCity = async (val) => {
    const token = localStorage.getItem('token');
    const body = {
      country: valuee.country || 'USA',
      query: val,
    };
    try {
      const response = await Api('resume/get_city_states', 'POST', body, token);
      if (response.status === 200 && response.data.code === 200) {
        setCityData(response.data.data || []);
        console.log("response.data.data", response.data.data)
        // const Newdata = response.data.data
        // if (Newdata.length > 0) {
        //   const selectedCity = Newdata[0];
        //   // setLatt(selectedCity.lat);
        //   // setLong(selectedCity.lng);
        //   // setStateQuery(selectedCity.state);
        //   // setStateCode(selectedCity.stateCode)
        // } else {
        //   console.log('No city data found');
        //   setCityAndState(null);
        //   setLatt('');
        //   setLong('');
        // }
      } else {
        console.error(response.data.message);
        toast.error(response.data.message)
        setCityData([]);
      }
    } catch (err) {
      console.error(err);
      setCityData([]);
    }

  };
  // const [cityy, setCityy]= useState()

  const childFuc = (val) => {
    setCityData([])
    console.log("vall===", val)
    if (typeof (val) === "string") {
      console.log("val", val)
      // alert("USA")
      if (val.length >= 3) {
        getCity(val);
      }

      setCityQuery(val);
      // handleCityChange(val);
    }
    else {
      // alert("CAN") 
      if (val.city.length >= 3) {
        getCity(val.city);
      }
      setStateQuery(val?.state)
      setCityQuery(val?.city);
      // handleCityChange(val.city);
      setLatt(val?.lat);
      setLong(val?.lng);
      setStateCode(val?.stateCode || val?.postal)
    }
    // setStateQuery(val.state)
    // setCityy(val)
    // const selectedCity = cityData.find(city => city && city.name === val);
    // console.log("selectedCity===>>>", selectedCity)
    // if (selectedCity) {
    //   setCityAndState(selectedCity);
    // } else {
    //   setCityAndState(null);
    // }
  };
  // const stateData1 = ["Georgia", "Columbus", "New York"]
  const [stateData, setStateData] = useState([]);
  const [stateQuery, setStateQuery] = useState("");
  const [stateId, setStateId] = useState("");
  const [selectedState, setSelectedState] = useState(null);

  console.log("====stateQuery======", stateQuery)
  console.log("====stateData======", stateData)
  const handleStateChange = async (val) => {
    console.log("=======", val)
    // alert("hh")
    setStateQuery(val?.state_name || val?.province_or_StateName);
    setStateId(val?.state_id || val?.province_id)
    // await getState(val);
    // console.log("val====",val)
    if (val.length >= 3) {
      await getState(val);
    }
  };

  const getState = async (val) => {
    const token = localStorage.getItem('token');
    const body = {
      country: valuee.country,
      stateName: val,
    };
    try {
      const response = await Api('resume/get_states', 'POST', body, token);
      if (response.status === 200 && response.data.code === 200) {
        setStateData(response.data.data || []);

      } else {
        console.error(response.data.message);
        setStateData([]);
      }
    } catch (err) {
      console.error(err);
      setStateData([]);
    }
  };

  const childFucState = async (val) => {
    console.log("stateval", val)
    setStateQuery(val?.state_name || val?.province_or_StateName);
    handleStateChange(val);

    //   if (stateData.includes(val)) {
    //     setSelectedState(val);
    //   } else {
    //     setSelectedState(null);
    //   } 
  };

  console.log("latt==>>", latt)
  console.log("long==>>", long)
  console.log("dist==>>", dist)

  const searchDis = async () => {
    const token = localStorage.getItem('token');
    const body = {
      "center": [long, latt],
      "radiusInMiles": dist
    };
    try {
      const response = await Api('common/search/cv', 'POST', body, token);
      if (response.status === 200 && response.data.code === 200) {
        // setStateData(response.data.data || []); 
        searchData(response.data.data)

        handleSearchClose()
      } else {
        console.error(response.data.message);
        // setStateData([]);       
      }
    } catch (err) {
      console.error(err);
      // setStateData([]); 
    }
  };

  const handleSearch = () => {
    if (dist) {
      searchDis();
      setDistance('')
      // setStateQuery('')
      // setCityQuery('')
      // setValue('')
      setStateCode('')

    } else {
      setStateCode('')
      fn();
      setDistance('')
      // setStateQuery('')
      // setCityQuery('')
      // setValue('')
    }
  };

  const clearInputField = () => {
    setFormValues({
      ...formValues,
      commonSearch: '',
      jobTitle: [],
      skills: [],
      relocate: '',
      visaType: '',
      // city: '',
      // state: '',
      // duration: { unit: "", amount: "1" },
      duration: "",
      // distance: '',
      resource: ''
    });
    setCityQuery('')
    setStateQuery('')
    setDistance('')
    setStateCode('')
  }

  const [cityStateStyling, setCityStateStyling] = useState(false)

  const sendDataToParent = () => {
    onData(clearInputField);
  };

  const [loading, setLoading] = useState(false);

  return (
    <>
      {/* <ResumeRow pinned={pinned}/> */}
      {/* <ClickAwayListener onClickAway={handleClose}> */}
      <>

        {/* {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
          </IconButton>
        )} */}

        <div style={{ display: 'flex', alignItems: 'center', left: 115, right: 0 }}>
          <Paper
            component="form"
            sx={{
              // p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '25%',
              border: '1px solid #1E74B3',
              borderRadius: '10px',
 marginTop: -18, 
            }}
          >
            {/* <IconButton sx={{ p: '0.1px', ml: "7px", mr: "4px" }}>
              <Iconify icon="eva:search-outline"
              />
            </IconButton> */}
            <InputBase
              sx={{ flex: 1, color: 'text.primary', border: 'none', outline: 'none', pl: 1 }}
              placeholder="Name | Email | Phone Number"
              inputProps={{ 'aria-label': 'search' }}
              value={formValues1.commonSearch}
              onChange={(e) => setFormValues1({ ...formValues1, commonSearch: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); 
                  searchfn();
                  funcc(); 
                }
                
              }}
            />
          </Paper>
          {/* <Button style={{ marginLeft: '16px', color: 'white', backgroundColor: '#1E74B3', borderRadius: '10px', marginTop: '4px', height: '41px', width: '130px' }} onClick={fn}>Search</Button> */}
          <Button color="custom" variant="contained" size="small" sx={{ ml: 1.6, mt:-18 }}
            // disabled={formValues1.commonSearch === ''}
             disabled={loading1}
            onClick={()=>{searchfn(); funcc()}}
            
            >
            {/* Search */}
            {loading1 ? <CircularProgress size={24} /> : 'Search'}
          </Button>
          {(
            formValues?.commonSearch ||
            formValues1?.commonSearch ||
            formValues?.jobTitle.length > 0 ||
            formValues?.skills.length > 0 ||
            formValues?.relocate ||
            formValues?.visaType ||
            // formValues?.city ||
            // formValues?.state ||
            // (formValues.duration && (formValues.duration.unit || formValues.duration.amount)) ||
            formValues?.duration ||
            // formValues?.distance ||
            formValues?.resource ||
            // valuee?.country
            cityQuery || stateQuery
          ) && (
              // <IconButton
              //   sx={{ p: '1px', ml: '6px' }}
              //   color="custom"
              //   variant="contained"
              //   size="large"
              //   onClick={toggleVisibility}
              // >
              //   <Iconify icon="oui:cross-in-circle-filled" width="1.7rem" />
              // </IconButton>

              <Button color="custom" variant="contained" size="small" sx={{ ml: 1.6, mt: -18 }} 
                onClick={() => { toggleVisibility(); funnc() }}>
                Close
              </Button>
            )}

        </div>

        {/* <StyledIconButtonWrapper onClick={handleSearchOpen}> */}
        <StyledIconButtonWrapper onClick={() => {
          handleSearchOpen(); 
          setCityStateStyling(true);
          sendDataToParent()
        }}>

          <IconButton sx={{ display: 'flex', position: 'absolute', top: 55, marginRight: '68%' }}>
            <Iconify
              icon="bxs:down-arrow"
              color="#1E74B3"
            />
          </IconButton>
        </StyledIconButtonWrapper>

        <Modal open={searchOpen} onClose={handleSearchClose}>

          <Box sx={{
            position: 'absolute',
            top: '36%',
            left: '43%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', sm: '70%' },
            // width: { xs: '85%', sm: '60%' },
            bgcolor: 'background.paper',
            border: '3px solid #3C6BA5',
            boxShadow: 24,
            p: 2,
            borderRadius: 3,
          }}
          >

            {/* <ClickAwayListener> */}
            {/* <Slide direction="down" in={open} mountOnEnter unmountOnExit > */}

            {/* <StyledSearchbar > */}
            {/* <StyledInput
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            /> */}
            {/* <ModalContent

                sx={{
                  marginTop: '28%',
                  border: '1px solid #1E74B3',
                  // padding: '15px',
                  // maxWidth: 'auto',
                  maxWidth: '60%',
                  // maxHeight: 'auto',
                  marginLeft: '76px',
                  maxHeight: '300%',
                  marginRight: '66px'
                  // margin: 'auto', 
                }}> */}

            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  label="Name | Email | Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  value={formValues.commonSearch}
                  onChange={(e) => setFormValues({ ...formValues, commonSearch: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); 
                      handleSearch(); 
                      funcc()
                    }
                    
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  size="small"
                  id="job-title-autocomplete"
                  options={formValues.jobTitle}
                  value={formValues.jobTitle}
                  onChange={(e, newValue) => setFormValues({ ...formValues, jobTitle: newValue })}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const inputValue = params.inputValue.trim().toLowerCase();

                    if (inputValue !== '' && !options.some((option) => option.toLowerCase() === inputValue)) {
                      options.push(inputValue);
                    }

                    return options.filter((option) =>
                      option.toLowerCase().includes(inputValue)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Job Titles"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Search or enter job titles"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={6}>
                  <TextField
                    label="Skills"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    value={formValues.skills.join(',')} 
                    onChange={handleSkillsChange} 

                    // InputProps={{
                    //   placeholder: 'Skills', // Placeholder text
                     
                    //   style: {
                    //     height: '31px',
                    //     borderRadius: '4px',
                    //     paddingLeft: '5px',
                    //     // color: '#1E74B3'
                    //   },
                    // }}
                  />
                </Grid> */}

              <Grid item xs={4} sx={{ marginTop: "1px" }}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  size="small"
                  options={skillLst}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, newValue) => {
                    const skillsOnly = newValue?.map((item) => item?.title);
                    setFormValues({ ...formValues, skills: skillsOnly });
                  }}
                  value={formValues.skills?.map((skill) => ({ title: skill }))}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = options.filter((option) =>
                      option.title.toLowerCase().includes(params.inputValue.toLowerCase())
                    );

                    const { inputValue } = params;
                    if (inputValue !== '' && !options.some((option) => option.title === inputValue)) {
                      filtered.push({ title: inputValue });
                    }

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Skills" placeholder="Select or type to add skills" />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Relocation</InputLabel>
                  <Select
                    // labelId="relocation-label"
                    label="Relocation"
                    value={formValues.relocate}
                    // onChange={(e) => setFormValues({ ...formValues, "Yes": "No" })}
                    onChange={(e) => setFormValues({ ...formValues, relocate: e.target.value })}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Duration</InputLabel>
                  <Select
                    // labelId="unit-label"
                    label="Duration"
                    value={formValues.duration}
                    onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}
                  >
                    <MenuItem value="days">1 Day</MenuItem>
                    <MenuItem value="weeks">1 Week</MenuItem>
                    <MenuItem value="months">1 Month</MenuItem>
                    <MenuItem value="months">3 Month</MenuItem>
                    <MenuItem value="months">6 Month</MenuItem>
                    <MenuItem value="years">1 Year</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Visa</InputLabel>
                  <Select
                    label="Visa"
                    // options={visalist}
                    // label="Visa"
                    value={formValues.visaType}
                    onChange={(e) => setFormValues({ ...formValues, visaType: e.target.value })}
                  >
                    {visalist?.map((item, index) => (
                      <MenuItem key={index} value={item?.label}>
                        {item?.label}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Resource</InputLabel>
                  <Select
                    // labelId="resource-label"
                    label="Resource"
                    value={formValues.resource}
                    onChange={(e) => setFormValues({ ...formValues, resource: e.target.value })}

                  >
                    {userdata?.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item?.contactPersonName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>  

              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Select Country</InputLabel>
                  <Select
                    value={valuee.country}
                    onChange={handleChange}
                    label="Select Country"
                  >
                    <MenuItem value="USA">USA</MenuItem>
                    <MenuItem value="CANADA">CANADA</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} >
                <CustomCompleteWorksheet
                  // <CustomComplete
                  placeholder={'City'}
                  size="small"
                  option={cityData}
                  propVal={cityQuery}
                  childFuc={childFuc}
                  fetch={'state'}
                  cityStateStyling1={cityStateStyling}

                />

                {/* <FormControl fullWidth size="small">

                  {cityAndState && cityAndState.city && cityAndState.state ? (
                    <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                      <Typography color="text.secondary" variant="body1">
                        {cityAndState.city},
                        {cityAndState.state}
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl> */}
              </Grid>

              {/* <Grid item xs={3}>
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  // margin="dense"
                  size="small"
                  value={formValues.state}
                  onChange={(e) => setFormValues({ ...formValues, state: e.target.value })}
                />
              </Grid> */}


              <Grid item xs={4} size="small">
                <CustomComplete
                  placeholder={'State'}
                  size="small"
                  option={stateData}
                  propVal={stateQuery}
                  childFuc={childFucState}
                  cityStateStyling1={cityStateStyling}

                />

                <FormControl fullWidth size="small">
                  {selectedState ? (
                    <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                      <Typography color="text.secondary" variant="body1">
                        {/* {selectedState.state_name} */}
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl>
              </Grid>


              {/* <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Distance</InputLabel>
                  <Select
                    label="Distance"
                    // value={formValues.distance}
                    // onChange={(e) => setFormValues({ ...formValues, distance: e.target.value })}
                    value={dist}
                    onChange={(e) => setDistance(e.target.value)}
                  >
                    <MenuItem value="10">10 Miles</MenuItem>
                    <MenuItem value="20">20 Miles</MenuItem>
                    <MenuItem value="40">40 Miles</MenuItem>
                    <MenuItem value="50">50 Miles</MenuItem>
                    <MenuItem value="100">100 Miles</MenuItem>
                    <MenuItem value="500">500 Miles</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel >Relocation</InputLabel>
                  <Select
                    // labelId="relocation-label"
                    label="Relocation"
                    value={formValues.relocate}
                    // onChange={(e) => setFormValues({ ...formValues, "Yes": "No" })}
                    onChange={(e) => setFormValues({ ...formValues, relocate: e.target.value })}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{mt:0.9 }} components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="From Date"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue)}
                       
                      // console.log("uncontrol date",newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ }} components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="Till Date"
                        value={tillDate}
                        onChange={(newValue) => setTillDate(newValue)}
                        
                      // console.log("uncontrol date",newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid> */}


              <Grid item xs={6} textAlign="center" sx={{ marginTop: "-10px" }}>
                {/* <Button color="custom" variant="contained" onClick={handleClose} sx={{ marginRight: 7, borderRadius: '10px',marginBottom:'12px' }}>
                    Close
                  </Button> */}
                <Button color="custom" variant="contained" size="small" disabled={loading}
                  onClick={() => { handleSearch(); funcc() }}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter') {
                  //     e.preventDefault(); 
                  //     handleSearch();
                  //     funcc(); 
                  //   }
                    
                  // }}
                >
                  {/* Search */}
                  {loading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
                <Button color="custom" variant="contained" size="small"
                  sx={{ marginLeft: '12px' }}
                  onClick={clearInputField}>
                  Clear All
                </Button>

                {/* <Button color="custom" variant="contained" size="small" style={{ marginLeft: '22px' }} onClick={handleClose}>
                      Close
                    </Button> */}

              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* </ModalContent> */}
        {/* </StyledSearchbar> */}
        {/* </Slide> */}
        {/* </ClickAwayListener> */}
      </>
      {/* </ClickAwayListener> */}
    </>
  );

}



