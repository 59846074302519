import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

function ClientLeads() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locData = location.state;
  console.log('=====>>>', locData);
  // alert(location.state)
  const token = localStorage.getItem('token');
  const [editTrue, setEditTrue] = React.useState(true);
  const [tableData, setTableData] = useState([]);
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    designation: '',
    email: '',
    name: '',
    phone: '',
    linkedin: '',
  });
  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal 1
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [id, setId] = useState('');
  const [localId, setLocalId] = useState(localStorage.getItem('userid'));
  const [role, setRole] = useState('');

  useEffect(() => {
    // console.log("========ashish====>>>", id)
    getCompanyDetail();
  }, []);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  function handlePopUp(val) {
    setEditTrue(val);
    if (val === true) {
      handleOpen1();
    } else {
      handleClose1();
    }
  }
  const CheckContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          phone: e,
        });
      }
    } else {
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        phone: e,
      });
    }
  };
  const getCompanyDetail = () => {

    const body = {
      "companyName":locData.companyName,
    };

    Api('company/getleads', 'POST', body, token)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 200) {
            // toast.success(res.data.message)
            setTableData(res.data.data);
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addContact = () => {
    const body = {
      companyName: locData.companyName,
      contactPersonName: formValuesAddComapnny.name,
      designation: formValuesAddComapnny.designation,
      linkedinUrl: formValuesAddComapnny.linkedin,
      contactNoWithISD: formValuesAddComapnny.phone,
      email: formValuesAddComapnny.email,
    };
    Api('company/add_new_contact', 'POST', body, token)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          if (res.data.code === 200) {
            // toast.success(res.data.message);
            createLead(res.data.data._id);
            // getCompanyDetail();
        
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createLead = (id) => {
    const body = {
      contactId: id,
    };
    Api('company/create_lead', 'POST', body, token)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            toast.success(res.data.message);
            getCompanyDetail();
            handleClose();
            // navigate(-1);
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const funclick = () => {
    getCompanyDetail()
  };

  // const delete_contact () 
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Stack flexDirection={'row'} gap={1} alignItems={'center'} color={'text.secondary'}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
          <Typography variant="h5" color={'text.secondary'} sx={{ textTransform: 'capitalize' }}>
            {locData.companyName}'s Leads
          </Typography>
        </Stack>
        <Stack flexDirection={'row'} gap={1}>
          <Button color="custom" variant="contained" size="small" onClick={handleOpen}>
            Add Lead
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Date Added</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Designation</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Phone</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Email</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Jobs</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Open Jobs</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Placements</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Status</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <ManagementRow key={row._id} row={row} id={id} fun={funclick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
              <TextField
                id="outlined-basic"
                label="Name"
                placeholder="Name"
                variant="outlined"
                value={formValuesAddComapnny.name}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                id="outlined-basic"
                label="Designation"
                placeholder="Designation"
                variant="outlined"
                value={formValuesAddComapnny.designation}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    designation: e.target.value,
                  })
                }
              />
              <TextField
                id="outlined-basic"
                label="Linkedin"
                placeholder="Linkedin"
                variant="outlined"
                value={formValuesAddComapnny.linkedin}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    linkedin: e.target.value,
                  })
                }
              />
              <TextField
                id="outlined-basic"
                label="Phone"
                placeholder="Phone"
                variant="outlined"
                value={formValuesAddComapnny.phone}
                // inputProps={{ type: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
                onChange={(e) => CheckContact(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Email Id"
                placeholder="Email Id"
                variant="outlined"
                value={formValuesAddComapnny.email}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    email: e.target.value,
                  })
                }
              />
            </Grid>
          </Scrollbar>
          <Stack flexDirection={'row'} gap={1} mt={2}>
            <Button color="custom" variant="contained" onClick={(e) => addContact(e)}>
              Add
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

function ManagementRow({ row, id, fun }) {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [edit, setEdit] = useState(false);
  const [client, setClient] = useState({
    contactPersonName: row.contactPersonName,
    designation: row.designation,
    linkedinUrl: row.linkedinUrl,
    contactNoWithISD: row.contactNoWithISD,
    email: row.email,
  });

 
  async function editFun(row) {
    const token = localStorage.getItem('token');
    console.log('=====id=====>>>', id);
    if (edit === false) {
      setEdit(true);
    } else if (edit === true) {
      const body = {
        contactId: row._id,
        contactPersonName: client.contactPersonName,
        designation: client.designation,
        linkedinUrl: client.linkedinUrl,
        contactNoWithISD: client.contactNoWithISD,
        email: client.email,
       };
      Api(`company/edit_contact`, 'POST', body, token)
        .then((Response) => {
          console.log('======getUser==response=====>', Response);
          if (Response.status === 200) {    
            if (Response.data.code === 200) {
              setEdit(false);
             fun();
              toast.success(Response.data.message);
              console.log('======getUser===data.data pdata====>', Response);
            } else {
              console.log('======getUser=======>', +Response);
              toast.warn(Response.data.message);
              // enqueueSnackbar(Response.data.message);
            }
          }
        })
        .catch((err) => {
          toast.error(err, +'!');
          console.log('===========', err);
        });
    }


  }

  

  const deleteLead = () => {
    const token = localStorage.getItem('token');
    const body = {
      contactId: row._id,
    };
    Api('company/delete_contact', 'POST', body, token)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            toast.success(res.data.message);
            fun();
          } else {
            toast.success(res.data.message);
          }
        }
      }
     
      )
    
      };


  return (
    <TableRow>
      <TableCell>
        {moment(row.createdAT).format('ll')}
      </TableCell>
      <TableCell sx={{ pl: 1, textTransform: 'capitalize',width:140,overflow:'hidden',textOverflow:'ellipsis'}}>
        {edit ? (
          <TextField
            id="outlined-basic"
            label="Contact Person Name"
            placeholder="Contact Person Name"
            variant="outlined"
            value={client.contactPersonName}
            onChange={(e) => setClient({ ...client, contactPersonName: e.target.value })}
          />
        ) : (
          <Typography >
            {row.contactPersonName}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 1,width:140,overflow:'hidden',textOverflow:'ellipsis' }}>
        {edit ? (
          <TextField
            id="outlined-basic"
            label="Designation"
            placeholder="Designation"
            variant="outlined"
            value={client.designation}
            onChange={(e) => setClient({ ...client, designation: e.target.value })}
          />
        ) : (
          <Typography sx={{ fontWeight: '600' }} color={'text.secondary'}>{row.designation}</Typography>
        )}
      </TableCell>
     
      <TableCell sx={{ pl: 1 ,width:140}}>
        {edit ? (
          <TextField
            id="outlined-basic"
            label="Contact Number"
            placeholder="Contact Number"
            variant="outlined"
            value={client.contactNoWithISD}
            onChange={(e) => setClient({ ...client, contactNoWithISD: e.target.value })}
          />
        ) : (
          <Typography>{row.contactNoWithISD || "-"}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 1, textTransform: 'none' ,width:160,overflow:'hidden',textOverflow:'ellipsis'}}>
        {edit ? (
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Email"
            variant="outlined"
            value={client.email}
            onChange={(e) => setClient({ ...client, email: e.target.value })}
          />
        ) : (
          <Typography>{row.email}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 1 }}>{row.totalJobs}</TableCell>
      <TableCell sx={{ pl: 1 }}>{row.openJobs}</TableCell>
      <TableCell sx={{ pl: 1 }}>0</TableCell>
      <TableCell sx={{ pl: 1 ,width:120}}>
        {edit?(
           <FormControl fullWidth>
           <InputLabel id="demo-simple-select-label">Status</InputLabel>
           <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             label="Status"
             placeholder="Status"
             value={client.status}
             onChange={(e) => setClient({ ...client, status: e.target.value })}
           >
             <MenuItem value="Active">Active</MenuItem>
             <MenuItem value="Inactive">Inactive</MenuItem>
           </Select>
         </FormControl>
        ):(
          <Typography>{row.status}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 1 }}>
        <Stack flexDirection={'row'} gap={1}>
          <Button color="custom" type="submit" variant="contained" size="small" onClick={()=>editFun(row)}>
            {edit === false ? 'edit' : 'save'}
          </Button>
          
            < Button color="custom" type="submit" variant="contained" size="small"
            
              onClick={deleteLead}>
              delete
            </Button>
        
        </Stack>
      </TableCell>
    </TableRow>
  );
}
export default ClientLeads;
