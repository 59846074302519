import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener, Grid, TextField, Paper, InputBase } from '@mui/material';
import Iconify from '../../../components/iconify';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const HEADER_MOBILE1 = 150;
const HEADER_DESKTOP1 = 300;

const StyledSearchbar = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ModalContent = styled('div')(({ theme }) => ({
  marginTop: '220px',
  width: '100%',
  display: 'flex',
  marginRight: '50px',

  height: HEADER_MOBILE1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP1,
    padding: theme.spacing(0, 5),
  },
}));

const StyledIconButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
});

export default function Searchbar() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>

        {/* {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
          </IconButton>
        )} */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '59rem',
              border: '1px solid #ccc',
              borderRadius: '20px',
              marginTop: '15px',
            }}
          >
            <IconButton sx={{ p: '10px' }} >
              <Iconify icon="eva:search-outline" />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1, color: 'text.primary', border: 'none', outline: 'none' }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Paper>
          <Button style={{ marginLeft: '10px', color: 'white', backgroundColor: '#1E74B3', marginTop: '14px' }}>Search</Button>
        </div>

        <StyledIconButtonWrapper onClick={handleOpen}>
          <IconButton>
            <Iconify
              icon="bxs:down-arrow"
              color="#1E74B3"
            />
          </IconButton>
        </StyledIconButtonWrapper>


        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            {/* <StyledInput
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            /> */}
            <ModalContent sx={{ border: 'blue' }}>
              <Grid container spacing={1} justifyContent="center">

                <Grid item xs={3}>
                  <TextField
                    label="Name / Email / Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Job Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Skills"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Location: City / State"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Distance"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                {/* Second Row */}
                <Grid item xs={3}>
                  <TextField
                    label="Willing to Relocate"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Visa"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Updated Visa"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button color="custom" variant="contained" onClick={handleClose} sx={{ marginRight: 7, borderRadius: '10px' }}>
                    Close
                  </Button>
                  <Button color="custom" variant="contained" sx={{ borderRadius: '10px' }}>
                    Search
                  </Button>
                </Grid>
              </Grid>

            </ModalContent>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

// import { useState } from 'react';
// import { styled } from '@mui/material/styles';
// import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener, Grid, TextField } from '@mui/material';
// import Iconify from '../../../components/iconify';

// const HEADER_MOBILE = 64;
// const HEADER_DESKTOP = 92;

// const HEADER_MOBILE1 = 150;
// const HEADER_DESKTOP1 = 300;

// const StyledSearchbar = styled('div')(({ theme }) => ({
//   top: 0,
//   left: 0,
//   zIndex: 99,
//   width: '100%',
//   display: 'flex',
//   position: 'absolute',
//   alignItems: 'center',
//   height: HEADER_MOBILE,
//   padding: theme.spacing(0, 3),
//   // boxShadow: theme.customShadows.z8,
//   [theme.breakpoints.up('md')]: {
//     height: HEADER_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }));

// const ModalContent = styled('div')(({ theme }) => ({
//   marginTop: '250px',
//   width: '100%',
//   display: 'flex',
//   marginRight: '50px',

//   height: HEADER_MOBILE1,
//   padding: theme.spacing(2),
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.customShadows.z8,
//   [theme.breakpoints.up('md')]: {
//     height: HEADER_DESKTOP1,
//     padding: theme.spacing(0, 5),
//   },
// }));


// export default function Searchbar() {
//   const [open, setOpen] = useState(false);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <ClickAwayListener onClickAway={handleClose}>
//       <div>
//         {!open && (
//           <IconButton onClick={handleOpen}>
//             <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
//           </IconButton>
//         )}

//         <Slide direction="down" in={open} mountOnEnter unmountOnExit>
//           <StyledSearchbar>
//             {/* <StyledInput
//               autoFocus
//               fullWidth
//               disableUnderline
//               placeholder="Search…"
//               startAdornment={
//                 <InputAdornment position="start">
//                   <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
//                 </InputAdornment>
//               }
//               sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
//             /> */}
//             <ModalContent sx={{ border: 'blue' }}>
//               <Grid container spacing={1} justifyContent="center">

//                 <Grid item xs={4}>
//                   <TextField
//                     label="Client Name / Email / Phone Number"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>
//                 <Grid item xs={4}>
//                   <TextField
//                     label="Location : City / State"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>
//                 <Grid item xs={4}>
//                   <TextField
//                     label="Domain"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>
//                 <Grid item xs={4}>
//                   <TextField
//                     label="Duration"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>
//                 <Grid item xs={4}>
//                   <TextField
//                     label="Updated via"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>

//                 {/* Second Row */}
//                 <Grid item xs={4}>
//                   <TextField
//                     label="Job : Job ID / Job Title"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     label="Clients : Client ID / Client Name / Email"
//                     variant="outlined"
//                     fullWidth
//                     margin="normal"
//                   />

//                 </Grid>
//                 <Grid item xs={12} textAlign="center">
//                   <Button color="custom" variant="contained" onClick={handleClose} sx={{ marginRight: 7, borderRadius: '10px' }}>
//                     Close
//                   </Button>
//                   <Button color="custom" variant="contained" onClick={handleClose} sx={{ borderRadius: '10px' }}>
//                     Search
//                   </Button>
//                 </Grid>
//               </Grid>

//             </ModalContent>
//           </StyledSearchbar>
//         </Slide>
//       </div>
//     </ClickAwayListener>
//   );
// }
