import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import {
    Typography,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Modal,
    Box,
    TextField,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

function BenchMang() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { md: '50%', xs: '95%' },
        bgcolor: 'background.paper',
        border: '3px solid #3C6BA5',
        boxShadow: 24,
        p: 3,
        borderRadius: 3,
    };

    const navigate = useNavigate();
    const [reclst, setRecLst] = useState([])
    const [val, setVal] = useState(false)

    useLayoutEffect(() => {
        getRecList()
    }, [])

    useEffect(() => {
        getRecList()
    }, [val])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [values, setValues] = useState({
        recruitorName: '',
        recruitorCompanyName: '',
        phone: "",
        email: '',
        role: '',
    })

    const createRec = async () => {
        if (!values.recruitorName || !values.recruitorCompanyName || !values.phone || !values.email || !values.role) {
            console.log("j")
            toast.warn("All fields are mandentory")
            return;
        }
        setVal(true)
        const body = {
            "recruitorName": values.recruitorName,
            "recruitorCompanyName": values.recruitorCompanyName,
            "phone": values.phone,
            "email": values.email,
            "role": values.role
        };

        const token = localStorage.getItem('token');
        Api(`bench/addBenchRecruitor`, 'POST', body, token)
            .then((response) => {
                console.log('====rec ====', response);
                if (response.status === 200) {
                    if (response.data.code === 200) {
                        toast.success(response.data.message);
                        // getRecList()
                        //   funn()
                        handleClose()
                        setVal(false)
                        // fun()
                        setValues({
                            ...values,
                            recruitorName: "",
                            recruitorCompanyName: "",
                            phone: "",
                            email: "",
                            role: ""
                        })
                    }
                }
            })
            .catch((err) => {
                toast.error(err, +'!');
            });
    };

    // const funn = () => {
    //     getRecList()
    // }
    const getRecList = () => {
        const token = localStorage.getItem('token');
        Api(`bench/getBenchRecruitorList`, 'GET', '', token)
            .then((response) => {
                console.log('==========rec list=====>>>', response);
                if (response.status === 200) {
                    setRecLst(response.data.result);
                    if (response.data.status === 200) {
                        // alert("l")
                        // setRecLst(response.data.data.data.result);
                    }
                }
            })
            .catch((err) => { });
    };

    const tablebg = 'rgba(82, 130, 190, 0.08)';


    return (
        <>
            <ToastContainer />
            {/* <Stack direction="row" justifyContent="space-between" sx={{ ml:141, mt:2, mb:-9}}>
     <Button color="custom" variant="contained" size="small" 
    onClick={handleOpen}
    >
        Add
    </Button>
</Stack> */}

            <Iconify icon="ion:arrow-back" fontSize={25}
                sx={{ cursor: 'pointer', color: 'text.secondary', fontSize: '0.9rem', fontWeight: '600', mr: 1 }}
                onClick={() => navigate(-1)} />

            <Box
                sx={{
                    border: '2px solid #1E74B3',
                    borderRadius: '13px',
                    padding: '0.7rem',

                }}
            >
                <Button color="custom" variant="contained" size="small"
                    sx={{ float: "right" }}
                    onClick={handleOpen}
                >
                    Add
                </Button>
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    // m={1}
                    color="custom" variant="contained"
                    sx={{ mb: 2 }}
                // sx={{ backgroundColor: 'text.secondary', padding: 0.5, borderRadius: 1.5, mt:5 }}

                >
                    <Typography variant="h5" color={'text.secondary'} fontWeight={"bold"}>
                        Recruiters
                    </Typography>
                </Stack >
                <Stack
                    flexDirection="row"
                    //   justifyContent="space-between"

                    //   m={1}
                    color="custom"
                    variant="contained"
                    sx={{ mb: 0.3, mt: 1, backgroundColor: 'text.secondary', borderRadius: 1, width: "100%" }}
                >
                    <Typography sx={{ p: 1.5, py: 0.7, color: 'white', fontWeight: "bold" }} width='15%'>Company Name</Typography>
                    <Typography sx={{ p: 1.5, py: 0.7, color: 'white', fontWeight: "bold" }} width='16%'>Recruiter Name</Typography>
                    <Typography sx={{ p: 1.5, py: 0.7, color: 'white', fontWeight: "bold" }} width='16%'>Role</Typography>
                    <Typography sx={{ p: 1.5, py: 0.7, color: 'white', fontWeight: "bold" }} width='16%'>Email</Typography>
                    <Typography sx={{ p: 1.5, py: 0.7, color: 'white', fontWeight: "bold" }} width='18%'>Phone</Typography>
                </Stack>


                {reclst
                    .filter(row => row !== null && row !== '')
                    .map((row, index) => {

                        const backgroundColor = index % 2 === 0 ? 'text.background' : tablebg

                        return (
                            <div key={row._id} style={{ backgroundColor, padding: '14px' }}>
                                <ManagementRow reclst1={row} fun={getRecList} />
                            </div>
                        );
                    })
                }

            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
                        <Grid
                            display={'grid'}
                            py={1}
                            pr={{ xs: 1 }}
                            gap={2}
                            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
                        >
                            <TextField
                                id="outlined-basic"
                                name="Company Name"
                                label="Company Name"
                                placeholder="Company Name"
                                variant="outlined"
                                value={values.recruitorCompanyName}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        recruitorCompanyName: e.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-basic"
                                name="Name"
                                label="Name"
                                placeholder="Name"
                                variant="outlined"
                                value={values.recruitorName}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        recruitorName: e.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-basic"
                                type="email"
                                name="email"
                                label="Role"
                                placeholder="Role"
                                variant="outlined"
                                value={values.role}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        role: e.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-basic"
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                variant="outlined"
                                value={values.email}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        email: e.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-basic"
                                name="phone"
                                label="Phone"
                                placeholder="Phone"
                                variant="outlined"
                                value={values.phone}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        phone: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                    </Scrollbar>
                    <Stack
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                        mt={2}
                    >
                        <Button color="custom" type="submit" variant="contained"
                            onClick={() => createRec()}>
                            Add
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

export default BenchMang;

function ManagementRow({ reclst1, fun }) {

    const [edit, setEdit] = useState(false);
    const tablebg = 'rgba(82, 130, 190, 0.08)';

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('')
    const handleOpenDeleteModal = (id) => {
        setDeleteId(id)
        setDeleteModalOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const [value, setValue] = useState({
        recruitorName: reclst1?.recruitorName ? reclst1?.recruitorName : '',
        recruitorCompanyName: reclst1?.recruitorCompanyName ? reclst1?.recruitorCompanyName : '',
        phone: reclst1?.phone ? reclst1?.phone : "",
        email: reclst1?.email ? reclst1?.email : '',
        role: reclst1?.role ? reclst1?.role : '',
        recId: reclst1?._id ? reclst1?._id : '',
    })


    const updateRec = async () => {
        const body = {
            "recruitor_id": value.recId,
            "recruitorName": value.recruitorName,
            "recruitorCompanyName": value.recruitorCompanyName,
            "phone": value.phone,
            "email": value.email,
            "role": value.role
        };
        const token = localStorage.getItem('token');
        Api(`bench/benchRecruitorUpdateAPI`, 'POST', body, token)
            .then((response) => {
                console.log('====rec ====', response);
                if (response.status === 200) {
                    // toast.success(response.data.resmessage);
                    toast.success("Recruiter updated successfully!");
                    fun()
                    setEdit(false)
                }
            })
            .catch((err) => {
                toast.error(err, +'!');
            });
    };

    const deleteRec = async () => {
        const body = {
            "recruitor_id": deleteId,
        };
        const token = localStorage.getItem('token');
        Api(`bench/benchRecruitorDelete`, 'POST', body, token)
            .then((response) => {
                console.log('====rec ====', response);
                if (response.status === 200) {
                    // toast.success(response.data.resmessage);
                    toast.success("Recruiter deleted successfully!");
                    fun()
                }
            })
            .catch((err) => {
                toast.error(err, +'!');
            });
    };

    return (
        <>
            <Table sx={{ tableLayout: 'fixed', width: '87%', mt: -2, mb: -2 }}>
                <TableBody>
                    <TableRow sx={{ borderBottom: 'none' }}>
                        <TableCell sx={{ pl: 2, width: '18%', borderBottom: 'none' }}>
                            {edit ? (
                                <TextField
                                    id="outlined-basic"
                                    label="Company Name"
                                    placeholder="Company Name"
                                    variant="outlined"
                                    size="small"
                                    value={value.recruitorCompanyName}
                                    onChange={(e) =>
                                        setValue({ ...value, recruitorCompanyName: e.target.value })
                                    }
                                />
                            ) : (
                                <span
                                    style={{
                                        color: '#1E74B3',
                                        fontWeight: '800',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '60%',
                                        textTransform: 'capitalize',
                                        // fontWeight: 'bold',
                                    }}
                                >
                                    {reclst1?.recruitorCompanyName}
                                </span>
                            )}
                        </TableCell>
                        <TableCell sx={{ pl: 1, width: '18%', borderBottom: 'none' }}>
                            {edit ? (
                                <TextField
                                    id="outlined-basic"
                                    label="Name"
                                    placeholder="Name"
                                    variant="outlined"
                                    size="small"
                                    value={value.recruitorName}
                                    onChange={(e) =>
                                        setValue({ ...value, recruitorName: e.target.value })
                                    }
                                />
                            ) : (
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '100%',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {reclst1?.recruitorName}
                                </span>
                            )}
                        </TableCell>
                        <TableCell sx={{ pl: 1, width: '18%', borderBottom: 'none' }}>
                            {edit ? (
                                <TextField
                                    id="outlined-basic"
                                    label="Role"
                                    placeholder="Role"
                                    variant="outlined"
                                    size="small"
                                    value={value.role}
                                    onChange={(e) => setValue({ ...value, role: e.target.value })}
                                />
                            ) : (
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {reclst1?.role}
                                </span>
                            )}
                        </TableCell>
                        <TableCell sx={{ pl: 1, width: '18%', borderBottom: 'none' }}>
                            {edit ? (
                                <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    placeholder="Email"
                                    variant="outlined"
                                    size="small"
                                    value={value.email}
                                    onChange={(e) => setValue({ ...value, email: e.target.value })}
                                />
                            ) : (
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {reclst1?.email}
                                </span>
                            )}
                        </TableCell>
                        <TableCell sx={{ pl: 1, width: '25%', borderBottom: 'none' }}>
                            {edit ? (
                                <TextField
                                    id="outlined-basic"
                                    label="Phone"
                                    placeholder="Phone"
                                    variant="outlined"
                                    size="small"
                                    value={value.phone}
                                    onChange={(e) => setValue({ ...value, phone: e.target.value })}
                                />
                            ) : (
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {reclst1?.phone}
                                </span>
                            )}
                        </TableCell>
                        <TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: 1.5,
                                    pl: 23.7,
                                }}
                            >
                                {edit ? (
                                    <>
                                        <Button
                                            color="custom"
                                            variant="contained"
                                            size="small"
                                            onClick={() => updateRec()}
                                            sx={{ paddingLeft: 8.9, paddingRight: 8.9 }}
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            color="custom"
                                            variant="contained"
                                            size="small"
                                            onClick={() => setEdit(true)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="custom"
                                            variant="contained"
                                            size="small"
                                            onClick={() => handleOpenDeleteModal(reclst1._id)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

            {/* <Divider sx={{mt:1.8, mb:1.8}}/> */}
            <Modal

                open={deleteModalOpen}
                onClose={handleCloseDeleteModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "30%",
                    bgcolor: 'background.paper',
                    border: '3px solid #3C6BA5',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 3,
                }}>
                    <Typography variant="h6" color={'#3C6BA5'} textAlign={'center'}>
                        Are you sure you want to delete this?
                    </Typography>
                    <Stack flexDirection={'row'} gap={2} mt={2} sx={{ justifyContent: 'center' }}>
                        <Button color="custom" variant="contained"
                            onClick={() => deleteRec()}
                        >
                            Yes
                        </Button>
                        <Button color="custom" variant="contained" onClick={handleCloseDeleteModal}>
                            No
                        </Button>
                    </Stack>
                </Box>
            </Modal>

        </>
    )
}
