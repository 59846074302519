import React, { useState, useEffect } from 'react';
import { Button, Box, Modal, Typography, Grid, Stack, Divider, Card, CardContent } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Api } from '../../../webservices';

const Signature = () => {

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [signList, setSignList] = useState([]);
    const [selectedSignatureId, setSelectedSignatureId] = useState('');

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const [modalOpen1, setModalOpen1] = useState(false);

    const handleOpenModal1 = () => {
        setModalOpen1(true);
    };

    const handleCloseModal1 = () => {
        setModalOpen1(false);
    };

    useEffect(() => {
        GetSign();
    }, []);

    const handleOpenDeleteModal = (signatureId) => {
        setSelectedSignatureId(signatureId);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const AddSign = () => {
        if (!name.trim()) {
            toast.warn('Please fill input field!');
            return;
        }

        const body = {
            "name": name,
        };
        const token = localStorage.getItem("token");
        Api('email/signature/set', 'POST', body, token).then((Response) => {
            if (Response.status === 200) {
                if (Response.data.code === 200) {
                    toast.success("Signature added successfully!");
                    GetSign();
                    setName('');
                    handleCloseModal1();
                } else {
                    toast.warn(`${Response.data.status}!`);
                }
            } else {
                toast.warn('Error!');
            }
        });
    };      
 
    const GetSign = () => {
        const token = localStorage.getItem('token');
        Api('email/signature/getAll', 'GET', '', token).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 200) {
                    setSignList(response.data.result);
                    localStorage.setItem('sign', response.data.result[0].signature.name);
                } else {
                    console.log('data:', response.data.message);
                }
            }
        });
    };

    const DeleteSign = () => {
        const body = {
            "signatureId": selectedSignatureId,
        };
        const token = localStorage.getItem("token");
        Api('email/signature/delete', 'POST', body, token).then((Response) => {
            if (Response.status === 200) {
                if (Response.data.code === 200) {
                    toast.success("Signature deleted successfully!");
                    GetSign();
                    handleCloseDeleteModal();
                } else {
                    toast.warn(`${Response.data.status}!`);
                }
            } else {
                toast.warn('Error!');
            }
        });
    };
    const [updatedName, setUpdatedName] = useState('');
    const [selectedSignature, setSelectedSignature] = useState(null);

    const handleUpdateNameChange = (event, editor) => {
        const data = editor.getData();
        setUpdatedName(data);
    };

    const handleOpenUpdateModal = (signature) => {
        setSelectedSignature(signature);
        setUpdatedName(signature.signature.name);
        setModalOpen(true);
    };

    const UpdateSign = () => {
        if (!updatedName.trim()) {
            toast.warn('Please fill input field!');
            return;
        }

        const body = {
            signatureId: selectedSignature._id,
            name: updatedName,
        };

        const token = localStorage.getItem("token");
        Api('email/signature/update', 'POST', body, token).then((Response) => {
            if (Response.status === 200) {
                if (Response.data.code === 200) {
                    toast.success("Signature updated successfully!");
                    GetSign();
                    handleCloseModal();
                } else {
                    toast.warn(`${Response.data.status}!`);
                }
            } else {
                toast.warn('Error!');
            }
        });
    };

    return (
        <>
            <Modal
                open={modalOpen1}
                onClose={handleCloseModal1}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "50%",
                    bgcolor: 'background.paper',
                    border: '3px solid #3C6BA5',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 3,
                }}>
                    <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
                        Add Signature
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1 }} />
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={name}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setName(data);
                            }}
                        />
                    </Grid>
                    <Stack flexDirection={'row'} gap={1} mt={2} sx={{ justifyContent: 'center' }}>
                        <Button color="custom" variant="contained" onClick={AddSign}>
                            Add
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={deleteModalOpen}
                onClose={handleCloseDeleteModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "30%",
                    bgcolor: 'background.paper',
                    border: '3px solid #3C6BA5',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 3,
                }}>
                    <Typography variant="h6" color={'#3C6BA5'} textAlign={'center'}>
                        Are you sure you want to delete this signature?
                    </Typography>
                    <Stack flexDirection={'row'} gap={2} mt={2} sx={{ justifyContent: 'center' }}>
                        <Button color="custom" variant="contained" onClick={DeleteSign}>
                            Yes
                        </Button>
                        <Button color="custom" variant="contained" onClick={handleCloseDeleteModal}>
                            No
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "50%",
                    bgcolor: 'background.paper',
                    border: '3px solid #3C6BA5',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 3,
                }}>
                    <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
                        {selectedSignature ? "Update Signature" : "Add Signature"}
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1 }} />
                    <CKEditor
                        editor={ClassicEditor}
                        data={updatedName}
                        onChange={handleUpdateNameChange}
                    />
                    <Stack flexDirection={'row'} gap={1} mt={2} sx={{ justifyContent: 'center' }}>
                        <Button color="custom" variant="contained" onClick={selectedSignature ? UpdateSign : AddSign}>
                            {selectedSignature ? "Update" : "Add"}
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Button color="custom" variant="contained" onClick={handleOpenModal1}>
                Add Signature
            </Button>

            <Box sx={{ mt: 3 }}>
                {/* <Typography variant="h6">
          Signature List:
        </Typography> */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {signList.length > 0 ? (
                        signList.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item._id}>
                                <Card
                                    sx={{
                                        border: '1px solid #1E74B3',
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.01)',
                                            boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
                                            border: '1px solid #1E74B3',
                                        },
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.signature.name }} />
                                    </CardContent>
                                    <Stack flexDirection={'row'} gap={3} mt={2} mb={1} sx={{ justifyContent: 'center' }}>
                                        <Button color="custom" variant="contained" onClick={() => handleOpenUpdateModal(item)}>
                                            Update
                                        </Button>
                                        <Button color="custom" variant="contained" onClick={() => handleOpenDeleteModal(item._id)}>
                                            Delete
                                        </Button>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))
                    ) : null}
                </Grid>
            </Box>
            <ToastContainer />
        </>
    );
};

export default Signature;
