
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Box, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { ForgotForm } from '../sections/auth/forgot';
import { LoginForm } from '../sections/auth/login';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  maxWidth: 550,
  width: '100%',
  minHeight: '100vh',
  // paddingTop:50,
  // paddingLeft: 90,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // boxShadow: theme.customShadows.card,
  // backgroundColor: theme.palette.background.default,
  // backgroundImage: `url('/assets/logo.svg')`,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 550,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgot, setForgot] = useState(false)
  const func = (e) => {
    setForgot(true)
  }

  const func1 = (e) => {
    setForgot(false)
  }
  return (
    <>
      <Helmet>
        <title> Login | Foxearch </title>
      </Helmet>

      <StyledRoot backgroundColor="#F5F5F5">
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}
        <Box sx={{
          position: 'fixed',
          top: { xs: 10, sm: 24, md: 40 },
          left: { xs: 10, sm: 24, md: 40 },
        }}>
          <Link to="/" sx={{ display: 'contents' }}>
            <img alt="." src='/assets/logoBlue.svg' height={"80%"} width={"80%"} />
          </Link>
        </Box>
        {mdUp && (
          <StyledSection>
            <Stack sx={{
              justifyContent: 'center',
              height: '60%',
              backgroundRepeat: 'no-repeat',
              // backgroundImage: `url('/assets/greylogo.png')`,
              backgroundSize: 'auto',
              backgroundPosition: 'center',
            }}>
              {/* <Typography color={'text.secondary'} sx={{ fontStyle: 'italic', fontSize: '30px'}}>
              Foxearch
            </Typography> */}
              <Stack sx={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Typography variant="h5" sx={{ fontWeight: '600', color: 'text.secondary' }} color="#2E3C49">
                  ATS&nbsp;
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: '600', color: 'black' }} color="text.secondary">
                  |&nbsp;
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: '600', color: 'text.secondary' }} color="#2E3C49">
                  VMS&nbsp;
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: '600', color: 'black' }} color="text.secondary">
                  |&nbsp;
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: '600', color: 'text.secondary' }} color="#2E3C49">
                  SOS
                </Typography>
              </Stack>
              <Typography color="#1D2630" sx={{ textAlign: 'center' }} variant="h6">
                An Ultimate Destination for All Your Hiring Endeavours
              </Typography>
            </Stack>
          </StyledSection>
        )}
        <Stack >
          <StyledContent>
            { }

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Click here</Link>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography> */}
            {/* </Divider> */}
            {forgot === false ? (
              <LoginForm func={(e) => func(e)} />
            ) : (
              <ForgotForm func1={(e) => func1(e)} />
            )}
            {/* <Typography color={'#1D2630'} variant="caption" margin={'28px 0 0 70px'}>
              Contact Sales : (386) 465 2764
            </Typography> */}
          </StyledContent>
        </Stack>
      </StyledRoot>
    </>
  );
}
