import React, { Fragment, useEffect, useState } from 'react';
import {
  Slide,
  IconButton,
  ClickAwayListener,
  InputBase,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
  Modal,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Pagination,
  CircularProgress,
  Link

} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../components/iconify';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styleSkillset = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: { xs: '60%', sm: '50%' },
  width: '50%',
  bgcolor: 'background.paper',
  border: '3px solid #3C6BA5',
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};

const styleSkillset12 = {
  position: 'absolute',
  top: 30,
  left: 80,
  width: '90%',
  minHeight: '90%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '1px solid #3C6BA5',
  boxShadow: 10,
  p: 3,
  borderRadius: 1,
  overflowY: 'auto',
};


const tablebg = 'rgba(82, 130, 190, 0.08)';
const filter = createFilterOptions();
function Jobs() {
  const navigate = useNavigate();
  const role = useSelector((state) => state.userData.data.role);
  const [currentTab, setCurrentTab] = React.useState('Active');
  const [jobList, setJobList] = React.useState([]);
  const [searchResult, setSearchResult] = React.useState();
  const [tempRole, setTempRole] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [pages, setPages] = useState(null)

  console.log("!!!!!!!!!!!!!searchResult", searchResult)
  const handlePage = (event, value) => {
    console.log(value)
    setPageNumber(value);
    getJobs(currentTab, value)
  };

  useEffect(() => {
    getJobs('Active');
  }, []);

  const getJobs = (val, value) => {
    setJobList([])
    setLoading("Loading...")
    const body = {
      status: val,
      "page": value || 1,
      "limit": "35"
    };
    const token = localStorage.getItem('token');
    Api('company/jobs', 'POST', body, token).then((Response) => {

      if (Response.data.code === 200) {
        if (Response.data.data.length) {
          setLoading("")
          setJobList(Response.data.data);
          // setSearchResult(Response.data.data?.length)
          const totalPage = Math.ceil(Response.data.count / 35)
          setPages(totalPage)
        } else {
          setLoading("No Data Found")
          setJobList([])
        }
      }
    });
  };
  const getJobsFun = (val) => {
    getJobs(val);
  };

  const handleTabs = (val) => {
    setCurrentTab(val);
    getJobs(val);
    // getJobs(val);
  }

  const searchDataFun = (data) => {
    // alert('hhh')
    setJobList(null)
    if (currentTab !== 'active') {
      setJobList('active')
    }
    setJobList(data)
    setSearchResult(data?.length)
  }
  const resetTableData = () => {
    // alert("table")
    getJobs('Active');
  }


  const [loading, setLoading] = useState("")

  useEffect(() => {

  }, [loading]);

  const [tableShow, setTableShow] = useState(true)

  const hidePage = () => {
    // alert('hello')
    setTableShow(false)
  }

  const showtable = () => {
    setTableShow(true)
  }

  return (
    <>
      <Search searchData={searchDataFun} func={resetTableData} funcc={hidePage} funnc={showtable}
        resetSearch={() => setSearchResult('')} />
      <ToastContainer />

      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
        <Typography variant="h5" color={'text.secondary'}>
          All Jobs
        </Typography>


        {role === 'admin' || role === 'salesHead' || role === "salesManager" || role === 'recruiterHead' || role === 'recruiterManager' || role === 'recruiterTeamLead' ? (
          <Button color="custom" variant="contained" size="small" onClick={() => navigate('/addjob', { state: { row: '' } })}
          // sx={{ml:1}}
          >

            Add New Job
          </Button>
        ) : null}
      </Stack>
      {!tableShow && (

        <Typography sx={{ float: "right", mt: 0.5 }} fontSize={"0.85rem"} color={'text.secondary'}>
          Total: {searchResult || "0"}
        </Typography>
      )}
      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignItems: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === '' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('')

          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={17} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            All
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Active' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('Active')

          }}
        >
          <Iconify icon="iconamoon:star-thin" fontSize={17} color={currentTab === "Active" ? "#2ECC71" : "text.textG"} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Active Job
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'On Hold' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('On Hold')
          }}
        >
          <Iconify icon="mynaui:pause" fontSize={17} color={currentTab === 'On Hold' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            On Hold
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Closed' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('Closed')
          }}
        >
          <Iconify icon="material-symbols:block" fontSize={17} color={currentTab === "Closed" ? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Closed Jobs
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'cancelled' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('cancelled')
          }}
        >
          <Iconify icon="jam:triangle-danger" fontSize={17} color={currentTab === "cancelled" ? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Cancelled Jobs
          </Typography>
        </Stack>
      </Stack>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Role</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '14%' }}>Compensation</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Job Info</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Activity</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Search</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Assignee</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobList && jobList.map((item, index) => (
              <JobRow key={item._id} row={item} index={index} cTab={currentTab} role={role} getJobsFun={getJobsFun} />
            ))}
            {/* {jobList && jobList.length > 0 ? (
  jobList.map((item, index) => (
    <JobRow
      key={item._id}
      row={item}
      index={index}
      cTab={currentTab}
      role={role}
      getJobsFun={getJobsFun}
    />
  ))
) : (
  <div style={{
    marginLeft: '40rem',
    marginTop: "30px",
    color: 'red',
    fontSize: '12px',
     textAlign: 'center'
  }}>
    No data found
  </div>
)} */}

          </TableBody>
        </Table>
      </TableContainer>
      {/* {jobList.length < 1 && (
        <Stack sx={{ mt: 1 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )} */}

      {loading !== "" && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>{loading}</Typography>
        </Stack>
      )}
      {tableShow && (
        <>
          {jobList?.length > 1 ? (
            <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
              <Pagination variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage} />
            </Stack>
          ) : null}

        </>
      )}
    </>

  );
}

const JobRow = ({ row, index, cTab, getJobsFun, role }) => {
  const navigate = useNavigate();
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSub, setOpenSub] = useState(false);
  const subCloses = () => setOpenSub(false);
  const subOpen = () => setOpenSub(true);

  const [openInt, setOpenInt] = useState(false);
  const intCloses = () => setOpenInt(false);
  const intOpen = () => setOpenInt(true);

  const [openPla, setOpenPla] = useState(false);
  const plaCloses = () => setOpenPla(false);
  const plaOpen = () => setOpenPla(true);

  const [client, setClient] = useState([]);

  const [openstatus, setOpenstatus] = useState(false);
  const statusCloses = () => setOpenstatus(false);
  const statusOpen = () => setOpenstatus(true);

  const [openP, setOpenP] = useState(false);
  const pCloses = () => setOpenP(false);
  const pOpen = () => setOpenP(true);

  const [openAss, setOpenAss] = useState(false);
  const assCloses = () => setOpenAss(false);
  const assOpen = () => setOpenAss(true);

  const [statuss, setStatus] = useState(row.status);
  const [priorityy, setPriority] = useState('');
  const [member, setMember] = useState('individual');

  const [actionData, setActionData] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [unAssignee, setUnAssignee] = useState([]);
  const [allUsers, setAllUsers] = useState([])

  const [openJobM, setOpenJobM] = useState(false);
  const closeJobM = () => setOpenJobM(false);
  const openJobMm = () => setOpenJobM(true);
  const [jobUserList, setJobUserList] = useState([])

  const [tempRole, setTempRole] = useState('')
  const [personName, setPersonName] = React.useState([]);
  const [personId, setPersonId] = React.useState([]);
  const [roleUsers, setRoleUsers] = useState([])
  const [underRole, setUnderRole] = useState([])
  const [selectUser, setSelectUser] = useState([])
  const [selectUserId, setSelectUserId] = useState([])
  const [allAssign, setAllAssign] = useState(false)
  const [editAssign, setEditAssign] = useState(false)


  const handleChange = (event) => {
    console.log('event', event.target.value)
    setPersonName(event.target.value);
    setSelectUser([...selectUser, roleUsers[event.target.value]])
    setSelectUserId([...selectUserId, roleUsers[event.target.value]._id])
    // alert(roleUsers[event.target.value]._id)
    userUnderRole(roleUsers[event.target.value]._id)
    // const {target: { value }, } = event.target.value[0].contactPersonName;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  const changeStatus = () => {
    const token = localStorage.getItem('token');
    const body = {
      id: row._id,
      status: statuss,
      noteStatus: `Marked ${statuss}`,
      jobId: row.jobId,
      active: "change Status"
    };
    Api('company/newjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          statusCloses();
          getJobsFun(cTab);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const changePriority = () => {
    const token = localStorage.getItem('token');
    const body = {
      jobId: row._id,
      priority: priorityy,
    };
    Api('company/set_priority', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          pCloses();
          getJobsFun(cTab);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const jobAction = (val) => {
    const token = localStorage.getItem('token');
    const body = {
      jobId: row.jobId,
      status: val,
    };
    Api('company/actionsbyjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const getAllUser = async () => {
    const token = localStorage.getItem('token');
    Api('company/all_users', 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setAllUsers(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  }

  const asignData = (val) => {
    if (val.target.checked) {
      setAssignee([...assignee, val.target.value])
    } else {
      assignee.forEach((item, index) => {
        if (item === val.target.value) {
          assignee.splice(index, 1)
          setAssignee(assignee)
        }
      })
    }
  };

  const unAsignData = (val) => {
    if (val.target.checked) {
      setUnAssignee([...unAssignee, val.target.value])
    } else {
      unAssignee.forEach((item, index) => {
        if (item === val.target.value) {
          unAssignee.splice(index, 1)
          setUnAssignee(unAssignee)
        }
      })
    }
  };

  const AllAsignData = (val) => {
    const tempData = []
    setAllAssign(true)
    allUsers.forEach((item) => {
      tempData.push(item._id)
      console.log('all-', item._id)
    })
    setAssignee(tempData)

  }

  const assignJob = async () => {

    const token = localStorage.getItem('token');
    const body = {
      "jobId": row._id,
      "assignedTo": member === 'team' ? selectUserId : assignee,
      "assignType": member === 'team' ? 'team' : 'individual'
    };
    Api('company/assignjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          assCloses()
          toast.success(Response.data.message);
          // setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const unAssignJob = async () => {
    const token = localStorage.getItem('token');
    const body = {
      "jobId": row._id,
      "usersToUnassign": unAssignee,
    };
    Api('company/remove_job', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setEditAssign(false)
          closeJobM()
          toast.success(Response.data.message);
          // setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const jobUsers = async () => {
    openJobMm()
    const token = localStorage.getItem('token');
    Api(`company/users_for_job/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setJobUserList(Response.data.data);

          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  }

  const handleRole = (val) => {
    setPersonName([])
    const token = localStorage.getItem('token');
    Api(`company/userbyrole/${val}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setRoleUsers(Response.data.data);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  }

  const userUnderRole = (val) => {
    const token = localStorage.getItem('token');
    Api(`company/underusers/${val}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setUnderRole([...underRole, Response.data.data]);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  }

  const handleRoleUser = (index) => {
    selectUser.splice(index, 1)
    setSelectUser([...selectUser])
    selectUserId.splice(index, 1)
    setSelectUserId([...selectUserId])
    console.log('selectUserId', selectUserId)
  }

  const [openn, setOpenn] = useState(false);
  const handleOpenn = () => setOpenn(true);
  const handleClosen = () => setOpenn(false);

  const [activityData, setActivityData] = useState([])

  const jobActivity = async () => {
    const token = localStorage.getItem('token');
    const body = {
      "jobId": row.jobId,
    };
    Api('company/jobActivityHistroyList', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        setActivityData(Response.data.result);
        handleOpenn()
        if (Response.data.code === 200) {
          // setActionData(Response.data.data);
        }
      } else {
        handleOpenn()
        // toast.warn(Response.data.error, +'!');
      }
    });
  };

  console.log("============activityData", activityData.addedByName)

  const optionsDate = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  };

  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  };
const [hideBtn, setHideBtn] = useState()
  const [jobTit, setJobTit] = useState()
  const [jobDSkills, setJobDSkills] = useState()
  const [jobSkills, setJobSkills] = useState()
  const [consultModal, setConsultModal] = useState(false)
  const [cityMatch, setCityMatch] = useState()
  const [stateCodeMatch, setSateCodeMatch] = useState()
  const [stateMatch, setStateMatch] = useState()
  const [altMatch, setAltMatch] = useState()
  const openConsultModal = (jobTitle, dSkills, skills, city, stateCode, altJobTitle, state) => {
    setAltMatch(altJobTitle)
    setJobDSkills(dSkills)
    setJobSkills(skills)
    setJobTit(jobTitle)
    setConsultModal(true)
    setCityMatch(city)
    setSateCodeMatch(stateCode)
    setStateMatch(state)
    setValuee("consult")
  }
  console.log("jobDSlills", jobDSkills)
  console.log("jobSkills", jobSkills)

  const combinedSkills = [
    // ...(jobDSkills?.map(skill => skill.desiredSkill) || []),
    ...(jobSkills?.map(skill => skill.mustHaveSkill) || [])
  ];

  console.log("Combined Skills:", combinedSkills);

  const closeConsultModal = () => setConsultModal(false)
  const [valuee, setValuee] = React.useState('consult');
  const [consultData, setConsultData] = useState([])
  const [loading, setLoading] = useState('')
  const [tableCount, setTableCount] = useState()
  const [pageNumber, setPageNumber] = useState(1)

  const getConsultData = (value) => {
    setHideBtn('')
    setConsultData([])
    setLoading("Loading...")
    setTableCount('')
    getLocalisedData()
    const token = localStorage.getItem('token');
    const body = {
      "jobTitle": jobTit || '',
      "skill": combinedSkills || '',
      "page": value || 1,
      "limit": "50",
      "altJobTitle": altMatch

    }
    Api('bench/jobPopupConsultant', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.result && Response.data.result.length > 0) {
          setConsultData(Response.data.result);
          setTableCount(Response.data.count)
          setHideBtn(Response.data.result.length)
          const totalPage = Math.ceil(Response.data.count / 35)
          // setPages(totalPage)
          setLoading('');
        } else {
          setLoading('No data found');
        }
      } else {
        setLoading("No data found");
      }
    }).catch(error => {
      setLoading("No data found");

    });
  };

  const [hideTab, setHideTab] = useState(false)
  const [TableDataCon, setTableDataCon] = useState([])

  const getLocalisedData = (value) => {
    setConsultData([])
    setTableDataCon([])
    setLoading("Loading...")
    setTableCount('')
    const token = localStorage.getItem('token');
    const body = {
      "jobTitle": jobTit || '',
      "skill": combinedSkills || '',
      "page": value || 1,
      "limit": "50",
      "altJobTitle": altMatch
    }
    Api('resume/jobPopUpLocalised', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.result) {
          setTableDataCon(Response.data.result)
          // setConsultData(Response.data.result)
          setTableCount(Response.data.count)
          const totalPage = Math.ceil(Response.data.count / 35)
          // setPages(totalPage)
          // setHideTab()
          // setHideTab(Response.data.result?.otherData)
          setLoading('')
        } else {
          setLoading('No data found'); 
        }
      } else {
        setLoading("")
        // handleOpenn()
        // toast.warn(Response.data.error, +'!');
      }
    });
  };

  const [moveToData, setMoveToData] = useState()
  const selectTab = (tab) => {
    setValuee(tab)
    // setMoveToData(tab)
    const Cities = TableDataCon.filter(item => item.city === cityMatch && item.state ===  stateMatch);
    console.log('Cities===>>>', Cities);
    console.log('cityMatch===>>>', cityMatch);
    if (tab === 'localised') {
      if (Cities.length > 0) {
        setConsultData(Cities);
        setHideBtn(Cities.length)
        setLoading('');
      } else {
        setConsultData([]);
        setLoading('No data found');
      }
    }
    const NoCity = TableDataCon.filter(item => item.city !== cityMatch);
    console.log('NoCity===>>>', NoCity);
    const States = NoCity.filter(item => item.stateCode === stateCodeMatch);
    console.log('States===>>>', States);

    if (tab === 'statewise') {
      if (States.length > 0) {
        setConsultData(States);
        setLoading('');
      } else {
        setConsultData([]);
        setLoading('No data found');
      }
    }
    const States1 = NoCity.filter(item => item.stateCode !== stateCodeMatch);
    console.log("States1", States1)
    const Reloca = States1.filter(item => item.reallocate === "yes");
    console.log('Reloca===>>>', Reloca);

    if (tab === 'relocation') {
      if (Reloca.length > 0) {
        setConsultData(Reloca);
        setHideBtn(Reloca.length)
        setLoading('');
      } else {
        setConsultData([]);
        setLoading('No data found');
      }
    }
    const Allover = States1.filter(item => item.reallocate !== "yes");
    console.log('Allover===>>>', Allover);

    if (tab === 'allover') {
      if (Allover.length > 0) {
        setConsultData(Allover);
        setHideBtn(Allover.length)
        setLoading('');
      } else {
        setConsultData([]);
        setLoading('No data found');
      }
    }
  }

  console.log("hideTab", hideTab)
  useEffect(() => {
    if (consultModal) {
      getConsultData();
      // getLocalisedData()
    }
  }, [consultModal]);

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheck = (itemId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const handleSelectAll = () => {
    const allChecked = consultData.length > 0 && consultData.every(item => checkedItems[item._id]);
    const newCheckedItems = consultData.reduce((acc, item) => {
      acc[item._id] = !allChecked;
      return acc;
    }, {});

    setCheckedItems(newCheckedItems);

  };

  const [loadingMov, setLoadingMov] = useState(false);

  const moveTo = () => {
    setLoadingMov(true)
    const token = localStorage.getItem('token');
    consultData.forEach(item => {
      if (checkedItems[item._id]) {
        if (valuee === "consult") {
          moveToBench(item, token)
        } else {
          moveToWorksheet(item, token);
        }
      }
    });
  };

  const moveToWorksheet = (row, token) => {
    const body = {
      "newCV": "no",
      email: row.email,
      fullName: row.fullName,
      jobTitle: row.jobTitle,
      phone: row.phone,
      currentCompany: row.currCompany,
      skill: row.skill,
      visaType: row.visa,
      taxTerm: row.taxTerm,
      chargeType: row.chargeType,
      expectedCompensation: row.expectedCompensation,
      linkedinUrl: row.linkedinUrl,
      reallocate: row.reallocate,
      resumeUrl: row.resumeUrl,
      jobId: row.jobid,
      note: row.notes,
      zipcode: row.zip,
      stateCode: row.scode,
      country: row.country,
      status: row.status,
      userStatus: row.userStatus,
    };

    Api(`resume/create_resume`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200 && response.data.code === 200) {
          toast.success('Data successfully moved!');
          setLoadingMov(false)
          // selectTab(moveToData)
          //   setValuee(moveToData)
        } else {
          toast.error("A resume with the same email already exists");
          setLoadingMov(false)

        }
      })
      .catch(error => {
        console.error('API error:', error);
        // toast.error("An error occurred while moving data.");
      });
  };
  const moveToBench = (row, token) => {
    if (!row.email) {
      toast.error("Email is required");
      return;
    }
    if (!row.fullName) {
      toast.error("Full name is required");
      return;
    }
    if (!row.jobTitle) {
      toast.error("Job title is required");
      return;
    }
    const body = {
      email: row.email,
      fullName: row.fullName,
      jobTitle: row.jobTitle,
      phone: row.phone,
      currentCompany: row.currCompany,
      skill: row.skill,
      visaType: row.visa,
      visaTransfer: row.visaTrn,
      taxTerm: row.tax,
      chargeType: row.comType,
      expectedCompensation: row.expectedCom,
      cityOrZipcode: row.city,
      linkedinUrl: row.linkedinUrl,
      reallocate: row.reloc,
      zipcode: row.zip,
      stateCode: row.stateCode,
      resumeUrl: row.pdffile,
      state: row.state,
      visaFile: row.pdffileVisa,
      otherFile: row.pdffileOtherDoc,
      i94_File: row.pdffile94,
      DL_File: row.pdffileDL,
      note: row.notes,
      userStatus: row.userStatus,
      country: row.country,
      validity: row.validity,
      DOB: row.dateofbirth,
      summary: row.summary,
      experienceInYear: row.yearExp
    };

    Api(`bench/addNewBenchProspect`, 'POST', body, token)
      .then((response) => {
        console.log('=====bench==========>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setLoadingMov(false);
            toast.success('Data successfully moved!');
            getConsultData();
            setValuee("consult");

          } else {
            toast.warn("A bench worksheet with the same email already exists");
          }
        } else {
          toast.warn("A bench worksheet with the same email already exists");
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.warn("A bench worksheet with the same email already exists");
      })
      .finally(() => {
        setLoadingMov(false);
      });
  };


  useEffect(() => {
    // getSkills();
  }, []);

  const [tempskill, setTempskill] = useState([]);
  const [addedSkill, setAddedSkill] = useState([])
  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data?.result?.map((item) => {
              return tempskill?.push({ title: item?.skillName });
            });
            // setSkill(tempskill);
            setAddedSkill(tempskill)
            // console.log('===value skilll========', JSON.stringify(skill));
          } else {
            // setSkill([{ title: '' }]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };


  // const [pages, setPages] = useState(null)

  const handlePage = (valuee) => {
    setPageNumber(pageNumber + 1);
    // console.log(value)
    if (valuee === "consult") {
      // setPageNumber(pageNumber+1)
      getConsultData(pageNumber + 1)
    } else {
      getLocalisedData(pageNumber + 1)
      // setPageNumber(pageNumber+1)
      selectTab(valuee)
    }
    // getJobs(currentTab, value)
  };

  console.log("PageNumber", pageNumber)
  console.log("hideBtn", hideBtn)
  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        <TableCell sx={{}}>
          {/* <Typography sx={{}}>
  
  Up - 
  {row.modified_At ? (
    <>
      {new Date(row.modified_At).toLocaleString('en-US', optionsDate)} 
      {new Date(row.modified_At).toLocaleString('en-US', optionsTime)}
    </>
  ) : (
    " -"
  )}
</Typography> */}
          <Typography sx={{}}>
  Up - {" "}
  {row.modified_At ? (
    <>
      {new Date(row.modified_At).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })}{" "}
      {
        (() => {
          const date = new Date(row.modified_At);
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        })()
      }
    </>
  ) : (
    " -"
  )}
</Typography>


          {/* <Typography sx={{}}>Since {moment().diff(row.createdAT, 'months')} months</Typography> */}

          <Typography sx={{}}>
            {/* Cr - {moment(row.createdAT).format('lll')} */}
            Cr - {`${new Date(row.createdAT).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAT).getHours()}`}:{`${new Date(row?.createdAT).getMinutes()}`}
          </Typography>

          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            {role === 'admin' || role === 'salesHead' || role === "salesManager" || role === 'recruiterHead' || role === 'recruiterManager' || role === 'recruiterTeamLead' ? (
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/addJob', { state: { row } })}
              >
                Edit
              </Typography>
            ) : (
              <Typography>&nbsp;</Typography>
            )}
            <Stack sx={{ flexDirection: 'row' }}
              onClick={(e) => jobActivity()}

            >
              <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
              <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>

                {row?.clients ? `+ ${row?.clients}` : ''}
              </Typography>
            </Stack>

          </Stack>
        </TableCell>
        <TableCell sx={{}}>
          <Typography
            sx={{
              fontWeight: '600', fontSize: '0.90rem', cursor: 'pointer', maxWidth: 160,
              overflow: 'hidden', textOverflow: 'ellipsis'
            }}
            color={(row.status === "Closed" || row.status === "cancelled" ||
              row.status === "On Hold") ? "red" : "#286ACC"}
            onClick={() => navigate('/jobDetail', { state: { row } })}
          >
            {row?.jobTitle || '-'}

          </Typography>
          {/* <Typography sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} >
            {row.endClientOrImpPartner || '-'} 
          </Typography> */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.endClientOrImpPartner || '-'}
            </Typography>
            {row.endClientOrImpPartner && (

              <Iconify
                icon="mdi:web"
                color="text.secondary"
                sx={{ ml: 0.5, height: 15, width: 15, cursor: 'pointer' }}
                onClick={() => window.open(row.website, '_blank', 'noopener', 'noreferrer')}
              />
            )}
          </Box>

          <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>
            <Typography sx={{ fontWeight: '600', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'#286ACC'}>
              {/* {row.location || '-'} */}
              {row.city || '-'}, {row.stateCode || ''}
            </Typography>
            {row.outOfAreaCandidate === "Yes" && (
              <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
            )}
          </Stack>
        </TableCell>

        <TableCell sx={{}}>
          {row.compensationType === 'annual' ? (
            <Typography sx={{}} >
              ${row.annualMinCompensation} K{' - '}
              ${row.annualMaxCompensation} K
            </Typography>
          ) : null}

          {row.compensationType === 'per hour' ? (
            <Typography sx={{ textTransform: 'none' }} >
              ${row.payRateOn.minPayRate} P/H{' - '}
              ${row.payRateOn.maxPayRate} P/H
            </Typography>
          ) : null}
          <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.taxTerm.length ? row.taxTerm.map((item, index) => {
              return <Typography key={index} sx={{ textTransform: 'none' }}>{item} {index < row.taxTerm.length - 1 && '|'}</Typography>
            }) : <Typography sx={{ flexDirection: 'row' }}>-</Typography>}
          </Stack>

          <Typography sx={{ flexDirection: 'row', color: 'text.textG' }}>{row.duration || '-'}</Typography>
        </TableCell>
        <TableCell sx={{}}>
          <Typography sx={{ flexDirection: 'row', color: 'text.secondary', fontWeight: '600' }}>{row.jobId || '-'}</Typography>
          <Typography sx={{ flexDirection: 'row' }}>{row.jobType || '-'}</Typography>
          {row.openings === '1' ? (
            <Typography sx={{ flexDirection: 'row', color: 'text.textG' }}>{row.openings} opening</Typography>
          ) : (
            <Typography sx={{ flexDirection: 'row', color: 'text.textG' }}>{row.openings} openings</Typography>
          )}
        </TableCell>
        <TableCell sx={{}}>
          {/* <Stack sx={{ flexDirection: 'row' }} 
        // onClick={(e) => getClients()}
        onClick={handleOpenn}
        >
                <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
                <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                  
                  {row?.clients ? `+ ${row?.clients}` : ''}
                </Typography>
              </Stack> */}
          <Typography
            sx={{ color: '#286ACC', cursor: 'pointer', fontWeight: '600' }}
            onClick={() => {
              handleOpen();
              jobAction('submitted');
            }}
          >
            submissions - {row.submittedCount || 0}
          </Typography>
          <Typography
            sx={{ color: '#AE8D18', cursor: 'pointer', fontWeight: '600' }}
            onClick={() => {
              intOpen();
              jobAction('interview');
            }}
          >
            Interviews - {row.interviewCount || 0}
          </Typography>
          <Typography
            sx={{ color: '#399648', cursor: 'pointer', fontWeight: '600' }}
            onClick={() => {
              plaOpen();
              jobAction('placement');
            }}
          >
            Placements - {row.placementCount || 0}
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Typography sx={{ fontWeight: '600', cursor: 'pointer' }} color={'text.secondary'}
            onClick={() => {
              openConsultModal(row.jobTitle, row.desiredSkillsWithExp,
                row.skillWithExp, row.city, row.stateCode, row.altJobTitle, row.state)
            }}>
            Foxearch
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Stack sx={{ flexDirection: 'row' }} >
            <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }} onClick={() => jobUsers()}>Assignees</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' || role === 'recruiterManager' ? (
              <Iconify
                icon="bx:edit"
                // color={'text.secondary'}
                sx={{ height: 15, width: 15, ml: 0.3, cursor: 'pointer', alignSelf: 'center' }}
                onClick={() => { assOpen(); getAllUser() }}
              />
            ) : null}
          </Stack>
          {row.status === "Active" ? (
            <Stack sx={{ flexDirection: 'row', cursor: 'pointer' }}>
              <Typography sx={[{ color: row.priority === 'Top Priority' ? '#399648' : row.priority === 'Mid Priority' ? '#AE8D18' : row.priority === 'Low Priority' ? '#E08787' : null }]}>{row.priority ? row.priority : 'Priority'}</Typography>
              {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' ? (
                <Iconify
                  icon="bx:edit"
                  onClick={() => pOpen()}
                  // color={'text.secondary'}
                  sx={{ height: 15, width: 15, alignSelf: 'center', ml: 0.3 }}
                />
              ) : null}
            </Stack>
          ) : (
            <Typography   >
              Priority
            </Typography>
          )}
          <Typography sx={{ color: 'text.textG', maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}  >
            {row.addedByName ? row.addedByName : '-'}
          </Typography>
        </TableCell>
        <TableCell sx={{}}>
          <Stack sx={{ flexDirection: 'row', cursor: 'pointer' }}>
            <Typography sx={{}}>{row.status || '-'}</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' ? (
              <Iconify
                icon="bx:edit"
                onClick={() => statusOpen()}
                // color={'text.secondary'}
                sx={{ height: 15, width: 15, alignSelf: 'center', ml: 0.3 }}
              />
            ) : null}

          </Stack>
          <Typography sx={{ maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }} >
            {row.client || '-'}
          </Typography>
          <Typography sx={{ maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
            {row.lead || '-'}
          </Typography>
        </TableCell>
      </TableRow>

      {/* #############################@@@@@@@@@@$$$$$$$$$$$$$$$$%%%%%%%%%%%&&&&&&&&&&************ */}

      <Modal open={consultModal} onClose={closeConsultModal} style={{ borderRadius: 10 }}>
        <Box sx={styleSkillset12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Box sx={{ ml: "43%", mb: 1 }}>
              <Typography variant="h5" color="textSecondary">
                Foxearch
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              <Button color="custom" variant="contained" size="small" onClick={() => handleSelectAll()}>
                Select All
              </Button>
              <Button color="custom" variant="contained" size="small">
                Email
              </Button>
              <Button color="custom" variant="contained" size="small" disabled={loadingMov} onClick={() => moveTo()}>
                {loadingMov ? <CircularProgress size={24} /> : 'Add to Worksheet'}
              </Button>
            </Stack>
          </Stack>

          {/* <Divider sx={{ my: 2 }} />  */}
          <Stack sx={{
            flexDirection: 'row', gap: 2, mb: 0.4, cursor: 'pointer', alignItems: 'center', mt: -0.1

          }}>
            <Stack
              sx={[
                { flexDirection: 'row', alignItems: 'center' },
                valuee === 'consult' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                // navigate('/worksheet');
                // navigate('/jobs');
                setPageNumber(1)
                // getResume();
                setValuee('consult');
                getConsultData()
                // setLoading(true)
              }}
            >
              <Iconify icon="gridicons:multiple-users" fontSize={17} />
              <Typography variant="tabs" sx={{ ml: 1 }}>
                Consultants
              </Typography>
            </Stack>
            {/* <Stack
          sx={[
            { flexDirection: 'row', alignItems: 'center' },
            valuee === 'favourites' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            // navigate('/worksheet',{ state: 'favourites' })
            setPageNumber(1);
            getFav();
            setValuee('favourites');
            // setLoading(true)
          }}
        >
          <Iconify
            icon="ph:heart"
            fontSize={17}
            color={valuee === 'favourites' ? { color: '#CB4335' } : { color: 'text.textG' }}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Favourites
          </Typography>
        </Stack> */}
            <Stack
              sx={[
                { flexDirection: 'row', ml: 1, alignItems: 'center' },
                valuee === 'localised' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              // onClick={() => handleChange('submitted')}
              onClick={() => {
                // navigate('/jobs', { state: 'localised' }); 
                selectTab("localised")
                setPageNumber(1)
              }}
            >
              <Iconify
                icon="icon-park-outline:local"
                fontSize={17}
                color={valuee === 'localised' ? '#B7950B' : 'text.textG'}
              />
              <Typography variant="tabs" sx={{ ml: 1 }}>
                Localised
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row', ml: 1, alignItems: 'center' },
                valuee === 'statewise' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                // navigate('/jobs', { state: 'statewise' }); 
                selectTab("statewise")
                setPageNumber(1)
              }}
            >
              <Iconify icon="mdi:sign-real-estate" fontSize={17}
                color={valuee === 'statewise' ? '#F4D03F' : 'text.textG'}
              />
              <Typography variant="tabs" sx={{ ml: 1 }}>
                Statewise
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row', ml: 1, alignItems: 'center' },
                valuee === 'relocation' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                // navigate('/jobs', { state: 'relocation' }); 
                selectTab("relocation")
                setPageNumber(1)
              }}
            >
              <Iconify
                icon="mdi:aeroplane"
                // sx={{ transform: 'rotate(270deg)' }}
                fontSize={17}
                color={valuee === 'relocation' ? '#2ECC71' : 'text.textG'}
              />
              <Typography variant="tabs" sx={{ ml: 1 }}>
                Relocation
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row', ml: 1, alignItems: 'center' },
                valuee === 'allover' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                selectTab("allover")
                setPageNumber(1)
              }}
            >
              <Iconify icon="subway:world" fontSize={13}
                color={valuee === 'allover' ? '#CB4335' : 'text.textG'}
              />
              <Typography variant="tabs" sx={{ ml: 1 }}>
                All Over
              </Typography>
            </Stack>

          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
                  <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Date</TableCell>
                  {valuee !== "consult" ? (
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                      Detail
                    </TableCell>
                  ) : (<TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '11%' }}>
                    Detail
                  </TableCell>)}

                  {valuee !== "consult" ? (
                    <TableCell
                      sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}
                    >
                      Contact Details
                    </TableCell>
                  ) : (<TableCell
                    sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}
                  >
                    Contact Details
                  </TableCell>)}

                  {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Contact Details</TableCell> */}
                  {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Skills</TableCell> */}
                  {valuee !== "consult" ? (
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                      Skills
                    </TableCell>
                  ) : (
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                      Skills
                    </TableCell>
                  )}

                  <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '13%' }}>
                    Visa & Terms
                  </TableCell>
                  {valuee !== "consult" ? (

                    <>
                      {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Visa & Terms</TableCell> */}
                      <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>
                        Status

                      </TableCell>
                      {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Summary</TableCell> */}
                      <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Job Info</TableCell>
                      {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Job Info</TableCell> */}
                      <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Action</TableCell>
                    </>

                  ) : (
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Summary</TableCell>
                  )}
                </TableRow>
              </TableHead>
              {/* <ConsultRow
        checked1={checked}
        handleChange={handleChange1}
        
      /> */}
              {consultData?.map((item, index) => (
                <ConsultRow
                  key={item._id}
                  index={index}
                  row={item}
                  checked1={checkedItems[item._id]}
                  handleCheck1={() => handleCheck(item._id)}
                  filterTab={valuee}
                  loading={loading}
                  addedSkills={addedSkill}
                  hideTab={hideTab}
                />
              ))}
            </Table>
            {loading !== "" && (
              <Stack sx={{ mt: 2, p: 2 }}>
                <Typography sx={{ alignSelf: 'center', color: 'red' }}>{loading}</Typography>
              </Stack>
            )}


            {/* {tableCount > 35 ? (
              <Stack
                spacing={2}
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mt: 2
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: 16, height: 32, borderRadius: '5px',
                    mt: 2,
                    ml: 0.5, cursor: 'pointer'
                  }}
                  onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                >
                  {"<"}
                </Button>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  color="secondary"
                  hideNextButton
                  hidePrevButton
                  count={pages}
                  page={pageNumber}
                  onChange={handlePage}
                  sx={{ cursor: 'pointer', mb: (pageNumber !== 1) ? -4 : -2 }}
                />
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: 16, height: 32, borderRadius: '5px', mr: 1,
                    mt: -2,
                    cursor: 'pointer'
                  }}
                  onClick={() => setPageNumber(prev => Math.min(prev + 1, pages))}
                >
                  {">"}
                </Button>

              </Stack>
            ) : null} */}
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt:1 }}>
  {hideBtn > 48 && (

  <Button
    color="custom" variant="contained" size="small"
    onClick={() => handlePage(valuee)}
  >
    Next Batch
  </Button>
  )}
</Box>

          </TableContainer>
        </Box> 
      </Modal>

      {/* ################################@@@@@@@@@@$$$$$$$$$$$$$$$$%%%%%%%%%%%&&&&&&&&&&************ */}

      <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '90%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
              <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '22%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Date
                </Typography>
                <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  User
                </Typography>
                <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Status
                </Typography>
                <Typography sx={{ width: '55%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Note
                </Typography>
              </Stack>
            </Stack>
            {activityData &&
              activityData.map((item, index) => {
                return (
                  <Stack
                    sx={[
                      { background: index % 2 === 0 ? 'text.background' : tablebg },
                      { flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                    ]}
                  >
                    <Typography sx={{ width: '22%' }}>
                      {/* {moment(`${item?.createdAT}`).format('lll')} */}
                      {`${new Date(item?.createdAT).toLocaleString('en-US', optionsDate)} 
 ${new Date(item?.createdAT).getHours()}`}:{`${new Date(item?.createdAT).getMinutes()}`}
                    </Typography>
                    <Typography sx={{ width: '15%', color: 'text.secondary', textTransform: 'capitalize', fontWeight: '600' }}>
                      {item.addedByName}</Typography>

                    {item.status === 'Active' ? (
                      <Typography sx={{ width: '25%' }}>Active</Typography>
                    ) : ''}
                    {item.status === 'Closed' ? (
                      <Typography sx={{ width: '25%' }}>Closed</Typography>
                    ) : ''}
                    {item.status === 'On Hold' ? (
                      <Typography sx={{ width: '25%' }}>On Hold</Typography>
                    ) : ''}
                    {item.status === 'cancelled' ? (
                      <Typography sx={{ width: '25%' }}>Cancelled</Typography>
                    ) : ''}
                    {item.status !== 'Active' && item.status !== 'Closed' && item.status !== 'On Hold' && item.status !== 'cancelled' ? (
                      <Typography sx={{ width: '25%' }}>{item.status}</Typography>
                    ) : ''}
                    <Typography sx={{ width: '55%', textTransform: 'capitalize' }}>
                      {/* <div dangerouslySetInnerHTML={{ __html: item.note }}/>
                       */}

                      {item.notes}
                    </Typography>
                  </Stack>
                );
              })}
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={open} onClose={handleClose} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '70%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Submissions
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '19%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Name
                  </Typography>
                  <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Email
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Compensation
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Tax Term
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                  console.log('client list----------------------', item);
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { '&:last-child td, &:last-child th': { border: 0 } },
                        { '&:hover': { boxShadow: 5 } }, { flexDirection: 'row', justifyContent: 'space-between', width: '100%', p: 1 }
                      ]}
                    >
                      <Typography sx={{ width: '19%' }}>
                        {/* {moment(item.createdAt).format('lll')} */}
                        {`${new Date(item?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(item?.createdAt).getHours()}`}:{`${new Date(item?.createdAt).getMinutes()}`}
                      </Typography>

                      <Typography
                        sx={{ width: '25%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary', cursor: "pointer" }}

                        onClick={() => {
                          navigate('/resumeDetail', {
                            state: {
                              row: {
                                _id: item.resumeId,
                                resumeId: item.resumeId,
                                // fullName: row.fullName,
                                resumeUrl: "",
                              },
                              fTab: 'submitted',
                              mtab: 'Resume',
                            },
                          });
                        }
                        }
                      >
                        {item.fullName ? item.fullName : '-'}
                      </Typography>

                      <Typography sx={{ width: '25%', textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.prospectEmail ? item.prospectEmail : '-'}
                      </Typography>
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        ${item.submissionData?.expectedCompensation}{" "}
                        {item.submissionData?.chargeType === 'Annually' ? 'K' : 'P/H'}
                      </Typography>
                      <Typography sx={{ width: '20%', textTransform: 'uppercase' }}>{item.submissionData?.taxTerm || "-"}</Typography>
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>{item.submissionData?.tempSubmitStatus || "Submitted"}</Typography>
                      <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.presentedBy ? item.presentedBy : '-'}
                      </Typography>
                    </Stack>
                  );
                })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openInt} onClose={intCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '70%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Interviews
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '13%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Name
                  </Typography>
                  <Typography sx={{ width: '17.5%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Email
                  </Typography>
                  <Typography sx={{ width: '8%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Mode
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Location
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Hiring Manager
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                  console.log('client list----------------------', item);
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { '&:last-child td, &:last-child th': { border: 0 } },
                        { '&:hover': { boxShadow: 5 } }, { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 }
                      ]}
                    >
                      <Typography sx={{ width: '13%' }}>
                        {/* {moment(item.submissionData?.interviewDate).format('lll')} */}
                        {`${new Date(item?.submissionData?.interviewDate).toLocaleString('en-US', optionsDate)}`}
                      </Typography>
                      <Typography
                        // sx={{ width: '15%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary', cursor: "pointer" }}

                        sx={{ overflow: 'auto', whiteSpace: 'nowrap', width: '15%', 
                          color: 'text.secondary', fontWeight: '600', cursor: 'pointer',textTransform: 'capitalize', }}

                        onClick={() => {
                          navigate('/resumeDetail', {
                            state: {
                              row: { 
                                _id: item.resumeId,
                                resumeId: item.resumeId,
                                // fullName: row.fullName,
                                resumeUrl: "",
                              }, 
                              fTab: 'submitted',
                              mtab: 'Resume',
                            },
                          });
                        }
                        }
                      >
                        {item.fullName ? item.fullName : '-'}
                      </Typography>
                      <Typography sx={{ width: '17%', textTransform: 'none', marginRight:"8px" }}>
                        {item.prospectEmail ? item.prospectEmail : '-'}
                      </Typography>
                      <Typography sx={{ width: '8%', textTransform: 'capitalize' }}>{item.submissionData?.interviewMode}</Typography>
                      {item.submissionData?.interviewMode === 'phoneic' && (
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData?.contactNo ? item.submissionData?.contactNo : '-'}
                          {item.submissionData?.interviewMode === '' ? item.submissionData?.contactNo : ''}
                          {item.submissionData?.interviewMode === 'onsite' ? item.submissionData?.contactNo : ''}
                        </Typography>
                      )}
                      {item.submissionData?.interviewMode === 'video' && (
                        <Typography
                          sx={{ width: '10%', color: 'text.secondary' }}
                          onClick={() => window.open(item.submissionData?.meetingLink, '_blank')}
                        >
                          {item.submissionData?.videoPlatform ? item.submissionData?.videoPlatform : '-'}
                        </Typography>
                      )}
                      {item.submissionData?.interviewMode === 'onsite' && (
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData?.companyAddress ? item.submissionData?.companyAddress : '-'}
                        </Typography>
                      )}
                      <Typography sx={{ width: '15%', textTransform: 'capitalize' }}>
                        {item.submissionData?.hiringManagerName ? item.submissionData?.hiringManagerName : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.submissionData?.stepWiseStatus ? item.submissionData?.stepWiseStatus : '-'} Interview
                      </Typography>
                      <Typography sx={{ width: '15%', textTransform: 'capitalize' }}>
                        {item.presentedBy ? item.presentedBy : '-'}
                      </Typography>
                    </Stack>
                  );
                })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openPla} onClose={plaCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '70%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Placements
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '12%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Date</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Placements No
                  </Typography>
                  <Typography sx={{ width: '11%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Name</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Employement Type
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Tax Term</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Compensation</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Start Date</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    End Date / Expected ED
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Status</Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Presented By</Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                  console.log('client list----------------------', item);
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { '&:last-child td, &:last-child th': { border: 0 } },
                        { '&:hover': { boxShadow: 5 } }, { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 }
                      ]}
                    >
                      <Typography sx={{ width: '12%' }}>
                        {/* {moment(item.submissionData?.updatedAt).format('MMM YY')} */}
                        {/* {moment(item.submissionData?.updatedAt).format('lll')} */}
                        {`${new Date(item?.submissionData?.updatedAt).toLocaleString('en-US', optionsDate)} 

 ${new Date(item?.submissionData?.updatedAt).getHours()}`}:{`${new Date(item?.submissionData?.updatedAt).getMinutes()}`}
                      </Typography>
                      <Typography sx={{ width: '9%' }}>NA</Typography>
                      <Typography 
                      // sx={{ width: '10%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary', cursor: "pointer" }}
                      sx={{
                        width: '11.5%', textTransform: 'capitalize', overflow: 'auto', whiteSpace: 'nowrap',
                        color: 'text.secondary', fontSize: '0.90rem', fontWeight: '600', cursor: 'pointer',
                        marginRight:"12px"
                      }}
                        onClick={() => {
                          navigate('/resumeDetail', {
                            state: {
                              row: {
                                _id: item.resumeId,
                                resumeId: item.resumeId,
                                // fullName: row.fullName,
                                resumeUrl: "",
                              },
                              fTab: 'submitted',
                              mtab: 'Resume',
                            },
                          });
                        }
                        }
                      >
                        {item.fullName ? item.fullName : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.submissionData?.offeredJob ? item.submissionData?.offeredJob : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.submissionData?.taxTerm ? item.submissionData?.taxTerm : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        $ {item.submissionData?.expectedCompensation} {item.submissionData?.chargeType === 'Annually' ? 'K' : 'P/H'}
                      </Typography>
                      <Typography sx={{ width: '10%', }}>
                        {/* {moment(row.joiningDate).format('lll')} */}
                        { row?.joiningDate ?
                        `${new Date(row?.joiningDate).toLocaleString('en-US', optionsDate)}`
                        : "-"}

                      </Typography>
                      <Typography sx={{ width: '10%', }}>
                        {item.submissionData?.tentativeStartDate ?
                          // moment(item.submissionData?.tentativeStartDate).format('lll') : "-"}
                          `${new Date(item?.submissionData?.tentativeStartDate).toLocaleString('en-US', optionsDate)}`
                          : "-"}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>{item.submissionData?.status ? item.submissionData?.status : '-'}</Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}>
                        {item.presentedBy ? item.presentedBy : '-'}
                      </Typography>
                    </Stack>
                  );
                })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openstatus} onClose={statusCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select New Status"
                  value={statuss}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>;<MenuItem value={'Closed'}>Closed</MenuItem>;
                  <MenuItem value={'On Hold'}>On Hold</MenuItem>;<MenuItem value={'cancelled'}>Cancelled</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => changeStatus()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openP} onClose={pCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Set Priority
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select Priority</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Priority"
                  InputLabelProps={{ shrink: true }}
                  value={priorityy}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value={'Top Priority'}>Top Priority</MenuItem>
                  <MenuItem value={'Mid Priority'}>Mid Priority</MenuItem>;
                  <MenuItem value={'Low Priority'}>Low Priority</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => changePriority()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openAss} onClose={assCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { p: 1, width: '60%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Assign Professionals
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Resources</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Resources"
                placeholder="Select Resources"
                value={member}
                onChange={(e) => {
                  setMember(e.target.value);
                  if (e.target.value === 'all') {
                    AllAsignData()
                  } else {
                    setAssignee([])
                  }
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"individual"}>Individual</MenuItem>
                <MenuItem value={"team"}>Team</MenuItem>
              </Select>
            </FormControl>
            {member === 'team' && (
              <>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Resources"
                    placeholder="Select Resources"
                    value={tempRole}
                    onChange={(e) => { setTempRole(e.target.value); handleRole(e.target.value) }}
                  >
                    <MenuItem value={"recruiterHead"}>RH</MenuItem>
                    <MenuItem value={"recruiterManager"}>RM</MenuItem>
                    <MenuItem value={"recruiterTeamLead"}>RL</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple    
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) => selected.join(', ')}
                  // MenuProps={MenuProps}
                  >
                    {roleUsers.map((name, index) => (
                      <MenuItem key={name._id} value={index}>
                        {/* <Checkbox checked={personName.indexOf(name.contactPersonName) > -1} value={name._id}  /> */}
                        <ListItemText primary={name.contactPersonName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectUser.length > 0 && (
                  <Grid container gridTemplateColumns={{ sm: 'repeat(8, 1fr)', xs: 'repeat(8, 1fr)' }} sx={{ mt: 1, gap: 1 }}>
                    {selectUser.map((item, index) => {
                      return <Stack sx={{ flexDirection: 'row', border: 1, borderRadius: 1, p: 0.5, borderColor: 'text.secondary' }}>
                        <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>{item.contactPersonName}</Typography>
                        <Icon icon="jam:close" color={'red'} fontSize={16} sx={{ cursor: 'pointer', mt: -1 }}
                          onClick={() => handleRoleUser(index)}
                        />
                      </Stack>
                    })}
                  </Grid>
                )}

                <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                  {underRole.map((item, index) => {
                    return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                      <FormControlLabel
                        control={<Checkbox defaultChecked disabled />}
                        value={item._id}
                      // onChange={(e) => asignData(e)}
                      />
                      <Typography sx={{ ml: -3 }}>{item.contactPersonName} {" "}{item.USERID}</Typography>
                      {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                    </Stack>
                  })}
                </Grid>
              </>
            )}
            {member === 'individual' && (
              <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                {allUsers.map((item, index) => {
                  return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                    <FormControlLabel
                      control={<Checkbox />}
                      value={item._id}
                      onChange={(e) => asignData(e)}
                    />
                    <Typography sx={{ ml: -3 }}>{item.contactPersonName} {" "}{item.USERID}</Typography>
                    {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                  </Stack>
                })}
              </Grid>
            )}

            {member === 'all' && (
              <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                {allUsers.map((item, index) => {
                  return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                    <FormControlLabel
                      control={<Checkbox checked={allAssign} />}
                      value={item._id}
                    // onChange={(e) => asignData(e)}
                    />
                    <Typography sx={{ ml: -3 }}>{item.contactPersonName} {" "}{item.USERID}</Typography>
                    {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                  </Stack>
                })}
              </Grid>
            )}
            {/* <Grid container sx={{ mt: 2 ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}  gap={2}>
            
            
          </Grid> */}
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }}
                onClick={() => assignJob()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openJobM} onClose={closeJobM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { p: 1, width: '90%' }]}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Icon icon="bx:edit" fontSize={16} sx={{ cursor: 'pointer', mt: -1 }} onClick={() => setEditAssign(true)} />
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ mt: -3 }} textAlign={'center'}>
            {!editAssign ? "Assigned" : "Unassigned"} Professionals
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            {!editAssign ? (
              <>
                <Grid display={'grid'} gap={1} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
                  <Stack sx={{ flexDirection: 'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      User ID
                    </Typography>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Name
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      User ID
                    </Typography>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Name
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      User ID
                    </Typography>
                    <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Name
                    </Typography>
                  </Stack>
                </Grid>
                <Grid gap={1} display={'grid'} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
                  {jobUserList.map((item, index) => {
                    return <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                      ]}
                    >
                      <Typography sx={{ width: '50%' }}>
                        {item.USERID}
                      </Typography>
                      <Typography sx={{ width: '50%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}>
                        {item.contactPersonName}
                      </Typography>
                    </Stack>
                  })}
                </Grid>
              </>
            ) :
              <>
                <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                  {jobUserList.map((item, index) => {
                    return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        value={item._id}
                        onChange={(e) => unAsignData(e)}
                      />
                      <Typography sx={{ ml: -3 }}>{item.contactPersonName} {" "}{item.USERID}</Typography>
                    </Stack>
                  })}
                </Grid>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }}
                    onClick={() => unAssignJob()}>
                    Unassign
                  </Button>
                </Stack>
              </>}

          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
};

export default Jobs;

function ConsultRow({ index, row, checked1, handleCheck1, filterTab, addedSkills, hideTab }) {

  const CircleCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.Mui-checked': {
      color: '#1976d2',
      cursor: 'pointer',
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      backgroundColor: '#1976d2',
      borderRadius: '50%',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
      width: 23,
      height: 23,
      border: '2px solid #1976d2',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }));

  const styleClients1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '40%', sm: '60%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleSkillset1 = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '30%', sm: '50%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const styleSkillset11 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '50%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    // paddingTop:3,
    // paddingBottom:3,
    borderRadius: 3,
  };

  // console.log(" rec row====>>>+++", row)
  // console.log("row.resumeUrl[0]===>>>>>>>", row.additionalResumes)

  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const optionsDate = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  };

  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  };

  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const [addSkill, setAddSkill] = useState(false);
  const handleSkillOpen = () => setAddSkill(true);
  const handleSkillClose = () => setAddSkill(false);

  const [newSkill, setNewSkill] = useState([])
  const [details1, setDetails1] = useState([])
  console.log("details1", details1)

  const [details2, setDetails2] = useState([])
  console.log("details2", details2)

  const [details3, setDetails3] = useState([])
  console.log("details3", details3)

  const [details4, setDetails4] = useState([])
  console.log("details4", details4)

  console.log("<<<<<<<< >>>>> newSkill", newSkill)

  const [skillss, setSkillss] = useState([])
  console.log("skillss", skillss)

  const validSkills = row.skill.filter(item => item !== '' && item !== null);
  const validSkills1 = newSkill.filter(item => item !== '' && item !== null);

  const [hideButton, setHideButton] = useState(true)
  const [hideCross, setHideCross] = useState(true)

  const handleDeleteSkill = (index) => {
    console.log("===============indx", index)
    console.log("====validSkills", validSkills)

    const arrr = validSkills.filter((_, i) => i !== index);
    const arrr1 = validSkills1.filter((_, i) => i !== index);
    console.log("arrr", arrr1)
    setNewSkill(arrr1)
  }

  const [loading12, setLoading12] = useState(false);

  const deleteSkill = async () => {
    setLoading12(true)
    console.log('===========update', newSkill);
    const updatedSkill = [];
    newSkill?.map((item) => {
      return updatedSkill.push(item);
    });
    console.log('=======after map skill', updatedSkill);

    const token = localStorage.getItem('token');
    const body = {
      // "newCV": "no",
      // resumeId: details4,
      bench_id: details4,
      fullName: details1,
      email: details2,
      jobTitle: details3,
      skill: updatedSkill,
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          toast.success(Response.data.message);
          // fun();
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            // fun();
            setLoading12(false)
            // console.log('=====>', Response);
            setHideButton(true)
            setHideCross(true)
            console.log('=====>', Response);
          } else {
            console.log('+++======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const [loading11, setLoading11] = useState(false);

  const AdddSkill = async () => {
    setLoading11(true)
    console.log('===========update', skillss);
    const updatedSkill = [];

    skillss?.forEach((item) => {
      const lowerCaseSkill = item.title.toLowerCase();
      if (!newSkill.map(skill => skill.toLowerCase()).includes(lowerCaseSkill)) {
        updatedSkill.push(item.title);
      }
    });

    const combinedSkills = [...newSkill, ...updatedSkill];
    const uniqueSkills = [...new Set(combinedSkills.map(skill => skill.toLowerCase()))];

    const token = localStorage.getItem('token');
    const body = {
      // "newCV": "no",
      bench_id: details4,
      fullName: details1,
      email: details2,
      jobTitle: details3,
      skill: uniqueSkills,
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          toast.success(Response.data.message);
          // fun();
          handleCloses()
          setLoading11(false)
          handleSkillClose();
          // handleCloses()
          setSkillss([]);
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            // fun();
            handleCloses()
            setLoading11(false)
            handleSkillClose();
            // handleCloses()
            setSkillss([]);
            console.log('=====>', Response);
          } else {
            console.log('+++======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const [openclient1, setOpenclient1] = useState(false);
  const [EditSumId, setEditSumId] = useState()

  const [enableEditSum, setEnableEditSum] = useState(false)
  const handleOpenclient1 = (row) => {
    setUpdateSummary(row.summary);
    setOpenclient1(true)
    if (filterTab === "worksheet") {
      setEditSumId(row._id)
    } else {
      setEditSumId(row.benchId)
    }
  }
  const handleCloseclient1 = () => setOpenclient1(false);
  const [updateSummary, setUpdateSummary] = useState('')
  const [loadingSum, setLoadingSum] = useState(false)
  const [checkRow, setCheckRow] = useState()
  const selectCheckBox = (row) => {
    setCheckRow(row)
    handleCheck1()
  }
  console.log("checkRow", checkRow)

  const getHis = () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_history/${filterTab !== 'consult' ? row._id : row.resumeId}`, 'GET', '', token)
      .then((response) => {
        // console.log('==========get_history=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setHis(response.data.data);
            // setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };

  const handleClosen = () => setOpenn(false);

  const [openn, setOpenn] = useState(false);
  const [his, setHis] = useState([]);
  const handleOpenn = () => {
    setOpenn(true);
    getHis();
  };

  const [openclient, setOpenclient] = useState(false);
  const handleOpenclient = () => setOpenclient(true);
  const handleCloseclient = () => setOpenclient(false);

  const [client, setClients] = useState([]);
  const getClients = async () => {
    const token = localStorage.getItem('token');
    let id;
    if (filterTab !== 'consult') {
      id = row._id
    }
    else {
      id = row.resumeId
    }
    console.log("==========id", id)

    Api(`resume/get_history/${id}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('=========get_client_for_resume======>>>>>>>', response);
          if (response.data.code === 200) {
            setClients(response.data.data);
            // console.log('client========', JSON.stringify(response.data));
            handleOpenclient();
          } else {
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  return (
    <>

      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },

          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        {/* {filterTab === 'consult' && ( */}
        <TableCell sx={{ py: 0 }}>
          <Typography sx={{ color: 'text.secondary' }} >
            {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
            {/* Upd - {moment().diff(moment(row.updatedAt), 'days')}{" "}Days Ago */}
            Up - {`${new Date(row.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}

          </Typography>
          <Typography sx={{}} >
            {/* Cr - {moment().diff(moment(row.createdAt), 'months')}{' '}Months */}
            Cr - {`${new Date(row?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
          </Typography>

          {/* <FormControlLabel
    control={<CircleCheckbox checked={checked1} onChange={() => selectCheckBox(row)} />}
    label=""
  /> */}
          <FormControlLabel sx={{ ml: -0.1 }}
            control={
              checked1 ? (
                <Iconify
                  icon="ri:checkbox-circle-line"
                  color='#1976d2'
                  width="768"
                  height="768"
                  onClick={() => selectCheckBox(row)}
                />
              ) : (
                <Iconify
                  icon="ri:checkbox-blank-circle-line"
                  width="768"
                  height="768"
                  color='#1976d2'
                  onClick={() => selectCheckBox(row)}
                />
              )
            }
          />

        </TableCell>

        {/* {filterTab === "consult" && ( */}
        <TableCell sx={{ py: 0 }}>
          <Typography
            sx={{
              color: 'text.secondary', fontWeight: '600', fontSize: '0.9rem',
              maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis'
            }}>
            {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
            {row.fullName}

          </Typography>
          <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
            {/* Cr- {moment(row.createdAt).format('lll')} */}
            {row.currentCompany ? row.currentCompany : "-"}
          </Typography>
          <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
            {/* Cr- {moment(row.createdAt).format('lll')} */}
            {row.jobTitle ? row.jobTitle : "-"}
          </Typography>

        </TableCell>

        <TableCell sx={{ py: 0 }}>
          {filterTab === "consult" ? (
            <>
              <Typography
                sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
                {row.userId?.contactNo ? row.userId?.contactNo : '-'}

              </Typography>
              <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
                {/* Cr- {moment(row.createdAt).format('lll')} */}
                {row.userId?.email ? row.userId?.email : '-'}

              </Typography>
              <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography >
                {/* Cr- {moment(row.createdAt).format('lll')} */}
                {row.city ? `${row.city}, ${row.stateCode || ''}` : '-'}
              </Typography>
              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : ''}
              </Stack>
            </>
          ) : (
            <>
              <Typography
                sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
                {row.phone ? row.phone : '-'}

              </Typography>
              <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
                {/* Cr- {moment(row.createdAt).format('lll')} */}
                {row.email ? row.email : "-"}
              </Typography>
              <Stack sx={{ flexDirection: 'row', gap: 0.5, maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography >
                {/* Cr- {moment(row.createdAt).format('lll')} */}
                {row.city ? `${row.city}, ${row.stateCode || ''}` : '-'}
              </Typography>
              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : ''}
              </Stack>
            </>
          )}

        </TableCell>
        <TableCell sx={{ width: 170 }}>
          {row.skill && row.skill.length > 0 ? (
            <Grid container sx={{ maxWidth: 160, height: 44, overflow: 'hidden', textOverflow: 'ellipsis', gap: 0.3 }}>
              {row.skill?.map((item, index) => (
                item && (
                  <Typography key={index} sx={{}}>
                    {item}
                    {index < row.skill?.filter(skill => skill).length - 1 && ' | '}
                  </Typography>
                )
              ))}
            </Grid>
          ) : (
            <Typography>-</Typography>
          )}
          {row.skill && row.skill.length > 0 ? (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                handleOpens(); setNewSkill(row.skill); setDetails1(row.fullName);
                setDetails2(row.email); setDetails3(row.jobTitle); setDetails4(row._id)
              }}

            >
              view all
            </Typography>
          ) : null}
        </TableCell>

        <TableCell sx={{ py: 0, width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>

          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: -0.6, color: 'text.secondary' }}>
              <Typography sx={{ paddingRight: 1 }}>
                {row.visaType ? row.visaType : '-'}
              </Typography>
              {row.visaTransfer === "yes" ? (
                <Iconify icon="fluent:arrow-swap-16-filled" width="1.1rem" />
              ) : null}
            </Box>
            <Typography sx={{}}>
              {row.offeredJob && (row.annualSalary || row.hourlyRateRateOffered) && (
                <>
                  ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
                  {row.chargeType === 'hourly' ? '/Hour' : 'K'}
                </>
              )}
            </Typography>

            <Typography sx={{}}>
              {row.expectedCompensation && (
                <>
                  ${row.expectedCompensation}
                  {row.chargeType === 'hourly' ? '/Hour' : 'K'}
                </>
              )}
            </Typography>

            <Typography sx={{ textTransform: "capitalize" }} color={'text.textG'}>
              {row.taxTerm ? row.taxTerm : '-'}
            </Typography>

          </>
        </TableCell>

        {filterTab !== "consult" ?


          (
            (!row.otherData ? (
              <>
                <TableCell sx={{ py: 0, width: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      textDecoration: (row.status === 'interview') ? 'underline' : 'none',
                      cursor: (row.status === 'interview' || ['l1', 'l2', 'l3', 'l4', 'l5'].includes(row.interviewSubStatus)) ? 'pointer' : '',
                    }}
                  // onClick={() => {
                  //   if (row.status === 'interview') {
                  //     navigate('/benchresumedetails', {
                  //       state: {
                  //         addedSkills,
                  //         row: {
                  //           _id: row._id,
                  //           resumeId: row.resumeId,
                  //           status: 'submitted',
                  //           fullName: row.fullName,
                  //           resumeUrl: row.resumeUrl,
                  //           clientName: row.clientName,
                  //           interviewStatus: row.interviewSubStatus,
                  //         },
                  //         fTab: 'interview',
                  //       },
                  //     });
                  //   }
                  // }}
                  >
                    {/* {row.status === 'interview' && (
      <span>{row.interviewSubStatus} </span>
    )}
                    <span style={{ color: 'inherit' }}>
      {row.status}
    </span> */}
                  </Typography>

                  <Typography sx={{}}>
    {row.startStatus !== '' ? row.startStatus : row.status}
  </Typography>

                  {/* <Typography
    sx={{ color: 'text.secondary', fontWeight: "bold", cursor: 'pointer' }}
    // onClick={() =>
    //    handleOpens1(row._id, row.userStatus)}
  >
    {row.userStatus}
  </Typography> */}

                  {/* <Typography sx={{ color: 'text.secondary', fontWeight: "bold" }}>
                    {row.activeStatus}
                  </Typography> */}

                  {/* <Typography
    sx={{
      color: 'text.secondary',
      textDecoration: row.status === "submitted" ? 'underline' : '',
      mt: -0.8,
      cursor: 'pointer'
    }}
    onClick={() => {
      if (row.status === "submitted") {
        // setLevelM(true);
      }
    }}
  >
    {row.submitSubStatus !== "" && row.status === "submitted" ? row.submitSubStatus : row.status}
  </Typography> */}

                  <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>

                    <Stack sx={{ flexDirection: 'row' }}
                      onClick={(e) => getClients()}
                    >
                      <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
                      <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                        {row.clients ? `+${row.clients}` : ''}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Typography sx={{}} color={'text.textG'}>
                    {row.contactPersonName ? row.contactPersonName : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>-</Typography>

                  <Typography
                    sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleOpenn()}
                  >
                    Notes
                  </Typography>
                  <Typography>-</Typography>
                </TableCell>

                <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
                    <Iconify icon="ic:baseline-email" fontSize={10} />
                    {/* <Typography sx={{}} onClick={() => { navigate('/Email') }}>Email</Typography> */}
                    <Typography sx={{}}
                    // onClick={() => handleOpenModal((row._id))} 
                    >Email</Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
                    <Iconify icon="bx:support" fontSize={10} />
                    <Typography sx={{}}>Contact</Typography>
                  </Stack>
                  {row.userStatus !== 'fake' && row.userStatus !== 'blacklisted' ? (
                    <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
                      {row.startStatus !== 'quit' && row.startStatus !== 'terminated' && row.startStatus !== 'project ended' ? (
                        <Iconify icon="icon-park-solid:check-one" fontSize={10} />
                      ) : (
                        <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
                      )}

                      <Typography
                      // onClick={() => navigate('/resumeDetail', {
                      //   state: {
                      //     addedSkills, row, fTab: 'submitted',
                      //     ...((row.status === 'placement' || row.status === 'started') && { openModal: "editOrNot" })
                      //   }
                      // })}
                      >
                        {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                      </Typography>


                    </Stack>
                  ) : (
                    <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
                  )}
                </TableCell>
              </>
            ) : (
              <>

                <TableCell sx={{}}>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    -
                  </Typography>

                </TableCell>

                <TableCell sx={{}}>
                  <Stack sx={{ mt: 1.4 }}>
                    <img
                      alt="Company Logo"
                      src="/assets/logoBlue.svg"
                      style={{ width: '100%', height: '90%' }}
                    />

                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack sx={{ mt: 2 }}>

                    <Typography variant="body1" sx={{ ml: 2 }}>
                      -
                    </Typography>
                  </Stack>
                </TableCell>
              </>
            ))
          ) : (
            <>
              <TableCell sx={{ mt: -0.3 }}>

                <Typography sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleOpenclient1(row)}>
                  {/* {row.summary && row.summary.length > 10 
    ? `${row.summary.substring(0, 10)}...` 
    : row.summary || '-'} */}
                  Summary
                </Typography>
                <Typography>{"Exp - "}{row.experienceInYear}{" Yrs"}</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {/* {dayjs(row.DOB).format('MMM/DD/YYYY')} */}
                  {row.DOB ? (
                    <>
                      D.O.B - {moment(row.DOB).format('ll')}
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </TableCell>
            </>
          )
        }

        <Modal open={openclient} onClose={handleCloseclient} style={{ borderRadius: 10 }}>
          <Box sx={styleClients}>
            <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
              Activities
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
              <Stack sx={{ alignItems: 'center' }}>
                <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                  <Stack
                    sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Date
                    </Typography>
                    <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      User Name
                    </Typography>
                    <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Job ID
                    </Typography>
                    <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Client
                    </Typography>
                    <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Job Title
                    </Typography>
                    <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                      Status
                    </Typography>
                  </Stack>
                </Stack>

                {client
                  ? client.map((item, index) => {
                    // console.log('client list----------------------', item);
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                        ]}
                      >
                        <Typography sx={{ width: '25%' }}>
                          {/* {moment(`${item.createdAt}`).format('lll')} */}
                          {`${new Date(item.createdAt).toLocaleString('en-US', optionsDate)} 
${new Date(item?.createdAt).getHours()}`}:{`${new Date(item?.createdAt).getMinutes()}`}

                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize', fontWeight: '800', color: 'text.secondary' }}>
                          {item.actionBy}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.jobId ? item.jobId : '-'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.clientName ? item.clientName : '-'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.jobIdTitle || item.jobTitle}
                        </Typography>
                        <Typography
                          sx={[
                            {
                              color:
                                item.actionTo === 'started' || item.actionTo === 'placement'
                                  ? 'green'
                                  : item.actionTo === 'Backout from Job offer'
                                    ? 'red'
                                    : '',
                            },
                            { width: '20%', textTransform: 'capitalize' },
                          ]}
                        >
                          {item.actionTo}
                        </Typography>
                      </Stack>
                    );
                  })
                  : null}
              </Stack>
            </Scrollbar>
          </Box>
        </Modal>

        <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
          <Box sx={styleClients}>
            {/* <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Button
              // sx={{width:'13%'}}
              color="custom"
              variant="contained"
              size="small"
              onClick={() =>
                navigate('/resumeDetail', {
                  state: {
                    addedSkills,
                    row,
                    mtab: 'Resume',
                    fTab:
                      filterTab === 'worksheet'
                        ? 'submitted'
                        : '' || filterTab === 'favourites'
                          ? 'submitted'
                          : '' || filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                              ? 'start'
                              : '',
                  },
                })
              }
            >
              Add Note
             
            </Button>
          </Stack> */}
            <Typography variant="h5" color={'#3C6BA5'} sx={{ textTransform: 'capitalize', mt: -3 }} textAlign={'center'}>
              {/* {row.fullName.split(" ",1)}'s Notes */}
              {/* {row.fullName}'s Notes */}
              {/* {/\s/.test(row.fullName) ? row.fullName.split(" ", 1) : row.fullName}'s  */}
              Notes
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
              <Stack sx={{ backgroundColor: 'text.secondary', flexDirection: 'row', p: 1, py: 0.5, borderRadius: 1 }}>
                <Typography color={'white'} sx={{ width: '16%', fontSize: '0.976rem', fontWeight: '600' }}>
                  Date
                </Typography>
                <Typography color={'white'} sx={{ width: '10%', fontSize: '0.976rem', fontWeight: '600' }}>
                  User
                </Typography>
                <Typography color={'white'} sx={{ width: '80%', fontSize: '0.976rem', fontWeight: '600' }}>
                  Note
                </Typography>
              </Stack>
              {his.length
                ? his.map((item, index) => {
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { flexDirection: 'row', p: 1 },
                      ]}
                    >
                      <Typography sx={{ width: '16%' }}>
                        {/* {moment(`${item.createdAt}`).format('lll')} */}
                        {`${new Date(item?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(item?.createdAt).getHours()}`}:{`${new Date(item?.createdAt).getMinutes()}`}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize', fontWeight: '800', color: 'text.secondary' }}>{item.actionBy}</Typography>
                      <Typography sx={{ width: '80%' }}>
                        {item.note}
                        {/* <div dangerouslySetInnerHTML={{ __html: item.note }} /> */}
                      </Typography>
                    </Stack>
                  );
                })
                : null}
            </Scrollbar>
          </Box>
        </Modal>

        <Modal open={openclient1} onClose={handleCloseclient1} style={{ borderRadius: 10 }}>
          <Box sx={styleClients1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h5"
                color={'#3C6BA5'}
                textAlign={'center'}
                sx={{ flexGrow: 1, textAlign: 'center' }}
              >
                {/* {row.fullName}'s  */}
                Summary
              </Typography>
              {/* {!enableEditSum && (

  <Button color="custom" variant="contained" onClick={(e)=>setEnableEditSum(true)}>
    Edit
  </Button>
  )}  */}
              {enableEditSum && (
                <Button color="custom" variant="contained" size="small" disabled={loadingSum}
                // onClick={(e)=>editSummary()}
                >
                  {loadingSum ? <CircularProgress size={22} /> : 'Save'}

                </Button>
              )
              }
            </Stack>

            <Divider sx={{ mt: 1, mb: 1, borderWidth: 1.7, borderColor: '#3C6BA5' }} />
            {!enableEditSum && (

              <Typography sx={{ width: '100%', fontSize: '0.976rem', fontWeight: '100' }}>
                {row.summary}
              </Typography>
            )}
            {enableEditSum && (

              <TextField
                sx={{}}
                id="outlined-multiline-static"
                label="Summary"
                multiline
                rows={4}
                width="100%"
                fullWidth
                value={updateSummary}
                onChange={(e) => setUpdateSummary(e.target.value)}

              />
            )}
          </Box>
        </Modal>


        <Modal open={opens} onClose={handleCloses} style={{ borderRadius: 10 }}>
          <Box sx={styleSkillset11}>
            <Stack sx={{ float: "right" }} direction="row" spacing={1.9}>
              {/* <Button color="custom" variant="contained"  onClick={handleSkillOpen}>Add</Button> */}
              {/* {hideButton && (

    <Button color="custom" variant="contained"  onClick={() => { setHideButton(!hideButton); setHideCross(false) }}>Delete
    </Button>
  )}  */}
              {!hideButton && (

                <Button color="custom" variant="contained" onClick={() => { deleteSkill() }}
                  disabled={loading12}
                >
                  {/* Save */}
                  {loading12 ? <CircularProgress size={24} /> : 'Save'}
                </Button>
              )}
            </Stack>
            <Typography variant="h5" color={'#3C6BA5'} sx={{ ml: "46%" }}>
              Skills

            </Typography>

            <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
              <Divider sx={{ mt: 0.5, borderWidth: 1.7, borderColor: '#3C6BA5' }} />
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 0.5,


                  flexWrap: 'wrap'
                }}
              >
                {/* {row.skill?.map((item, index) => (
        item && (
          <Typography
            key={index}
            variant="h6"
            color="text.textG"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            {item}
            {index < row.skill.filter(skill => skill).length - 1 && ' | '}
          </Typography>
        )
      ))} */}

                {/* {row.skill?.map((item, index) => ( */}
                {newSkill?.filter(item => item !== '' && item != null).map((item, index) => (

                  item && (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="text.textG"
                        textAlign="center"
                        textTransform="capitalize"
                        sx={{ paddingRight: '2px', position: 'relative', paddingTop: '-3px' }}
                      >
                        {item} {index < row.skill.length - 1 && '|'}

                        {!hideCross && (
                          <Iconify
                            icon="system-uicons:cross-circle"
                            width="1.45rem"
                            height="1.45rem"
                            sx={{
                              position: 'absolute',
                              top: '-15px',
                              right: '1px',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                            onClick={() => handleDeleteSkill(index)}
                          />
                        )}

                      </Typography>
                    </Box>
                  )
                ))}

              </Stack>
            </Scrollbar>
          </Box>
        </Modal>

        <Modal open={addSkill} onClose={handleSkillClose} sx={{ borderRadius: 10 }}>
          <Box sx={styleSkillset1}>
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                onChange={(event, newValue) => {
                  setSkillss([...newValue]);
                }}
                options={addedSkills}
                getOptionLabel={(option) => option.title}
                value={skillss}
                filterSelectedOptions
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;

                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `${inputValue}`,
                    });
                  }
                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Skills" placeholder="Enter Skill" autoFocus />
                )}
              />
              <Stack direction="row" justifyContent="center" spacing={3}>
                <Button
                  color="custom"
                  variant="contained"
                  onClick={() => {
                    // handleSkillClose();
                    // handleCloses()
                    // setSkillss([]);
                    AdddSkill()
                  }}
                  disabled={loading11}
                >
                  {/* Add Skill */}
                  {loading11 ? <CircularProgress size={24} /> : 'Add Skill'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </TableRow>
    </>
  );
};


const StyledIconButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
});

function Search({ searchData, func, funcc, funnc, resetSearch }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formValues, setFormValues] = useState({
    commonSearch: "",
  });

  const [Values, setValues] = useState({
    commonSearch: "",
    addedBy: "",
    status: "",
    fromDate: "",
    tillDate: "",
    jobType: "",
    jobTime: ""
  });

  const CommonSearcFun = () => {
    const body = {
      "commonSearch": Values.commonSearch,
      // "resource": Values.addedBy,
      "addedById": Values.addedBy,
      "status": Values.status,

      "fromDate": Values.fromDate,
      "tillDate": Values.tillDate,
      "remote": Values.jobType,
      // "jobType": Values.jobType
      "jobType": Values.jobTime
    }

    const token = localStorage.getItem('token');
    Api('common/search/jobs', 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // searchData(response.data.data)
            if (response.data.data && response.data.data.length > 0) {
              searchData(response.data.data);
            } else {
              searchData([]);
            }
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [loading1, setLoading1] = useState(false);

  const fn = () => {
    setLoading1(true)
    const body = {
      "commonSearch": formValues.commonSearch,
    }
    const token = localStorage.getItem('token');
    Api('common/search/jobs', 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            searchData(response.data.data)
            setLoading1(false)
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    resetSearch()
    clearInputField()
    setIsVisible(!isVisible);
    func()
    setFormValues({ ...formValues, commonSearch: '' });
  };

  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  // const handleDateChange = (date, field) => {
  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     [field]: date ? date.toISOString() : null,
  //   }));
  // };

  const handleDateChange = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setValues((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    console.log("formattedDate====>>>>", formattedDate);
  };

  const clearInputField = () => {
    setValues({
      ...Values,
      commonSearch: '',
      addedBy: "",
      status: "",
      fromDate: "",
      tillDate: "",
      jobType: "",
      jobTime: ''
    });

  }

  const [userdata, setUserdata] = useState([])
  console.log("userdata ===>>>>", userdata)
  useEffect(() => {
    allUsers()
  }, []);

  const allUsers = () => {

    const token = localStorage.getItem('token');
    // Api('company/all_users', 'GET', '', token)
    Api('company/jobAddedByList', 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          setUserdata(response?.data?.result);
          if (response.data.code === 200) {
            // const users = response.data.data;
            // const contactPersonNames = users.map(user => user.contactPersonName);

          } else {
            console.log('Error:', response.data.message);
          }

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>

          {/* {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
          </IconButton>
        )} */}


          <div style={{ display: 'flex', alignItems: 'center', left: 115, right: 0 }}>
            <Paper
              component="form"
              sx={{
                // p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '25%',
                border: '1px solid #1E74B3',
                borderRadius: '10px',
                marginTop: -18,
              }}
            >
              {/* <IconButton sx={{ p: '0.1px', ml: "7px" }}>
              <Iconify icon="eva:search-outline" />
            </IconButton> */}
              <InputBase
                sx={{ flex: 1, color: 'text.primary', border: 'none', outline: 'none', pl: 1 }}
                placeholder="Job Id | Job Title"
                inputProps={{ 'aria-label': 'search' }}
                value={formValues.commonSearch}
                onChange={(e) => setFormValues({ ...formValues, commonSearch: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fn();
                    funcc();
                  }
                }}
              />
            </Paper>
            {/* <Button style={{ marginLeft: '16px', color: 'white', backgroundColor: '#1E74B3', borderRadius: '10px', marginTop: '4px', height: '41px', width: '130px' }} onClick={fn}>Search</Button> */}
            <Button color="custom" variant="contained" size="small" sx={{ ml: 1.6, mt: -18 }}
              onClick={() => { fn(); funcc() }}
              disabled={loading1}

            >
              {/* Search */}
              {loading1 ? <CircularProgress size={24} /> : 'Search'}
            </Button>
            {(formValues.commonSearch ||
              Values?.commonSearch ||
              Values?.addedBy ||
              Values?.status ||
              Values?.fromDate ||
              Values?.tillDate ||
              Values?.jobType ||
              Values?.jobTime

            )
              && (
                // <IconButton
                //   sx={{ p: '1px', ml: '6px' }}
                //   color="custom"
                //   variant="contained"
                //   size="large"
                //   onClick={toggleVisibility}
                // >
                //   <Iconify icon="oui:cross-in-circle-filled" width="1.7rem" />
                // </IconButton>
                <Button color="custom" variant="contained" size="small"
                  sx={{ ml: 1.6, mt: -18 }}
                  onClick={() => { toggleVisibility(); funnc() }}>
                  Close
                </Button>
              )}

          </div>
          <StyledIconButtonWrapper onClick={handleSearchOpen}>
            <IconButton sx={{ display: 'flex', position: 'absolute', top: 55, marginRight: '68%' }}>
              <Iconify
                icon="bxs:down-arrow"
                color="#1E74B3"
              />
            </IconButton>
          </StyledIconButtonWrapper>
        </div>
      </ClickAwayListener>

      <Modal open={searchOpen} onClose={handleSearchClose}>

        <Box sx={{
          position: 'absolute',
          top: '34%',
          left: '43%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '70%' },
          // width: { xs: '85%', sm: '60%' },
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 2,
          borderRadius: 3,
        }}
        >

          <Grid container spacing={1} justifyContent="center">

            <Grid item xs={12} >
              <TextField
                label="Job ID / Job Title"
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"

                value={Values.commonSearch}
                onChange={(e) => setValues({ ...Values, commonSearch: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    CommonSearcFun();
                    handleSearchClose();
                    funcc()
                  }
                }}
              />
            </Grid>

            <Grid container sx={{ flexDirection: "row", marginLeft: '1px' }} spacing={1}>
              <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      slotProps={{ textField: { size: "small" } }}
                      value={Values.fromDate ? dayjs(Values.fromDate) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange(date, 'fromDate')}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Till"
                      slotProps={{ textField: { size: "small" } }}
                      value={Values.tillDate ? dayjs(Values.tillDate) : null}
                      // onChange={(date) => handleDateChange1(date, 'tillDate')}
                      onChange={(date) => handleDateChange(date, 'tillDate')}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={6}>


              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={value.domain}
                  value={Values.status}
                  label="Status"
                  // InputLabelProps={{ shrink: true }}
                  onChange={(e) => setValues({ ...Values, status: e.target.value })}
                >
                  {/* {Domains.map((item) => {
           return <MenuItem value={item}>{item}</MenuItem>
         })} */}

                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"On Hold"}>On Hold</MenuItem>
                  <MenuItem value={"Closed"}>Closed</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel >Added By</InputLabel>
                <Select
                  // labelId="resource-label"
                  label="Added By"
                  value={Values.addedBy}
                  onChange={(e) => setValues({ ...Values, addedBy: e.target.value })}

                >
                  {/* {userdata.map((item, index) => (
            <MenuItem key={index} value={item._id}>
              {item.contactPersonName}
            </MenuItem>
          ))} */}
                  {userdata.filter(item => item).map((item, index) => (
                    <MenuItem key={index} value={item._id?.addedById}>
                      {item._id?.addedByName}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={"Tony"}>Tony</MenuItem>
          <MenuItem value={"Tom"}>Tom</MenuItem>
          <MenuItem value={"Stark"}>Stark</MenuItem>
          <MenuItem value={"Homelander"}>Homelander</MenuItem> */}
                  {/* <MenuItem value={"Inactive"}>Kyra</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Remote</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={value.domain}
                  value={Values.jobType}
                  label="Job Type"
                  // InputLabelProps={{ shrink: true }}
                  onChange={(e) => setValues({ ...Values, jobType: e.target.value })}
                >
                  {/* {Domains.map((item) => {
           return <MenuItem value={item}>{item}</MenuItem>
         })} */}

                  <MenuItem value={"onsite Job"}>Onsite</MenuItem>
                  <MenuItem value={"remote"}>Remote</MenuItem>
                  <MenuItem value={"hybrid"}>Hybrid</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Job Type"
                  placeholder="Job Type"
                  value={Values.jobTime}
                  onChange={(e) => setValues({ ...Values, jobTime: e.target.value })}
                >
                  <MenuItem value={'fulltime'}>Full Time</MenuItem>
                  <MenuItem value={'permanent'}>Permanent</MenuItem>
                  <MenuItem value={'contract'}>Contract</MenuItem>
                  <MenuItem value={'CTH'}>CTH</MenuItem>
                  <MenuItem value={'temporary'}>Temporary</MenuItem>
                  <MenuItem value={'internship'}>Internship</MenuItem>
                  <MenuItem value={'parttime'}>Part Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} textAlign="center" sx={{ marginTop: "5px" }}>
              <Button color="custom" variant="contained" size="small"
                // onClick={() => { handleSearch(); funcc() }}
                onClick={() => { CommonSearcFun(); handleSearchClose(); funcc() }}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     e.preventDefault(); 
              //     CommonSearcFun();
              //     handleSearchClose(); 
              //     funcc()
              //   }

              // }}
              >
                Search
              </Button>
              <Button color="custom" variant="contained" size="small"
                sx={{ marginLeft: '12px' }}
                onClick={clearInputField}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
