import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  console.log("date-----",chartLabels)
  // const chartOptions = useChart({
  //   plotOptions: { bar: { columnWidth: '18%' } },
  //   fill: { type: chartData.map((i) => i.fill) },
  //   // labels: chartLabels,
  //   xaxis: { 
  //     categories:chartLabels,
  //     labels: {
  //       style: {
  //         colors: 'black', 
  //       },
  //     // rotate: 360,
  //     // offsetX: 35, 
  //   },
    
  //   },
  //   tooltip: {
  //     shared: true,
  //     intersect: false,
  //     y: {
  //       formatter: (y) => {
  //         if (typeof y !== 'undefined') {
  //           return `${y.toFixed(0)}`;
  //           // return `${y.toFixed(0)} visits`;
  //         }
  //         return y;
  //       },
  //     },
  //   },
  // });

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '18%' } },
    fill: { type: chartData.map((i) => i.fill) },
    xaxis: {
      categories: chartLabels,
      labels: {
        style: {
          colors: 'black', 
         
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'black',  
        },
      },
    },
    grid: {
      // borderColor: '#333', 
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });
  
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={380} />
      </Box>
    </Card>
  );
}
