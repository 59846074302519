// // @mui
// import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
// import { Card, Stack, Typography } from '@mui/material';
// // utils
// import { fShortenNumber } from '../../../utils/formatNumber';
// // components
// import Iconify from '../../../components/iconify';

// // ----------------------------------------------------------------------

// const StyledIcon = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   border:"grey",
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(6),
//   height: theme.spacing(6),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(2),
// }));

// // ----------------------------------------------------------------------

// AppWidgetSummary.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   total: PropTypes.number.isRequired,
//   percentage: PropTypes.string.isRequired,
//   perSign: PropTypes.string.isRequired,
//   sx: PropTypes.object,
//   selected: PropTypes.bool,
// };

// export default function AppWidgetSummary({selected, title, total, percentage, perSign, icon, color = 'primary', sx, ...other }) {
//   return (
//     <Card
//       sx={{
//         p: 2,
//         boxShadow: 0,
//         textAlign: 'center',
//         color: (theme) => theme.palette[color].darker,
//         bgcolor: (theme) => theme.palette[color].lighter,
//         ...sx,
//       }}
//       {...other}
//     >
//       {/* <StyledIcon
//         sx={{
//           color: (theme) => theme.palette[color].dark,
//           backgroundImage: (theme) =>
//             `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
//               theme.palette[color].dark,
//               0.24
//             )} 100%)`,
//         }}
//       >
//         <Iconify icon={icon} width={24} height={24} />
//       </StyledIcon> */}

//       <Typography variant="h6" sx={{  textAlign: 'left', color: selected?"text.secondary":"text.textG", ml: 0.4 }}>{title}</Typography>
//       <Stack sx={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
//         <Stack direction="row" gap={1} >
//           {perSign !== 'DOWN' ? (<Iconify icon='icon-park-solid:up-one' color={selected?percentage < 0 ?"#CB4335": percentage >= 0 && percentage <= 10 ?'#F4D03F':'#2ECC71':"text.textG"} width={30} height={30} />
//           ) : (
//             <Iconify icon='icon-park-solid:down-one' color={selected?percentage < 0 ?"#CB4335": percentage >= 0 && percentage <= 10 ?'#F4D03F':'#2ECC71':"text.textG"} width={30} height={30} />
//           )}
//          <Typography variant="h6" style={{ fontSize: '18px'}} sx={{ color:selected?percentage < 0 ?"#CB4335": percentage >= 0 && percentage <= 10 ?'#F4D03F':'#2ECC71':"text.textG" }}>{percentage}%</Typography>
//       </Stack>
//           <Iconify icon={'foundation:graph-bar'} color={selected?percentage < 0 ?"#CB4335": percentage >= 0 && percentage <= 10 ?'#F4D03F':'#2ECC71':"text.textG"} width={30} height={30} />
//       </Stack>
//       <Typography variant="h6" sx={{ textAlign: 'left', ml: 0.5 ,color: selected?"text.secondary":"text.textG" }} style={{ fontSize: '18px'}}>{fShortenNumber(total) || 0}</Typography>
//     </Card>
//   );
// }


// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  border: `1px solid ${theme.palette.grey[400]}`, // Adjusted for better visibility
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  percentage: PropTypes.string.isRequired,
  perSign: PropTypes.string.isRequired,
  sx: PropTypes.object,
  selected: PropTypes.bool,
};

export default function AppWidgetSummary({ title, total, percentage, perSign, icon, color = 'primary', sx, ...other }) {
  // Always show colorful
  const percentageColor = percentage < 0 ? "#CB4335" : percentage >= 0 && percentage <= 10 ? '#F4D03F' : '#2ECC71';

  return (
    <Card
      sx={{
        p: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      {/* Optional Icon Display */}
      {/* <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon> */}

      <Typography variant="h6" sx={{ textAlign: 'left', color: 'text.secondary', ml: 0.4 }}>
        {title}
      </Typography>
      <Stack sx={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack direction="row" gap={1}>
          {perSign !== 'DOWN' ? (
            <Iconify icon='icon-park-solid:up-one' color={percentageColor} width={30} height={30} />
          ) : (
            <Iconify icon='icon-park-solid:down-one' color={percentageColor} width={30} height={30} />
          )}
          <Typography variant="h6" sx={{ color: percentageColor, fontSize: '18px' }}>
            {percentage}%
          </Typography>
        </Stack>
        <Iconify icon='foundation:graph-bar' color={percentageColor} width={30} height={30} />
      </Stack>
      <Typography variant="h6" sx={{ textAlign: 'left', ml: 0.5, color: 'text.secondary', fontSize: '18px' }}>
        {fShortenNumber(total) || 0}
      </Typography>
    </Card>
  );
}

