import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, List, ListItemText, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [],navwd, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} navwd={navwd}/>
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item,navwd }) {
  const { title, path, icon, info, req } = item;
  const tablebg = 'rgba(82, 130, 190, 0.08)';
  return (
    <StyledNavItem
    navwd={navwd}
      component={RouterLink}
      to={path}
      sx={{
        position: 'relative',
        '&.active': {
          color: 'text.secondary',
          // bgcolor: 'action.selected',
          bgcolor: tablebg,
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} style={navwd===100?{fontSize:12,textAlign:'center'}:{}}/>

      {info && info}
      {/* {req && <Button sx={{ backgroundColor: 'red' }}>{req}</Button>} */}
    </StyledNavItem>
  );
}
