import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, Grid, Button, Modal, Select, MenuItem, IconButton, InputAdornment, Stack } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../components/iconify';
import { Api, UploadFile } from '../webservices';

function Email() {
    const location = useLocation();
    const navigate = useNavigate();
    const [tempList, setTemplList] = useState([])
    const [newsub, setNewSub] = useState('')
    const [newemail, setNewEmail] = useState('')
    const [tempidx, setTempidx] = useState(0)
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [selectedTemplate1, setSelectedTemplate1] = useState(0);
    const [displayEmails, setDisplayEmails] = useState(false);
    const [emailBody, setEmailBody] = useState('')
    const [subject, setSubject] = useState('')
    const [greeting, setGreeting] = useState('')
    const [formValues, setFormValues] = useState({
        templateName: '',
        companyId: "",
        userId: ""
    });

    useEffect(() => {
        // const defaultContent = `<p><br>Regards,<br>Your Name</p>`;
        // setEmailBody(defaultContent);
        GetTempList();
        setTimeout(() => {
            setDisplayEmails(true);
        }, 1000);

    }, []);

    const GetTempList = (val) => {
        const token = localStorage.getItem('token');
        Api('email/getEmailTemplateList', 'GET', '', token)
            .then((response) => {
                console.log('Response:---------', response);
                if (response.status === 200) {
                    if (response.data.code === 200) {
                        setTemplList(response.data.result)   
                        if(val === 'add'){
                            setSelectedTemplate({})
                            setSelectedTemplate1()  
                            setSubject('')
                            setEmailBody('')
                        }else{
                            setSelectedTemplate1(0)
                            setSelectedTemplate(response.data.result[tempidx])
                            if(response.data.result[tempidx].template.length>0){
                                setSubject(response.data.result[tempidx].template[0].subject);
                                setEmailBody(response.data.result[tempidx].template[0].emailBody); 
                            } 
                        }         
                    } else {
                        toast.error(response.data.message)
                        console.log('data:', response.data.message);
                    }
                }
            })
            .catch((error) => {
                toast.error(error)
                console.error("Error:", error);
            });
    };

   
    const TemplateClick = (template) => {
        setSelectedTemplate(template);
        if(template.template[0].subject!==''){
            setSubject(template.template[0].subject);
        }
        if(template.template[0].emailBody!==''){
            setEmailBody(template.template[0].emailBody);
        }       
    };

    const TemplateClick1 = (template1) => {
        console.log("selectedTemplate email====>>>>",template1)
            setSelectedTemplate1(template1)
            if(selectedTemplate.template.length>0){
                setSubject(selectedTemplate.template[template1].subject);
            } else {
                setSubject('');
            }
            if(selectedTemplate.template.length>0){
                setEmailBody(selectedTemplate.template[template1].emailBody);
            } else {
                setEmailBody('');
            }
            // setTemplateid(selectedTemplate.template[template1]._id);
           
    };

    
    const handleEditorChange = (e, editor) => {
        const data = editor.getData();
        // setEditor(data);
        setEmailBody(data);
    };

 

    const CreateTemp = () => {
        const token = localStorage.getItem('token');
        const body = 
        {
            "templateName":formValues.templateName,
            "template":[{
               "index": 1,
               "subject": "",
               "emailBody": ""
            },{
               "index": 2,
               "subject": "",
               "emailBody": ""
            },{
               "index": 3,
               "subject": "",
               "emailBody": ""
            },{
               "index": 4,
               "subject": "",
               "emailBody": ""
            }]
            
        }  
        Api('email/emailTemplate', 'POST', body, token)
            .then((response) => {
                console.log('Response emailTemplate:---------', response);
                if (response.status === 200) {
                    toast.success(response.data.message)
                    GetTempList('add')
                    setFormValues({ ...formValues, templateName: '' });
                }
              else   
               {
                toast.error(response.data.message)
             }       
            })
            .catch((error) => {
                toast.error(error)
                console.error("Error:", error);
            });
    };

    const UpdateTemp = () => {
        const token = localStorage.getItem('token');
        const body =
        {
            "templateId":selectedTemplate._id,
            "index": selectedTemplate1+1,
            "subject":subject,
            "emailBody":emailBody,
            "greeting":greeting
        }
        Api('email/updateEmailTemplateList', 'POST', body, token)
            .then((response) => {
                console.log('Response:---------', response);
                if (response.status === 200) {
                    toast.success(response.data.message)
                    GetTempList()
                    setSubject('');
                    setEmailBody('')
                }else{
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error(error)
                console.error("Error:", error);
            });
    };
    const deleteTemplete = (id) => {
        const token = localStorage.getItem('token');
        const body =
        {
            "templateId":id,
        }
        Api('email/deleteEmailTemplate', 'POST', body, token)
            .then((response) => {
                console.log('Response:---------', response);
                if (response.status === 200) {
                    toast.success(response.data.message)
                    GetTempList()
                    setSubject('');
                    setEmailBody('')
                }else{
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error(error)
                console.error("Error:", error);
            });
    };

    return (
        <>
        <ToastContainer />
        <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer', color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600',mr:1 }} onClick={() => navigate(-1)} />
     
            <Box
                sx={{
                    border: '1px solid #1E74B3',
                    borderRadius: '13px',
                    padding: '0.7rem',
                }}
            >
                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={2}>
                        <TextField
                            label="Template Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            // value={newTemplate}
                            value={formValues.templateName}
                            // onChange={(e) => setNewTemplate(e.target.value)}
                            onChange={(e) => setFormValues({ ...formValues, templateName: e.target.value })}
                            InputProps={{ style: { borderRadius: '10px', paddingLeft: '10px' } }}
                            InputLabelProps={{ style: { marginLeft: '1px' } }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            // variant="outlined"
                            fullWidth
                            color="custom"
                            variant="contained"
                            // onClick={addTemplate}
                            onClick={CreateTemp}
                            style={{
                                backgroundColor: '#1E74B3',
                                color: 'white',
                                borderRadius: '10px',
                                // height: '50px',
                                height: '35px',
                                marginTop: '8px',
                            }}
                        // disabled={newTemplate.trim() === ''}
                        >
                            Add
                        </Button>

                    </Grid>
                </Grid>

                <Grid container spacing={1} mt={1}>
                    {tempList.map((template, index) => (
                        <Grid item xs={3} key={index} >
                            <Stack  direction="row" >
                            <Button
                                variant="outlined"
                                color="custom"
                                // variant="contained"
                                sx={{
                                    borderRadius: '10px',
                                    // height: '50px',
                                    height: '35px',
                                    // color: '#1E74B3',
                                    width: '94%',
                                    color: selectedTemplate === template ? 'white' : '#1E74B3',
                                    backgroundColor: selectedTemplate === template ? '#1E74B3' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedTemplate === template ? '#1E74B3' : 'rgba(30, 116, 179, 0.1)',
                                    },
                                }}
                                onClick={() => {TemplateClick(template);setTempidx(index)}}
                            >
                                {template.templateName}
                            </Button>
                            <Stack sx={{borderRadius:'50%',border:'1px solid #1E74B3',height:20,width:20,ml:0.3,mt:-1}}>

                                <Iconify icon="fluent:delete-24-filled"  sx={{ cursor: 'pointer', color: 'text.secondary' ,p:0.4}} onClick={() => deleteTemplete(template._id)} />
                            </Stack>
                      </Stack>  </Grid>
                    ))}
                </Grid>

                {displayEmails && tempList.length > 0 && (
                    <Grid container spacing={1.8} alignItems="center" sx={{ marginBottom: "16px" }}>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                fullWidth
                                size="small"
                                onClick={() => TemplateClick1(0)}
                                sx={{
                                    borderRadius: '10px',
                                    paddingLeft: '5px',
                                    marginLeft: '1px',
                                    marginTop: '15px',
                                    // height: '50px',
                                    height: '35px',
                                    color: selectedTemplate1 === 0 ? 'white' : '#1E74B3',
                                    backgroundColor: selectedTemplate1 === 0 ? '#1E74B3' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedTemplate1 === 0 ? '#1E74B3' : 'rgba(30, 116, 179, 0.1)',
                                    },
                                }}
                            >
                                Email 1
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => TemplateClick1(1)}
                                sx={{
                                    borderRadius: '10px',
                                    paddingLeft: '5px',
                                    marginLeft: '2px',
                                    marginTop: '15px',
                                    // height: '50px',
                                    height: '35px',
                                    color: selectedTemplate1 === 1 ? 'white' : '#1E74B3',
                                    backgroundColor: selectedTemplate1 === 1 ? '#1E74B3' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedTemplate1 === 1 ? '#1E74B3' : 'rgba(30, 116, 179, 0.1)',
                                    },
                                }}
                            >
                                Email 2
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => TemplateClick1(2)}
                                sx={{
                                    borderRadius: '10px',
                                    paddingLeft: '5px',
                                    marginLeft: '2px',
                                    marginTop: '15px',
                                    // height: '50px',
                                    height: '35px',
                                    color: selectedTemplate1 === 2 ? 'white' : '#1E74B3',
                                    backgroundColor: selectedTemplate1 === 2 ? '#1E74B3' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedTemplate1 === 2 ? '#1E74B3' : 'rgba(30, 116, 179, 0.1)',
                                    },
                                }}
                            >
                                Email 3
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => TemplateClick1(3)}
                                sx={{
                                    borderRadius: '10px',
                                    paddingLeft: '5px',
                                    marginLeft: '2px',
                                    marginTop: '15px',
                                    // height: '50px',
                                    height: '35px',
                                    color: selectedTemplate1 === 3 ? 'white' : '#1E74B3',
                                    backgroundColor: selectedTemplate1 === 3 ? '#1E74B3' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedTemplate1 === 3 ? '#1E74B3' : 'rgba(30, 116, 179, 0.1)',
                                    },
                                }}
                            >
                                Email 4
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Sub"
                            variant="outlined"
                            fullWidth 
                            margin="normal"
                            size="small"   
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            InputProps={{ style: { borderRadius: '10px', paddingLeft: '10px' } }}
                            InputLabelProps={{ style: { marginLeft: '1px' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Greeting"
                            variant="outlined"
                            fullWidth 
                            size="small"    
                            value={greeting}
                            onChange={(e) => setGreeting(e.target.value)}
                            InputProps={{ style: { borderRadius: '10px', paddingLeft: '10px' } }}
                            InputLabelProps={{ style: { marginLeft: '1px' } }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{}}>
                        <CKEditor
                            data={emailBody}
                            onChange={handleEditorChange}
                            editor={ClassicEditor}
                        />
                        {/* {/ {/ <button onClick={insertSignature}>Insert Signature</button> /} /} */}
                                <div dangerouslySetInnerHTML={{ __html: localStorage.getItem('sign') }}/>
                    </Grid>
                </Grid>
                <Button
                    color="custom"
                    variant="contained"
                    sx={{
                        display: 'block',
                        margin: '15px auto',
                        borderRadius: '10px',
                        height: '35px',
                        width: '100px',
                    }}
                    onClick={UpdateTemp}>
                    Save
                </Button>
            </Box>

        </>
    );
}

export default Email;