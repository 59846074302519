import { useEffect } from 'react';
import { useDispatch } from 'react-redux';



import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { userdetail } from '../../../redux/slice/userSlice';
import { Api } from '../../../webservices';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import Signature from './Signature'
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';


// ----------------------------------------------------------------------

const NAV_WIDTH = 1245;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('token')
    Api(`company/get_user_details`, 'GET', '', token).then(response => {
      console.log("==========get_user_details=====>>>>>>>", JSON.stringify(response.data.data.role));
      if (response.status === 200) {
        if (response.data.data) {
          dispatch(userdetail(response.data.data[0]))
          localStorage.setItem("contactPersonName",response.data?.data?.contactPersonName)
          console.log("----------------->> header dispach",response.data.data[0])
        }
      }
    })
  }, [])
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        > 
           <NotificationsPopover /> 
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          <AccountPopover />
          {/* <Signature/> */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
