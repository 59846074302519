import React, { useState, useEffect } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Button,
    TextField,
    Grid,
    Modal,
    Box,
    Divider,
    Badge,
    IconButton
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Api } from '../webservices';

const MasterData = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [addJobTitle, setAddJobTitle] = useState('');
    const [addSkillTitle, setAddSkillTitle] = useState('');
    const [addCompanyTitle, setAddCompanyTitle] = useState('');
    const [data, setData] = useState([]);
    const [splitData, setSplitData] = useState({ part1: [], part2: [] });
    const [titleId, setTitleId] = useState(null);
    const [selectedType, setSelectedType] = useState('job');
    const [del, setDel] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [softDelete, setSoftDelete] = useState('')
    const [loading, setLoading] = useState(false)
    const [inputData, setInputData] = useState('')
    const openDeleteModal = (id, actionType) => {
        setDeleteId(id);
        setSoftDelete(actionType)
        setDel(true);
    };

    const closeDeleteModal = () => {
        setDeleteId(null);
        setDel(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { md: '50%', xs: '95%' },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        border: '3px solid #3C6BA5',
        borderRadius: 3,
    };

    useEffect(() => {
        if (selectedType === 'job') {
            getJobTitles();
        } else if (selectedType === 'skill') {
            getSkillTitles();
        } else if (selectedType === 'company') {
            getCompanyTitles();
        }
        else if (selectedType === 'deletedskills') {
            getSoftDeletedSkillsLst();
        }
        else if (selectedType === 'deletedjobtitles') {
            getJobTitlesLst()
        }
        else if (selectedType === 'deletedcompanies') {
            getSoftDeletedCompsLst()
        }
    }, [selectedType]);

    useEffect(() => {
        const half = Math.ceil(data?.length / 2);
        setSplitData({ part1: data?.slice(0, half), part2: data?.slice(half) });
    }, [data]);

    const handleEditClick = (id, currentTitle) => {
        setIsEditing(true);
        setTitleId(id);
        setEditedTitle(currentTitle);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        if (selectedType === 'job') {
            updateJobTitle(titleId);
        } else if (selectedType === 'skill') {
            updateSkillTitle(titleId);
        } else if (selectedType === 'company') {
            updateCompanyTitle(titleId);
        }
    };

    const getJobTitles = () => {
        setLoading('Loading...')
        setData([]);
        const token = localStorage.getItem('token');
        Api(`common/jobtitle/get/active`, 'GET', '', token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    setData(response.data.jobTitles);
                    setLoading('')
                } else {
                    setLoading('No data found')
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getJobTitlesLst = () => {
        setLoading('Loading...');
        setData([]);

        const token = localStorage.getItem('token');

        Api('common/jobtitle/get/inactive', 'GET', '', token)
            .then((response) => {

                if (response.status === 200 && response.data.code === 200) {
                    const jobTitles = response.data.jobTitles;

                    if (jobTitles.length === 0) {
                        setLoading('No data found');
                    } else {
                        setData(jobTitles);
                        setLoading('');
                    }
                } else {
                    setLoading('No data found');
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading('An error occurred');
            });
    };


    const getSkillTitles = () => {
        setData([]);
        setLoading('Loading...')
        const token = localStorage.getItem('token');
        Api(`resume/get_skills`, 'GET', '', token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    setData(response.data.result);
                    setLoading('')
                } else {
                    setLoading('No data found')
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getSoftDeletedSkillsLst = () => {
        setLoading('Loading...');
        setData([]);

        const token = localStorage.getItem('token');

        Api('resume/softDeleteSkillList', 'GET', '', token)
            .then((response) => {

                if (response.status === 200 && response.data.code === 200) {
                    const result = response.data.result;

                    if (result.length === 0) {
                        setLoading('No data found');
                    } else {
                        setData(result);
                        setLoading('');
                    }
                } else {
                    setLoading('No data found');
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading('An error occurred');
            });
    };


    const getCompanyTitles = () => {
        setData([]);
        setLoading('Loading...')
        const token = localStorage.getItem('token');
        Api(`common/companyName/get/active`, 'GET', '', token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    setData(response.data.companies);
                    setLoading('')
                } else {
                    setLoading('No data found')

                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getSoftDeletedCompsLst = () => {
        setLoading('Loading...');
        setData([]);

        const token = localStorage.getItem('token');
        Api(`common/companyName/get/inactive`, 'GET', '', token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    const companies = response.data.companies;

                    if (companies.length === 0) {
                        setLoading('No data found');
                    } else {
                        setData(companies);
                        setLoading('');
                    }
                } else {
                    setLoading('No data found');
                    alert('Error fetching data');
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading('An error occurred');
            });
    };


    const addJobTitleFunc = () => {
        const addJobTitles = addJobTitle.split(',').map(title => title.trim());
        addJobTitles.forEach(title => {
            const body = {
                jobTitle: title,
            };
            const token = localStorage.getItem('token');
            Api(`common/jobtitle/add`, 'POST', body, token)
                .then((response) => {
                    if (response.status === 200 && response.data.code === 200) {
                        getJobTitles();
                        setAddJobTitle('');
                        toast.success(`Job title added successfully!`);
                    } else {
                        toast.error(`Job title already exists!`);
                        setAddJobTitle('');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // toast.error(`Failed to add job title "${title}"`);
                });
        });
    };

    const moveToJobTitle = (id, item) => {
        const body = {
            jobtitle_id: id,
            // skillStatus: "approved",
        };
        const token = localStorage.getItem('token');
        Api(`common/moveToJobtitle`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getJobTitles();
                    toast.success("job title_id moved successfully!");
                } else {
                    toast.error("job title_id already exists!")
                }
            })
            // toast.error("Skill already exists")
            .catch((err) => {
                console.error(err);
            });
    };

    const moveToSkill = (id, item) => {
        const body = {
            skillName: item,
            skillStatus: "approved",
        };
        const token = localStorage.getItem('token');
        Api(`common/skill/update/${id}`, 'PUT', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getSkillTitles();
                    toast.success("Skill moved successfully!");
                } else {
                    toast.error("Skill already exists!")
                }
            })
            // toast.error("Skill already exists")
            .catch((err) => {
                console.error(err);
            });
    };

    const moveToCompany = (id, item) => {
        const body = {
            companyName: item,
            companyNameStatus: "approved",
        };
        const token = localStorage.getItem('token');
        Api(`common/companyName/update/${id}`, 'PUT', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getCompanyTitles();
                    toast.success("Company moved successfully!");
                } else {
                    toast.error("Company already exists!")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const addSkillTitleFunc = () => {
        const skillTitles = addSkillTitle.split(',').map(skill => skill.trim());
        const token = localStorage.getItem('token');

        Promise.all(skillTitles.map(skill => {
            const body = {
                skillName: skill,
                skillStatus: "approved",
            };
            return Api(`common/skill/add`, 'POST', body, token);
        }))
            .then(responses => {
                if (responses.every(response => response.status === 200 && response.data.code === 200)) {
                    getSkillTitles();
                    setAddSkillTitle('');
                    toast.success("Skill ddded successfully!");
                } else {
                    toast.error("Skill already exist!");
                    getSkillTitles();
                    setAddSkillTitle('');
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const addCompanyTitleFunc = () => {
        const addCompanyTitles = addCompanyTitle.split(',').map(title => title.trim());
        addCompanyTitles.forEach(title => {
            const body = {
                companyName: title,
                companyNameStatus: "approved"
            };
            const token = localStorage.getItem('token');
            Api(`common/companyName/add`, 'POST', body, token)
                .then((response) => {
                    if (response.status === 200 && response.data.code === 200) {
                        getCompanyTitles();
                        setAddCompanyTitle('');
                        toast.success(`Company title added successfully!`);
                    } else {
                        toast.error(`Company already exists!`);
                        setAddCompanyTitle('');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // toast.error(`Failed to add company title`);
                });
        });
    };

    const updateJobTitle = (id) => {
        const body = {
            jobTitle: editedTitle,
        };
        const token = localStorage.getItem('token');
        Api(`common/jobtitle/update/${id}`, 'PUT', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getJobTitles();
                    toast.success("Job title updated successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const updateSkillTitle = (id) => {
        const body = {
            skillName: editedTitle,
            skillStatus: "approved",
        };
        const token = localStorage.getItem('token');
        Api(`common/skill/update/${id}`, 'PUT', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getSkillTitles();
                    toast.success("Skill title updated successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const updateCompanyTitle = (id) => {
        const body = {
            companyName: editedTitle,
            companyNameStatus: "approved"
        };
        const token = localStorage.getItem('token');
        Api(`common/companyName/update/${id}`, 'PUT', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getCompanyTitles();
                    toast.success("Company title updated successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const deleteJobTitle = (id) => {
        const body = {
            jobTitle: ""
        }
        const token = localStorage.getItem('token');
        Api(`common/jobtitle/delete/${id}`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getJobTitles();
                    toast.success("Job title deleted successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteJobTitle = () => {
        const body = {
            jobtitle_id: deleteId,
            status: "inactive"
        }
        const token = localStorage.getItem('token');
        Api(`common/softDeleteJobtitle`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getJobTitles();
                    setSelectedType("deletedjobtitles")
                    toast.success("Job title deleted successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteJobTitleMoveTo = (id) => {
        const body = {
            jobtitle_id: id,
            status: "active"
        }
        const token = localStorage.getItem('token');
        Api(`common/softDeleteJobtitle`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getJobTitles();
                    toast.success("Job Title moved successfully!");
                    getJobTitles()
                    setSelectedType("job")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteSkillTitle = () => {
        const body = {
            skill_id: deleteId,
            status: "inactive"
        }
        const token = localStorage.getItem('token');
        Api(`resume/softDeleteSkill`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getSkillTitles();
                    toast.success("Skill title deleted successfully!");
                    setSelectedType("deletedskills")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteSkillMove = (id) => {
        const body = {
            skill_id: id,
            status: "active"
        }
        const token = localStorage.getItem('token');
        Api(`resume/softDeleteSkill`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getSkillTitles();
                    toast.success("Skill moved successfully!");
                    getSkillTitles()
                    setSelectedType("skill")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const deleteSkillTitle = (id) => {
        const body = {
            skillName: ""
        }
        const token = localStorage.getItem('token');
        Api(`common/skill/delete/${id}`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getSkillTitles();
                    toast.success("Skill title deleted successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteCompanyTitle = () => {
        const body = {
            company_id: deleteId,
            status: "inactive"
        }
        const token = localStorage.getItem('token');
        Api(`common/softDeleteCompany`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getCompanyTitles();
                    toast.success("Company title deleted successfully!");
                    setSelectedType("deletedcompanies")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const softdeleteCompMove = (id) => {
        const body = {
            company_id: id,
            status: "active"
        }
        const token = localStorage.getItem('token');
        Api(`common/softDeleteCompany`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    // getSkillTitles();
                    toast.success("Company moved successfully!");
                    getCompanyTitles()
                    setSelectedType("company")
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const deleteCompanyTitle = (id) => {
        const body = {
            companyName: ""
        }
        const token = localStorage.getItem('token');
        Api(`common/companyName/delete/${id}`, 'POST', body, token)
            .then((response) => {
                if (response.status === 200 && response.data.code === 200) {
                    getCompanyTitles();
                    toast.success("Company title deleted successfully!");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const addTitleFunc = () => {
        if (selectedType === 'job') {
            addJobTitleFunc();
        } else if (selectedType === 'skill') {
            addSkillTitleFunc();
        } else if (selectedType === 'company') {
            addCompanyTitleFunc();
        }
    };

    const addTitle = () => {
        // console.log('addTitle')
        if (selectedType === 'job') {
            return addJobTitle;
        } if (selectedType === 'skill') {
            return addSkillTitle;
        } if (selectedType === 'company') {
            return addCompanyTitle;
        }

        return null;
    };

    const setAddTitle = (value) => {
        // console.log('setAddTitle')
        if (selectedType === 'job') {
            setAddJobTitle(value);
        } else if (selectedType === 'skill') {
            setAddSkillTitle(value);
        } else if (selectedType === 'company') {
            setAddCompanyTitle(value);
        }
    };

    const deleteTitleFunc = () => {
        if (selectedType === 'job') {
            deleteJobTitle(deleteId);
        } else if (selectedType === 'skill') {
            deleteSkillTitle(deleteId);
        } else if (selectedType === 'company') {
            deleteCompanyTitle(deleteId);
        }
    };

    const deleteTitleFunc1 = () => {
        if (softDelete === 'softdeletetitle') {
            softdeleteJobTitle()
            // alert("title")
        } else if (softDelete === 'softdeleteskill') {
            softdeleteSkillTitle()
            // alert("skill")
        } else if (softDelete === 'softdeletecompany') {
            softdeleteCompanyTitle()
            // alert("company")
        }
    };


    const renderTable = (data) => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600, backgroundColor: 'white' }}>
                <TableBody>
                    {data?.map((item) => (
                        <>
                            <TableRow key={item._id} sx={{ backgroundColor: '#EFF4FB', marginBottom: 2 }}>
                                <TableCell sx={{ color: '#327CC0', padding: 1, fontWeight: 'bold', textTransform: "none" }}>
                                    {isEditing && titleId === item._id ? (
                                        <TextField
                                            size="small"
                                            value={editedTitle}
                                            onChange={(event) => setEditedTitle(event.target.value)}
                                        />
                                    ) : (

                                        selectedType === 'job' ? item?.jobTitle :
                                            selectedType === 'skill' ? item?.skillName :
                                                selectedType === 'company' ? item?.companyName :
                                                    selectedType === 'deletedskills' ? item?.skillName :
                                                        selectedType === 'deletedjobtitles' ? item?.jobTitle :
                                                            item?.companyName
                                    )?.length > 30 ? (

                                        `${(selectedType === 'job' ? item?.jobTitle :
                                            selectedType === 'skill' ? item?.skillName :
                                                selectedType === 'company' ? item?.companyName :
                                                    selectedType === 'deletedskills' ? item?.skillName :
                                                        selectedType === 'deletedjobtitles' ? item?.jobTitle :
                                                            item?.companyName)?.slice(0, 30)}...`
                                    ) : (

                                        selectedType === 'job' ? item?.jobTitle :
                                            selectedType === 'skill' ? item?.skillName :
                                                selectedType === 'company' ? item?.companyName :
                                                    selectedType === 'deletedskills' ? item?.skillName :
                                                        selectedType === 'deletedjobtitles' ? item?.jobTitle :
                                                            item?.companyName
                                    )}
                                </TableCell>

                                {data && (
                                    <TableCell sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>

                                        {isEditing && titleId === item._id ? (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: 'text.secondary',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        backgroundColor: 'text.secondary',
                                                    },
                                                }}
                                                onClick={handleSaveClick}
                                            >
                                                Save
                                            </Button>
                                        ) : (
                                            selectedType === 'job' ? (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: 'text.secondary',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                backgroundColor: 'text.secondary',
                                                            },
                                                        }}
                                                        onClick={() => handleEditClick(item._id, item.jobTitle)}
                                                        disabled={isEditing}
                                                    >
                                                        Edit
                                                    </Button>
                                                    {/* <Button
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: 'error.main',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        backgroundColor: 'error.main',
                                                    },
                                                }}
                                                onClick={() => openDeleteModal(item._id)}
                                            >
                                                Delete
                                            </Button> */}

                                                    {item.jobTitleStatus === "new" && (

                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: 'white',
                                                                backgroundColor: 'text.secondary',
                                                                boxShadow: 'none'
                                                            }}
                                                            onClick={() => moveToJobTitle(item._id, item.jobTitle
                                                            )}
                                                            disabled={isEditing}
                                                        >
                                                            Move to Job Title
                                                        </Button>
                                                    )}
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: 'error.main',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                backgroundColor: 'error.main',
                                                            },
                                                        }}
                                                        onClick={() => openDeleteModal(item._id, "softdeletetitle")}
                                                    >
                                                        Delete
                                                    </Button>
                                                </>
                                            ) : selectedType === 'skill' ? (
                                                <>

                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: 'text.secondary', boxShadow: 'none'
                                                        }}
                                                        onClick={() => handleEditClick(item._id, item.skillName)}
                                                        disabled={isEditing}
                                                    >
                                                        Edit
                                                    </Button>
                                                    {item.skillStatus === 'new' && (
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                color: 'white',
                                                                backgroundColor: 'text.secondary',
                                                                boxShadow: 'none'
                                                            }}
                                                            onClick={() => moveToSkill(item._id, item.skillName)}
                                                            disabled={isEditing}
                                                        >
                                                            Move to Skill
                                                        </Button>
                                                    )}
                                                    {/* <Button
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: 'error.main',
                                                        boxShadow: 'none'
                                                    }}
                                                    onClick={() => openDeleteModal(item._id)}
                                                >
                                                    Delete
                                                </Button> */}
                                                    {selectedType === 'skill' && (
                                                        <Button
                                                        variant="contained"
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: 'error.main',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                backgroundColor: 'error.main',
                                                            },
                                                        }}
                                                            onClick={() => openDeleteModal(item._id, "softdeleteskill")}
                                                        >
                                                            Delete
                                                        </Button>
                                                    )}

                                                </>
                                            ) : (
                                                <>
                                                    {selectedType === "company" && (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                                onClick={() => handleEditClick(item._id, item.companyName)}
                                                                disabled={isEditing}
                                                            >
                                                                Edit
                                                            </Button>
                                                            {item.companyNameStatus === 'new' && (
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                                    onClick={() => moveToCompany(item._id, item.companyName)}
                                                                    disabled={isEditing}
                                                                >
                                                                    Move to Company
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* <Button
                                                variant="contained"
                                                sx={{ color: 'white', backgroundColor: 'error.main', boxShadow: 'none' }}
                                                onClick={() => openDeleteModal(item._id)}
                                            >
                                                Delete
                                            </Button> */}
                                                    {selectedType === 'company' && (
                                                        <Button
                                                        variant="contained"
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: 'error.main',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                backgroundColor: 'error.main',
                                                            },
                                                        }}
                                                            onClick={() => openDeleteModal(item._id, "softdeletecompany")}
                                                        >
                                                            Delete
                                                        </Button>
                                                    )}
                                                    {selectedType === 'deletedskills' &&
                                                        item.skillStatus === 'new' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                                    onClick={() => softdeleteSkillMove(item._id)}
                                                                // disabled={isEditing}
                                                                >
                                                                    Move to Skill
                                                                </Button>

                                                                {/* <Button 
                                                    variant="contained"
                                                    sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                    // onClick={() => moveToCompany(item._id, item.companyName)}
                                                    // disabled={isEditing}
                                                >
                                                    Record
                                                </Button> */}
                                                                <IconButton
                                                                    sx={{
                                                                        // position:"absolute",
                                                                        width: 30,
                                                                        height: 30,
                                                                        // ml: 9,
                                                                        // mt: -3.8,
                                                                        backgroundColor: 'lightgrey',
                                                                        borderRadius: '50%',
                                                                        padding: 0,
                                                                        '&:hover': {
                                                                            backgroundColor: 'grey',
                                                                        },
                                                                    }}
                                                                >
                                                                    {item?.record?.length || '0'}
                                                                </IconButton>
                                                            </>
                                                        )}

                                                    {selectedType === 'deletedjobtitles' &&

                                                        <>
                                                            {item.jobTitleStatus === "new" && (

                                                                <Button
                                                                    variant="contained"
                                                                    sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                                    onClick={() => softdeleteJobTitleMoveTo(item._id)}
                                                                // disabled={isEditing}
                                                                >
                                                                    Move to Job Title
                                                                </Button>
                                                            )}
                                                            <IconButton
                                                                sx={{
                                                                    // position:"absolute",
                                                                    width: 30,
                                                                    height: 30,
                                                                    // ml: 9,
                                                                    // mt: -3.8,
                                                                    backgroundColor: 'lightgrey',
                                                                    borderRadius: '50%',
                                                                    padding: 0,
                                                                    '&:hover': {
                                                                        backgroundColor: 'grey',
                                                                    },
                                                                }}
                                                            >
                                                                {item?.record?.length || '0'}
                                                            </IconButton>
                                                        </>

                                                    }
                                                    {selectedType === 'deletedcompanies' &&
                                                        item.companyNameStatus === 'new' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{ color: 'white', backgroundColor: 'text.secondary', boxShadow: 'none' }}
                                                                    onClick={() => softdeleteCompMove(item._id)}
                                                                // disabled={isEditing}
                                                                >
                                                                    Move to Company
                                                                </Button>
                                                                <IconButton
                                                                    sx={{
                                                                        // position:"absolute",
                                                                        width: 30,
                                                                        height: 30,
                                                                        // ml: 9,
                                                                        // mt: -3.8,
                                                                        backgroundColor: 'lightgrey',
                                                                        borderRadius: '50%',
                                                                        padding: 0,
                                                                        '&:hover': {
                                                                            backgroundColor: 'grey',
                                                                        },
                                                                    }}
                                                                >
                                                                    {item?.record?.length || '0'}
                                                                </IconButton>
                                                            </>
                                                        )}
                                                </>
                                            )
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableCell />
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Stack>
            <ToastContainer />
            <Typography variant="h5" color="text.secondary" sx={{ marginBottom: '6px' }}>
                Master Data
            </Typography>
            <Stack sx={{ flexDirection: "row", gap: "10px", marginBottom: "10px" }}>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'job' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('job')}
                >
                    Job Titles
                </Typography>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'skill' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('skill')}
                >
                    Skills
                </Typography>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'company' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('company')}
                >
                    Companies
                </Typography>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'deletedjobtitles' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('deletedjobtitles')}
                >
                    Deleted Job Titles
                </Typography>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'deletedskills' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('deletedskills')}
                >
                    Deleted Skills
                </Typography>
                <Typography
                    sx={{
                        backgroundColor: selectedType === 'deletedcompanies' ? 'secondary.main' : 'text.secondary',
                        color: 'white',
                        padding: 1,
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                    onClick={() => setSelectedType('deletedcompanies')}
                >
                    Deleted Companies
                </Typography>
            </Stack>
            <Grid container spacing={1} alignItems="center" sx={{ marginTop: '-14px', marginBottom: '7px' }}>
                <Grid item xs={2}>
                    <TextField
                        label={selectedType === 'job' ? 'Job Title' :
                            selectedType === 'skill' ? 'Skill' :
                                'Company Name'
                        }
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={addTitle()}
                        onChange={(e) => setAddTitle(e.target.value)}
                        InputProps={{ style: { borderRadius: '7px', paddingLeft: '10px' } }}
                        InputLabelProps={{ style: { marginLeft: '1px' } }}
                    />

{/* <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                       
                    /> */}

                </Grid>
                <Grid item xs={1}>
                    <Button
                        fullWidth
                        color="custom"
                        variant="contained"
                        disabled={!addTitle()}
                        onClick={addTitleFunc}
                        style={{
                            backgroundColor: 'text.secondary',
                            color: 'white',
                            borderRadius: '7px',
                            height: '35px',
                            marginTop: '8px',
                        }}
                    >
                        Add New
                    </Button>
                </Grid>
            </Grid>
            {/* <Divider sx={{ mb: 2, borderWidth: 1.7, borderColor: '#3C6BA5'}} /> */}
            {loading ? (
                <Typography sx={{ color: "red", ml: "45%", mt: 4 }}>{loading}</Typography>
            ) : (
                <Stack direction="row" spacing={2}>
                    {renderTable(splitData.part1)}
                    {renderTable(splitData.part2)}
                </Stack>
            )}
            <Modal open={del} onClose={closeDeleteModal} style={{ borderRadius: 10 }}>
                <Box sx={[style, { width: '20%' }]}>
                    <Stack sx={{ justifyContent: 'center', alignContent: 'center', width: '100%' }}>
                        <Stack sx={{ width: '100%' }}>
                            <Typography variant="h6" textAlign={'center'} color={'red'}>
                                Do you want to delete this {selectedType === 'job' ? 'job' :
                                    selectedType === 'skill' ? 'skill' :
                                        'company'
                                } title?
                            </Typography>
                        </Stack>
                        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', width: '25%', mt: 3, alignSelf: 'center' }}>
                            <Button
                                color="custom"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    deleteTitleFunc1(); closeDeleteModal()
                                    // if (softDelete) { deleteTitleFunc1(); closeDeleteModal()}
                                    // else {
                                    //     closeDeleteModal();deleteTitleFunc(); 
                                    // }
                                }}
                            >
                                Yes
                            </Button>
                            <Button color="custom" variant="contained" size="small" onClick={closeDeleteModal}>
                                No
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
    );
};

export default MasterData;
