import React, { useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Icon } from '@iconify/react';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import {
  Slide,
  IconButton,
  ClickAwayListener,
  InputBase,
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Pagination,
  Switch,
  Checkbox,
} from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
// import Upload from '../components/upload';
import Scrollbar from '../components/scrollbar/Scrollbar';
import CustomComplete from '../components/customComplete/CustomComplete';
import CustomCompleteWorksheet from '../components/customComplete/CustomCompleteWorksheet';
import { Api, UploadFile } from '../webservices';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf_viewer.js.map';
const tablebg = 'rgba(82, 130, 190, 0.08)';
const filter = createFilterOptions();

const Domains = [
  "Information Technology",
  "Engineering",
  "Construction",
  "Manufacturing",
  "Healthcare",
  "Production",
  "Automation & Controls",
  "Finance & Accounting",
  "Fintech",
  "Banking",
  "E-Commerce",
  "Supply Chain Management",
  "Science & Innovation",
  "Hospitality",
  "Food & Drink",
  "Real Estate",
  "Energy",
  "Sales",
  "Retail",
  'Trading &  Stocks',
  "Oil & Gas",
  "Logistics",
  "Aerospace",
  "Mining",
  "Pharmaceuticals",
  "Agriculture",
  "Insurance",
  "Gaming",
  "Travel & Tourism",
  "Educational",
  "Edtech",
  "Telecommunication",
  "Gov",
  "Defense",
  "Media & Entertainment",
  "Marketing",
  "Advertising",
  "Others"
]

function SalesWorksheet(props) {
  const location = useLocation();
  const navigate = useNavigate();
  function MyFormHelperText() {
    const { onFilled } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      if (onFilled) {
        return 'This field is being onFilled';
      }

      return 'Helper text11';
    }, [onFilled]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [askill, setAskill] = React.useState(null);
  const [finallst, setFinallst] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [evalid, setEvalid] = useState(true);
  const [dupemail, setDupemail] = useState(false);
  const [tempskill, setTempskill] = useState([]);
  const [cityerr, setCityerr] = useState(false);
  const [joblst, setJoblst] = useState([]);
  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  const [emails, getEmails] = useState([]);
  const [searchResult, setSearchResult] = React.useState();

  const [excel, setExcel] = useState(false);
  const excelOpen = () => setExcel(true);
  const excelClose = () => {
    setExcel(false);
    // alert("excel")
  }

  const userId = useSelector((state) => state.userData.data._id);
  const contactPer1 = useSelector((state) => state.userData.data.contactPersonName);

  // const[contactPer, setContactPerson] = useState(localStorage.getItems("contactPersonName"))
  // console.log("===========contactPer1", contactPer1)
  // console.log("===========contactPer",contactPer)

  // alert("contactPer1",contactPer1)
  // alert("contactPer",contactPer)

  const [value, setValue] = useState({
    email: '',
    companyName: '',
    linkedinUrl: '',
    contactPersonName: '',
    websiteUrl: '',
    contactNo: '',
    domain: '',
    designation: '',
    employeeNo: '',
    location: '',
    city: '',
    status: 'new',
    rsc: '',
    country: 'USA',
  });

  const [cityy, setCityy] = useState('');
  const [statee, setStatee] = useState('');
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  const [pdffile, setPdffile] = useState(null);
  const [docfile, setDocfile] = useState();
  const [zipcode, setZipcode] = useState('');
  const [mainTab, setMainTab] = useState('recruiter');
  const [emailerr, setEmailerr] = useState(false);
  const [companyerr, setCompanyerr] = useState(false);
  const [tempList, setTempList] = useState([])

  const handlePage = (event, value) => {
    // console.log(value)
    setPageNumber(value);
    // alert("yyy")
    const pg = value
    handleChange(valuee, pg)
  };

  const [valuee, setValuee] = React.useState('new');
  // console.log("===========location worksheet", location.state);
  const handleChangeAutoComplete = (event, value1) => {
    setValue({ ...value, skill: value1 });
  };

  const getTemplList = () => {
    const token = localStorage.getItem('token');
    // console.log("token ===>>>>> ",token)
    Api('email/getEmailTemplateList', 'GET', '', token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            //  console.log("hhh")

          } else {
            // console.log('data:', response.data.message);
          }
        }
        setTempList(response.data?.result);
        // setUserId(response.data.result.userId)
        // setCompanyId(response.data.result.companyId)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getTemplList()
    getSkills();
    handleChange('new');
  }, []);

  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmClose = () => {
    setOpenConfirm(false)
  }
  const [hideClosebtn, setHideCloseBtn] = useState(true)
  const [compN, setCompN] = useState()
  // console.log("compN", compN)
  const [tableCount, setTableCount] = useState([])
  // console.log("tableCount", tableCount)

  const handleChange = (newValue, pg) => {
    setTableCount([])
    setToggleButton(false)
    callReceivedFunction()
    setLoading("Loading...")
    // resetTable()
    setPinned(false)
    setValuee(newValue);
    // alert("jj")
    if (valuee !== "new") {
      setHideCloseBtn(false)
    }

    const id = localStorage.getItem('userid');
    const body = {
      currentStatus: newValue,
      userId: userId || id,
      "page": pg || 1,
      "limit": "35"
    };
    setTableData([]);
    const token = localStorage.getItem('token');
    Api(`company/get_clients_by_role`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.result.length) {
            setTableCount(response.data.count)
            setLoading("")
            // toast.success(response.data.message, +'!');
            setTableData(response.data.result);
            // setCompN(response.data?.result?.companyName)
            const companyNames = response.data?.result?.map((item) => item.companyName || "");
            setCompN(companyNames);
            const totalPage = Math.ceil(response.data.count / 35)
            setPages(totalPage)
          } else {
            setLoading("No Data Found")
            setTableData([]);
            // toast.warn('Data Not Found');
          }
        } else {
          setTableData([]);
          // toast.warn(response.data.message);
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const adminrole = useSelector((state) => state.userData.data.role);
  const cities = [];

  // console.log("adminrole", adminrole)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    // backgroundColor: 'secondary',
    ...theme.typography.button,
    padding: theme.spacing(0.5),
    // textAlign: 'center',
    color: 'white',
  }));

  const getResume = async (val) => {
    const id = localStorage.getItem('userid');
    const body = {
      currentStatus: 'new',
      userId: val || id,
    };
    const token = localStorage.getItem('token');
    Api(`company/get_clients_by_role`, 'POST', body, token)
      .then((response) => {
        // console.log('=========get_sales_client_list======>>>>>>>', response.data);

        if (response.status === 200) {
          if (response.data.length) {
            // toast.success(response.data.message, +'!');
            setTableData(response.data);
            // setValuee('worksheet')
            // console.log('======ashish======>>>>>>>', response);
          } else {
            setTableData([]);
            // toast.warn('Data Not Found');
          }
        } else {
          setTableData([]);
          toast.warn(response.data.message);
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const pdfUpload = async () => {
    // console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', docfile);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      // console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.status, +'!');
          //  console.log('=====1004=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  const addcv = async (e) => {
    setValuee({ ...valuee, status: 'new' });
    // if (value.email !== '') {
    if (value.companyName !== '') {
      const token = localStorage.getItem('token');
      const body = {
        email: selectedEmail,
        additionalEmail: value.additionalEmail,
        companyName: value.companyName,
        clientName: value.companyName,
        linkedinUrl: value.linkedinUrl,
        contactPersonName: value.contactPersonName,
        websiteUrl: value.websiteUrl,
        contactNoWithISD: value.contactNo,
        domain: value.domain,
        designation: value.designation,
        employeeNo: value.employeeNo,
        location: cityy,
        currentStatus: value.status,
        RPO_Staffing: value.rsc,
      };
      // console.log('=====create_resume==========>>>>>>>', finallst);
      Api(`company/add_sales_client`, 'POST', body, token)
        .then((response) => {
          // console.log('=====create_resume==========>>>>>>>', response);
          if (response.status === 200) {
            if (response.data.code === 200) {
              handleChange(value.status ? value.status : "new");
              toast.success(response.data.message, +'!');
              handleClose1();
              setValue({
                email: '',
                companyName: '',
                linkedinUrl: '',
                contactPersonName: '',
                websiteUrl: '',
                contactNo: '',
                domain: '',
                additionalEmail: '',
                designation: '',
                employeeNo: '',
                location: '',
                rsc: '',
              });
              setSelectedEmail('');
              getEmails([])
              setCityy('');
            } else if (response.data.code === 400) {
              toast.warn(response.data.error, +'!');
              handleClose1();
              setValuee(value.status)
              handleChange(value.status);
              setValue({
                email: '',
                companyName: '',
                linkedinUrl: '',
                contactPersonName: '',
                websiteUrl: '',
                contactNo: '',
                domain: '',
                additionalEmail: '',
                designation: '',
                employeeNo: '',
                location: '',
                rsc: '',
              });
              setSelectedEmail('');
              getEmails([])
              setCityy('');


            }
          }
        })
        .catch((err) => {
          // console.log('===========', err);
        });
    } else {
      setCompanyerr('true');
    }
    // } else {
    //   setEmailerr('true');

    // }
  };
  const funclick = (val) => {
    handleChange(valuee, pageNumber);
  };
  const funclick1 = (val) => {
    handleChange(valuee);
  };

  const changeS = (val) => {
    // alert(val)     
    tableData.splice(val, 1);
    setTableData([...tableData]);
  }
  const handleEmail = (e) => {
    // console.log('======================true=====1=============>>>>>>>>>', evalid);
    emails.forEach((item) => {
      if (item === e) {
        getDataByEmail(item);
      }
    });
    setValue({ ...value, email: e });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e));
    // setTimeout(() => {
    if (evalid === true) {
      setEmailerr(false);
      // console.log('=======email valid', evalid);
    } else {
      // console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    // console.log('======================true==================>>>>>>>>>', evalid);
  };

  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 400) {
  //           setDupemail(true);
  //         }
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data.result.map((item) => {
              return tempskill.push({ title: item.skillName });
            });
            setSkill(tempskill);
            // console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill([{ title: '' }]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getCity = async (val) => {
    setCityAndZip([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            setCityandstate(response.data.data[0]);
            if (response.data.data[0].zip) {
              setValue({ ...value, zip: response.data.data[0].zip });
            } else {
              setValue({ ...value, zip: '' });
            }
            // console.log('=======city and State', response.data.data[0].zip);
          } else {
            toast.error(response.data.message)
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const clearFormData = (e) => {
    e.preventDefault();
    getEmails([]);
    setSelectedEmail('')
    setCityAndZip([])
    setCityy('');
    setValue({
      email: "",
      companyName: '',
      linkedinUrl: '',
      contactPersonName: '',
      websiteUrl: '',
      contactNo: '',
      domain: '',
      designation: '',
      employeeNo: '',
      location: '',
      additionalEmail: '',
      status: '',
      rsc: "",

    });
  };
  const getDataByEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`company/get_client_by_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('=========get_client_by_email======>>>>>>>', response);
          if (response.data.code === 200) {
            // console.log('getDataByemail========', response.data.result);
            setValue({
              ...value,
              email: response.data.result.email,
              companyName: response.data.result.companyName,
              linkedinUrl: response.data.result.linkedinUrl,
              contactPersonName: response.data.result.contactPersonName,
              websiteUrl: response.data.result.websiteUrl,
              contactNo: response.data.result.contactNoWithISD,
              domain: response.data.result.domain,
              designation: response.data.result.designation,
              employeeNo: response.data.result.employeeNo,
              location: response.data.result.location,
              status: response.data.result.currentStatus,
              additionalEmail: response.data.result.additionalEmail,
              rsc: response.data.result.RPO_Staffing,
            });
            setCityy(response.data.result.location);
            setSelectedEmail(response.data.result.email);
          } else {
            // console.log('getDataByemail========', response.data);
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`company/check_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('====check_email=====status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            // console.log('city========', JSON.stringify(response.data));
            if (response.data.data !== null) {
              getEmails(response.data.data);
            }
          } else {
            getEmails([]);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  function addskill(val) {
    if (val !== '') {
      // console.log('add', finallst);
      finallst.push(val);
      setFinallst([...finallst]);
      setAskill('');
    }
    // console.log('------finallist', finallst);
  }
  function del(item) {
    // setSkill("");
    const idx = finallst.indexOf(item);
    finallst.splice(idx, 1);
    setFinallst([...finallst]);
    // console.log('delete', finallst);
    setAskill(null);
  }

  const childFuc = (e) => {
    // console.log('e', e);
    setCityy(e);

    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };

  const fullVal = (val) => {
    setCityy(`${val.city} ${val.stateCode}`);
    // setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };

  // const childFucEmail = (e) => {
  //   handleEmail(e);
  //   setSelectedEmail(e);
  //   const arr = e.match(/@/);
  //   if (arr != null) {
  //     // Did it match?
  //     getEmail(e);
  //   }
  //   // if (e.length >= 3) getEmail(e);
  // };

  const childFucEmail = (e) => {
    // console.log('Input value:', e);
    handleEmail(e);
    setSelectedEmail(e);

    const arr = e.match(/@/);
    if (arr != null) {
      // console.log('Fetching email suggestions for:', e);
      getEmail(e);
    }
  };

  const fullEmail = (val) => {
    // console.log('email', val);
    handleEmail(val);
    setSelectedEmail(val);
  };
  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { xs: '60%', sm: '50%' },
    width: '50%',
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if (e.length) {
      const lst = e[e.length - 1]
      if (!lst.match(reg)) {
        setValue({
          ...value,
          contactNo: e,
        })
      }
    } else {
      setValue({
        ...value,
        contactNo: e,
      })
    }

  }

  const [fileInputFiles, setFileInputFiles] = useState([]);

  // const handleFileSelect = (e) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     setFileInputFiles(file);
  //     uploadCSV(file);
  //     console.error('file====>>>>>>',file);
  //   } else {
  //     console.error('No files selected');
  //   }
  // };

  const handleFileSelect = (e) => {
    const files = e.target.files;
    const fileList = Array.from(files);
    // console.log('files browse', fileList)
    setFileInputFiles(fileList);
    fileList.map((item) => uploadCSV(item))
  };

  const uploadCSV = async (csvFile) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('directoryName', 'csv');

    try {
      const response = await UploadFile('company/add_clients/byfile', formData, token);

      if (response.status === 200) {
        if (response.data.code === 200) {
          excelClose();
          handleChange('new')
        } else {
          const errorMessage = response.data.data[0].message;
          // console.log('Error:', errorMessage);
          toast.warn(errorMessage);
          excelClose();
          handleChange('new')
        }
      } else {
        toast.warn('Error!');
      }
    } catch (error) {
      // console.error('Upload failed:', error);
      toast.warn('Upload failed!');
    }
  };


  // console.log("fileInputFiles", fileInputFiles)

  //   const handleFileSelect = (e) => {
  //     const files = e.target.files;
  //     const fileList = Array.from(files);
  //     setFileInputFiles(fileList);
  //     fileList.map((item) => uploadCSV(item))     
  //   };

  //   const uploadCSV = async (pdf) => {
  //     const token = localStorage.getItem('token');
  //     const formData = new FormData();  
  //     formData.append('document', pdf);
  //     formData.append('directoryName', 'resume');
  //     UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
  //         if (Response.status === 200) {
  //             if (Response.data.code === 200) {
  //                 // parseClose();
  //                 // ParseRes(Response.data.filePath);
  //                 console.log('=====404=gst===', Response.data.filePath);
  //             } else {
  //                 console.log('=====404=gst===', Response.data.message);
  //                 // toast.warn(Response.data.status + '!');
  //             }
  //         } else {
  //             toast.warn('Error !');
  //         }
  //     });
  // };


  const [pinned, setPinned] = useState(false)

  const searchDataFun = (data) => {
    // alert("hhh")
    setTableData(null)
    if (valuee !== 'new') {
      setValuee('new')
    }
    setTableData(data)
    setSearchResult(data?.length)
    setPinned(true)
  }

  // console.log("tableData", tableData)
  const resetTable = () => {
    // alert('hi!')

    handleChange('new');
    setPinned(false)
  }

  const [tableShow, setTableShow] = useState(true)

  const hidePage = () => {
    // alert('hello')
    setTableShow(false)
  }

  const showtable = () => {
    setTableShow(true)
  }


  const [loading, setLoading] = useState("")

  useEffect(() => {
    allUsers()

  }, [loading]);


  const [toggleButton, setToggleButton] = useState(false)
  const resetDataFun1 = () => {
    // getResume();
    // setPinned(false)
    // if(valuee === "new"){
    //   handleChange('new')
    // }
    if (valuee === "pipeline") {
      handleChange('pipeline');
    }
    // else if(valuee === "favourites"){
    //   handleChange("favourites")
    // }
    else if (valuee === "passiveclient") {
      handleChange("passiveclient")
    }
    else if (valuee === "meetings") {
      handleChange("meetings")
    }
    else if (valuee === "converted") {
      handleChange("converted")
    }
    else if (valuee === "dnc") {
      handleChange("dnc")
    }
    setToggleButton(false)
  }

  const [pepShow, setPepShow] = useState(false)

  const hidePep = () => {
    // alert('hello')
    setPepShow(false)
  }

  const showPep = () => {
    setPepShow(true)
  }

  const [resour, setResour] = useState('')
  const [resourData, setResourData] = useState([])

  const allUsers = () => {
    const token = localStorage.getItem('token');
    Api('common/users/SLO', 'GET', '', token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setResourData(response.data.data);
          } else {
            // console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChangeRes = (e) => {
    const resourceId = e.target.value;
    setResour(resourceId);
    setToggleButton(true)
    setDataaa(false)
  };

  const searchReso = (resourceId) => {
    const body = {
      "status": valuee,
      "resource": resourceId
    }
    const token = localStorage.getItem('token');
    Api('common/search/clients/status', 'POST', body, token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableData(response.data.data)
            hidePep()
            setResour('')
            // setResourData(response.data.data);
          } else {
            // console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (resour !== '') {
      searchReso(resour);
    }
  }, [resour]);

  useEffect(() => {
    setTimeout(() => {

      // getCompanyDetail1()
    }, 2000)
  }, [])

  const [tableData1, setTableData1] = useState([])
  // console.log("tableData1", tableData1)

  const getCompanyDetail1 = () => {
    const body = {
      "companyName": compN
    };
    const token = localStorage.getItem('token')

    // Api(`company/get_comp_details/${row.companyName}`, 'GET', '', token)
    Api('company/get_comp_details', 'POST', body, token)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (res.data.code === 200) {
            // toast.success(res.data.message)
            setTableData1(res.data.data);
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [editt, setEditt] = useState(false)

  const editFun = (data) => {
    // alert("hhh")
    setEditt(true)
    if (valuee !== 'new') {
      setValuee('new')
    }
    // handleChange('new');
    // setTableData(data)
    // setPinned(true)
  }

  const [data, setData] = useState(null);
  const [dataaa, setDataaa] = useState(true);

  // console.log("-----------data", data)

  const handleDataFromChild = (childData) => {
    // alert("h")
    setData(() => childData);
    if (data) {
      setDataaa(false)
    }
  };

  const callReceivedFunction = () => {
    if (data) {
      data();
    }
  };

  const [hideBtn, setHidebtn] = useState()

  return (
    <>
      <Search searchData={searchDataFun} func={resetTable} funcc={hidePage} funnc={showtable}
        editt1={editt} hideClosebtn1={hideClosebtn} onData={handleDataFromChild} 
        resetSearch={()=>setSearchResult('')}/>
      <ToastContainer />
      {toggleButton && (
        <Button color="custom" variant="contained" size="small"
          style={{ marginLeft: '240px', marginTop: "12px", position: "absolute" }}
          onClick={resetDataFun1}
        >
          Close
        </Button>

      )}
      {/* <SalesDetail /> */}
      {/* <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" color={'text.secondary'}>
          Sales Worksheet
        </Typography>
        <Button color="custom" variant="contained" size="small" onClick={handleOpen1}>
          Add Clientk
        </Button>
      </Stack> */}
      <Modal
        open={pepShow}
        onClose={hidePep}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '33%',
          left: '32%',
          width: "290px",
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          // textAlign: 'center',
          border: " 2.5px solid #1E74B3"
        }}>
          {/* <Grid item xs={4}> */}
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel >Resource</InputLabel>
            <Select
              // labelId="resource-label"
              label="Resource"
              value={resour}
              // onChange={(e) => {setResour(e.target.value); searchReso()}}
              onChange={handleChangeRes}
            >
              {resourData.map((item, index) => (
                <MenuItem key={index} value={item._id}>
                  {item.contactPersonName}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
          {/* </Grid> */}

        </div>
      </Modal>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" >
        <Typography variant="h5" color="textSecondary">
          Sales Worksheet
          {valuee !== "new" && valuee !== "favourites" && (
            <IconButton sx={{ marginLeft: "5px", marginTop: "-1px" }} onClick={showPep}>
              {/* <IconButton sx={{marginLeft:"1px", marginTop:"-1px"}} onClick={showPep}> */}
              {/* <Iconify icon="pepicons-pencil:people" width="1.8rem" color="#1E74B3"/> */}
              <Iconify
                icon="bxs:down-arrow"
                color="#1E74B3"
              />
              {/* </IconButton> */}
              {/* <Iconify icon="pepicons-pencil:people" width="1.8rem" color="#1E74B3"/> */}
            </IconButton>

          )}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button color="custom" variant="contained" size="small" onClick={excelOpen}>
            Parse Client
          </Button>
          <Button color="custom" variant="contained" size="small" onClick={handleOpen1}>
            Add Client
          </Button>
        </Stack>
      </Stack>
      {!tableShow && (

<Typography sx={{float: "right", mt:0.5}} fontSize={"0.85rem"} color={'text.secondary'}>
  Total: {searchResult || "0"}
</Typography>
)}
      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignContent: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row' },
            valuee === 'new' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleChange('new')
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={20} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            New
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'favourites' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('favourites') }}
        >
          <Iconify icon="tdesign:heart" fontSize={20} color={valuee === 'favourites' ? '#E74C3C' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Favourites
          </Typography>
        </Stack>

        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'pipeline' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('pipeline') }}
        >
          <Iconify icon="carbon:user-multiple" fontSize={20} color={valuee === 'pipeline' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Pipeline
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'meetings' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('meetings') }}
        >
          <Iconify icon="fluent-mdl2:date-time" fontSize={20} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Meetings
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'converted' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('converted') }}
        >
          <Iconify icon="bi:check-circle" fontSize={20} color={valuee === 'converted' ? '#2ECC71' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Converted
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'passiveclient' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('passiveclient') }}
        >
          <Iconify icon="ps:check-box" fontSize={20} color={valuee === 'passiveclient' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Passive Client
          </Typography>
        </Stack>

        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'dnc' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => { handleChange('dnc') }}
        >
          <Iconify icon="bx:error" fontSize={20} color={valuee === 'dnc' ? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            DNC
          </Typography>
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '16%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Detail</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '20%' }}>Contact Details</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '15%' }}>Location</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Activities</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '11%' }}>Status</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((item, index) => (
              <ResumeRow
                index={index}
                key={item._id}
                row={item}
                fun={funclick}
                fun1={funclick1}
                // fun2={pushNote}
                changeS={changeS}
                searchDataFun1={searchDataFun}
                // skillsett={skill}
                // joblst1={joblst}
                filterTab={valuee}
                pinned={pinned}
                tempList={tempList}
                // getCompanyDetail={getCompanyDetail1}
                // tableData={tableData1}
                editFun1={editFun}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {tableData.length < 1 && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )} */}

      {/* {loading && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'blue' }}>Loading...</Typography>
        </Stack>
      )} */}
      {loading !== "" && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>{loading}</Typography>
        </Stack>
      )}


      {/* {tableData.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage} />
        </Stack>
      ) : null} */}

      {tableShow && dataaa && (
        <>
          {tableCount > 35 ? (
            // <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
            //   <Pagination variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage} />
            // </Stack>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2
              }}
            >
              {/* {pageNumber !== 1 && ( */}
              <Button
                variant="outlined"
                sx={{
                  minWidth: 16, height: 32, borderRadius: '5px',
                  mt: 2,
                  ml: 0.5, cursor: 'pointer'
                }}
                onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
              >
                {"<"}
              </Button>
              {/* )} */}

              <Pagination
                variant="outlined"
                shape="rounded"
                color="secondary"
                hideNextButton
                hidePrevButton
                count={pages}
                page={pageNumber}
                onChange={handlePage}
                sx={{ cursor: 'pointer', mb: (pageNumber !== 1) ? -4 : -2 }}
              />
              {/* {pageNumber !== pages && ( */}
              <Button
                variant="outlined"
                sx={{
                  minWidth: 16, height: 32, borderRadius: '5px', mr: 1,
                  mt: -2,
                  cursor: 'pointer'
                }}
                onClick={() => setPageNumber(prev => Math.min(prev + 1, pages))}
              >
                {">"}
              </Button>
              {/* )} */}
            </Stack>
          ) : null}
        </>
      )}
      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={style}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            New Prospect
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <CustomComplete
                  err={emailerr}
                  req={'true'}
                  placeholder={'Email'}
                  option={emails}
                  propVal={value.email}
                  childFuc={childFucEmail}
                  fullVal={fullEmail}
                  fetch={'email'}
                // helperText={"Please enter valid email address"}
                />

                {evalid === false ? (
                  <Stack sx={{ ml: 1 }}>
                    <Typography color={'red'} variant="body">
                      This is not vaild mail
                    </Typography>
                  </Stack>
                ) : null}
                {dupemail === true ? (
                  <Stack sx={{ ml: 1, mt: 1, flexDirection: 'row' }}>
                    <Typography color={'red'} variant="body">
                      Duplicate Record Found
                    </Typography>
                    <Typography color={'red'} variant="body" sx={{ ml: 2 }}>
                      -
                    </Typography>
                    <Typography
                      color={'text.secondary'}
                      variant="body"
                      sx={{ ml: 2, cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      Click here to view
                    </Typography>
                  </Stack>
                ) : null}

                <TextField
                  error={companyerr}
                  required
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.companyName}
                  // sx={{mt:-2}}
                  onChange={(e) => {
                    setValue({ ...value, companyName: e.target.value });
                    if (value.companyName.length > 4) {
                      setCompanyerr(false);
                    } else {
                      setCompanyerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Website"
                  placeholder="Website"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.websiteUrl}
                  // inputProps={{ type: 'numeric'}}
                  // type='number'
                  // onChange={(e) => setValue({ ...value, websiteUrl: e.target.value })}

                  onChange={(e) => {
                    let updatedUrl = e.target.value.trim();
                    updatedUrl = updatedUrl.replace(/^(https?:\/\/)?(www\.)?/i, '');

                    if (updatedUrl.startsWith('www')) {
                      updatedUrl = `https://${updatedUrl}`;
                    } else if (updatedUrl !== '') {
                      updatedUrl = `https://www.${updatedUrl}`;
                    }

                    setValue({ ...value, websiteUrl: updatedUrl });
                  }}

                />
                <TextField
                  id="outlined-basic"
                  label="Contact Name"
                  placeholder="Contact Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.contactPersonName}
                  // inputProps={{ type: 'numeric'}}
                  // type='number'
                  onChange={(e) => setValue({ ...value, contactPersonName: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Designation"
                  placeholder="Designation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.designation}
                  // inputProps={{ type: 'numeric'}}

                  onChange={(e) => setValue({ ...value, designation: e.target.value })}
                />

                {/* <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.linkedinUrl}
                  // onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                  onChange={(e) => {
                    let updatedUrl = e.target.value.trim();
                 
                    updatedUrl = updatedUrl.replace(/^https?:\/\//i, '');
                   if (updatedUrl) {
                      updatedUrl = `https://${updatedUrl}`;
                    }
                
                    setValue({ ...value, linkedinUrl: updatedUrl });
                  }}
                />    */}

                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.linkedinUrl}
                  onChange={(e) => {
                    let updatedUrl = e.target.value.trim();
                    updatedUrl = updatedUrl.replace(/^(https?:\/\/)?(www\.)?/i, '');

                    if (updatedUrl.startsWith('www')) {
                      updatedUrl = `https://${updatedUrl}`;
                    } else if (updatedUrl !== '') {
                      updatedUrl = `https://www.${updatedUrl}`;
                    }

                    setValue({ ...value, linkedinUrl: updatedUrl });
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  placeholder="Contact Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.contactNo}
                  // inputProps={{ type: 'numeric'}}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Stack>
              <Stack gap={2} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.domain}
                    label="Domain"
                    // InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, domain: e.target.value })}
                  >
                    {Domains.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}

                  </Select>
                </FormControl>

                {/* <TextField
                  id="outlined-basic"
                  multiline
                  label="Additional Contact"
                  placeholder="Additional Contact"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.additionalEmail}
                  onChange={(e) => setValue({ ...value, additionalEmail: e.target.value })}
                /> */}
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">RPO/Staffing/Consulting</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    placeholder="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    value={value.rsc}
                    onChange={(e) => setValue({ ...value, rsc: e.target.value })}
                  >
                    <MenuItem value={'RPO'}>RPO</MenuItem>
                    <MenuItem value={'Staffing'}>Staffing</MenuItem>
                    <MenuItem value={'Consulting'}>Consulting</MenuItem>
                    <MenuItem value={'MSP'}>MSP</MenuItem>
                    <MenuItem value={'Contingent'}>Contingent</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">No of Employees</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="No of Employees"
                    placeholder="No of Employees"
                    value={value.employeeNo}
                    onChange={(e) => setValue({ ...value, employeeNo: e.target.value })}
                  >
                    <MenuItem value={'0-10'}>0-10</MenuItem>
                    <MenuItem value={'10-25'}>10-25</MenuItem>
                    <MenuItem value={'25-50'}>25-50</MenuItem>
                    <MenuItem value={'50-100'}>50-100</MenuItem>
                    <MenuItem value={'100-250'}>100-250</MenuItem>
                    <MenuItem value={'250-500'}>250-500</MenuItem>
                    <MenuItem value={'more than 500'}>more than 500</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">Current Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Current Status"
                    placeholder="Current Status"
                    value={value.status}
                    onChange={(e) => setValue({ ...value, status: e.target.value })}
                  >
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'meetings'}>Meetings</MenuItem>
                    <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                    <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                    <MenuItem value={'favourites'}>Favourites</MenuItem>
                    <MenuItem value={'converted'}>Converted</MenuItem>
                    <MenuItem value={'dnc'}>DNC</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Stack sx={{ flexDirection: 'row', gap: 1, mt: 1, justifyContent: 'center' }}>
              <Button color="custom" sx={{ width: 100 }} variant="contained" onClick={(e) => {
                if (value.email === "") {
                  setOpenConfirm(true)
                }
                else { addcv() }
              }}>
                Add
              </Button>
              <Button color="custom" variant="contained" onClick={(e) => clearFormData(e)}>
                Clear data
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openConfirm} onClose={confirmClose} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '30%' }]}>
          <Stack sx={{ justifyContent: "center", alignItems: 'center', width: '100%' }}>
            <Stack sx={{ width: "100%" }}>
              <Typography sx={{}} variant='h6' textAlign={"center"} color={'red'}>
                Proceed without an Email ?
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 3, alignSelf: 'center', gap: 2 }}>
              <Button color="custom" sx={{}} variant="contained" size='small' onClick={() => { confirmClose(); addcv() }}>
                Yes
              </Button>
              <Button color="custom" sx={{}} variant="contained" size='small' onClick={confirmClose}>
                No
              </Button>
            </Stack>
          </Stack>

        </Box>
      </Modal>

      <Modal open={excel} onClose={excelClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            backgroundColor: 'white',
            border: '4px solid #1E74B3',
            borderRadius: '13px',
            padding: '1rem',
            outline: 'none',
            height: 'auto'
          }}

        >
          <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
            <div
              style={{
                border: '3px dashed #1E74B3',
                padding: '10px',
                borderRadius: '15px',
                margin: '1rem',
                // justifyContent: 'center',
                // alignItems: 'center',
                textAlign: 'center',
                color: '#1E74B3',
                // width: '330px',
                marginLeft: '25',
                alignContent: 'center',
              }}
            >
              <Typography variant="body1" fontWeight="bold" mb={1}>
                Browse Excel File
              </Typography>
              <input
                id="file-upload"
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                multiple
              />

            </div>
          </label>
          {fileInputFiles.length > 0 && (
            <div>
              {/* {/ <Typography variant="body1">Uploaded Files</Typography> /} */}
              {fileInputFiles.map((file, index) => (
                <Typography key={index} variant="body1" sx={{ marginLeft: '20px' }}>{file.name}</Typography>
              ))}
            </div>
          )}
          <Typography color="custom" variant="body1" sx={{ marginLeft: "30%" }}>
            <a href="/assets/Parsing-Sales.xlsx" download style={{ textDecoration: 'underline', color: '#1E74B3' }}>
              Download Excel Format
            </a>
          </Typography>
        </Box>
      </Modal>

    </>
  );
}


export default SalesWorksheet;

function ResumeRow({ row, fun, fun1, skillsett, joblst1, filterTab, index, changeS, fun2, pinned, tempList,
  searchDataFun1, editFun1 }) {

  //  ==============>>>>>>>> email popup

  const [showPassword, setShowPassword] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();
  // const locData = location.state;
  // alert(location.state)
  const token = localStorage.getItem('token');
  const [editTrue, setEditTrue] = React.useState(true);
  const [tableData, setTableData] = useState([]);
  // console.log("tableData2", tableData)
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    designation: '',
    email: '',
    name: '',
    phone: '',
    linkedin: '',
  });

  const [opennn, setOpennn] = React.useState(false);
  const handleOpennn = () => setOpennn(true);
  const handleCloseee = () => setOpennn(false);

  // modal 1
  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen1(true);
  const handleClose11 = () => setOpen1(false);
  const [id, setId] = useState('');
  const [localId, setLocalId] = useState(localStorage.getItem('userid'));
  const [role, setRole] = useState('');

  const [salesOpenModal, setSalesOpenModal] = useState(false)

  const salesClosedModal = () => {
    setSalesOpenModal(false)
  }

  const salesOpenedModal = () => {
    setSalesOpenModal(true)
  }

  const [compD, setComD] = useState()
  const [compC, setComC] = useState()

  useEffect(() => {
    // console.log("========ashish====>>>", id)
    if (compD) {
      getCompanyDetail();
    }

  }, [compD]);

  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  function handlePopUp(val) {
    setEditTrue(val);
    if (val === true) {
      handleOpen1();
    } else {
      handleClose1();
    }
  }
  const CheckContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          phone: e,
        });
      }
    } else {
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        phone: e,
      });
    }
  };

  // console.log("row", row)
  const getCompanyDetail = () => {
    const body = {
      "companyName": compD || row.companyName
    };

    // Api(`company/get_comp_details/${row.companyName}`, 'GET', '', token)
    Api('company/get_comp_details', 'POST', body, token)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (res.data.code === 200) {
            // toast.success(res.data.message)
            setTableData(res.data.data);
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const addContact = () => {
    const body = {
      companyName: row.companyName,
      contactPersonName: formValuesAddComapnny.name,
      designation: formValuesAddComapnny.designation,
      linkedinUrl: formValuesAddComapnny.linkedin,
      contactNoWithISD: formValuesAddComapnny.phone,
      email: formValuesAddComapnny.email,
    };
    Api('company/add_new_contact', 'POST', body, token)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          if (res.data.code === 200) {
            toast.success(res.data.message);
            getCompanyDetail();
            handleClose();
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const funclick = () => {
    getCompanyDetail()
  };

  // console.log("row====>>>====", locData)


  function ManagementRow({ row, id, fun, rolee }) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [edit, setEdit] = useState(false);
    const [client, setClient] = useState({
      contactPersonName: row.contactPersonName,
      designation: row.designation,
      linkedinUrl: row.linkedinUrl,
      contactNoWithISD: row.contactNoWithISD,
      email: row.email,
    });

    const createLead = (id) => {
      const body = {
        contactId: id,
      };
      Api('company/create_lead', 'POST', body, token)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              toast.success(res.data.message);
              navigate(-1);
            } else {
              toast.success(res.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    async function editFun(row) {
      const token = localStorage.getItem('token');
      // console.log('=====id=====>>>', id);
      if (edit === false) {
        setEdit(true);
      } else if (edit === true) {
        const body = {
          contactId: row._id,
          contactPersonName: client.contactPersonName,
          designation: client.designation,
          linkedinUrl: client.linkedinUrl,
          contactNoWithISD: client.contactNoWithISD,
          email: client.email,
        };
        Api(`company/edit_contact`, 'POST', body, token)
          .then((Response) => {
            // console.log('======getUser==response=====>', Response);
            if (Response.status === 200) {
              if (Response.data.code === 200) {
                setEdit(false);
                fun();
                toast.success(Response.data.message);
                // console.log('======getUser===data.data pdata====>', Response);
              } else {
                // console.log('======getUser=======>', +Response);
                toast.warn(Response.data.message);
                // enqueueSnackbar(Response.data.message);
              }
            }
          })
          .catch((err) => {
            toast.error(err, +'!');
            // console.log('===========', err);
          });
      }
    }
    return (
      <TableRow>
        <TableCell sx={{ pl: 1, textTransform: 'capitalize' }}>
          {edit ? (
            <TextField
              id="outlined-basic"
              label="Contact Person Name"
              placeholder="Contact Person Name"
              variant="outlined"
              value={client.contactPersonName}
              onChange={(e) => setClient({ ...client, contactPersonName: e.target.value })}
            />
          ) : (
            <Typography sx={{ fontWeight: '600' }} color={'text.secondary'}>
              {row.contactPersonName}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          {edit ? (
            <TextField
              id="outlined-basic"
              label="Designation"
              placeholder="Designation"
              variant="outlined"
              value={client.designation}
              onChange={(e) => setClient({ ...client, designation: e.target.value })}
            />
          ) : (
            <Typography>{row.designation}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1, textTransform: 'lowercase' }}>
          {edit ? (
            <TextField
              id="outlined-basic"
              label="Linkedin Url"
              placeholder="Linkedin Url"
              variant="outlined"
              value={client.linkedinUrl}
              onChange={(e) => setClient({ ...client, linkedinUrl: e.target.value })}
            />
          ) : (
            <Typography>{row.linkedinUrl}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          {edit ? (
            <TextField
              id="outlined-basic"
              label="Contact Number"
              placeholder="Contact Number"
              variant="outlined"
              value={client.contactNoWithISD}
              onChange={(e) => setClient({ ...client, contactNoWithISD: e.target.value })}
            />
          ) : (
            <Typography>{row.contactNoWithISD}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1, textTransform: 'lowercase' }}>
          {edit ? (
            <TextField
              id="outlined-basic"
              label="Email"
              placeholder="Email"
              variant="outlined"
              value={client.email}
              onChange={(e) => setClient({ ...client, email: e.target.value })}
            />
          ) : (
            <Typography>{row.email}</Typography>
          )}
        </TableCell>
        <TableCell sx={{ pl: 1 }}>
          <Stack flexDirection={'row'} gap={1}>
            <Button color="custom" type="submit" variant="contained" size="small" onClick={() => editFun(row)}>
              {edit === false ? 'edit' : 'save'}
            </Button>
            {row.isTeamLead === 'no' && (
              <Button color="custom" type="submit" variant="contained" size="small" onClick={() => createLead(row._id)}>
                create new lead
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  const [temp, setTemp] = useState('Template');
  // const [subject, setSubject] = useState('');
  const [editor, setEditor] = useState('');

  const [date, setDate] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')
  const [openModal1, setOpenModal1] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)

  // const [candidateId, setCandidateId] = useState('')
  const [selectedCandidateId, setSelectedCandidateId] = useState('');

  // console.log("candidateId ====>>>>",candidateId )
  // console.log("selectedCandidateId ====>>>>", selectedCandidateId)

  const [selectedFile, setSelectedFile] = useState(null);

  const FileChange = (event) => {

    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
    } else {
      toast.warn('No file selected!');
    }
  };

  const [selTemp, setSelTemp] = useState('')
  const [recipientEmail, setRecipientEmail] = useState(row.email);
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [bccEmail, setBccEmail] = useState([]);
  const [ccEmail, setCcEmail] = useState([]);
  const [uploadfile, setUploadFile] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [sub1, setSub1] = useState('')
  const [sub2, setSub2] = useState('')
  const [sub3, setSub3] = useState('')
  const [body1, setBody1] = useState('')
  const [body2, setBody2] = useState('')
  const [body3, setBody3] = useState('')
  const [time1, setTime1] = useState(dayjs())
  const [time2, setTime2] = useState(dayjs())
  const [time3, setTime3] = useState(dayjs())
  const [date3, setDate3] = useState('')
  const [checked, setChecked] = useState(false);

  const [checkedEmail1, setCheckedEmail1] = useState(false);
  const [checkedEmail2, setCheckedEmail2] = useState(false);
  const [checkedEmail3, setCheckedEmail3] = useState(false);

  const SendEmail = () => {
    const token = localStorage.getItem('token')
    const bcc = [];
    const cc = [];
    bccEmail.map((item) => {
      return bcc.push(item.title);
    });
    ccEmail.map((item) => {
      return cc.push(item.title);
    });
    const body =
    {
      // "date": new Date().toISOString().slice(0, 10),
      // "time": new Date().toLocaleTimeString(),
      "subject": subject,
      'emailBody': emailBody,
      "recipientEmail": recipientEmail,
      "bccEmail": bcc,
      "ccEmail": cc,
      // "fileUrl": uploadfile,
    }
    Api('email/mail/instant', 'POST', body, token)
      .then(response => {
        // console.log("emailData ====>>>>>>>>>", response.data);
        if (response.status === 200) {
          if (response.data.code === 200) {
            handleCloseModal()
            toast.success(response.data.message)
            // alert('Email sent successfully!');
            // if (checked === true) {
            //   secheduleEmail()
            // }

            if (checked && (checkedEmail1 || checkedEmail2 || checkedEmail3)) {
              scheduleEmail();

            } else if (checked === true) {
              scheduleEmail()
            }

          } else {
            toast.error(response.data.message)
            // alert('Failed to send email.');
          }
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
        // alert('Error sending email. Please try again.');
      });
  };
  const scheduleEmail = () => {
    const token = localStorage.getItem('token')
    const bcc = [];
    const cc = [];
    bccEmail.map((item) => {
      return bcc.push(item.title);
    });
    ccEmail.map((item) => {
      return cc.push(item.title);
    });
    const body = {
      "candidateId": selectedCandidateId,
      "templates": [
        {
          "date": date1,
          // "time": dayjs(time1).format('LT'),
          "time": dayjs(time1).format('HH:mm'),
          "subject": sub1,
          "emailBody": body1,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        },
        {
          "date": date2,
          // "time": dayjs(time2).format('LT'),
          "time": dayjs(time2).format('HH:mm'),
          "subject": sub2,
          "emailBody": body2,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        },
        {
          "date": date3,
          // "time": dayjs(time3).format('LT'),
          "time": dayjs(time3).format('HH:mm'),
          "subject": sub3,
          "emailBody": body3,
          // "fileUrl": "base64_encoded_file_content", // replace this with actual base64 content if any
          "recipientEmail": recipientEmail,
          "bccEmail": bcc,
          "ccEmail": cc,
        }
      ]
    }
    Api('email/mail/schedule', 'POST', body, token)
      .then(response => {
        try {
          // console.log("emailData ====>>>>>>>>>", response.data);
          if (response.status === 200) {
            if (response.data.code === 200) {
              toast.success(response.data.message);
            } else if (response.data.code === 400 && response.data.message === "Emails already scheduled") {
              toast.warn(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          } else {
            toast.error('Emails already scheduled.');
          }
        } catch (error) {
          console.error('Error handling response:', error);
          toast.error('Error handling response. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
        toast.error('Error sending email. Please try again.');
      });
  };

  const uploadFile = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {

          setUploadFile(Response.data.filePath)
          // console.log('=====404=gst===', Response.data.filePath);

        } else {
          // console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  const handleTemplateSelect = (item) => {
    // const selectedTemplate = tempList.find((item) => item.templateName === templateName);
    setSelectedTemplate(item)

    // console.log("selectedTemplate ====>>>>>>>", item.template);

    if (item && item.template && item.template.length > 0) {
      if (item.template[0]) {
        setSubject(item.template[0].subject)
        setEmailBody(item.template[0].emailBody)
      }
      if (item.template[1]) {
        setSub1(item.template[1].subject)
        setBody1(item.template[1].emailBody)
      }
      if (item.template[2]) {
        setSub2(item.template[2].subject)
        setBody2(item.template[2].emailBody)
      }
      if (item.template[3]) {
        setSub3(item.template[3].subject)
        setBody3(item.template[3].emailBody)
      }

    }
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }
  const [email, setEmail] = useState("")
  // const [email, setEmail] = useState(getTomorrowDate())

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDateSelect = (selectedDate) => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setDate1(formattedDate);
    setOpenModal(false);
  };

  const getDateFromThreeDaysLater = () => {
    const threeDaysLater = new Date();
    threeDaysLater.setDate(threeDaysLater.getDate() + 4);
    return threeDaysLater.toISOString().split('T')[0];
  }
  const [email1, setEmail1] = useState('')
  // const [email1, setEmail1] = useState(getDateFromThreeDaysLater())
  const handleModalOpen1 = () => {
    setOpenModal1(true);
  };

  const handleModalClose1 = () => {
    setOpenModal1(false);
  };

  const handleDateSelect1 = (selectedDate) => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setDate1(formattedDate);
    setOpenModal(false);
  };

  const getDateFromOneWeekLater = () => {
    const OneWeekLater = new Date();
    OneWeekLater.setDate(OneWeekLater.getDate() + 9);
    return OneWeekLater.toISOString().split('T')[0];
  }
  const [email2, setEmail2] = useState('')
  // const [email2, setEmail2] = useState(getDateFromOneWeekLater())
  const handleModalOpen2 = () => {
    setOpenModal2(true);
  };

  const handleModalClose2 = () => {
    setOpenModal2(false);
  };


  const handleDateSelect2 = (selectedDate) => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    // const formattedDate = selectedDate.toISOString().replace('T', ' ').split('.')[0];
    setDate2(formattedDate);
    // console.log("====>>>>>", formattedDate);
    handleModalClose1(false)
  };
  const handleDateSelect3 = (selectedDate) => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    // const formattedDate = selectedDate.toISOString().replace('T', ' ').split('.')[0];
    setDate3(formattedDate);
    // console.log("====>>>>>", formattedDate);
    setOpenModal2(false);
  };

  const [modalOpen, setModalOpen] = useState(false);

  // const handleOpenModal = () => {
  //   // alert('hello')
  //   setModalOpen(true);
  // };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = (id) => {
    setSelectedCandidateId(id);
    setModalOpen(true);
    // alert(`Selected Candidate ID: ${id}`);
  };

  useEffect(() => {
    // GetTempList()
  }, []);

  // const getTemplList = () => {
  //   const token = localStorage.getItem('token');
  //   // console.log("token ===>>>>> ",token)
  //   Api('email/getEmailTemplateList', 'GET', '', token)
  //     .then((response) => {
  //       console.log('Response:---------', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           //  console.log("hhh")
  //           // if (response.data.result.length > 0) {
  //           //   const firstTemplate = response.data.result;
  //           //   setUserId(firstTemplate.userId);
  //           //   setCompanyId(firstTemplate.companyId);
  //           // }
  //           const temp = response.data.result;
  //           const extractedData = temp.map((item) => {
  //             return {
  //               companyId: item.companyId,
  //               userId: item.userId
  //             };
  //           });
  //           // console.log('Extracted Data:', extractedData);

  //           if (extractedData.length > 0) {
  //             const firstItem = extractedData;
  //             // setCompanyId(firstItem.companyId); 
  //             // setUserId(firstItem.userId); 
  //           }

  //         } else {
  //           console.log('data:', response.data.message);
  //         }
  //       }
  //       setTempList(response.data.result);
  //       // setUserId(response.data.result.userId)
  //       // setCompanyId(response.data.result.companyId)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }; 


  const handleChangebtn = (event) => {
    setChecked(event.target.checked);
    // console.log('=========mail chk=====>>>', event.target.checked);
    setCheckedEmail1(event.target.checked);
    // console.log('checkboxEmail 1=====>>>', event.target.checked);
    setCheckedEmail2(event.target.checked);
    // console.log('checkboxEmail 2=====>>>', event.target.checked);
    setCheckedEmail3(event.target.checked);
    // console.log('checkboxEmail 3=====>>>', event.target.checked);
  };

  const handleChangeEmail1 = (event) => {
    // console.log('checkboxEmail 1=====>>>', event.target.checked);
    setCheckedEmail1(event.target.checked);
  };


  const handleChangeEmail2 = (event) => {
    // console.log('checkboxEmail 2=====>>>', event.target.checked);
    setCheckedEmail2(event.target.checked);
  };

  const handleChangeEmail3 = (event) => {
    // console.log('checkboxEmail 3=====>>>', event.target.checked);
    setCheckedEmail3(event.target.checked);
  };

  useEffect(() => {
    // console.log('=================rowwww===>>>', row);
  }, []);

  const navigate = useNavigate();
  const [tmpskl, setTmpskl] = useState([]);

  const [status, setStatus] = useState('Pending');
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openpdf, setOpenpdf] = useState(false);

  const [openskill, setOpenskill] = useState(true);
  const skillclose = () => setOpenskill(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [totalDays, setTotalDays] = useState(0);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const [openn, setOpenn] = useState(false);
  const handleOpenn = () => setOpenn(true);
  const handleClosen = () => setOpenn(false);

  const [openmeeting, setMeeting] = useState(false)
  const handleOpenM = () => setMeeting(true);
  const handleCloseM = () => setMeeting(false);
  const [editM, setEditM] = useState(false)
  const openEditM = () => setEditM(true)
  const closeM = () => setEditM(false)

  const [openclient, setOpenclient] = useState(false);
  const handleOpenclient = () => setOpenclient(true);
  const handleCloseclient = () => setOpenclient(false);

  const [upnote, setUpnote] = useState(false)
  const noteCloses = () => setUpnote(false)
  const noteOpen = () => setUpnote(true)
  const [newNote, setNewNote] = useState('')

  const handleOpenpdf = () => setOpenpdf(true);
  const Closepdf = () => setOpenpdf(false);
  const [pdffile, setPdffile] = useState(row.resumeUrl);
  const [docfile, setDocfile] = useState();
  const [commentt, setCommentt] = useState('');
  const [companyerr, setCompanyerr] = useState(false);
  const [value, setValue] = useState({
    email: row.email,
    companyName: row.companyName,
    linkedinUrl: row.linkedinUrl,
    contactPersonName: row.contactPersonName,
    websiteUrl: row.websiteUrl,
    contactNo: row.contactNoWithISD,
    domain: row.domain,
    additionalEmail: row.additionalEmail, 
    designation: row.designation,
    employeeNo: row.employeeNo,
    location: row.location,
    status: row.currentStatus,
    rsc: row.RPO_Staffing,
    country: 'USA',
  });
  const [tempskill, setTempskill] = useState([]);
  const [skill, setSkill] = useState('');
  const [finallst, setFinallst] = useState(row.skill);
  const [evalid, setEvalid] = useState(true);
  const [cityy, setCityy] = useState(row.location || '');
  // const [ city , setCity] = useState('')
  const [askill, setAskill] = React.useState(null);
  const [statee, setStatee] = useState('');
  const [cityAndZip, setCityAndZip] = useState([]);
  const cities = [];
  const [skillset, setSkillset] = useState([{ title: 'item.skillName' }]);
  const [client, setClients] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  const [statusnotes, setStatusnotes] = useState('');
  const [statusch, setStatusch] = useState(row.currentStatus);
  // const [changeStatus, setChangeStatus] = useState({
  //   status: '',
  //   note: '',
  //   date: '',
  //   datee: '',
  //   day: '',
  //   month: '',
  //   year: '',
  //   sl: '/',
  // });

  const [datee, setDatee] = useState(dayjs());
  // console.log("++++++==========datee===========++++++",datee)
  const [ConvertedDate, setConvertedDate] = useState(dayjs());
  // const [cmntarr, setCmntarr] = useState([]);
  const [gemail, setGemail] = useState(row.email)
  const [ccc, setCcc] = useState('')
  const [subline, setSubline] = useState('')
  const [meetingDate, setMeetingDate] = useState(dayjs());
  const [startTimee, setStartTimee] = useState(dayjs());
  const [endTimee, setEndTimee] = useState(dayjs());
  const [timez, setTimez] = useState('est');
  const [meetingMode, setMeetingMode] = useState('');
  const [pNumber, setPNumber] = useState('');
  const [vPlatform, setVPlatform] = useState('');
  const [mUrl, setMurl] = useState('');
  const [address, setAddress] = useState('');
  const [meetingNote, setMeetingNote] = useState('');
  const [meetingData, setMeetingData] = useState({});

  const [editgemail, setEditgemail] = useState("")
  const [editccc, setEditccc] = useState("")
  const [editsubline, setEditsubline] = useState("")
  const [editmeetingDate, setEditmeetingDate] = useState(dayjs());
  const [editstartTimee, setEditstartTimee] = useState(dayjs());
  const [editendTimee, setEditendTimee] = useState(dayjs());
  const [edittimez, setEdittimez] = useState('est');
  const [editmeetingMode, setEditmeetingMode] = useState('');
  const [editpNumber, setEditpNumber] = useState('');
  const [editvPlatform, setEditvPlatform] = useState('');
  const [editMurl, setEditMurl] = useState('');
  const [editaddress, setEditaddress] = useState('');
  const [editmeetingNote, setEditmeetingNote] = useState('');
  const [addEmails, setAddEmails] = useState("")
  const [convertedDis, setConvertedDis] = useState(statusch === "converted" ? "none" : "")
  // const [convertedDate, setConvertedDate] = useState(statusch === "converted" ? "none" : "")

  const [emailCopy, setEmailCopy] = useState(false)
  const [phoneCopy, setPhoneCopy] = useState(false)


  const getAddEmail = async (cname) => {
    const token = localStorage.getItem('token')
    Api(`company/get_mails/${cname}`, 'GET', "", token)
      .then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            if (Response.data.data) {
              const tempAdd = Response.data.data.toString()
              const tempAdd1 = tempAdd.replace(/,/g, ' | ')
              // console.log('======get_add_email=====>', tempAdd1);
              setAddEmails(tempAdd1)
            }
          }
        }
        // console.log('======get_add_email=====>', Response);  
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  const getMeeting = async (id) => {
    // console.log('======get_meeting=====>', id,"======get_meeting=====>", row._id );
    const token = localStorage.getItem('token');
    Api(`company/meeting/${id}`, 'GET', "", token)
      .then((Response) => {
        // console.log('======get_meeting=====>', Response);
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            setMeetingData(Response.data.data)
            handleOpenM()
            setEditgemail(Response.data.data.guestEmail)
            setEditccc(Response.data.data.cc)
            setEditsubline(Response.data.data.subject)
            setEditmeetingDate(dayjs(Response.data.data.dateOfMeeting))
            setEditstartTimee(dayjs(Response.data.data.startTime))
            setEditendTimee(dayjs(Response.data.data.endTime))
            setEdittimez(Response.data.data.timeZone)
            setEditmeetingMode(Response.data.data.modeOfMeeting)
            setEditpNumber(Response.data.data.phone)
            setEditvPlatform(Response.data.data.meetingPlatform)
            setEditMurl(Response.data.data.meetingLink)
            setEditaddress(Response.data.data.meetingAddress)
            setEditmeetingNote(Response.data.data.additionalNotes)
            // handleClose1();
            // toast.success(Response.data.message);
            // fun();
            // console.log('======getUser Meetings===data.data pdata====>', Response);
          } else {
            toast.error(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        // console.log('===========', err);
      });
  }

  const editmeetingData = (id) => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: id,
      guestEmail: editgemail,
      cc: editccc,
      subject: editsubline,
      dateOfMeeting: editmeetingDate,
      // startTime: editstartTimee,
      startTime: new Date(editstartTimee).toLocaleString('en-US', 
        { hour: '2-digit', minute: '2-digit', hour12: true }),
      // endTime: editendTimee,
      endTime: new Date(editendTimee).toLocaleString('en-US', 
        { hour: '2-digit', minute: '2-digit', hour12: true }),
      timeZone: edittimez,
      modeOfMeeting: editmeetingMode,
      phone: editpNumber,
      meetingPlatform: editvPlatform,
      meetingLink: editMurl,
      meetingAddress: editaddress,
      currentStatus: 'meetings',
      date: editmeetingDate,
      additionalNotes: editmeetingNote,
      status: "Meeting Rescheduled"
    };
    Api(`company/edit_meeting`, 'POST', body, token)
      .then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            toast.success(Response.data.message);
            closeM()
            fun1("meetings")
          }
          // console.log('======edit_meeting=====>', Response);


        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        // console.log('===========', err);
      });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '500px',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // flexDirection: 'row',
    display: 'flex',
    gap: 2,
  };
  const style2 = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  };
  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: 'white',
  }));

  const editSales = async (row) => {
    const id = row._id;
    const token = localStorage.getItem('token');
    if (value.companyName !== '') {
      const body = {
        clientId: id,
        companyName: value.companyName,
        clientName: value.companyName,
        email: value.email,
        linkedinUrl: value.linkedinUrl,
        contactPersonName: value.contactPersonName,
        websiteUrl: value.websiteUrl,
        contactNoWithISD: value.contactNo,
        domain: value.domain,
        designation: value.designation,
        employeeNo: value.employeeNo,
        location: cityy,
        // additionalEmail: value.additionalEmail,
        currentStatus: value.status,
        RPO_Staffing: value.rsc,
      };
      Api(`company/edit_sales_client`, 'POST', body, token)
        .then((Response) => {
          // console.log('======edit_sales_client==response=====>', Response);
          if (Response.status === 200) {
            handleClose1();
            toast.success(Response.data.message);
            // row.companyName = "muza"
            row.companyName = value.companyName;
            row.clientName = value.companyName;
            row.email = value.email;
            row.linkedinUrl = value.linkedinUrl;
            row.contactPersonName = value.contactPersonName;
            row.websiteUrl = value.websiteUrl;
            row.contactNoWithISD = value.contactNo;
            row.domain = value.domain;
            row.designation = value.designation;
            row.employeeNo = value.employeeNo;
            row.location = cityy;
            // additionalEmail: value.additionalEmail,
            row.currentStatus = value.status;
            row.RPO_Staffing = value.rsc;
            // fun();
            // searchDataFun1(data)
            // editFun1()
            // console.log('======getUser===data.data pdata====>', Response);
          }
        })
        .catch((err) => {
          toast.error(err, +'!');
          // console.log('===========', err);
        });
    } else {
      setCompanyerr('true');
    }
  };
  const schMeeting = async () => {
    if (statusch === "converted") {
      const token = localStorage.getItem('token');
      Api('company/generate/id', 'GET', '', token).then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            // console.log('Response', Response.data.clientCompanyId)
            schMeetingg(Response.data.clientCompanyId)
          }
        }
      })
    } else {
      schMeetingg()
    }

  }
  const schMeetingg = async (ccId) => {
    const token = localStorage.getItem('token');
    const body = {
      clientCompanyId: ccId,
      clientId: row._id,
      guestEmail: statusch === "meetings" ? gemail : "",
      cc: statusch === "meetings" ? ccc : "",
      subject: statusch === "meetings" ? subline : "",
      dateOfMeeting: statusch === "meetings" ? meetingDate : "",
      // startTime: statusch === "meetings" ? startTimee : "",
      startTime: statusch === "meetings" ? new Date(startTimee).toLocaleString('en-US', 
        { hour: '2-digit', minute: '2-digit', hour12: true }) : "",
      // endTime: statusch === "meetings" ? endTimee : "", 
      endTime: statusch === "meetings" ? new Date(endTimee).toLocaleString('en-US', 
        { hour: '2-digit', minute: '2-digit', hour12: true }) : "",
      timeZone: statusch === "meetings" ? timez : "",
      modeOfMeeting: statusch === "meetings" ? meetingMode : "",
      phone: statusch === "meetings" && meetingMode === "phone" ? pNumber : "",
      meetingPlatform: statusch === "meetings" && meetingMode === "conference" ? vPlatform : "",
      meetingLink: statusch === "meetings" && meetingMode === "conference" ? mUrl : "",
      meetingAddress: statusch === "meetings" && meetingMode === "face to face" ? address : "",
      currentStatus: statusch,
      status: statusch === "new" ? "new" : "" || statusch === "favourites" ? "Moved To Favourites" : "" || statusch === "pipeline" ? "Moved To Pipeline" : "" || statusch === "meetings" ? "Meeting Scheduled" : "" || statusch === "meetings" ? "Meeting Scheduled" : "" || statusch === "converted" ? "Client Engaged" : "" || statusch === "passiveclient" ? "Moved To Passive Client" : "" || statusch === "dnc" ? "Moved To DNC" : "",
      date: statusch !== "meetings" ? datee : "",
      connectDate: statusch === "passiveclient" ? datee : "",
      additionalNotes: statusch === "meetings" && meetingNote,
      note: statusch !== "meetings" ? statusnotes : "",
      startDate: statusch === "converted" ? ConvertedDate : "",
    };
    // console.log("new date====", {date:new Date(datee)})

    Api(`company/add_meeting`, 'POST', body, token)
      .then((Response) => {
        // console.log('======company/add_meeting=====>', Response);
        if (Response.status === 200) {
          handleCloses();
          // setValuee(statusch)
          setStatusch('');
          setStatusnotes('');
          // toast.success(Response.data.message);
          // fun1();
          changeS(index)

          // console.log('======getUser===data.data pdata====>', Response);
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        // console.log('===========', err);
      });
  };

  const handleEmail = (e) => {
    // console.log('======================true=====1=============>>>>>>>>>', evalid);

    setValue({ ...value, email: e.target.value });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e.target.value));
    // setTimeout(() => {
    if (evalid === true) {
      // console.log('=======email valid', evalid);
    } else {
      // console.log('=======email unvalid', evalid);
    }
    // console.log('======================true==================>>>>>>>>>', evalid);
  };
  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getCity = async (val) => {
    const token = localStorage.getItem('token');
    setCityAndZip([])
    const body = {
      country: value.country || "USA",
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          // console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            setCityandstate(response.data.data[0]);
            if (response.data.data[0].zip) {
              setValue({ ...value, zip: response.data.data[0].zip });
            } else {
              setValue({ ...value, zip: '' });
            }
            // console.log('=======city and State', response.data.data[0].zip);
          } else {
            toast.error(response.data.message)
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const childFuc = (e) => {
    setCityAndZip([])
    // setValue([])
    setCityandstate([])
    // console.log('e', e);
    setCityy(e);

    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };

  const fullVal = (val) => {
    // setCityAndZip([])
    // console.log(val);
    setCityy(`${val.city} (${val.state}) (${val.zip || val.postal})`);
  };

  const styleSkill = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { xs: '60%', sm: '50%' },
    width: '50%',
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '50%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if (e.length) {
      const lst = e[e.length - 1]
      if (!lst.match(reg)) {
        setValue({
          ...value,
          contactNo: e,
        })
      }
    } else {
      setValue({
        ...value,
        contactNo: e,
      })
    }

  }

  const changeClientStatus = (val) => {
    const token = localStorage.getItem('token');
    const body = {
      "clientId": row._id,
      "status": row.currentStatus,
      "note": newNote,
    }
    Api(`company/update_client_status`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          const obj = {
            "status": row.currentStatus,
            "note": newNote,
            "noteBy": localStorage.getItem('username'),
            "createdAt": new Date()

          }
          row.notes.push(obj)
          // fun2(obj)
          noteCloses()
          toast.success(Response.data.message);
          // setTempData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const handleCopy = () => {
    toast.success('Copied to clipboard!', {
      position: toast.POSITION.TOP_CENTER
    });
  };


  const pinData = async (value) => {
    // console.log("value================", value)
    // setValuee({ ...valuee, status: 'new' });
    // if (value.email !== '') { 
    // if (value.companyName !== '') {
    const token = localStorage.getItem('token');
    const body = {
      email: value.email,
      additionalEmail: value.additionalEmail,
      companyName: value.companyName,
      clientName: value.companyName,
      linkedinUrl: value.linkedinUrl,
      contactPersonName: value.contactPersonName,
      websiteUrl: value.websiteUrl,
      contactNoWithISD: value.contactNo,
      domain: value.domain,
      designation: value.designation,
      employeeNo: value.employeeNo,
      // location: cityy,
      location: value.city,
      currentStatus: 'new',
      RPO_Staffing: value.rsc,
    };
    // console.log("+++++++++++++++++++++++currentStatus", currentStatus)
    Api(`company/add_sales_client`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // console.log('success') 
          }
          toast.success('Data successfully pinned!');
        }
      })
  };
  // const pinData = async (row) => {
  //   // const skillLst = [];
  //   // finallst.map((item) => {
  //   //   return skillLst.push(item.title);
  //   // });

  //   const skillLst = [];
  //   finallst?.map((item) => {
  //     return skillLst.push(item.title);
  //   });
  //   console.log('lst', skillLst);

  //   console.log("row", row);
  //   // console.log('lst', skillLst);

  //   const token = localStorage.getItem('token');
  //   const body = {
  //     // "newCV": "no",
  //     email: row.email,
  //     fullName: row.clientName,
  //     jobTitle: row.designation,
  //     phone: row.phone,
  //     currentCompany: row.currCompany,
  //     skill: skillLst,
  //     visaType: row.visa,
  //     taxTerm: row.taxTerm,
  //     chargeType: row.chargeType,
  //     expectedCompensation: row.expectedCompensation,
  //     // cityOrZipcode: row.cityOrZipcode,
  //     linkedinUrl: row.linkedinUrl,
  //     reallocate: row.reallocate,
  //     // zipcode: row.zipcode,
  //     // stateCode: row.stateCode,
  //     resumeUrl: row.resumeUrl,
  //     jobId: row.jobid,
  //     note: row.notes,
  //     status: row.status,
  //     userStatus: row.userStatus,
  //     // country: row.country,
  //   };
  //   console.log('=====create_resume==========>>>>>>>', finallst);
  //   Api(`resume/create_resume`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('=====create_resume==========>>>>>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           console.log('success')
  //         }
  //       }
  //       toast.success('Data successfully pinned!');
  //     })
  // };

  const parseAndFormatDate = (dateString) => {
    if (!dateString) return 'No Date';

    const date = moment(dateString, moment.ISO_8601, true);

    return date.isValid() ? date.format('lll') : 'Invalid Date';
  };

  const parseAndFormatDate1 = (dateString) => {
    if (!dateString) return 'No Date';
  
    const date = moment(dateString, moment.ISO_8601, true);
  
    return date.isValid() ? date.format('LL') : 'Invalid Date';
  };
  
  
  const optionsDate = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric', 
    timeZone: 'UTC'
};

const optionsTime = { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false,
    timeZone: 'UTC'
};

const optionsDate1 = { year: 'numeric', month: 'short', day: 'numeric' };
const optionsTime1 = { 
  hour: '2-digit', 
  minute: '2-digit', 
  hour12: true, 
  timeZoneName: 'short' 
};

const adminrole = useSelector((state) => state.userData.data.role);
  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        <TableCell sx={{ py: 0 }}>
          {filterTab === "new" && (
            <>
              <Typography sx={{}}>
                {/* updated - {moment(row.createdAt).format('ll')} */}
                {/* Upd - {(row.updatedAt) ? (moment(row.updatedAt).format('lll')) : ''} */}
                Up - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
              </Typography>
              <Typography sx={{}}>
                {/* Created At - {moment(row.newClientCreatedAt).format('ll')}  */}
                {/* Cr - {moment(row.createdAt).format('lll')} */}
                Cr - {`${new Date(row.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
              </Typography>
              <Stack sx={{ flexDirection: 'row' }}>
                <Typography
                  sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
                >
                  Edit

                </Typography>
                <Typography>
                  {(row.currentStatus === "new" || row.currentStatus === "favourites" || row.currentStatus === "pipeline") && pinned && (
                    <Iconify
                      icon="icon-park-solid:pin"
                      color='#2ECC71'
                      sx={{ m: 0.3, ml: 14, fontSize: 7, cursor: 'pointer' }}
                      hover
                      onClick={() => pinData(row)}
                    />
                  )}

                  {/* {pinned && (
                    <Iconify
                      icon="icon-park-solid:pin"
                      // color={'text.secondary'}
                      color='#2ECC71'
                      sx={{ m: 0.3, ml: 14, fontSize: 7, cursor: 'pointer' }}
                      hover
                      onClick={() => pinData(row)}
                    />
                  )} */}
                </Typography>
              </Stack>
            </>
          )}
          {filterTab === "favourites" && (
            <>
              <Typography sx={{ marginTop: "-21px" }}>
                {/* updated on - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')} */}
                {/* updated on - {moment(`${row?.updatedAt}`).format('lll')} */}
                Up on - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
              </Typography>
              <Typography sx={{}}>
                {/* Since - {moment().diff(row.createdAt, 'months')} months */}
                {/* Created At - {moment(row.createdAt).format('ll')}  */}
              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
              >
                Edit
              </Typography>
            </>
          )}
          {filterTab === "pipeline" && (
            <>
              <Typography sx={{ marginTop: "-21px" }}>
                {/* updated on - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')} */}
                {/* Upd - {moment(`${row?.updatedAt}`).format('lll')} */}
                Up - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}
              </Typography>
              <Typography sx={{}}>
                {/* Since - {moment().diff(row.createdAt, 'months')} months */}
                {/* Created At - {moment(row.createdAt).format('ll')}  */}

              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
              >
                Edit
              </Typography>
            </>
          )}
          {filterTab === "passiveclient" && (
            <>
              <Typography sx={{}}>
                {/* Connect On - {moment(`${row.connectDate}`).format('lll')} */}
                Connect On - {`${new Date(row?.connectDate).toLocaleString('en-US', optionsDate)}`}
              </Typography>
              <Typography sx={{}}>
                {/* Upd - {moment(`${row?.notes[row.notes.length - 1]?.createdAt}`).format('lll')} */}
                Up - {`${new Date(row?.notes[row?.notes?.length - 1]?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.notes[row?.notes?.length - 1]?.createdAt).getHours()}`}:{`${new Date(row?.notes[row?.notes?.length - 1]?.createdAt).getMinutes()}`}
                {/* Created At - {moment(row.createdAt).format('ll')}  */}

              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
              >
                Edit
              </Typography>
            </>
          )}


          {filterTab === "meetings" && (
            <>
              <Typography sx={{ cursor: 'pointer', color: "text.secondary" }} onClick={() => getMeeting(row._id)}>
                {/* Meeting On - {moment(`${row.meetingDetails?.dateOfMeeting}`).format('lll')} */}
                Meeting On - {`${new Date(row?.meetingDetails?.dateOfMeeting).toLocaleString('en-US', optionsDate)} 
`}
{/* ${new Date(row?.meetingDetails?.dateOfMeeting).toLocaleString('en-US', optionsTime)} */}
              </Typography>
              <Typography sx={{ cursor: 'pointer', textTransform: 'uppercase' }} onClick={() => getMeeting(row._id)}>
                {/* {moment(`${row?.meetingDetails?.startTime}`).format('LT')} - 
                {moment(`${row?.meetingDetails?.endTime}`).format('LT')}  */}
                {/* {`${new Date(row?.meetingDetails?.startTime).toLocaleString('en-US', optionsDate1)} 
${new Date(row?.meetingDetails?.startTime).toLocaleString('en-US', optionsTime1)}`} - {" "}
{`${new Date(row?.meetingDetails?.endTime).toLocaleString('en-US', optionsDate1)} 
${new Date(row?.meetingDetails?.endTime).toLocaleString('en-US', optionsTime1)}`}

                {row.meetingDetails?.timeZone} */}
                {new Date(row?.meetingDetails?.startTime).toLocaleString('en-US', 
                { hour: '2-digit', minute: '2-digit', hour12: true })}{" - "}

{new Date(row?.meetingDetails?.endTime).toLocaleString('en-US', 
                { hour: '2-digit', minute: '2-digit', hour12: true })}

              </Typography>
              <Typography 
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => openEditM(row._id)}
              >
                Edit Meeting
              </Typography>

            </>
          )}
          {/* {filterTab === "converted" && (
            <>
              <Typography sx={{}}>
                Converted On - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')}
              </Typography>
              <Typography sx={{}}>
                Start on - {moment(`${row.meetingDetails?.startDate}`).format('ll')}
              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
              >
                Edit
              </Typography>
            </>
          )} */}
          {filterTab === "converted" && (
            <>
              <Typography>
                {/* Converted On - {parseAndFormatDate(row?.notes[row?.notes.length - 1]?.date)} */}
                {/* Converted On  - {moment(row?.convertedDate).format('lll')} */}
                Converted On  - {`${new Date(row?.convertedDate).toLocaleString('en-US', optionsDate)}`}
              </Typography>
              <Typography>
                Start on - {parseAndFormatDate1(row?.startDate)}
              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName); }}
              >
                Edit
              </Typography>
            </>
          )}

          {filterTab === "dnc" && (
            <>
              <Typography sx={{ marginTop: "-21px" }}>
                {/* Updated - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')} */}
                {/* Upd - {moment(`${row?.updatedAt}`).format('lll')} */}
                Up - {`${new Date(row?.updatedAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.updatedAt).getHours()}`}:{`${new Date(row?.updatedAt).getMinutes()}`}

              </Typography>
              <Typography sx={{}}>
                {/* Since {moment().diff(row.createdAt, 'months')} months */}
                {/* Created At - {moment(row.createdAt).format('ll')}  */}

              </Typography>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => { handleOpen1(); getAddEmail(row.companyName) }}
              >
                Edit
              </Typography>
            </>
          )}


        </TableCell>
        <TableCell
          //  onClick={() => getComment(row._id)}
          sx={{ py: 0 }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {row.currentStatus === 'dnc' ? (
              <Typography sx={{ fontWeight: '600', cursor: 'pointer', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'red'}>
                {row.companyName}
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: '600', cursor: 'pointer', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}
                color={'text.secondary'}
                // onClick={(e) => navigate('/salesDetail', { state: row })}
                onClick={() => { setComD(row.companyName); salesOpenedModal() }}
              >
                {row.companyName}
              </Typography>
            )}
            <Iconify
              icon="mdi:web"
              color={'text.textB'}
              sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
              hover
              onClick={() => window.open(row.websiteUrl, '_blank')}
            />
          </Stack>
          {row.contactPersonName ? (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{ maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.contactPersonName}</Typography>
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                // color={row.linkedinUrl ? 'text.textG' : 'text.disabled'}
                sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              // onClick={() => {
              //   if (row.linkedinUrl) {
              //     window.open(row.linkedinUrl, '_blank');
              //   }
              // }}
              />
            </Stack>
          ) : (
            <Typography sx={{}} color={'text.textG'}>
              -
            </Typography>
          )}

          <Typography
            //  sx={{ textTransform: 'uppercase' }} 
            color={'text.textG'}>
            {row.RPO_Staffing || '-'}
          </Typography>
          {/* <Typography sx={{  }} color={'text.textG'}>
            {row.currentCompany}
          </Typography> */}
        </TableCell>
        <TableCell sx={{ py: 0 }}>
          <Typography sx={{ textTransform: 'none' }}>{row.designation || '-'}</Typography>
          {row.email ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ textTransform: 'lowercase', maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.email}</Typography>
              <CopyToClipboard text={row.email} onCopy={() => setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography sx={{ textTransform: 'lowercase' }} color={'text.textG'}>
              -
            </Typography>
          )}
          {/* <Typography sx={{ textTransform:'lowercase' }} color={'text.textG'}>
            {row.additionalEmail}
          </Typography> */}
          {row.contactNoWithISD ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ maxwidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
                {/* {row.contactNoWithISD} */}
                {/* {row?.contactNoWithISD?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} */}
                {
    row?.contactNoWithISD?.trim()
    ? row?.contactNoWithISD?.trim()
        ?.replace(/\s+/g, '')
        ?.replace(/^(\+?\d{1,3})?(\d{10,20})$/, (match, p1, p2) => {
          if (p1) {
            const countryCode = p1;
            const lastDigits = p2?.slice(-10);
            return `${countryCode} ${lastDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
          }
          return p2?.replace(/(\d{3})(\d{3})(\d{4})(\d{3,4})?/, '$1 $2 $3 $4')?.trim();
        })
    : ''
  }
              </Typography>
              <CopyToClipboard text={row?.contactNoWithISD?.replace(/(\d{3})(?=\d)/g, '$1 ').trim()} onCopy={() => setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy ? 'text.secondary' : 'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography sx={{ textTransform: 'lowercase' }} color={'text.textG'}>
              -
            </Typography>
          )}
        </TableCell>

        <TableCell sx={{ py: 0 }}>
          <Typography sx={{ maxwidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.location || '-'}</Typography>
          <Typography sx={{ maxwidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.domain || '-'}</Typography>
          <Typography sx={{}} color={'text.textG'}>
            {row.employeeNo || '-'}
          </Typography>
        </TableCell>
        <TableCell >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}
          onClick={(e) => handleOpenn()}
          >
            <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
            <Typography
              sx={{ color: 'text.secondary', cursor: 'pointer', py: 0 }}
              // onClick={(e) => handleOpenn()}
            >
              {row.notes.length ? `+${row.notes.length}` : ''}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ py: 0 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography sx={{}}>{row.currentStatus}</Typography>

            {/* <Box sx={{ paddingTop: 1.5 }}> */}
            <Iconify
              icon="bx:edit"
              sx={{ width: 15, height: 15 }}
              style={{ cursor: 'pointer' }}
              onClick={(e) => setOpens(true)}
            />
            {/* </Box> */}

          </Stack>

          <Typography sx={{}}>-</Typography>
          <Typography sx={{}} color={'text.textG'}>
            {row.addedBy ? row.addedBy : "-"}
          </Typography>
        </TableCell>
        {/* <TableCell sx={{ , maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography variant="body" sx={{ maxWidth: 100 }}>
            {row.notes.length ? row.notes[0].note : ''}
          </Typography>
          {row.notes.length ? (
            <Typography
              sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer',  }}
              onClick={handleOpenclient}
            >
              more
            </Typography>
          ) : null}
        </TableCell> */}
        <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {/* <Button color="custom" type="submit" variant="contained" size="small" onClick={() => handleOpen()}>
            Edit
          </Button> */}
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="ic:baseline-email" fontSize={22} />
            {/* <Typography sx={{ ml: 0.5 }}>Email</Typography> */}
            {/* <Typography sx={{ ml: 0.5 }} onClick={() => { navigate('/Email') }}>Email</Typography> */}
            <Typography sx={{}} onClick={() => handleOpenModal((row._id))}>Email</Typography>
          </Stack>
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="bx:support" fontSize={22} />
            <Typography sx={{ ml: 0.5 }}>Call / Text</Typography>
          </Stack>
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="fluent:note-16-filled" fontSize={22} />
            <Typography sx={{ ml: 0.5 }} onClick={() => { setNewNote(''); noteOpen() }}>Notes</Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={styleSkill}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            Edit Prospect
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                  />
                </FormControl>

                <TextField
                  error={companyerr}
                  required
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.companyName}
                  onChange={(e) => {
                    setValue({ ...value, companyName: e.target.value });
                    if (value.companyName.length > 4) {
                      setCompanyerr(false);
                    } else {
                      setCompanyerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Website"
                  placeholder="Website"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.websiteUrl}
                  onChange={(e) => setValue({ ...value, websiteUrl: e.target.value })}
                />
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Contact Name"
                    placeholder="Contact Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.contactPersonName}
                    // type='number'
                    onChange={(e) => setValue({ ...value, contactPersonName: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Designation"
                    placeholder="Designation"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.designation}
                    // type='number'
                    onChange={(e) => setValue({ ...value, designation: e.target.value })}
                  />
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.linkedinUrl}
                  onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                />
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Contact Number"
                    placeholder="Contact Number"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.contactNo}
                    // type="number"
                    // onChange={(e) => setValue({ ...value, contactNo: e.target.value })}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.domain}
                    label="Domain"
                    // InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, domain: e.target.value })}
                  >
                    {Domains.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}

                  </Select>
                </FormControl>

                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">RPO/Staffing/Consulting</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    placeholder="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    value={value.rsc}
                    onChange={(e) => setValue({ ...value, rsc: e.target.value })}
                  >
                    <MenuItem value={'RPO'}>RPO</MenuItem>
                    <MenuItem value={'Staffing'}>Staffing</MenuItem>
                    <MenuItem value={'Consulting'}>Consulting</MenuItem>
                    <MenuItem value={'MSP'}>MSP</MenuItem>
                    <MenuItem value={'Contingent'}>Contingent</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">No of Employees</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Start Typing Domain"
                    placeholder="Start Typing Domain"
                    value={value.employeeNo}
                    onChange={(e) => setValue({ ...value, employeeNo: e.target.value })}
                  >
                    <MenuItem value={'0-10'}>0-10</MenuItem>
                    <MenuItem value={'10-25'}>10-25</MenuItem>
                    <MenuItem value={'25-50'}>25-50</MenuItem>
                    <MenuItem value={'50-100'}>50-100</MenuItem>
                    <MenuItem value={'100-250'}>100-250</MenuItem>
                    <MenuItem value={'250-500'}>250-500</MenuItem>
                    <MenuItem value={'more than 500'}>more than 500</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">Current Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Current Status"
                    placeholder="Current Status"
                    value={value.status}
                    disabled
                    onChange={(e) => setValue({ ...value, status: e.target.value })}
                  >
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'meetings'}>Meetings</MenuItem>
                    <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                    <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                    <MenuItem value={'favourites'}>Favourites</MenuItem>
                    <MenuItem value={'converted'}>Converted</MenuItem>
                    <MenuItem value={'dnc'}>DNC</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <FormControl fullWidth>
              <TextField
                disabled
                id="outlined-basic"
                label="Additional Contact"
                placeholder="Additional Contact"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={addEmails}
              // onChange={(e) => setValue({ ...value, additionalEmail: e.target.value })}
              />
            </FormControl>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => editSales(row)}>
                Save
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={opens} onClose={handleCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid container sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} gap={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
                <Select
                  disabled={convertedDis}
                  // disabled={adminrole !== "admin"}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select New Status"
                  InputLabelProps={{ shrink: true }}
                  value={statusch}
                  onChange={(e) => setStatusch(e.target.value)}
                >
                  <MenuItem value={'new'}>New</MenuItem>
                  <MenuItem value={'meetings'}>Meetings</MenuItem>
                  <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                  <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                  <MenuItem value={'favourites'}>Favourites</MenuItem>
                  <MenuItem value={'converted'}>Converted</MenuItem>
                  <MenuItem value={'dnc'}>DNC</MenuItem>
                </Select>
              </FormControl>
              {statusch === 'passiveclient' && (
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        // disablePast
                        sx={{ width: '100%' }}
                        label="Select Next Connect Date"
                        value={datee}
                        onChange={(e) => {
                          setDatee(e);
                          // console.log('date----------', e);
                          // setDatee(e.$D+ e.$M + 1  e.$y)
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              )}
              {statusch === 'converted' && (
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        // disablePast
                        sx={{ width: '100%' }}
                        label="Start Date"
                        // disabled={convertedDis}
                        // disabled={statusch === 'converted'}
                        value={ConvertedDate}
                        onChange={(e) => {
                          setConvertedDate(e);
                          // console.log('date----------', e);
                          // setDatee(e.$D+ e.$M + 1  e.$y)
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              )}
              {statusch === 'meetings' && (
                <>
                  <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Guest Email"
                        placeholder="Guest Email"
                        value={gemail}
                        onChange={(e) => setGemail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="CC"
                        placeholder="CC (optional)"
                        value={ccc}
                        onChange={(e) => setCcc(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Subject Line"
                      placeholder="Subject Line"
                      value={subline}
                      onChange={(e) => setSubline(e.target.value)}
                    />
                  </FormControl>
                  <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            disablePast
                            sx={{ width: '100%' }}
                            label="Meeting Date"
                            value={meetingDate}
                            onChange={(e) => {
                              setMeetingDate(e);
                              // console.log('date----------', e);
                              // setDatee(e.$D+ e.$M + 1  e.$y)
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            sx={{ width: '100%' }}
                            label="Start Time"
                            value={startTimee}
                            onChange={(e) => {
                              setStartTimee(e);
                              // setItime({ ...itime, hrs: e.$H, min: e.$m });
                              console.log('===============time===', e);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>

                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            sx={{ width: '100%' }}
                            label="End Time"
                            value={endTimee}
                            onChange={(e) => {
                              setEndTimee(e);
                              // setItime({ ...itime, hrs: e.$H, min: e.$m });
                              console.log('===============time===', e.$H, ':', e.$m);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Time zone</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Time Zone"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Time zone"
                        value={timez}
                        onChange={(e) => setTimez(e.target.value)}
                      >
                        <MenuItem value={'est'}>EST</MenuItem>
                        <MenuItem value={'pst'}>PST</MenuItem>
                        <MenuItem value={'mst'}>MST</MenuItem>
                        <MenuItem value={'cst'}>CST</MenuItem>
                      </Select>
                    </FormControl>

                  </Stack>
                  <Grid container gap={1} sx={{ width: '100%' }} columns={2}>

                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-simple-select-label">Meeting Mode</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        InputLabelProps={{ shrink: true }}
                        label="Meeting Mode"
                        placeholder="Meeting Mode"
                        value={meetingMode}
                        onChange={(e) => setMeetingMode(e.target.value)}
                      >
                        <MenuItem value={'phone'}>Phone</MenuItem>
                        <MenuItem value={'conference'}>Video Conference</MenuItem>
                        <MenuItem value={'face to face'}>Inperson Meeting</MenuItem>

                      </Select>
                    </FormControl>
                    {meetingMode === 'phone' && (
                      <FormControl sx={{ width: '100%' }}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Phone Number"
                          placeholder="Note..."
                          value={pNumber}
                          onChange={(e) => setPNumber(e.target.value)}
                        />
                      </FormControl>
                    )}
                    {meetingMode === 'conference' && (
                      <>
                        {/* <FormControl sx={{ width: '100%'}}>
                        <InputLabel id="demo-simple-select-label">IMs / Video Platform</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="IMs / Video Platform"
                          value={vPlatform}
                          onChange={(e) => setVPlatform(e.target.value)}
                        >
                          <MenuItem value="microsoftTeam">Microsoft Team</MenuItem>
                          <MenuItem value="googlemeet">Google Meet</MenuItem>
                          <MenuItem value="zoom">Zoom</MenuItem>
                          <MenuItem value="slack">Slack</MenuItem>
                          <MenuItem value="dialpadMeetings">Dialpad Meetings</MenuItem>
                          <MenuItem value="trueConfOnline">TrueConf Online</MenuItem>
                          <MenuItem value="freeConference">Free Conference</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
             </FormControl> */}

                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Meeting URL"
                            placeholder="Meeting URL"
                            value={mUrl}
                            onChange={(e) => setMurl(e.target.value)}
                          />
                        </FormControl>
                      </>
                    )}
                    {meetingMode === 'face to face' && (
                      <FormControl sx={{ width: '100%' }}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Address"
                          placeholder="Note..."
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </FormControl>
                    )}
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Additional Note..."
                        placeholder="Additional Note..."
                        multiline
                        rows={5}
                        value={meetingNote}
                        onChange={(e) => setMeetingNote(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {statusch !== 'meetings' && (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Note... (Max 2000 char)"
                    placeholder="Note..."
                    multiline
                    rows={5}
                    value={statusnotes}
                    onChange={(e) => setStatusnotes(e.target.value)}
                  />
                </FormControl>
              )}
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={(e) => schMeeting(row)}>
                Submit
              </Button>

            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '70%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
              <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '21%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Date
                </Typography>
                <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  User
                </Typography>
                <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Status
                </Typography>
                <Typography sx={{ width: '55%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Note
                </Typography>
              </Stack>
            </Stack>
            {row.notes.length &&
              row.notes.slice(0).reverse().map((item, index) => {
                return (
                  <Stack
                    sx={[
                      { background: index % 2 === 0 ? 'text.background' : tablebg },
                      { flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                    ]}
                  >
                    <Typography sx={{ width: '21%' }}>
                     
                       {`${new Date(item?.createdAt).toLocaleString('en-US', optionsDate)} 
 ${new Date(row?.createdAt).getHours()}`}:{`${new Date(row?.createdAt).getMinutes()}`}
 
                      </Typography>
                    <Typography sx={{ width: '15%', color: 'text.secondary', textTransform: 'capitalize', fontWeight: '600' }}>{item.noteBy}</Typography>
                    {item.status === 'dnc' ? (
                      <Typography sx={{ width: '25%' }}>DNC</Typography>
                    ) : null}
                    {item.status === 'passiveclient' ? (
                      <Typography sx={{ width: '25%' }}>Passive Client</Typography>
                    ) : null}
                    {item.status !== 'dnc' && item.status !== 'passiveclient' ? (
                      <Typography sx={{ width: '25%' }}>{item.status}</Typography>
                    ) : null}
                    <Typography sx={{ width: '55%', textTransform: 'capitalize' }}>
                      {/* <div dangerouslySetInnerHTML={{ __html: item.note }}/>
                       */}

                      {item.note}
                    </Typography>
                  </Stack>
                );
              })}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openclient} onClose={handleCloseclient} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }} color={'#3C6BA5'} textAlign={'center'}>
            Note
          </Typography>
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              {/* <Stack sx={{ backgroundColor: 'text.hover', width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '50%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="h5" textAlign={'center'} sx={{ p: 2 }}>
                    Client
                  </Typography>
                  <Typography variant="h5" textAlign={'center'} sx={{ p: 2 }}>
                    Status
                  </Typography>
                </Stack>
              </Stack> */}
              <Typography textAlign={'center'} sx={{ p: 2 }}>
                {row.notes.length && row.notes[0].note}
              </Typography>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openmeeting} onClose={handleCloseM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '70%' }]}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Button
              color="custom"
              variant="contained"
              size='small'
              onClick={() => { handleCloseM(); openEditM(row._id) }}
            >
              Edit Meeting
            </Button>
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ textTransform: 'capitalize', mt: -3 }} textAlign={'center'}>
            Meeting Detail
          </Typography>

          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'text.hover', p: 1 }}>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Date
                </Typography>
              </Stack>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Time
                </Typography>
              </Stack>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Mode
                </Typography>
              </Stack>

            </Stack>

            <Stack
              sx={[
                { background: index % 2 === 0 ? 'text.background' : tablebg },
                { flexDirection: 'row', justifyContent: 'space-between', p: 1 },
              ]}
            >
              <Typography sx={{ width: '30%' }}>
                {/* {moment(meetingData?.dateOfMeeting).format('lll')} */}
                {`${new Date(meetingData?.dateOfMeeting).toLocaleString('en-US', optionsDate)}`}
                </Typography>
              <Typography sx={{ width: '30%', textTransform: 'uppercase' }}>{moment(meetingData?.startTime).format('LT')} - {moment(meetingData?.endTime).format('LT')} {meetingData?.timeZone}</Typography>
              <Stack sx={{ flexDirection: "row", width: '30%', textTransform: 'capitalize', overflow: 'hidden' }}>
                {meetingData?.modeOfMeeting === 'phone' && (
                  <>
                    <Typography sx={{ textTransform: 'capitalize' }}>Phone - ({meetingData?.phone})</Typography>
                    <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('lll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                       
                          Mode: ${"Phone"} - ${meetingData.phone},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                      <Iconify
                        icon="radix-icons:copy"
                        color={'text.textG'}
                        sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                        hover
                      />
                    </CopyToClipboard>
                  </>
                )}
                {meetingData?.modeOfMeeting === 'conference' && (
                  <>
                    <Typography sx={{ textTransform: 'capitalize' }}>Video Conference - </Typography>


                    <Typography sx={{ textTransform: 'capitalize', color: 'text.secondary' }}
                      onClick={() => { window.location.href = meetingData.meetingLink }}
                    >
                      &nbsp; meeting URL
                    </Typography>
                    {/* <a href={meetingData.meetingLink} target="_blank" rel="noreferrer">
                          &nbsp; Meeting link
                          </a> */}

                    <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('lll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                     
                          Mode: ${"Video Conference"} - ${meetingData.meetingLink},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                      <Iconify
                        icon="radix-icons:copy"
                        color={'text.textG'}
                        sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                        hover
                      />
                    </CopyToClipboard>
                  </>
                )}
                {meetingData?.modeOfMeeting === 'face to face' && (
                  <>
                    <Typography sx={{ textTransform: 'capitalize', maxWidth: '90%' }}>Inperson Meeting - {meetingData.meetingAddress}</Typography>
                    <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('lll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                       
                          Mode: ${"Inperson Meeting"} - ${meetingData.meetingAddress},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                      <Iconify
                        icon="radix-icons:copy"
                        color={'text.textG'}
                        sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                        hover
                      />
                    </CopyToClipboard>
                  </>
                )}

              </Stack>
            </Stack>


          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={editM} onClose={closeM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Meetings
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid container sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} gap={2}>
              <Stack sx={{ flexDirection: 'row', gap: 1, width: '100%', justifyContent: 'space-between' }}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Guest Email"
                    placeholder="Guest Email"
                    value={editgemail}
                    onChange={(e) => setEditgemail(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="CC"
                    placeholder="CC (optional)"
                    value={editccc}
                    onChange={(e) => setEditccc(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="outlined-multiline-static"
                  label="Subject Line"
                  placeholder="Subject Line"
                  value={editsubline}
                  onChange={(e) => setEditsubline(e.target.value)}
                />
              </FormControl>
              <Stack sx={{ flexDirection: 'row', gap: 1, width: '100%', justifyContent: 'space-between' }}>
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        disablePast
                        sx={{ width: '100%' }}
                        label="Meeting Date"
                        value={editmeetingDate}
                        onChange={(e) => {
                          setEditmeetingDate(e);
                          // console.log('date----------', e);
                          // setDatee(e.$D+ e.$M + 1  e.$y)
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <TimePicker
                        sx={{ width: '100%' }}
                        label="Start Time"
                        value={editstartTimee}
                        onChange={(e) => {
                          setEditstartTimee(e);
                          // setItime({ ...itime, hrs: e.$H, min: e.$m });
                          // console.log('===============time===', e.$H, ':', e.$m);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>


              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 1, width: '100%', justifyContent: 'space-between' }}>

                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <TimePicker
                        sx={{ width: '100%' }}
                        label="End Time"
                        value={editendTimee}
                        onChange={(e) => {
                          setEditendTimee(e);
                          // setItime({ ...itime, hrs: e.$H, min: e.$m });
                          // console.log('===============time===', e.$H, ':', e.$m);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-autowidth-label">Time zone</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    label="Time Zone"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Time zone"
                    value={edittimez}
                    onChange={(e) => setEdittimez(e.target.value)}
                  >
                    <MenuItem value={'est'}>EST</MenuItem>
                    <MenuItem value={'pst'}>PST</MenuItem>
                    <MenuItem value={'mst'}>MST</MenuItem>
                    <MenuItem value={'cst'}>CST</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Meeting Mode</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  InputLabelProps={{ shrink: true }}
                  label="Meeting Mode"
                  placeholder="Meeting Mode"
                  value={editmeetingMode}
                  onChange={(e) => setEditmeetingMode(e.target.value)}
                >
                  <MenuItem value={'phone'}>Phone</MenuItem>
                  <MenuItem value={'conference'}>Video Conference</MenuItem>
                  <MenuItem value={'face to face'}>Inperson Meeting</MenuItem>

                </Select>
              </FormControl>
              {editmeetingMode === 'phone' && (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Phone Number"
                    placeholder="Note..."
                    value={editpNumber}
                    onChange={(e) => setEditpNumber(e.target.value)}
                  />
                </FormControl>
              )}
              {editmeetingMode === 'conference' && (
                <>
                  {/* <FormControl sx={{ width: '100%'}}>
                        <InputLabel id="demo-simple-select-label">IMs / Video Platform</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="IMs / Video Platform"
                          value={editvPlatform}
                          onChange={(e) => setEditvPlatform(e.target.value)}
                        >
                          <MenuItem value="microsoftTeam">Microsoft Team</MenuItem>
                          <MenuItem value="googlemeet">Google Meet</MenuItem>
                          <MenuItem value="zoom">Zoom</MenuItem>
                          <MenuItem value="slack">Slack</MenuItem>
                          <MenuItem value="dialpadMeetings">Dialpad Meetings</MenuItem>
                          <MenuItem value="trueConfOnline">TrueConf Online</MenuItem>
                          <MenuItem value="freeConference">Free Conference</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
             </FormControl> */}

                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Meeting URL"
                      placeholder="Meeting URL"
                      value={editMurl}
                      onChange={(e) => setEditMurl(e.target.value)}
                    />
                  </FormControl>
                </>
              )}
              {editmeetingMode === 'face to face' && (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Address"
                    placeholder="Note..."
                    value={editaddress}
                    onChange={(e) => setEditaddress(e.target.value)}
                  />
                </FormControl>
              )}
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="outlined-multiline-static"
                  label="Additional Note..."
                  placeholder="Additional Note..."
                  multiline
                  rows={5}
                  value={editmeetingNote}
                  onChange={(e) => setEditmeetingNote(e.target.value)}
                />
              </FormControl>


            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={(e) => editmeetingData(row._id)}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={upnote} onClose={noteCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Update Note
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl fullWidth mt={1}>
                <TextField
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                color="custom"
                variant="contained"
                sx={{ width: '10%', mt: 2 }}
                onClick={() => changeClientStatus()}

              >
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={modalOpen} onClose={handleCloseModal} style={{ borderRadius: 10 }}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '70%' },
          // width: { xs: '85%', sm: '60%' },
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}
        >
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Send Email
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            {/* <Divider sx={{ mb: 2 }} /> */}

            <Grid container spacing={1} mt={1}>
              <Grid item xs={5.9}>
                <TextField
                  fullWidth
                  required
                  label="To"
                  variant="outlined"
                  size="small"
                  // value={to}
                  value={recipientEmail}
                  // onChange={(e) => setTo(e.target.value)}
                  onChange={(e) => setRecipientEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={5.9}>
                <Autocomplete
                  size='small'
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setCcEmail([...newValue]);
                  }}
                  options={[{ title: '' }]}
                  getOptionLabel={(option) => option.title}
                  value={ccEmail.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Cc" placeholder="Cc"

                    size="small"
                  />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={5.9}>
                <Autocomplete
                  size='small'
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setBccEmail([...newValue]);
                  }}
                  options={[{ title: '' }]}
                  getOptionLabel={(option) => option.title}
                  value={bccEmail.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="BCc" placeholder="BCc"

                    size="small"
                  />}
                />

              </Grid>

              <Grid item xs={5} sx={{}}>
                <FormControl variant="outlined" fullWidth size="small" >
                  <InputLabel >Select Template</InputLabel>
                  <Select
                    label="Select Template"
                    // value={formValues.resource}
                    onChange={(e) => handleTemplateSelect(e.target.value)}

                  >
                    {tempList && tempList?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Typography
                // component={Button}
                // onClick={() => { navigate('/Email'),{ state: { undid:"undid" } } }}
                onClick={() => navigate('/Email', { state: { undid: "undid" } })}

                sx={{
                  color: '#23A6F0',
                  fontWeight: 'bold',
                  fontSize: '17px',
                  marginLeft: '30px',
                  marginTop: '18px',
                  cursor: 'pointer',
                }}
              >
                Edit
              </Typography>
            </Grid>
            <Grid container alignItems="center" style={{ flexDirection: 'row' }}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ml: 1, color: '#1E74B3' }}>Attachment:</Typography>
                <input type="file" onChange={FileChange} style={{ marginLeft: '9px' }} />
                {/* <Button variant="contained" onClick={UploadFile}>
        Upload
    </Button> */}
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={11.8}>
                <TextField
                  label='Sub'
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>

              <Grid item xs={11.8}>
                <CKEditor
                  data={emailBody}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEmailBody(data);
                  }}
                  editor={ClassicEditor}
                />
              </Grid>
            </Grid>
            <Stack flexDirection={'row'} gap={1.4} mt={1} sx={{}}>
              <Typography sx={{ ml: 2.5, mt: 1, mb: 0.5, fontSize: '1rem', fontWeight: 'bold' }}>Automailer</Typography>
              <Switch checked={checked} onChange={handleChangebtn} inputProps={{ 'aria-label': 'controlled' }} />

            </Stack>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail1}
                  onChange={handleChangeEmail1}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8}>
                <TextField
                  disabled
                  label="Email 1"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub1}
                  InputProps={{ style: { borderRadius: '10px' } }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time1}
                      onChange={(newValue) => setTime1(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date1}
                  onChange={(e) => setDate1(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                />
              </Grid>
              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>
            </Grid>

            <Modal
              open={openModal}
              onClose={handleModalClose}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box sx={{
                width: '24%',
                height: '53%',
                overflowY: 'auto',
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px solid #1E74B3'
              }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    date={date1}
                    onChange={handleDateSelect1}
                  />
                </LocalizationProvider>
              </Box>
            </Modal>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail2}
                  onChange={handleChangeEmail2}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8} >
                <TextField
                  disabled
                  label="Email 2"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub2}
                  InputProps={{ style: { borderRadius: '10px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>
              {/* <Grid item xs={2}>
                <FormControl sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <TimePicker
                        slotProps={{ textField: { size: 'small' } }}
                        value={time2}
                        onChange={(e) => {
                          setTime2(e);
                          // setItime({ ...itime, hrs: e.$H, min: e.$m });
                          // console.log('===============time===', e.$H, ':', e.$m);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Grid> */}
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time2}
                      onChange={(newValue) => setTime2(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>

                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date2}
                  onChange={(e) => setDate2(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>

              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen1}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>

              <Modal
                open={openModal1}
                onClose={handleModalClose1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{
                  width: '24%',
                  height: '53%',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '2px solid #1E74B3'

                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      date={date2}
                      onChange={(newDate) => handleDateSelect2(newDate)}
                    />
                  </LocalizationProvider>

                </Box>
              </Modal>

            </Grid>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Checkbox
                  checked={checkedEmail3}
                  onChange={handleChangeEmail3}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
              </Grid>
              <Grid item xs={5.8}>

                <TextField
                  disabled
                  label="Email 3"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={sub3}
                  // onChange={(e) => setEmail2(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      slotProps={{ textField: { size: 'small' } }}
                      value={time3}
                      onChange={(newValue) => setTime3(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={date3}
                  onChange={(e) => setDate3(e.target.value)}
                  InputProps={{ style: { borderRadius: '10px', paddingLeft: '5px' } }}
                // InputLabelProps={{ style: { marginLeft: '14px' } }}
                />
              </Grid>

              <Grid item xs="auto">
                <IconButton
                  onClick={handleModalOpen2}
                  sx={{
                    color: '#1E74B3',
                    padding: '10px',
                    borderRadius: '15%',
                    width: '90px',
                    height: '35px',
                    marginTop: '7%',
                    backgroundColor: '#1E74B3',
                    '&:hover': { backgroundColor: '#E6F3FD' }
                  }}
                >
                  <Icon icon="noto:calendar" />
                </IconButton>
              </Grid>

              <Modal
                open={openModal2}
                onClose={handleModalClose2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{
                  width: '24%',
                  height: '53%',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '2px solid #1E74B3',

                }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      date={date3}
                      onChange={(newDate) => handleDateSelect3(newDate)}
                    />
                  </LocalizationProvider>
                </Box>
              </Modal>

            </Grid>
            {/* {recipientEmail!=='' && subject!==""?(
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={SendEmail}>
                Send
              </Button>
            </Stack>
):null} */}

            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button
                color="custom"
                variant="contained"
                onClick={SendEmail}
                disabled={recipientEmail === '' || subject === ''}
              >
                Send
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={salesOpenModal} onClose={salesClosedModal}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '99%', sm: '80%' },
          // width: { xs: '85%', sm: '60%' },
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}
        >
          <>
            <ToastContainer />
            <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
              <Stack flexDirection={'row'} gap={1} alignItems={'center'} color={'text.secondary'}>
                <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <Typography variant="h5" color={'text.secondary'} sx={{ textTransform: 'capitalize' }}>
                  {row.companyName}'s Details
                </Typography>
              </Stack>
              <Stack flexDirection={'row'} gap={1}>
                <Button color="custom" variant="contained" size="small" onClick={handleOpennn}>
                  Add New Contact
                </Button>
              </Stack>
            </Stack>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Designation</TableCell>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Linkedin</TableCell>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Phone</TableCell>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Email</TableCell>
                    <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <ManagementRow key={row._id} row={row} id={id} fun={funclick} />
                    // console.log({row})
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              open={opennn}
              onClose={handleCloseee}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1}>
                <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
                  <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      placeholder="Name"
                      variant="outlined"
                      value={formValuesAddComapnny.name}
                      onChange={(e) =>
                        setFormValuesAddCompany({
                          ...formValuesAddComapnny,
                          name: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Designation"
                      placeholder="Designation"
                      variant="outlined"
                      value={formValuesAddComapnny.designation}
                      onChange={(e) =>
                        setFormValuesAddCompany({
                          ...formValuesAddComapnny,
                          designation: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Linkedin"
                      placeholder="Linkedin"
                      variant="outlined"
                      value={formValuesAddComapnny.linkedin}
                      onChange={(e) =>
                        setFormValuesAddCompany({
                          ...formValuesAddComapnny,
                          linkedin: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      placeholder="Phone"
                      variant="outlined"
                      value={formValuesAddComapnny.phone}
                      // inputProps={{ type: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
                      onChange={(e) => CheckContact(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Email Id"
                      placeholder="Email Id"
                      variant="outlined"
                      value={formValuesAddComapnny.email}
                      onChange={(e) =>
                        setFormValuesAddCompany({
                          ...formValuesAddComapnny,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Scrollbar>
                <Stack flexDirection={'row'} gap={1} mt={2}>
                  <Button color="custom" variant="contained" onClick={(e) => addContact(e)}>
                    Add
                  </Button>
                </Stack>
              </Box>
            </Modal>
          </>
        </Box>
      </Modal>

    </>
  );
}

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const HEADER_MOBILE1 = 150;
const HEADER_DESKTOP1 = 300;

const StyledSearchbar = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  // boxShadow: theme.customShadows.z8,
  backdropFilter: 'blur(100px)', // Apply backdrop filter for blurring effect
  transition: 'backdrop-filter 0.3s ease', // Smooth transition for the effect
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ModalContent = styled('div')(({ theme }) => ({
  marginTop: '220px',
  width: '100%',
  display: 'flex',
  marginRight: '50px',

  height: HEADER_MOBILE1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP1,
    padding: theme.spacing(0, 5),
  },
}));

const StyledIconButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
});

function Search({ searchData, func, funcc, funnc, editt1, hideClosebtn1, onData, resetSearch }) {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [tillDate, setTillDate] = useState();

  const Domains = [
    "Information Technology",
    "Engineering",
    "Construction",
    "Manufacturing",
    "Healthcare",
    "Production",
    "Automation & Controls",
    "Finance & Accounting",
    "Fintech",
    "Banking",
    "E-Commerce",
    "Supply Chain Management",
    "Science & Innovation",
    "Hospitality",
    "Food & Drink",
    "Real Estate",
    "Energy",
    "Sales",
    "Retail",
    'Trading &  Stocks',
    "Oil & Gas",
    "Logistics",
    "Aerospace",
    "Mining",
    "Pharmaceuticals",
    "Agriculture",
    "Insurance",
    "Gaming",
    "Travel & Tourism",
    "Educational",
    "Edtech",
    "Telecommunication",
    "Gov",
    "Defense",
    "Media & Entertainment",
    "Marketing",
    "Advertising",
    "Others"
  ]

  const [formValues, setFormValues] = useState({
    // clientName: "",
    // email: "",
    // phone: "",
    commonSearch: "",
    domain: "",
    city: "",
    state: "",
    // duration: {
    //   from: "",
    //   till: ""
    // },
    // duration: "",
    // location: "",
    stateCode: "",
    fromDate: "",
    toDate: "",
    resource: "",
    RPO_Staffing: "",
    activity: ""
  });

  // useEffect(()=>{
  //   if (editt1) {
  //     fn()
  //     searchFn()
  //   }

  // }, [])

  const [loading, setLoading] = useState(false);

  const fn = () => {
    setLoading(true)
    
    const body = {
      "action": "client",
      "commonSearch": formValues.commonSearch,
      "domain": formValues.domain,
      // "duration": {
      //   "unit": formValues.duration,
      //   "amount": "1",
      // },
      "stateCode": stateCode || stateId,
      ...(stateQuery && !cityQuery ? { "state": stateQuery } : {}),
      "fromDate": formValues.fromDate,
      "toDate": formValues.toDate,
      "location": formValues.location,
      // "state": stateQuery,
      "city": cityQuery,
      "resource": formValues.resource,
      "RPO_Staffing": formValues.RPO_Staffing,
      "activity": formValues.activity
    };

    const token = localStorage.getItem('token');
    Api('common/search/clients', 'POST', body, token)
      .then((response) => {
        // console.log('Response:', response);
        if (response.status === 200) {
          if (response.data && response.data.code === 200) {
            if (response.data.data && Array.isArray(response.data.data)) {
              if (response.data.data.length) {
                searchData(response.data.data);
                setLoading(false)
                // alert("data found");
              } else {
                searchData([]);
                setLoading(false)
                // alert("data not found");
              }
              // handleClose();
              handleSearchClose()
              setLoading(false)
            } else {
              setLoading(false)
              // console.log('Error: data is not an array');
              // alert("Unexpected data format");
              // handleClose();
              // toast.error(response.data ? response.data.message : 'No data');
              handleSearchClose()
            }
          } else {
            setLoading(false)
            // console.log('Error:', response.data ? response.data.message : 'No data');
            // toast.error(response.data ? response.data.message : 'No data');
            // alert("data");
            handleClose();
            handleSearchClose()
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handleUnitChange = (e) => {
    const selectedUnit = e.target.value;
    setFormValues({
      ...formValues,
      duration: {
        ...formValues.duration,
        unit: selectedUnit
      }
    });
  };

  const [formValues1, setFormValues1] = useState({

    commonSearch: "",

  });

  const [loading1, setLoading1] = useState(false);

  const searchFn = () => {
    setLoading1(true)
    const body = {
      "commonSearch": formValues1.commonSearch,
    };

    const token = localStorage.getItem('token');
    Api('common/search/clients', 'POST', body, token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            searchData(response.data.data)
            setLoading1(false)
          } else {
            // console.log('Error:', response.data.message);
          }
          handleClose()
          // alert("client")
          // func()
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    resetSearch()
    // setIsVisible(!isVisible); 
    func()
    setFormValues1({ ...formValues1, commonSearch: '' });
    setFormValues({
      ...formValues,
      commonSearch: '',
      // city: '',
      // state: '',
      // duration: { unit: "", amount: "" },
      duration: "",
      resource: '',
      domain: '',
      fromDate: '',
      toDate: "",
      RPO_Staffing: "",
      activity: ""
    });
    setCityQuery('')
    setStateQuery('')
  };

  const [userdata, setUserdata] = useState([])
  // console.log("userdata ===>>>>", userdata)
  useEffect(() => {
    allUsers()
  }, []);

  const allUsers = () => {

    const token = localStorage.getItem('token');
    Api('common/users/SLO', 'GET', '', token)
      // Api('company/all_users', 'GET', '', token)
      // Api('common/users/SL', 'GET', '', token)
      .then((response) => {
        // console.log('Response:---------', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // const users = response.data.data;
            // const contactPersonNames = users.map(user => user.contactPersonName);

            setUserdata(response.data.data);
          } else {
            // console.log('Error:', response.data.message);
          }

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const isDisabled = (
  //   formValues.commonSearch === '' ||
  //   formValues1.commonSearch === '' ||
  //   formValues.city === '' ||
  //   formValues.state === '' ||
  //   formValues.duration === '' ||
  //   formValues.resource === '' ||
  //   formValues.domain === ''
  // );


  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const [dist, setDistance] = useState('')
  const [latt, setLatt] = useState('')
  const [long, setLong] = useState('')
  const [valuee, setValue] = useState({ country: "USA" });
  const [cityData, setCityData] = useState([]);
  const [cityQuery, setCityQuery] = useState("");
  const [cityAndState, setCityAndState] = useState(null);

  // console.log("cityData", cityData)
  // console.log("cityQuery", cityQuery)
  // console.log("cityAndState", cityAndState)

  const handleChange = (e) => {
    setValue({ country: e.target.value });
  };

  // const handleCityChange = async (val) => {
  //   setCityQuery(val);
  //   if (val.length >= 3) {
  //     await getCity(val);
  //   }
  // };

  const [stateCode, setStateCode] = useState('')
  // setCityData([])
  const getCity = async (val) => {
    const token = localStorage.getItem('token');
    const body = {
      country: valuee.country,
      query: val,
    };
    try {
      const response = await Api(`resume/get_city_states`, 'POST', body, token);
      if (response.status === 200 && response.data.code === 200) {
        // console.log('=========status 200======>>>>>>>', response);
        // Ensure the new data appears at the top by resetting the cityData state
        setCityData(response.data.data);
      } else {
        toast.error(response.data.message)
        // Handle other cases
        // setCityAndZip(response.data.data);
        // toast.error(response.data.message + '!');
      }
    } catch (err) {
      // console.log('===========', err);
      // toast.error(err + '!');
    }
  };

  const childFuc = (val) => {
    setCityData([])
    // console.log("vall===", val);
    if (typeof val === "string") {
      if (val.length >= 3) {
        getCity(val);
      }
      setCityQuery(val);
    } else {
      setStateQuery(val.state);
      setCityQuery(val.city);
      setStateCode(val.stateCode);
      if (val.city.length >= 3) {
        getCity(val.city);
      }
    }
  };

  const [stateData, setStateData] = useState([]);
  const [stateQuery, setStateQuery] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [stateId, setStateId] = useState("");
  const handleStateChange = async (val) => {
    setStateQuery(val.state_name || val.province_or_StateName);
    setStateId(val.state_id || val.province_id)
    // await getState(val);
    if (val.length >= 3) {
      await getState(val);
    }
  };

  const getState = async (val) => {
    setStateData([])
    const token = localStorage.getItem('token');
    const body = {
      country: valuee.country || 'USA',
      stateName: val,
    };
    try {
      const response = await Api('resume/get_states', 'POST', body, token);
      if (response.status === 200 && response.data.code === 200) {
        setStateData(response.data.data || []);

      } else {
        console.error(response.data.message);
        setStateData([]);
      }
    } catch (err) {
      console.error(err);
      setStateData([]);
    }
  };

  const childFucState = async (val) => {
    // setStateQuery(val);
    // await handleStateChange(val);
    setStateQuery(val.state_name || val.province_or_StateName);
    handleStateChange(val);
    // if (stateData.includes(val)) {
    //   setSelectedState(val);
    // } else {
    //   setSelectedState(null);
    // }
  };

  // console.log("latt==>>", latt)
  // console.log("long==>>", long)
  // console.log("dist==>>", dist)

  // const searchDis = async () => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     "center": [long, latt],
  //     "radiusInMiles": dist
  //   };
  //   try {
  //     const response = await Api('common/search/cv', 'POST', body, token);
  //     if (response.status === 200 && response.data.code === 200) {
  //       // setStateData(response.data.data || []); 
  //     } else {
  //       console.error(response.data.message);
  //       // setStateData([]); 
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     // setStateData([]); 
  //   }
  // };

  // const handleSearch = () => {
  //   fn()
  //   // setStateQuery('')
  //   // setCityQuery('')
  //   // setValue('')
  // }

  // const handleSearch = () => {
  //   if (dist) {
  //     searchDis();
  //     setDistance('')
  //     setStateQuery('')
  //     setCityQuery('')
  //     setValue('')

  //   } else {
  //     fn();
  //     setDistance('')
  //     setStateQuery('')
  //     setCityQuery('')
  //     setValue('')
  //   }
  // };

  // const handleDateChange = (date, field) => {
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [field]: date ? date.toISOString() : null,
  //   }));
  // };

  const handleDateChange = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setFormValues((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  const clearInputField = () => {
    setFormValues({
      ...formValues,
      commonSearch: '',
      // city: '',
      // state: '',
      // duration: { unit: "", amount: "" },
      duration: "",
      resource: '',
      domain: '',
      fromDate: '',
      toDate: "",
      RPO_Staffing: "",
      activity: ""
    });
    setCityQuery('')
    setStateQuery('')

  }

  useEffect(() => {
    // alert("a")
    if (hideClosebtn1) {
      clearInputField()
    }
  }, [hideClosebtn1])

  // console.log("hideClosebtn1", hideClosebtn1)

  const [cityStateStyling, setCityStateStyling] = useState(false)

  const data = "muz"

  const handleData = (data) => {
    // Handle the data here, such as sending it to an API or updating state
    // console.log(data); // Example: logging the data
  };

  // const [inputValue, setInputValue] = useState('muz');

  const sendDataToParent = () => {
    onData(clearInputField);
  };


  return (
    // <ClickAwayListener onClickAway={handleClose}>
    <>

      {/* {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
          </IconButton>
        )} */}


      <div style={{ display: 'flex', alignItems: 'center', left: 115, right: 0 }}>
        <Paper
          component="form"
          sx={{
            // p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '25%',
            border: '1px solid #1E74B3',
            borderRadius: '10px',
            marginTop: -18, 
          }}
        >
          {/* <IconButton sx={{ p: '0.1px', ml: "7px", mr: "4px" }}>
            <Iconify icon="eva:search-outline" />
          </IconButton> */}
          <InputBase
            sx={{ flex: 1, color: 'text.primary', border: 'none', outline: 'none', pl: 1 }}
            placeholder="Name | Email | Phone Number"
            inputProps={{ 'aria-label': 'search' }}
            value={formValues1.commonSearch}
            onChange={(e) => {
              setFormValues1({ ...formValues1, commonSearch: e.target.value });
              
              sendDataToParent();
              if (e.target.value.length === 1 || e.target.value.length > 50) {
                // sendDataToParent();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); 
                searchFn();
                funcc(); 
              }
              
            }}
          />

        </Paper>
        {/* <Button style={{ marginLeft: '16px', color: 'white', backgroundColor: '#1E74B3', borderRadius: '10px', marginTop: '4px', height: '41px', width: '130px' }} onClick={fn}>Search</Button> */}
        <Button color="custom" variant="contained" size="small" sx={{ ml: 1.6, mt:-18 }}
          // disabled={formValues1.commonSearch === ''}
          disabled={loading1}
          onClick={()=>{searchFn(); funcc()}}
         
          >
          {/* Search */}
         
          {loading1 ? <CircularProgress size={24} /> : 'Search'}
        </Button>
        {(
          formValues?.commonSearch ||
          formValues1?.commonSearch ||
          // formValues?.city ||
          // formValues?.state ||
          // (formValues.duration && (formValues.duration.unit || formValues.duration.amount)) ||
          formValues?.duration ||
          formValues?.resource ||
          formValues?.domain ||
          formValues?.fromDate ||
          formValues?.toDate ||
          cityQuery || stateQuery ||
          formValues?.RPO_Staffing ||
          (formValues?.activity || formValues?.activity === 0)

        ) && (
            // <IconButton
            //   sx={{ p: '1px', ml: '6px' }}
            //   color="custom"
            //   variant="contained"
            //   size="large"
            //   onClick={toggleVisibility}
            // >
            //   <Iconify icon="oui:cross-in-circle-filled" width="1.7rem" />
            // </IconButton>

            <Button color="custom" variant="contained" size="small" sx={{ ml: 1.6, mt: -18 }} 
              onClick={() => { toggleVisibility(); funnc() }}>
              Close
            </Button>
          )}

      </div>


      <StyledIconButtonWrapper onClick={() => {
        handleSearchOpen(); setCityStateStyling(true);
        sendDataToParent()
      }}>
        <IconButton sx={{ display: 'flex', position: 'absolute', top: 55, marginRight: '68%' }}>
          <Iconify
            icon="bxs:down-arrow"
            color="#1E74B3"
          />


        </IconButton>
      </StyledIconButtonWrapper>

      <Modal open={searchOpen} onClose={handleSearchClose}>

        <Box sx={{
          position: 'absolute',
          top: '39%',
          left: '43%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '70%' },
          // width: { xs: '85%', sm: '60%' },
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 2,
          borderRadius: 3,
        }}
        >
          {/* <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <StyledSearchbar> */}
          {/* <StyledInput
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            /> */}
          {/* <ModalContent sx={{
            marginTop: '22%',
            border: '1px solid #1E74B3',
            // padding: '15px',
            // maxWidth: 'auto',
            maxWidth: '60%',
            maxHeight: '220%',
            marginLeft: '76px',
            // height: '30px',
            marginRight: '66px'
            // margin: 'auto', 
          }}> */}

          <Grid container spacing={1} justifyContent="center">

            <Grid item xs={12} >
              <TextField
                label="Name | Email | Phone Number"
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"

                value={formValues.commonSearch}
                onChange={(e) => setFormValues({ ...formValues, commonSearch: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); 
                    fn();
                    funcc(); 
                  }
                  
                }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel >Duration</InputLabel>
                <Select
                  // labelId="unit-label"
                  label="Duration"
                  value={formValues.duration}
                  onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}

                >
                  <MenuItem value="days">1 Day</MenuItem>
                  <MenuItem value="weeks">1 Week</MenuItem>
                  <MenuItem value="months">1 Month</MenuItem>
                  <MenuItem value="years">1 Year</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Typography>Duration</Typography> */}
            {/* <Grid sx={{ marginLeft:"-60px",  }}>
<Typography>
      Duration
    </Typography>
</Grid> */}

            {/* <Grid item xs={12}>
        <Typography sx={{ textAlign: "left", display: "block", marginTop:'-10px'}}>
          Duration
        </Typography>
      </Grid> */}

            <Grid container sx={{ flexDirection: "row", marginLeft: '1px' }} spacing={1}>
              <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      slotProps={{ textField: { size: "small" } }}
                      value={formValues.fromDate ? dayjs(formValues.fromDate) : null}
                      // onChange={(date) => handleDateChange1(date, 'fromDate')}
                      onChange={(date) => handleDateChange(date, 'fromDate')}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "1.5px" }}>
                <FormControl fullWidth size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Till"
                      slotProps={{ textField: { size: "small" } }}
                      value={formValues.toDate ? dayjs(formValues.toDate) : null}
                      // onChange={(date) => handleDateChange1(date, 'tillDate')}
                      onChange={(date) => handleDateChange(date, 'toDate')} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel >Resource</InputLabel>
                <Select
                  // labelId="resource-label"
                  label="Resource"
                  value={formValues.resource}
                  onChange={(e) => setFormValues({ ...formValues, resource: e.target.value })}

                >
                  {userdata.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.contactPersonName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              {/* <TextField
                label="Domain"
                variant="outlined"
                fullWidth
                // margin="dense"
                size="small"
                value={formValues.domain}
                onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
              /> */}

              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={value.domain}
                  value={formValues.domain}
                  label="Domain"
                  // InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
                >
                  {Domains.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel >Activity</InputLabel>
                <Select
                  // labelId="resource-label"
                  label="Activity"
                  value={formValues.activity}
                  onChange={(e) => setFormValues({ ...formValues, activity: e.target.value })}

                >
                  {/* {userdata.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.contactPersonName}
                    </MenuItem>
                  ))} */}
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                  {/* <MenuItem value={6}>6</MenuItem>
                   <MenuItem value={7}>7</MenuItem>
                   <MenuItem value={8}>8</MenuItem>
                   <MenuItem value={9}>9</MenuItem>
                   <MenuItem value={10}>10+</MenuItem>
                    */}

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              {/* <TextField
                label="Domain"
                variant="outlined"
                fullWidth
                // margin="dense"
                size="small"
                value={formValues.domain}
                onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
              /> */}

              {/* <FormControl sx={{}}> */}
              {/* <InputLabel id="demo-simple-select-label">RPO/Staffing/Consulting</InputLabel> */}
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel >RPO/Staffing/Consulting</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  InputLabelProps={{ shrink: true }}
                  // label="RPO/Staffing/Consulting/MSP/Contingent/Other"
                  label="RPO/Staffing/Consulting"
                  // placeholder="RPO/Staffing/Consulting/MSP/Contingent/Other"
                  placeholder="RPO/Staffing/Consulting"
                  value={formValues.RPO_Staffing}
                  onChange={(e) => setFormValues({ ...formValues, RPO_Staffing: e.target.value })}
                >
                  <MenuItem value={'RPO'}>RPO</MenuItem>
                  <MenuItem value={'Staffing'}>Staffing</MenuItem>
                  <MenuItem value={'Consulting'}>Consulting</MenuItem>
                  <MenuItem value={'MSP'}>MSP</MenuItem>
                  <MenuItem value={'Contingent'}>Contingent</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Select Country</InputLabel>
                <Select
                  value={valuee.country}
                  onChange={handleChange}
                  label="Select Country"
                >
                  <MenuItem value="USA">USA</MenuItem>
                  <MenuItem value="CANADA">CANADA</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} >
              <CustomCompleteWorksheet
                placeholder={'City'}
                size="small"
                option={cityData}
                propVal={cityQuery}
                childFuc={childFuc}
                fetch={'state'}
                cityStateStyling1={cityStateStyling}
              />

              {/* <FormControl fullWidth size="small">

                {cityAndState && cityAndState.city && cityAndState.state ? (
                  <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                    <Typography color="text.secondary" variant="body1">
                      {cityAndState.city},
                      {cityAndState.state}
                    </Typography>
                  </Stack>
                ) : null}
              </FormControl> */}
            </Grid>

            <Grid item xs={4} size="small" style={{ position: 'relative', maxHeight: 300, width: '100%' }}>
              <CustomComplete
                placeholder={'State'}
                size="small"
                option={stateData}
                propVal={stateQuery}
                childFuc={childFucState}
                cityStateStyling1={cityStateStyling}
              />

              <FormControl fullWidth size="small">
                {selectedState ? (
                  <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                    <Typography color="text.secondary" variant="body1">
                      {/* {selectedState} */}
                    </Typography>
                  </Stack>
                ) : null}
              </FormControl>
            </Grid>

            {/* <Grid item xs={4}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                // margin="dense"
                size="small"
                value={formValues.city}
                onChange={(e) => setFormValues({ ...formValues, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                // margin="dense"
                size="small"
                value={formValues.state}
                onChange={(e) => setFormValues({ ...formValues, state: e.target.value })}

              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <TextField
                label="Domain"
                variant="outlined"
                fullWidth
                // margin="dense"
                size="small"
                value={formValues.domain}
                onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
              />
            </Grid> */}
            <Grid item xs={4} textAlign="center" sx={{ marginTop: "-10px" }}>
              <Button color="custom" variant="contained" size="small" disabled={loading}
                onClick={() => { fn(); funcc() }}
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     e.preventDefault(); 
                //     fn();
                //     funcc(); 
                //   }
                  
                // }}
              >
                {loading ? <CircularProgress size={24} /> : 'Search'}

              </Button>
              <Button color="custom" variant="contained" size="small"
                sx={{ marginLeft: '12px' }}
                onClick={clearInputField}>
                Clear All
              </Button>
              {/* <Button color="custom" variant="contained" size="small" style={{ marginLeft: '22px' }} onClick={handleClose}>
                  Close
                </Button> */}

            </Grid>
          </Grid>

          {/* </ModalContent> */}
          {/* </StyledSearchbar> */}
          {/* </Slide> */}
        </Box>
      </Modal>

    </>
    // </ClickAwayListener> 
  );
}
