import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import {
  InputAdornment,
  Typography,
  Button,
  Stack,
  Box,
  TextField,
  Divider,
  Modal,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Chip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// import Autocomplete from '@mui/material/Autocomplete';
import { pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../components/iconify';
// import Upload from '../components/upload';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api, UploadFile } from '../webservices';

import CustomComplete from '../components/customComplete/CustomComplete';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf_viewer.js.map';
const styleClients = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '60%', sm: '90%' },
  bgcolor: 'background.paper',
  border: '3px solid #3C6BA5',
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};
function BenResumeDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const tablebg = 'rgba(82, 130, 190, 0.08)';
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const confirmClose = () => {
    setOpenConfirm(false);
  };

  const filter = createFilterOptions();
  const [openHis, setOpenHis] = useState(false)
  const openHistory = () => setOpenHis(true)
  const closeHistory = () => setOpenHis(false)
  const quit = location.state.q || '';
  const [confirmMsg, setConfirmMsg] = React.useState('');
  const [row, setRow] = React.useState({ ...location.state.row });
  const [row1, setRow1] = React.useState(location.state.openModal);
  // const [row1, setRow1] = React.useState("editOrNot");

  console.log("===!!!!!!!!!!!!!!!!!!!!!======row1", row1)
  const [skillss, setSkillss] = React.useState([
    ...(location.state?.addedSkills || location.state?.addedSkill || [])
  ]);

  //  const [skilllst, setSkilllst] = useState(row?.skill?.map(skill => ({ title: skill })));

  const [skilllst1, setSkilllst1] = useState(row?.skill);
  //  const skilllst1 = [
  //   "verbal communication",
  //   "organization",
  //   "management",
  //   "leadership",
  //   "planning",
  //   "problem-solving",
  //   "teamwork",
  //   "adaptability",
  //   "critical thinking",
  //   "time management"
  // ];

  const [skilllst, setSkilllst] = useState(
    (row?.skill || [])
      .filter(skill => skill)
      .map(skill => ({ title: skill }))
  );

  //  console.log("=======skillss==========",skillss)
  // console.log("skilllst", skilllst)

  const [filterTab, setFilterTab] = useState(location.state.fTab);
  const [resumeIframe, setResumeIframe] = useState(location.state.row?.resumeFile?.resumeFileUrl);
  const [cityStateStylings, setTityStateStyling] = useState(location.state?.cityStateStyling);
  console.log("cityStateStylings", cityStateStylings)
  // const [cityStateStyling, setTityStateStyling] = useState("cityStateStyling");

  console.log("resumeIframe===", resumeIframe)
  // const [hisId, setHisId] = useState(location.state.fTab === 'submitted' ? row._id : row.resumeId);
  const [hisId, setHisId] = useState(location.state.fTab === 'submitted' ? row._id : row.benchId);
  const [valuedata, setValuedata] = React.useState(dayjs());
  const [value2, setValue2] = React.useState({ jobType: '' });
  console.log("==================================hisId", hisId)
  // console.log("filterTab", filterTab)
  // console.log("row", row)

  const [startTime, setStarttime] = React.useState(dayjs('2023-12-11T18:30:11.625Z'));
  const [endtime, setEndtime] = React.useState(dayjs('2023-12-11T18:30:11.625Z'));
  const [timez, setTimez] = useState('est');
  const [mode, setMode] = useState('phoneic');
  const [idate, setIdate] = useState({ date: '', day: '', month: '', year: '' });
  const [itime, setItime] = useState({ hrs: '', min: '' });
  const [value, setValue] = React.useState(location.state.mtab ? location.state.mtab : 'Action');
  const [tabb, setTabb] = React.useState(location.state.kTab ? location.state.kTab : 'Action');
  // const [tabb, setTabb] = React.useState("Hide");
  const [value1, setValue1] = React.useState(location.state.mtab ? location.state.mtab : 'Action');
  // const [value1, setValue1] = React.useState("Detail");
  console.log("value =========================", value)
  const [emailDiss, setEmailDiss] = useState(true)
  const [btn, setBtn] = useState(filterTab);
  const [interstatus, setInterstatus] = useState('placed');
  const [jobid, setJobid] = useState(row.jobId);
  const [joblst, setJoblst] = useState([]);
  const [clientlst, setClientlst] = useState([]);
  const [subto, setSubto] = useState('position');
  const [rate, setRate] = useState('');
  const [tax, setTax] = useState(row.taxTerm);
  const [checked, setChecked] = React.useState(false);
  const [actionnotes, setActionnotes] = useState('');
  const [placenote, setPlacenote] = useState('');
  const [joinstatus, setJoinstatus] = useState('');
  const [joinnote, setJoinnote] = useState('');
  const [endstatus, setEndstatus] = useState('');
  const [enddate, setEnddate] = React.useState(dayjs());
  const [endnote, setEndnote] = useState('');
  const [editable, setEditable] = useState(true);
  const [his, setHis] = useState([]);
  // const [leveltype, setLeveltype] = useState(row.interviewStatus ? row.interviewStatus : 'l1');
  const [leveltype, setLeveltype] = useState(row.interviewSubStatus ? row.interviewSubStatus : 'l1');
  const [resumenote, setResumenote] = useState('')
  const [resumeArry, setResumeArry] = useState();
  const [dlArry, setDLArry] = useState();
  const [visaArry, setVisaArry] = useState();
  const [i92Arry, setI92Arry] = useState();
  const [otherdocArry, setOtherDocArry] = useState();
  const [resumeUrl, setResumeUrl] = useState();

  console.log("resumeUrl", resumeUrl)
  const [upload, setUpload] = useState(false);
  const [pdffile, setPdffile] = useState('');
  // const [upnote, setUpnote] = useState(row.notes);
  const [upnote, setUpnote] = useState();

  const [emailsub, setEmailsub] = useState('');
  const [emailbody, setEmailbody] = useState('');
  const [cname, setCname] = useState('');
  const [pname, setPname] = useState('');
  const [subId, setSubId] = useState(row._id);
  const [comType, setComType] = useState('');
  const [expectedCom, setExpectedCom] = useState('');
  const [loadBtn, setLoadbtn] = useState(null)
  const [interdata, setInterdata] = useState({
    clientName: row.clientName || '',
    managerName: '',
    contactNo: '',
    vPlatform: '',
    murl: '',
    companyname: '',
    caddress: '',
    inote: '',
    jobType: 'onsite',
    offeredJob: 'fulltime',
    AnnualSalary: '',
    JoiningBonus: '',
    AnnualBonus: '',
    ReloAssistance: '',
    hro: '',
    TaxTerm: '',
    OvertimeExpense: '',
    Additional: '',
  });

  const [boxx4, setBoxx4] = useState({

    spec1: row?.educationOne?.specialization ? row?.educationOne?.specialization : '',
    collage1: row?.educationOne?.collegeName ? row?.educationOne?.collegeName : '',
    country1: row?.educationOne?.countryName ? row?.educationOne?.countryName : '',
    year1: row?.educationOne?.year ? row?.educationOne?.year : '',

    spec2: row?.educationOne?.specialization ? row?.educationOne?.specialization : '',
    collage2: row?.educationOne?.collegeName ? row?.educationOne?.collegeName : '',
    country2: row?.educationOne?.countryName ? row?.educationOne?.countryName : '',
    year2: row?.educationOne?.year ? row?.educationOne?.year : '',

  });

  const [placestatus, setPlacestatus] = useState('placed');
  const [tsd, setTsd] = React.useState(dayjs());
  const [joindate, setJoindate] = React.useState(dayjs());
  const [fistable, setFirstable] = useState('f');
  const [secondable, setSecondable] = useState('f');
  const [box1, setBox1] = useState({
    source: row.source ? row.source : '',
    currentStatus: row.status ? row.status : '',
    candidateName: row?.fullName ? row?.fullName : '',
    candidateid: row.candidateId ? row.candidateId : '',
    linkedin: row.linkedinUrl ? row.linkedinUrl : '',
    box1email: row?.email ? row?.email : '',
    box1SeconEemail: row.secondaryEmail ? row.secondaryEmail : '',
    box1phone: row?.phone ? row?.phone : '',
    box1SecondPhone: row.secondaryPhone ? row.secondaryPhone : '',
    box1address: row?.address ? row?.address : '',
    box1compensation: row?.expectedCompensation ? row?.expectedCompensation : "",
    box1ChargeType: row?.chargeType ? row?.chargeType : "",

    box1visaType: row?.visaType ? row?.visaType : '',
    box1reallocate: row?.reallocate ? row?.reallocate : '',
    box1domain: row?.domain ? row?.domain : '',
    box1taxTerm: row?.taxTerm ? row?.taxTerm : '',
    box1jobTitle: row?.jobTitle ? row?.jobTitle : '',
    box1city: row?.address ? row?.address : '',
    box1Country: row?.country ? row?.country : "USA",
    box1Currcompany: row?.currentCompany ? row?.currentCompany : "",
    box1Country1: row?.country ? row?.country : "USA",
    box1Notes: row?.note ? row?.note : '',
    box1City: row?.city ? row?.city : '',
    box1ResumeUrl: row?.resumeFile?.resumeFileUrl ? row?.resumeFile?.resumeFileUrl : '',
    box1Exp: row?.experienceInYear ? row.experienceInYear : '',
    box1Validity: row?.validity ? row.validity : '',

  });

  // console.log("box1.reallocate", box1.box1reallocate)
  console.log("box1.box1ResumeUrl", box1.box1ResumeUrl)

  const [box2, setBox2] = useState({
    box2YofE: row.experienceInYear ? row.experienceInYear : '',
    box2currentCompany: '',
    box2duration:
      // row.currentCompanyDetails.duration?row.currentCompanyDetails.duration:
      '',
    box2jobtitle:
      // row.currentCompanyDetails.designation?row.currentCompanyDetails.designation:
      '',
    box2preCompany: '',
    box2PreComDur:
      // row.previousCompanyDetails.duration ? row.previousCompanyDetails.duration :
      '',
    box2PreComJobTitle:
      // row.previousCompanyDetails.designation?row.previousCompanyDetails.designation:
      '',
    box2education: row.education ? row.education : '',
    box2collageName: row.collegeName ? row.collegeName : '',
    box2Country: row.countryName ? row.countryName : '',
    box2Year: row.educationYear ? row.educationYear : '',
  });
  const [box3, setBox3] = useState({
    comType: '',
    currentCom: row?.compensation,
    // desiredCom: row.expectedCompensation ? row.expectedCompensation : '',
    desiredCom: row.desiredCompensation ? row.desiredCompensation : '',
    tax: row.taxTerm ? row.taxTerm : '',
    desiredJobType: row.desiredJobType ? row.desiredJobType : '',
    addedBy: row?.addedBy ? row?.addedBy : '',
    b3certificate: '',
    b3special: row?.certificate,
    //  row.certification.specialization?row.certification.specialization:

    b3CollageName:
      //  row.certification.collegeName?row.certification.collegeName:
      '',
    b3Country:
      //  row.certification.countryName?row.certification.countryName:
      '',
    b3Year: row.year ? row.year : "",
    // row.certification.year?row.certification.year:

  });
  const [box4, setBox4] = useState({
    // b4Ref: '',
    // b4Con1: '',
    b4Name1: row?.contactOne?.name,
    b4CName1: row?.contactOne?.company,
    // b4JobT1: '',
    b4Email1: row?.contactOne?.emailId,
    b4Phone1: row?.contactOne?.phoneNo,
    // b4Con2: '',
    b4Name2: row?.contactTwo?.name,
    // b4CompanyName2: '',
    // b4JobT2: '',
    b4CName2: row?.contactTwo?.company,
    b4Email2: row?.contactTwo?.emailId,
    b4Phone2: row?.contactTwo?.phoneNo,
    b4Name3: row?.contactThree?.name,
    // b4CompanyName3: '',
    // b4JobT2: '',
    b4CName3: row?.contactThree?.company,
    b4Email3: row?.contactThree?.emailId,
    b4Phone3: row?.contactThree?.phoneNo,
    b4Comment: row?.comments

  });

  const cardbg = '';

  const handleChangebtn = (event) => {
    setChecked(event.target.checked);
    console.log('=========mail=====>>>', event.target.checked);
  };

  useLayoutEffect(() => {
    if (row1 === "editOrNot") {
      handleOpenModal()
    }
    // getResumes()
  }, [])

  useEffect(() => {
    // if(value === "Detail"){
    // editDetail();
    // }
    // getResumes()
    getResumes()
    GetJobId();
    getClientList();
    recAndCompList()
    // getTimezoneList();
    // getHis();
    console.log('row', row);
  }, []);

  const [ModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [ModalUpload, setModalUpload] = useState(false);

  const handleUploadOpenModal = () => setModalUpload(true);

  const handleUploadCloseModal = () => setModalUpload(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const style2 = {
    display: 'flex',
    flexDirection: 'row',
  };
  const handleChange = (newValue) => {
    console.log(JSON.stringify(newValue));
    setValue(newValue);
  };
  console.log("upnote====", upnote)
  const updateNote = async () => {
    // if (value1 === "Detail"){
    // editResume()
    // }
    if (upnote) {
      const token = localStorage.getItem('token');
      const body = {
        resumeId: hisId,
        note: upnote,
      };
      Api(`resume/edit_notes`, 'POST', body, token)
        .then((response) => {
          console.log('====update Notes====', response);
          if (response.status === 200) {
            if (response.data.code === 200) {
              setUpnote('')
              // setEditable(false);
              toast.success(response.data.message);
              // setEditable(!editable);
            }
          }
        })
        .catch((err) => {
          toast.error(err, +'!');
        });
    }
  };

  const getResumes = async () => {
    const token = localStorage.getItem('token');
    // Api(`resume/resumes_by_candidate/${hisId}`, 'GET', '', token).then((response) => {
    Api(`bench/getBenchOfCandidate/${hisId}`, 'GET', '', token).then((response) => {
      console.log('res1===============>>>', response);
      // if (response.status === 200) {
      if (response.status === 200 && response.data) {
        setResumeArry(response.data.data);
        setDLArry(response.data.data);
        setVisaArry(response.data.data);
        setOtherDocArry(response.data.data);
        setI92Arry(response.data.data);
        // if(!row.resumeUrl){
        // }
        // setResumeUrl(response.data.data[0].resumeUrl)
        // if (response.data.code === 200) {
        //   setResumeArry(response.data.data);
        //   // if(!row.resumeUrl){
        //   // }
        //   setResumeUrl(response.data.data[0].resumeUrl)
        // }
      }
    })
      .catch((err) => { });
  };

  console.log("resumeArry===>>>>+++", resumeArry)

  const getHis = () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_history/${hisId}`, 'GET', '', token)
      .then((response) => {
        console.log('res2=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setHis(response.data.data);
            // setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };


  const getClientList = () => {
    const token = localStorage.getItem('token');
    Api(`company/active_clients`, 'GET', '', token)
      .then((response) => {
        console.log('==========resume_submission=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setClientlst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };

  // const getTimezoneList = () => {
  //   const token = localStorage.getItem('token');
  //   Api(`resume/get_timezone`, 'GET', '', token)
  //     .then((response) => {
  //       console.log('==========get_timezone=====>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           // setClientlst(response.data.data);
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // };

  const GetJobId = () => {
    const id = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    Api(`company/active_jobs`, 'GET', '', token)
      .then((response) => {
        console.log('==========resume_submission=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => { });
  };
  // const getHis = () => {
  //   const token = localStorage.getItem('token');
  //   Api(`resume/get_history/${hisId}`, 'GET', '', token)
  //     .then((response) => {
  //       console.log('==========get_history=====>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           setHis(response.data.data);
  //           // setJoblst(response.data.data);
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // };

  const [val, setVal] = useState({
    recruitorName: '',
    recruitorCompany: '',
    phoneNumber: '',
    emailId: '',
    submitTo: ''
  })

  const ConfirmSubmitted = () => {

    setLoadbtn(true)
    const token = localStorage.getItem('token');
    // if (subto === 'client') {
    // alert(clientlst[cname].contactPersonName)
    if (!val.submitTo) {
      setLoadbtn(false)
      // alert(!clientlst[cname]?.clientName)
      toast.error('Client name is required!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!pname) {
      setLoadbtn(false)
      toast.error('Position name is required!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    const body = {
      // resumeId: row._id,
      // submitTo: clientlst[cname].contactPersonName,
      // submitTo: clientlst[cname].clientName,
      "bench_id": row._id,
      "submitTo": val.submitTo,
      "positionName": pname

    };

    // Api(`resume/existing_client_sub_check`, 'POST', body, token).then((response) => {
    Api(`bench/checkExistClientSubmission`, 'POST', body, token).then((response) => {
      console.log('==========existing_client_sub_check=====>>>', response);
      if (response.status === 200) {
        // navigate('/benchworksheet', { state: 'submitted' });
        if (response.data.code === 409) {
          // alert("hi")
          setOpenConfirm(true);
          setConfirmMsg(response.data.message);
          setLoadbtn(false)
        } else {
          Submitted();
        }
      }
    });
    // } else {
    // alert('Submited')
    // Submitted();
    // }
  };

  const Submitted = async (ss) => {
    const token = localStorage.getItem('token');
    const body = {
      // resumeId: row._id,
      bench_id: row._id,
      // clientId: subto === 'client' ? clientlst[cname]._id : '',
      clientId: '',
      status: 'submitted',
      submitTo: val.submitTo,
      jobId: subto === 'position' ? jobid : '',
      emailSubject: emailsub || '',
      type: subto,
      positionName: pname,
      rate_salary: rate,
      taxTerm: tax,
      automailer: checked,
      note: actionnotes,
      chargeType: comType,
      expectedCompensation: expectedCom,
      // clientName: subto === 'client' ? clientlst[cname].clientName : '',
      clientName: val.submitTo,
      stepWiseStatus: 'submitted',
      recruitorName: val.recruitorName,
      recruitorCompany: val.recruitorCompany,
      phoneNumber: val.phoneNumber,
      emailId: val.emailId,
      // country:country1,
      country: box1.box1Country1,
      city: cityy,
      stateCode: cityandstate?.stateCode,
      state: cityandstate?.state,
      jobType: value2.jobType,
      zipCode: valueee?.zip,
      email: "",
      phone: "",
      role: ""

    };
    // Api(`resume/resume_submission`, 'POST', body, token)
    Api(`bench/BenchSubmission`, 'POST', body, token)
      .then((response) => {
        console.log('==========resume_submission=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            setRow({ ...row, status: 'submitted' });
            // alert("j")
            setSubId(response?.data?.resumeSubmission?._id);
            navigate('/benchworksheet', { state: 'submitted' });
            console.log(row);
            // alert("j")
          } else {
            toast.warn(response.data.message);
            setLoadbtn(false)
          }
        }
      })
      .catch((err) => { });
  };
  const setInterview = async (ss) => {
    setLoadbtn(true)
    const sl = '/';
    const comm = ':';
    const tarek = `${idate.day}${sl}${idate.month}${sl}${idate.year}`;
    const tam = `${itime.hrs}${comm}${itime.min}`;
    const token = localStorage.getItem('token');
    const body = {
      // submissionId: subId,
      submissionId: row._id,
      // resumeId: row.resumeId,
      bench_id: row.benchId,
      status: 'interview',
      interviewSubStatus: leveltype,
      stepWiseStatus: leveltype,
      interviewDate: valuedata,
      interviewStartTime: startTime,
      interviewEndTime: endtime,
      // interviewTime: valuetime,
      timezone: timez,
      interviewMode: mode,
      clientName: interdata.clientName,
      hiringManagerName: interdata.managerName,
      contactNo: interdata.contactNo,
      videoPlatform: interdata.vPlatform,
      meetingLink: interdata.murl,
      companyName: interdata.companyname,
      companyAddress: interdata.caddress,
      note: interdata.inote,
    };
    // Api(`resume/set_interview`, 'POST', body, token)
    Api(`bench/BenchInterviewStep`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_interview=====>>>', response);
        if (response.status === 200) {
          setRow({ ...row, status: 'interview' });
          // setFilterTab('placement');
          console.log(row);
          navigate('/benchworksheet', { state: 'interview' });
          setLoadbtn(false)
          if (response.data.code === 200) {
            toast.success(response.data.message);
            setRow({ ...row, status: 'interview' });
            // setFilterTab('placement');
            console.log(row);
            navigate('/benchworksheet', { state: 'interview' });
            // localStorage.setItem('tab', 'interview');
          }
        }
      })
      .catch((err) => { });
  };

  const setPlacement = async (ss) => {
    setBtn(true)
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      bench_id: row.benchId,
      // resumeId: row.resumeId,
      status: placestatus === 'placed' ? 'placement' : 'rejected',
      result: placestatus,
      note: placenote,
      jobType: interdata.jobType,
      offeredJob: interdata.offeredJob,
      chargeType: interdata.offeredJob === 'fulltime' ? 'K' : 'hourly',
      annualSalary: interdata.AnnualSalary,
      joiningBonus: interdata.JoiningBonus,
      annualBonus: interdata.AnnualBonus,
      relocationAssistance: interdata.ReloAssistance,
      additionals: interdata.Additional,
      tentativeStartDate: tsd,
      taxTerm: interdata.TaxTerm,
      hourlyRateRateOffered: interdata.hro,
      overTimeExpence: interdata.OvertimeExpense,
      startStatus: '',
    };
    // Api(`resume/set_placement`, 'POST', body, token)
    Api(`bench/BenchPlacementStep`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_placement=====>>>', response);
        if (response.status === 200) {
          toast.success(response.data.message);
          setRow({ ...row, status: placestatus });
          navigate('/benchworksheet', { state: 'placement' });
          console.log(placestatus);
          setBtn(false)
          if (response.data.code === 200) {
            toast.success(response.data.message);
            setRow({ ...row, status: placestatus });
            navigate('/benchworksheet', { state: 'placement' });
            console.log(placestatus);
          }
        }
      })
      .catch((err) => { });
  };
  const setStart = async (ss) => {
    setLoadbtn(true)
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      bench_id: row.benchId,
      status: joinstatus === 'started' ? 'join' : 'backout',
      // joiningDate: joinstatus === 'started' ? joindate : '',
      tentativeStartDate: joinstatus === 'started' ? joindate : '',
      note: joinnote,
    };
    // Api(`resume/set_start`, 'POST', body, token)
    Api(`bench/BenchStartStep`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_start=====>>>', response);
        if (response.status === 200) {
          toast.success(response.data.message);
          setRow({ ...row, status: body.status });
          navigate('/benchworksheet', { state: joinstatus === 'backout' ? 'backout' : 'placement' });
          console.log(placestatus);
          if (response.data.code === 200) {
            toast.success(response.data.message);
            setRow({ ...row, status: body.status });
            navigate('/benchworksheet', { state: joinstatus === 'backout' ? 'backout' : 'placement' });
            console.log(placestatus);
          }
        }
      })
      .catch((err) => { });
  };

  const setEnd = async () => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      // resumeId: row.resumeId,
      bench_id: row.benchId,
      terminatedAt: enddate,
      status: endstatus,
    };
    // Api(`resume/termination`, 'POST', body, token)
    Api(`bench/terminateStep`, 'POST', body, token)
      .then((response) => {
        console.log('==========termination=====>>>', response);
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate('/benchworksheet', { state: 'placement' });
          if (response.data.code === 200) {
            // alert('aghisiu')
            toast.success(response.data.message);
            navigate('/benchworksheet', { state: 'placement' });
            // navigate(-1);
          } else {
            // alert('vde')
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => { });
  };

  const [emailDiss1, setEmailDiss1] = useState(true)
  const editResume = () => {
    setEmailDiss(false)
    // alert("jj")
    console.log('update skill', skilllst);
    const skillLst = [];
    skilllst?.map((item) => {
      return skillLst.push(item.title);
    });
    console.log('after map skill', skillLst);
    const id = row._id;
    const token = localStorage.getItem('token');

    if (!box1.candidateName) {
      toast.error("Full Name is Required");
      return;
    }
    if (!box1.box1email) {
      toast.error("Email is Required");
      return;
    }

    if (!cityy) {
      toast.error("City & Pincode are required");
      return;
    }

    if (!box1.box1jobTitle) {
      toast.error("Job Title is Required");
      return;
    }

    const body = {
      // "newCV": "no",
      // resumeId: id,
      companyID: value.companyID,
      bench_id: id,
      // summary: '',
      experienceInYear: box1.box1Exp,
      otherFile: '',
      i94_File: '',
      DL_File: '',
      visaFile: '',
      validity: formValues.fromDate,
      updatedAt: '',
      // visaTransfer: '',
      // DOB: '',
      fullName: box1.candidateName,
      email: box1.box1email,
      jobTitle: box1.box1jobTitle,
      phone: box1.box1phone,
      currentCompany: box1.box1Currcompany,
      skill: skillLst,
      visaType: box1.box1visaType,
      taxTerm: box1.box1taxTerm,
      expectedCompensation: box1.box1compensation,
      cityOrZipcode: cityy,

      zipcode: valueee?.zip,
      // stateCode: cityandstate.stateCode, 
      reallocate: box1.box1reallocate,
      resumeUrl: box1.box1ResumeUrl,
      // jobId: value.jobid,
      // note: value.notes,
      // status: value.status,
      chargeType: box1.box1ChargeType,
      // userStatus: value.userStatus,
      country: box1.box1Country,
      // linkedinUrl: value.linkedinUrl,
    };

    // Api(`resume/update_resume`, 'POST', body, token)
    Api(`bench/updateBenchworksheet`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          navigate('/benchworksheet')
          if (Response.data.code === 200) {
            setEmailDiss1(false)
            toast.success(Response.data.message);

            console.log('======getUser===data.data pdata====>', Response);
          } else {
            console.log('======getUser=======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }

        // toast.error("Email, fullName, and jobTitle are required fields");
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const [sbox, setSbox] = useState({
    companyName1: row?.currentCompanyDetails?.companyName1,
    jobTitle1: row?.currentCompanyDetails?.jobTitle1,
    duration1: row?.currentCompanyDetails?.duration1,

    companyName2: row?.previousCompanyDetails?.companyName2,
    jobTitle2: row?.previousCompanyDetails?.jobTitle2,
    duration2: row?.previousCompanyDetails?.duration2,

  })

  const setBoxes = async () => {
    const token = localStorage.getItem('token');
    const body = {
      // resumeId: filterTab === 'submitted' ? row._id : row.resumeId,
      bench_id: row._id,
      source: box1.source,
      currentStatus: box1.currentStatus,
      fullName: box1.candidateName,
      candidateId: box1.candidateid,
      linkedinUrl: box1.linkedin,
      email: box1.box1email,
      secondaryEmail: box1.box1SeconEemail,
      phone: box1.box1phone,
      secondaryPhone: box1.box1SecondPhone,
      address: box1.box1address || box1.box1city,
      // taxTerm:box1.box1taxTerm,
      domain: box1.box1jobTitle,
      reallocate: box1.box1reallocate,
      visaType: box1.box1visaType,
      validity: box1.box1Validity,
      expInYear: box1.box1Exp,
      DOB: formValue.fromDate,
      // certification: {
      //   specialization: box3.b3special,
      //   collegeName: box3.b3CollageName,
      //   countryName: box3.b3Country,
      //   year: box3.b3Year,
      // },

      compensation: box3.currentCom,
      desiredCom: box3.desiredCom,
      desiredJobType: box3.desiredJobType,
      addedBy: box3.addedBy,
      // certification: {
      //   specialization: box3.b3special,
      //   collegeName: box3.b3CollageName,
      //   countryName: box3.b3Country,
      //   year: box3.b3Year,
      // },
      year: box3.b3Year,
      certificate: box3.b3special,
      "educationOne": {
        "specialization": sections.box2education,
        "collegeName": sections.box2collageName,
        "countryName": sections.box2Country,
        "year": sections.box2Year
      },

      "educationTwo": {
        "specialization": boxx4.spec1,
        "collegeName": boxx4.collage1,
        "countryName": boxx4.country1,
        "year": boxx4.year1
      },

      "educationThree": {
        "specialization": boxx4.spec2,
        "collegeName": boxx4.collage2,
        "countryName": boxx4.country2,
        "year": boxx4.year2
      },

      // taxTerm: box3.tax || box1.box1taxTerm,
      // compensation: box1.box1compensation,
      experienceInYear: box2.box2YofE,
      currentCompanyDetails: {
        // duration: box2.box2duration,
        // designation: box2.box2jobtitle,
        companyName1: sbox.companyName1,
        jobTitle1: sbox.jobTitle1,
        duration1: sbox.duration1,
      },
      previousCompanyDetails: {
        companyName2: sbox.companyName2,
        jobTitle2: sbox.jobTitle2,
        duration2: sbox.duration2,
        // duration: box2.box2PreComDur,
        // designation: box2.box2PreComJobTitle,
      },

      // education: box2.box2education,
      // collegeName: box2.box2collageName,
      // countryName: box2.box2Country,
      // educationYear: box2.box2Year,

      contactOne: {
        company: box4.b4CName1,
        name: box4.b4Name1,
        emailId: box4.b4Email1,
        phoneNo: box4.b4Phone1,
      },
      contactTwo: {
        company: box4.b4CName2,
        name: box4.b4Name2,
        emailId: box4.b4Email2,
        phoneNo: box4.b4Phone2,
      },
      contactThree: {
        company: box4.b4CName3,
        name: box4.b4Name3,
        emailId: box4.b4Email3,
        phoneNo: box4.b4Phone3,
      },
      comments: box4.b4Comment,
    };
    Api(`bench/editProspect`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_start=====>>>', response);
        if (response.status === 200) {
          toast.success(response.data.resmessage);
          setTimeout(() => {
            navigate('/benchworksheet');
          }, 300);

          if (response.data.code === 200) {
            toast.success(response.data.resmessage);
          }
        }
      })
      .catch((err) => { });
  };

  const [edit1, setEdit1] = useState(false);
  const [edit2, setEdit2] = useState(false);
  const [edit3, setEdit3] = useState(false);
  const [edit4, setEdit4] = useState(false);
  const [edit5, setEdit5] = useState(false);
  const [thirdable, setThirdable] = useState('t');
  const [fourable, setFourable] = useState('t');
  const [addMore, setAddMore] = useState(false)
  const [addMoreShow, setAddMoreShow] = useState(false)
  const [addMoreShow1, setAddMoreShow1] = useState(false)

  const [fifth, setFifth] = useState('t');

  const editSource = () => {

    setFirstable(false);
    if (edit1 === false) {
      setEdit1(true);
    } else if (edit1 === true) {
      console.log('hgscgj');
      setBoxes();
      setEmailDiss(false)

    }
  };
  const editCertificate = () => {
    setSecondable(false);
    if (edit2 === false) {
      setEdit2(true);
    } else if (edit2 === true) {
      console.log('hgscgj');
      setBoxes();
    }
  };
  const editRef = () => {
    setThirdable(false);
    if (edit3 === false) {
      setEdit3(true);
    } else if (edit3 === true) {
      console.log('hgscgj');
      setBoxes();
    }
  };
  const editYear = () => {

    setFourable(false);
    if (edit4 === false) {
      setAddMore(true)
      setEdit4(true);
    } else if (edit4 === true) {
      console.log('hgscgj');
      setBoxes();
    }
  };

  useEffect(() => {
    editAll()
  }, [value1])

  const editAll = () => {
    if (value1 === "Detail") {

      setEdit5(true);

      setFifth(false)

    }
  }

  const [emailDis, setEmailDis] = useState(true)

  const editDetail = () => {
    // setFifth(false);
    // if (edit5 === false) {
    // setEdit5(true);
    // } else if (edit5 === true) {
    // console.log('hgscgj');
    setBoxes();
    setEmailDis(false)
  }
  // };

  // const suru ="https://docs.google.com/gview?url="
  const suru = 'https://docs.google.com/viewer?url=';
  const lll = '&embedded=true';
  const tempDoc = 'https://file-examples.com/storage/fe9d743740654a8139a48e1/2017/02/file-sample_100kB.docx';

  // const [newData, setNewData] = useState('')
  const pdfUpload = async (pdf) => {
    // setResumeIframe('')
    // console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          // setNewData(Response.data.filePath)
          setPdffile(Response.data.filePath);
          // uploadPdf(Response.data.filePath)
          setResumeUrl(Response.data.filePath)
          getDate(Response.data.filePath)
          // getResumes()
          setResumeIframe(Response.data.filePath)
          handleUploadCloseModal()
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  // console.log("newData", newData)

  const getDate = async (file) => {
    const token = localStorage.getItem('token');
    if (!file) {
      console.warn('No file provided.');
      return;
    }
    const body = {
      bench_id: row._id,
    };

    if (fileInputRefResume.current) {
      body.resumeFile = { resumeFileUrl: file };
    } else if (fileInputRefOther.current) {
      body.otherFile = { otherFileUrl: file };
    } else if (fileInputRefDL.current) {
      body.DL_File = { DL_FileUrl: file };
    } else if (fileInputRefVisa.current) {
      body.visaFile = { visaFileUrl: file };
    } else if (fileInputRefi92.current) {
      body.i94_File = { i94_FileUrl: file };
    } else {
      console.warn('No valid input reference found.');
      return;
    }

    console.log('=====body=add===>>>', body);
    Api(`bench/editProspectFile`, 'POST', body, token)
      .then((response) => {
        console.log('==========set_start=====>>>', response);
        if (response.status === 200) {
          getResumes()
          if (response.data.code === 200) {
            // setUpload(true)
            // toast.success(response.data.message);

          }
        }
      })
      .catch((err) => { });
  };

  // const uploadPdf = async (file) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     "resumeId": hisId,
  //     // "resumeUrl": pdffile,                                                                  
  //     "resumeUrl": file,
  //     // "note": resumenote
  //   }
  //   console.log('=====body=add_resume===>>>', body);
  //   Api(`resume/add_resume`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('==========set_start=====>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 200) {
  //           setUpload(true)
  //           toast.success(response.data.message);
  //           getResumes()
  //           setResumenote('')
  //           setPdffile('')
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('')
  const [fileDlt, setFileDlt] = useState('')
  const handleOpenDeleteModal = (id, res) => {
    setFileDlt(res)
    console.log("================res", res)
    console.log("========delete_Id", id)
    setDeleteId(id)
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const resumeDelete = async () => {
    const token = localStorage.getItem('token');
    const body = {
      "resumeId": hisId,
      "resumeUrlId": deleteId,
    }
    console.log('=====body=add_resume===>>>', body);
    Api(`resume/delete_res`, 'POST', body, token).then((response) => {
      console.log('==========set_start=====>>>', response);
      if (response.status === 200) {
        if (response.data.code === 200) {
          toast.success(response.data.message);
          getResumes()
          handleCloseDeleteModal()
        }
      }
    })
      .catch((err) => { });
  };

  const fileDelete = async () => {
    const body = {
      // "resumeId": hisId,
      // "resumeUrlId": deleteId,
      "oldField": "visaFile",
      "newFieldValue": "",
      "bench_id": ""

    }
    const token = localStorage.getItem('token');
    console.log('=====body=add===>>>', body);
    Api(`bench/benchDeletebenchWorksheetData`, 'POST', body, token).then((response) => {
      console.log('==========dltfile=====>>>', response);
      if (response.status === 200) {
        if (response.data.code === 200) {
          toast.success(response.data.message);
          getResumes()
          // handleCloseDeleteModal()
        }
      }
    })
      .catch((err) => { });
  };

  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
    { label: 'Permanent Resident' },
  ];

  const [cityData, setcityData] = useState([]);
  const [cityandstate, setCityandstate] = useState();

  // const [cityandstate, setCityandstate] = useState({
  //   city: filterTab === ('submitted' || "Detail") ? row?.city : row?.cityOrZipcode || row?.stateCode,
  //   state: row?.stateCode,
  // });

  // const [cityy, setCityy] = useState(filterTab === ('submitted' || "Detail") ? row?.city : row?.cityOrZipcode || '');
  const [cityy, setCityy] = useState('');

  console.log("cityData", cityData)
  console.log("cityy", cityy)

  const childFuc = (e) => {
    setcityData([])
    setCityy(e);
    if (e.length >= 3) {
      getCity(e);
    } else if (e.length === 0) {
      setCityy(e);
      setcityData([]);
      setCityandstate({});
    }
  };

  const [valueee, setValueee] = useState('')
  const fullVal = (val) => {
    console.log(val);
    setCityandstate(val);
    setCityy(val.city);
    setValueee({ ...valueee, zip: val?.zip || val.postal });
  };

  const [country, setCountry] = useState("USA")
  const [country1, setCountry1] = useState("USA")
  const getCity = async (val) => {
    setcityData([])
    const token = localStorage.getItem('token');
    const body = {
      // country: value.country,
      "country": country,
      "query": val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('city========', JSON.stringify(response.data));
            setcityData(response.data.data);

          } else {
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const [formValues, setFormValues] = useState({
    fromDate: row.validity,

  });

  const handleDateChange = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setFormValues((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  const [formValue, setFormValue] = useState({
    fromDate: row.DOB,

  });

  const handleDateChange1 = (date, field) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setFormValue((prevRec) => ({
      ...prevRec,
      [field]: formattedDate
    }));
    // console.log("formattedDate====>>>>", formattedDate);
  };

  // const RefResume = useRef(null);
  // const RefDL = useRef(null);
  // const RefOther = useRef(null);
  // const RefVisa = useRef(null);
  // const Refi92 = useRef(null);

  const fileInputRefResume = useRef(null);
  const fileInputRefDL = useRef(null);
  const fileInputRefOther = useRef(null);
  const fileInputRefVisa = useRef(null);
  const fileInputRefi92 = useRef(null);

  const handleFileButton = (type) => {
    switch (type) {
      case 'resume':
        fileInputRefResume.current.click();
        // alert("res")
        break;
      case 'dl':
        fileInputRefDL.current.click();
        // alert("dl")
        break;
      case 'other':
        fileInputRefOther.current.click();
        // alert("oth")
        break;
      case 'visa':
        fileInputRefVisa.current.click();
        // alert("visa")
        break;
      case 'i92':
        fileInputRefi92.current.click();
        // alert("i92")
        break;
      default:
        break;
    }
  };

  const handleFileChange = (event) => {
    // alert("type", type)
    // console.log("===type", type)
    // switch (type) {
    //   case 'resume': 
    // fileInputRefResume.current.click();
    // alert(type)
    //     break;
    // }

    //   const fileInputRefDL = useRef(null);
    // const fileInputRefOther = useRef(null);
    // const fileInputRefVisa = useRef(null);
    // const fileInputRefi92 = useRef(null);

    const file = event.target.files[0];
    if (file) {
      if (fileInputRefResume.current) {
        pdfUpload(file)
      }
      else if (fileInputRefOther.current) {
        pdfUploadAll(file)
      }
      else if (fileInputRefDL.current) {
        pdfUploadAll(file)
      }
      else if (fileInputRefVisa.current) {
        pdfUploadAll(file)
      }
      else if (fileInputRefi92.current) {
        pdfUploadAll(file)
      }
    }
  };

  const pdfUploadAll = async (pdf) => {
    // console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'benchDocuments');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // setChoosefile1(true);
          // setDocfile1('');
          getDate(Response.data.filePath)
          // getResumes()
          setResumeIframe(Response.data.filePath)
          handleUploadCloseModal()
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          // setPdffileDL(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };

  const [sections, setSections] = useState({
    box2education: row?.educationOne?.specialization ? row?.educationOne?.specialization : '',
    box2collageName: row?.educationOne?.collegeName ? row?.educationOne?.collegeName : '',
    box2Country: row?.educationOne?.countryName ? row?.educationOne?.countryName : '',
    box2Year: row?.educationOne?.year ? row?.educationOne?.year : '',

  });

  console.log("sections", sections.box2education)

  const [dropComp, setDropComp] = useState([])
  const [dropRec, setDropRec] = useState([])


  const recAndCompList = () => {
    // alert(term)
    // const body = {
    //    "recruitorName":rec,
    //    "recruitorCompany":comp
    // }
    const token = localStorage.getItem('token');
    Api(`bench/recruitorDropDownList`, 'GET', '', token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          setDropComp(response.data.result);
          // setDropRec(response.data.result);
          if (response.data.code === 200) {
            console.log('response.data.result', response.data.result)
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    recList()
  }, [val.recruitorCompany])

  const recList = () => {
    // alert(term)
    const body = {
      //  "recruitorName":rec,
      "recruitorCompany": val.recruitorCompany
    }
    const token = localStorage.getItem('token');
    Api(`bench/recruitorNameDropDownList`, 'POST', body, token)
      .then((response) => {
        console.log('Response:---------', response);
        if (response.status === 200) {
          // setDropComp(response.data.result);
          setDropRec(response.data.result);
          const resResult = response.data.result;
          const recResult1 = resResult.map((item) => (item))
          setVal({
            ...val, recruitorName: recResult1[0].recruitorName,
            emailId: recResult1[0].email,
            phoneNumber: recResult1[0].phone
          });
          if (response.data.code === 200) {
            console.log('response.data.result', response.data.result)
          } else {
            console.log('Error:', response.data.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  console.log("======dropRec", dropRec)

  const [filtered, setFiltered] = useState(dropComp || dropRec);

  const handleInputChangeComp = (event, newValue) => {
    const inputValue = newValue || event?.target.value || '';
    setVal({ ...val, recruitorCompany: inputValue });
    // recAndCompList('', inputValue); 
    const filtered = dropComp?.filter(city =>
      city?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFiltered(filtered);
  };

  const handleChangeComp = (event, newValue) => {
    if (newValue) {
      setVal({ ...val, recruitorCompany: newValue });
      const Recruitor = dropComp.find(city => city === newValue);
      if (Recruitor) {
        setVal({
          ...val, recruitorName: Recruitor.recruitorName,
          emailId: Recruitor.email,
          phoneNumber: Recruitor.phone
        });
      }
    } else {
      setVal({ ...val, recruitorCompany: '', recruitorName: '' });
    }
  };

  const handleChangeRec = (e) => {
    const selectedRecruiter = dropRec.find(item => item.recruitorName === e.target.value);
    if (selectedRecruiter) {
      setVal({
        ...val,
        recruitorName: selectedRecruiter.recruitorName,
        emailId: selectedRecruiter.email,
        phoneNumber: selectedRecruiter.phone
      });
    } else {
      setVal({ ...val, recruitorName: '', emailId: '', phoneNumber: '' });
    }
  };

  const handleInputChangeRec = (event, newValue) => {
    const inputValue = newValue || event?.target.value || '';
    setVal({ ...val, recruitorName: inputValue });

    const filtered = dropRec?.filter(city =>
      city?.recruitorName?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFiltered(filtered);
  };
  const [showHide, setShowHide] = useState(true)
  const [showHide1, setShowHide1] = useState(true)

  return (
    <>
      <ToastContainer />
      <Stack sx={{ flexDirection: 'row', p: 1, justifyContent: 'space-between' }}>
        <Stack sx={{ flexDirection: 'row' }}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer', color: 'text.secondary', fontSize: '0.9rem', fontWeight: '600', mr: 1 }} onClick={() => navigate(-1)} />

          <Typography
            color={'text.secondary'}
            style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '0.9rem', fontWeight: '600' }}
            onClick={() => navigate(-1)}
          >
            Bench Worksheet
          </Typography>
          <Iconify icon="ic:round-greater-than" sx={{ color: 'text.secondary', fontSize: '0.9rem', fontWeight: '600' }} />
          <Typography color={'text.textG'} sx={{ mx: 1, fontSize: '0.9rem', fontWeight: '600', textTransform: 'capitalize' }}>
            {row.fullName}
          </Typography>
          <Iconify icon="ic:round-greater-than" sx={{ color: 'text.textG', fontSize: '0.9rem', fontWeight: '600', textTransform: 'capitalize' }} />
          <Typography color={'text.textG'} sx={{ mx: 1, fontSize: '0.9rem', fontWeight: '600', textTransform: 'capitalize' }}>
            {row.status}
          </Typography>
        </Stack>
        {/* <Button variant="contained" size="small" color="custom" onClick={() => window.open(resumeUrl, '_blank')}>
          Download
        </Button> */}
      </Stack>

      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "38%",
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}>
          <Typography variant="h6" color={'#3C6BA5'} textAlign={'center'}>
            Are you sure you want to delete this {fileDlt}?
          </Typography>
          <Stack flexDirection={'row'} gap={2} mt={2} sx={{ justifyContent: 'center' }}>
            <Button color="custom" variant="contained" onClick={resumeDelete}>
              Yes
            </Button>
            <Button color="custom" variant="contained" onClick={handleCloseDeleteModal}>
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={ModalOpen}
      // onClose={handleCloseDeleteModal}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "45%",
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}>
          <Typography variant="contained" color={'red'} textAlign={'center'} fontSize={"1rem"}>
            Candidate is in Placement status. Are you sure you want to submit him again?  </Typography>
          <Stack flexDirection={'row'} gap={2} mt={2} sx={{ justifyContent: 'center' }}>
            <Button color="custom" variant="contained" onClick={handleCloseModal}>
              Yes
            </Button>
            <Button color="custom" variant="contained" onClick={() => navigate('/benchworksheet')}>
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={ModalUpload}
        onClose={handleUploadCloseModal}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "40%",
          bgcolor: 'background.paper',
          border: '3px solid #3C6BA5',
          boxShadow: 24,
          p: 3,
          borderRadius: 3,
        }}>
          <Stack
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
              fontSize="1rem"
            >
              Which file do you want to upload?
            </Typography>
          </Stack>
          <Divider />

          <Stack sx={{ flexDirection: "row" }}>
            <Stack>

              <input
                id="fileInput"
                type="file"
                accept=".doc,.docx, application/pdf"
                ref={fileInputRefResume}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)
                  // pdfUpload(e.target.files[0])

                }

              />
              <Button
                sx={{
                  marginTop: "15px",
                  color: 'text.secondary',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: "90%",
                  marginLeft: "40px",

                }}
                onClick={() => handleFileButton('resume')}
              >
                Upload Resume
              </Button>

            </Stack>

            <Stack>
              <input
                id="fileInput"
                type="file"
                ref={fileInputRefDL}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              <Button
                sx={{
                  marginTop: "15px",
                  color: 'text.secondary',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: "94%",
                  marginLeft: "35px"
                }}
                onClick={() => handleFileButton('dl')}
              >
                Upload DL
              </Button>

            </Stack>

            <Stack>
              <input
                id="fileInput"
                type="file"
                ref={fileInputRefi92}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              <Button
                sx={{
                  marginTop: "15px",
                  color: 'text.secondary',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: "94%",
                  marginLeft: "39px"
                }}
                onClick={() => handleFileButton('i92')}
              >
                Upload | 94
              </Button>

            </Stack>

          </Stack>

          <Stack sx={{ flexDirection: "row" }}>
            <Stack>
              <input
                id="fileInput"
                type="file"
                ref={fileInputRefOther}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              <Button
                sx={{
                  marginTop: "15px",
                  color: 'text.secondary',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: "107%",
                  marginLeft: "40px"
                }}
                onClick={() => handleFileButton('other')}
              >
                Upload Other Doc
              </Button>

            </Stack>

            <Stack>
              <input
                id="fileInput"
                type="file"
                ref={fileInputRefVisa}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              <Button
                sx={{
                  marginTop: "15px",
                  color: 'text.secondary',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  width: "107%",
                  marginLeft: "65px"
                }}
                onClick={() => handleFileButton('visa')}
              >
                Upload Visa Copy
              </Button>

            </Stack>
          </Stack>


        </Box>
      </Modal>

      <Grid sx={style2}>
        <Box sx={{ width: '50%', p: 1 }}>
          <Scrollbar>
            <Stack sx={{ border: 2, borderColor: 'text.secondary', borderRadius: 1 }}>
              {/* <embed
            src="http://infolab.stanford.edu/pub/papers/google.pdf"
            type="application/pdf"
            frameBorder="0" 
            // scrolling="auto"
            height="100%"
            width="100%"
        /> */}
              {/* <iframe title="resume" src={`https://docs.google.com/gview?url=${row.resumeUrl}`} width="100%" height="650px"> */}
              {/* <iframe title="resume" src={row.resumeUrl} width="100%" height="650px"> */}
              <iframe title="resume" src={suru + resumeIframe + lll}
                allow="clipboard-read; clipboard-write"
                width="100%" height="650px" frameBorder="0">
                {resumeIframe}
              </iframe>
            </Stack>

            <Divider />
          </Scrollbar>
        </Box>
        <Box sx={{ width: '50%', ml: 1 }}>
          <Stack sx={{ flexDirection: 'row', gap: 2, mb: 2, cursor: 'pointer' }}>
            <Stack
              sx={[
                { flexDirection: 'row' },
                value === 'Resume' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setValue('Resume');
              }}
            >
              <Iconify icon="bi:filetype-pdf" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography sx={{ ml: 0.5, mb: 0.5, fontSize: '0.9rem', fontWeight: '600' }}>
                Resume
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row' },
                value === 'Detail' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setValue('Detail');
              }}
            >
              <Iconify icon="mingcute:user-4-fill" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography sx={{ ml: 0.5, mb: 0.5, fontSize: '0.9rem', fontWeight: '600' }}>
                Detail
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row', ml: 1 },
                value === 'Action' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => handleChange('Action')}
            >
              <Iconify icon="material-symbols:work-history" fontSize={20} sx={{ mb: 0.5 }} />
              <Typography sx={{ ml: 0.5, mb: 0.5, fontSize: '0.9rem', fontWeight: '600' }}>
                Action
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row', ml: 0.5 },
                value === 'History' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              // onClick={() => handleChange('History')}
              onClick={() => openHistory()}
            // onClick={() => navigate('/history', { state: { Id: hisId, name: row.fullName } })}
            >
              <Iconify icon="icon-park-outline:history-query" fontSize={20} sx={{ mb: 0.5 }} />
              <Typography sx={{ ml: 0.5, mb: 0.5, fontSize: '0.9rem', fontWeight: '600' }}>
                History
              </Typography>
            </Stack>
          </Stack>
          {value === 'Resume' ? (
            <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, p: 2, gap: 2 }}>
              <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                <Stack>
                  <Button
                    type="file"
                    variant="contained"
                    size="small"
                    color="custom"
                    sx={{ width: 'fit-content' }}
                    // onClick={(e) => updateNote()}
                    onClick={(e) => handleUploadOpenModal()}

                  >
                    {/* Add New Resume */}
                    Add New
                  </Button>
                  {/* <input
                    style={{ opacity: 0, position: 'absolute', width: 120 }}
                    type="file"
                    accept=".doc,.docx, application/pdf"
                    onChange={(e) => {
                      pdfUpload(e.target.files[0]);
                    }}
                  /> */}
                </Stack>
                {upload && <Typography sx={{ color: 'text.secondary', p: 0.5 }}>Success</Typography>}
              </Stack>
              <Scrollbar sx={{ maxHeight: 300, Scrollbar: 'thin' }}>

                {/* Resume Section */}
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1.7 }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      color={'text.secondary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => { setResumeIframe(resumeArry?.resumeFile?.resumeFileUrl) }}
                    >
                      Resume{""}
                    </Typography>
                    <span style={{ color: 'white', textDecoration: 'none' }}>NewL</span>
                    <Iconify
                      icon="mdi:open-in-new"
                      fontSize={20}
                      sx={{ cursor: 'pointer', ml: -1.5 }}
                      onClick={() => window.open(suru + resumeArry?.resumeFile?.resumeFileUrl + lll, '_blank')}
                      color={'text.secondary'}
                    />
                  </Stack>
                  <Typography >Uploaded on: {moment(resumeArry?.resumeFile?.updatedat).format('ll')}</Typography>
                  <Typography
                    color={'text.secondary'}
                    sx={{ cursor: 'pointer', ml: 3 }}
                    onClick={() => window.open(resumeArry?.resumeFile?.resumeFileUrl, '_blank')}
                  >
                    Download
                  </Typography>
                </Stack>

                {/* Visa Section */}
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1.7 }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      color={'text.secondary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        setResumeIframe(visaArry?.visaFile?.visaFileUrl);
                        // alert(visaArry?.visaFile?.visaFileUrl)
                      }}
                    >
                      Visa
                    </Typography>
                    <span style={{ color: 'white', textDecoration: 'none' }}>VisaiL</span>
                    <Iconify
                      icon="mdi:open-in-new"
                      fontSize={20}
                      sx={{ cursor: 'pointer', ml: 1 }}
                      onClick={() => window.open(suru + visaArry?.visaFile?.visaFileUrl + lll, '_blank')}
                      color={'text.secondary'}
                    />
                  </Stack>
                  <Typography sx={{ ml: 0.9 }}>Uploaded on: {moment(visaArry?.visaFile?.updatedat).format('ll')}</Typography>
                  <Typography
                    color={'text.secondary'}
                    sx={{ cursor: 'pointer', ml: 3 }}
                    onClick={() => window.open(visaArry?.visaFile?.visaFileUrl, '_blank')}
                  >
                    Download
                  </Typography>
                </Stack>

                {/* DL Section */}
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1.7 }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      color={'text.secondary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setResumeIframe(dlArry?.DL_File?.DL_FileUrl)}
                    >
                      DL
                    </Typography>
                    <span style={{ color: 'white', textDecoration: 'none' }}>DLLLiiL</span>
                    <Iconify
                      icon="mdi:open-in-new"
                      fontSize={20}
                      sx={{ cursor: 'pointer', ml: 1 }}
                      onClick={() => window.open(suru + dlArry?.DL_File?.DL_FileUrl + lll, '_blank')}
                      color={'text.secondary'}
                    />
                  </Stack>
                  <Typography sx={{ ml: 0.9 }}>Uploaded on: {moment(dlArry?.DL_File?.updatedat).format('ll')}</Typography>
                  <Typography
                    color={'text.secondary'}
                    sx={{ cursor: 'pointer', ml: 3 }}
                    onClick={() => window.open(dlArry?.DL_File?.DL_FileUrl, '_blank')}
                  >
                    Download
                  </Typography>
                </Stack>

                {/* I 92 Section */}
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1.7 }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      color={'text.secondary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setResumeIframe(i92Arry?.i94_File?.i94_FileUrl)}
                    >
                      I 92
                    </Typography>
                    <span style={{ color: 'white', textDecoration: 'none' }}>DLLLL</span>
                    <Iconify
                      icon="mdi:open-in-new"
                      fontSize={20}
                      sx={{ cursor: 'pointer', ml: 1 }}
                      onClick={() => window.open(suru + i92Arry?.i94_File?.i94_FileUrl + lll, '_blank')}
                      color={'text.secondary'}
                    />
                  </Stack>
                  <Typography sx={{ ml: 1 }}>Uploaded on: {moment(i92Arry?.i94_File?.updatedat).format('ll')}</Typography>
                  <Typography
                    color={'text.secondary'}
                    sx={{ cursor: 'pointer', ml: 3 }}
                    onClick={() => window.open(i92Arry?.i94_File?.i94_FileUrl, '_blank')}
                  >
                    Download
                  </Typography>
                </Stack>

                {/* Other Document Section */}
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1.7 }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                      color={'text.secondary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setResumeIframe(otherdocArry?.otherFile?.otherFileUrl)}

                    >
                      Other Doc
                    </Typography>
                    <Iconify
                      icon="mdi:open-in-new"
                      fontSize={20}
                      sx={{ cursor: 'pointer', ml: 1 }}
                      onClick={() => window.open(suru + otherdocArry?.otherFile?.otherFileUrl + lll, '_blank')}
                      color={'text.secondary'}
                    />
                  </Stack>
                  <Typography sx={{ ml: 1 }}>Uploaded on: {moment(otherdocArry?.otherFile?.updatedat).format('ll')}</Typography>
                  <Typography
                    color={'text.secondary'}
                    sx={{ cursor: 'pointer', ml: 3 }}
                    onClick={() => window.open(otherdocArry?.otherFile?.otherFileUrl, '_blank')}
                  >
                    Download
                  </Typography>
                </Stack>

              </Scrollbar>
              <FormControl fullWidth>
                <TextField
                  // label="Notes"
                  label="Summary"
                  multiline
                  rows={4}
                  onChange={(e) => setUpnote(e.target.value)} />
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="custom"
                  sx={{ width: 'fit-content' }}
                  onClick={(e) => updateNote()}
                >
                  Save
                </Button>
              </Box>

            </Stack>
          ) : null}
          {value === 'Detail' ? (
            <>
              <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, paddingTop: 3 }}>
                <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                  {/* {value1 === "Detail" ? (
<>
                  {(edit5 === false || value1 !== "Detail")? (
                    <Iconify
                      icon="bx:edit"
                      sx={{ width: 25, height: 25, mr: 3, mt: 1 }}
                      onClick={() => editDetail()}
                    />
                  ) : (
                    <Iconify
                      icon="carbon:save"
                      sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1 }}
                      onClick={() => editDetail()}
                    />
                  )}
                  </>
                  ):(  
                    null
                  )} */}
                </Stack>

                <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    {/* Candidate ID */}
                    Employee Name
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography fontWeight={500} sx={{ p: 1, width: '50%', ml: -9 }}>
                      {(box1?.candidateid) ? "-" : '-'}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                    // value={box1?.candidateid || ''}
                    // onChange={(e) => setBox1({ ...box1, candidateid: e.target.value })}
                    />)}
                </Stack>

                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    {/* Candidate Name */}
                    Contact Name
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography fontWeight={500} sx={{ p: 1, width: '38%', ml: -9 }}>
                      {box1.candidateName}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.candidateName || ''}
                      onChange={(e) => setBox1({ ...box1, candidateName: e.target.value })}
                    />)}
                </Stack>
                <Divider />

                {/* <Divider /> */}
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    Email
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography fontWeight={500} sx={{ p: 1, width: '45%', ml: -9 }}>
                      {box1.box1email}
                    </Typography>
                  ) : (
                    <TextField
                      disabled={!emailDiss1}
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.box1email || ''}
                      onChange={(e) => setBox1({ ...box1, box1email: e.target.value })}
                    />)}
                </Stack>
                <Divider />

                <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    Contact
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography fontWeight={500} sx={{ p: 1, width: '50%', ml: -9 }}>
                      {box1.box1phone}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.box1phone || ''}
                      onChange={(e) => setBox1({ ...box1, box1phone: e.target.value })}
                    />)}

                </Stack>
                <Divider sx={{ mb: 0.5 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  {/* <Typography color={'text.secondary'} sx={{p:1, width: '50%' }}>
                     Visa Type
                  </Typography>
                  {fifth ? (
                  <Typography sx={{ width: '50%' ,p:1}}>{row.visaType}</Typography>
                ):(
                  <TextField
                  size="small"
                  sx={{ width: '60%', py: 0.5, ml:-12 }}
                  value={box1?.visaType || ''}
                  onChange={(e) => setBox1({ ...box1, visaType: e.target.value })}
                />)} */}

                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Visa Type
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: -9 }}>{row?.visaType}</Typography>
                  ) : (
                    <FormControl variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>
                      <InputLabel >Visa Type</InputLabel>
                      <Select
                        label="Visa Type"
                        // options={visalist}
                        // label="Visa"
                        value={box1.box1visaType}
                        onChange={(e) => setBox1({ ...box1, box1visaType: e.target.value })}
                      >
                        {visalist.map((item, index) => (
                          <MenuItem key={index} value={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Divider sx={{ mb: 1.3, mt: 0.5 }} />
                <Grid sx={{ flexDirection: "row" }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%', ml: 1.3, mt: -0.9 }}>
                    Validity
                  </Typography>

                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: 28.3, mt: -4.5, pb: 3 }}>
                      {row.validity ? dayjs(row.validity).format('D MMM YYYY') : '-'}
                    </Typography>
                    // <Typography sx={{ width: '50%', p: 1, ml: -9 }}>{row?.validity}</Typography>
                  ) : (

                    <FormControl size="small" sx={{ width: '58.5%', ml: 25.5, mt: -4.3 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Validity"
                          slotProps={{ textField: { size: "small" } }}
                          value={formValues.fromDate ? dayjs(formValues.fromDate) : null}
                          onChange={(date) => handleDateChange(date, 'fromDate')}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                </Grid>

                <Divider sx={{ mb: 1.6, mt: - 1.6 }} />
                <Grid sx={{ flexDirection: "row" }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%', ml: 1.3, mt: -0.9 }}>
                    Years of Experience
                  </Typography>

                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: 28.4, mt: -4.5, pb: 3 }}>{row.experienceInYear}{" Yrs"}</Typography>
                    // <Typography sx={{ width: '50%', p: 1, ml: -9 }}>{row?.exp}</Typography>
                  ) : (

                    <FormControl fullWidth variant="outlined" size="small" sx={{ width: '58.5%', ml: 25.5, mt: -4.3 }}>
                      <InputLabel >Years of Experience</InputLabel>
                      <Select

                        value={box1.box1Exp}
                        label="Years of Experience"
                        // onChange={handleChange} 
                        onChange={(e) => setBox1({ ...box1, box1Exp: e.target.value })}

                      // size="small"
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10+</MenuItem>
                        <MenuItem value={15}>15+</MenuItem>
                        <MenuItem value={20}>20+</MenuItem>
                        <MenuItem value={25}>25+</MenuItem>
                        <MenuItem value={30}>30+</MenuItem>
                        <MenuItem value={35}>35+</MenuItem>
                        <MenuItem value={40}>40+</MenuItem>
                        <MenuItem value={45}>45+</MenuItem>
                        {/* {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year === 0 ? 'Less than 1 year' : `${year} ${year === 1 ? 'year' : 'years'}`}
          </MenuItem>
        ))} */}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Divider sx={{ mb: 1, mt: -1.4 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'}
                    // sx={{ p: 1, width: '34%',mt:4 }}
                    sx={{
                      p: 1,
                      width: '34%',
                      mt: value1 === "Detail" ? 4 : 0.7,
                    }}
                  >
                    Skills
                  </Typography>
                  {value1 !== "Detail" ? (
                    //  <Stack sx={{ width: '60%', gap: 0.5 }}>
                    <Grid container sx={{ display: 'flex', flexWrap: 'wrap', p: 1, width: '57%', ml: 3 }}>
                      {skilllst1 && skilllst1.length > 0 ? (
                        skilllst1.filter(item => item).map((item, index) => (
                          <Chip
                            key={index}
                            label={item}
                            sx={{ marginRight: 0.6, marginBottom: 1 }}
                          />
                        ))
                      ) : null}
                    </Grid>
                    // </Stack>
                  ) : (
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      onChange={(event, newValue) => {
                        setSkilllst([...newValue]);
                      }}
                      options={skillss}
                      getOptionLabel={(option) => option?.title || ''}
                      value={skilllst}
                      filterSelectedOptions
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option?.title);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `${inputValue}`,
                          });
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Skills"
                          placeholder="Skills"
                          sx={{
                            width: '350px',
                            '& .MuiInputBase-root': {
                              minHeight: '56px',
                              maxHeight: 'auto',
                              overflowY: 'auto',
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: '50%',
                      }}
                    />
                  )}


                </Stack>

                <Divider sx={{ mt: 1, mb: 1 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>

                  {/* <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Expected Compensation
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ p: 1, width: '50%' }}>
                    
                      {' $'}
                      {row.expectedCompensation}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.expectedCompensation || ''}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,

                      }}
                      onChange={(e) => setBox1({ ...box1, expectedCompensation: e.target.value })

                      }
                 
                    />)} */}
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    Compensation Type
                  </Typography>

                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '30%', p: 1, ml: -8 }}>{row?.chargeType}</Typography>
                  ) : (

                    <FormControl fullWidth variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>
                      <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={box1.box1ChargeType}
                        label="Compensation Type"
                        onChange={(e) => setBox1({ ...box1, box1ChargeType: e.target.value })}

                      // onChange={(e) => setComType(e.target.value)}
                      // onChange={(e) => setComType(e.target.value)}
                      >
                        <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                        <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: '50%', color: 'text.secondary' }}>
                    Expected Compensation
                  </Typography>

                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '30%', p: 1, ml: -8 }}>{(row?.expectedCompensation) && ("$")}{row?.expectedCompensation}</Typography>
                  ) : (


                    <TextField
                      size="small"
                      // width="60%"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      id="outlined-basic"
                      label="Expected Compensation( In Dollars )*"
                      placeholder="Expected Compensation"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={box1.box1compensation}
                      type="text"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: comType && (
                          <InputAdornment position="end">{comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                        ),
                      }}
                      // onChange={(e) => setExpectedCom(e.target.value)}
                      // onChange={(e) => setBox1({ ...box1, box1compensation: e.target.value })}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (/^\d*\.?\d*$/.test(newValue)) {
                          setBox1({ ...box1, box1compensation: newValue });
                        }
                      }}
                    />
                  )}
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
                {/* <Stack sx={{ flexDirection: 'row', px: 1}}>
                  <Typography color={'text.secondary'} sx={{p:1, width: '60%' }}>
                    Job ID search option - (Optional)
                  </Typography>
                  <Typography sx={{ width: '40%',p:1 }}>{row.jobId}</Typography>
                </Stack>
                <Divider /> */}
                {/* <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Location
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ p: 1, width: '50%' }}>{row.city || row.address}</Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1.box1city || row.address}
                      onChange={(e) => setBox1({ ...box1, box1city: e.target.value })}
                    />)}
                </Stack> */}


                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Country
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: -8 }}>{row?.country}</Typography>
                  ) : (

                    <FormControl variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>

                      <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={box1.box1Country}
                        // value={country}
                        label="Select Country"
                        // onChange={(e) => setCountry(e.target.value)}
                        onChange={(e) => setBox1({ ...box1, box1Country: e.target.value })}
                      // setCountry(e.target.value)}}

                      >
                        <MenuItem value="USA">USA</MenuItem>
                        <MenuItem value="CANADA">CANADA</MenuItem>;

                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />

                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    City & Pincode
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '30%', p: 1, ml: -8 }}>{row?.city}</Typography>
                  ) : (

                    <FormControl variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>

                      <CustomComplete
                        placeholder={'City & Pincode'}
                        option={cityData}
                        propVal={cityy}
                        childFuc={childFuc}
                        fullVal={fullVal}
                        fetch={'state'}
                        cityStateStyling1={cityStateStylings}

                      />
                    </FormControl>
                  )}
                </Stack>


                <FormControl size="small">
                  {cityandstate ? (
                    <Stack sx={{ ml: 26, flexDirection: 'row' }}>
                      {tabb === "Hide" && (

                        <Typography color={'text.textG'} variant="body">
                          {cityandstate.city},{" "}
                        </Typography>
                      )}
                      {tabb === "Hide" && (

                        <Typography color={'text.textG'} variant="body" sx={{ ml: 0.7 }}>
                          {cityandstate.state}
                          {/* {console.log("=====cityandstate.state===>>>", cityandstate.state)} */}
                        </Typography>
                      )}
                    </Stack>
                  ) : null}
                </FormControl>
                <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Zip Code
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: -8 }}>{row?.zipcode}</Typography>
                  ) : (

                    <FormControl variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>

                      {/* <FormControl fullWidth size="small"> */}
                      <TextField
                        size="small"
                        // id="outlined-basic"
                        // label="Zip code"
                        // placeholder="Zip code"
                        // variant="outlined"
                        // InputLabelProps={{ shrink: true }}
                        value={valueee?.zip}
                        // type="number"
                        onChange={(e) => setValueee({ ...valueee, zip: e.target.value })}
                      />
                      {/* </FormControl> */}

                    </FormControl>
                  )}
                </Stack>
                {/* <Divider/> */}
                <Divider sx={{ mt: 0.5, mb: 0.5 }} />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Relocate
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ p: 1, width: '50%', ml: -7 }}>{row?.reallocate}</Typography>


                  ) : (

                    <FormControl variant="outlined" size="small" sx={{ width: '60%', ml: -12 }}>
                      <InputLabel >Relocate</InputLabel>
                      <Select
                        label="Relocate"
                        // options={visalist}
                        // label="Visa"
                        value={box1.box1reallocate}
                        onChange={(e) => setBox1({ ...box1, box1reallocate: e.target.value })}
                      >
                        {/* {visalist.map((item, index) => ( */}
                        <MenuItem value={"yes"}> Yes</MenuItem>
                        <MenuItem value={"no"}> No</MenuItem>


                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                {/* <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Desired Work Type
                  </Typography>
                  <Typography sx={{ p: 1, width: '50%' }}>-</Typography>
                </Stack> */}
                {/* <Divider /> */}
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Tax Term
                  </Typography>

                  {value1 !== "Detail" ? (
                    <Typography sx={{ width: '50%', p: 1, ml: -7 }}>{row?.taxTerm}</Typography>
                  ) : (
                    <>
                      {/* {fifth ? (
                  <Typography sx={{p:1, width: '50%' }}>{row.taxTerm}</Typography>
                ):(
                  <TextField
                  size="small"
                  sx={{ width: '60%', py: 0.5, ml:-12 }}
                  value={box1?.taxTerm || ''}
                  onChange={(e) => setBox1({ ...box1, taxTerm: e.target.value })}
                />)} */}

                      <FormControl sx={{ ml: -13 }}>
                        {/* <FormLabel id="demo-radio-buttons-group-label">Tax Term</FormLabel> */}
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="c2c"
                          disabled={value1 !== "Detail"}
                          name="radio-buttons-group"
                          value={box1.box1taxTerm}
                          onChange={(e) => setBox1({ ...box1, box1taxTerm: e.target.value })}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            ml: 1,
                          }}
                        >
                          <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                          {/* <FormControlLabel value="w2" control={<Radio />} label="W2" /> */}
                          <FormControlLabel value="cth" control={<Radio />} label="CTH" />
                          {/* <FormControlLabel value="1099" control={<Radio />} label="1099" /> */}
                          {/* <FormControlLabel value="T4" control={<Radio />} label="T4" /> */}
                          {/* <FormControlLabel value="INC" control={<Radio />} label="INC" /> */}
                          <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}
                </Stack>
                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Job Title
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ p: 1, width: '50%', ml: -7 }}>{row.jobTitle}</Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.box1jobTitle || ''}
                      onChange={(e) => setBox1({ ...box1, box1jobTitle: e.target.value })}
                    />)}
                </Stack>

                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography color={'text.secondary'} sx={{ p: 1, width: '50%' }}>
                    Current Company
                  </Typography>
                  {value1 !== "Detail" ? (
                    <Typography sx={{ p: 1, width: '50%', ml: -7 }}>{row.currentCompany}</Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ width: '60%', py: 0.5, ml: -12 }}
                      value={box1?.box1Currcompany || ''}
                      onChange={(e) => setBox1({ ...box1, box1Currcompany: e.target.value })}
                    />)}
                </Stack>
                <Divider />
                {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Tax Term
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.tax}
                  </Typography>
                ) : (
                  <FormControl size="small" sx={{ width: '60%', py: 0.5 }}>
                    <Select
                      size="small"
                      // sx={{ py:0.5,width: '60%' }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={box3.tax}
                      label="Tax Term"
                      onChange={(e) => setBox3({ ...box3, tax: e.target.value })}
                    >
                      <MenuItem value={'c2c'}>C2C</MenuItem>
                      <MenuItem value={'w2'}>W2</MenuItem>
                      <MenuItem value={'1099'}>1099</MenuItem>
                      <MenuItem value={'t4'}>T4</MenuItem>;<MenuItem value={'inc'}>INC</MenuItem>
                      <MenuItem value={'fulltime'}>Full-Time</MenuItem>;
                    </Select>
                  </FormControl>
                )}
                </Stack> */}

                <Divider />
                <Stack sx={{ p: 2 }}>
                  <Stack justifyContent={'space-between'} flexDirection={'row'} width={'100%'}>
                    <Typography color={'text.secondary'}>Note...(max 2000 char)</Typography>
                    {/* {!editable ? (
                      <Iconify icon="bx:edit" onClick={(e) => setEditable(true)} sx={{ cursor: 'pointer' }} />
                    ) : (
                      <Iconify
                        icon="material-symbols:cancel-outline"
                        onClick={(e) => setEditable(!editable)}
                        sx={{ cursor: 'pointer' }}
                      />
                    )} */}
                  </Stack>
                </Stack>
                {!editable ? (
                  <Stack px={2} gap={2} mb={8}>
                    <TextField
                      disabled={value1 !== "Detail"}
                      id="outlined-multiline-static"
                      value={upnote}
                      sx={{ width: '100%' }}
                      // label="Multiline"
                      multiline
                      rows={4}
                    // defaultValue={upnote}
                    />
                  </Stack>
                ) : (
                  <Stack px={2} gap={2} mb={2} alignItems={'center'}>
                    {/* <Stack sx={{ width: 500 }}>
                      <CKEditor
                        editor={ClassicEditor}
                        // data="<p>{value.notes}<br><br></p>"
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setUpnote(data);
                          console.log('ckeditor------data', data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Stack> */}
                    <TextField
                      id="outlined-multiline-static"
                      value={upnote}
                      // label="Multiline"
                      // disabled={value1 !== "Detail"}

                      multiline
                      rows={4}
                      sx={{ width: '100%' }}
                      // defaultValue={upnote}
                      onChange={(e) => setUpnote(e.target.value)}
                    />
                    {/* {value1 === "Detail" && ( */}

                    <Button
                      type="submit"
                      variant="contained"
                      color="custom"
                      sx={{ width: 'fit-content' }}
                      onClick={(e) => { if (value1 === "Detail") { editResume() }; updateNote() }}
                    >
                      Save
                    </Button>
                    {/* )} */}
                  </Stack>
                )}
              </Stack>
            </>
          ) : null}
          {value === 'Action' ? (
            <Stack>
              <Dialog
                open={openConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={confirmClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle textAlign={'center'} fontSize={"15px"} color={'text.secondary'}>
                  {'Duplicate Detected'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText color={'red'} textAlign={'center'}>
                    <Typography variant="body" fontSize={"15px"}>{confirmMsg}</Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button color="custom" variant="contained" onClick={() => confirmClose()}>
                    Cancel
                  </Button>
                  <Button color="custom" variant="contained" onClick={() => Submitted()}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Stack sx={{ flexDirection: 'row' }} gap={1}>
                <Stack sx={[btn === 'submitted' ? { borderBottom: 3, borderColor: 'text.secondary' } : {}, { p: 0.5 }]}>
                  <Button
                    onClick={() => {
                      setBtn('submitted');
                    }}
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="small"
                    disabled={row.status === 'placement' || row.status === 'started' || filterTab !== 'submitted'}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                  </Button>
                </Stack>
                <Stack sx={[btn === 'interview' ? { borderBottom: 3, borderColor: 'text.secondary' } : {}, { p: 0.5 }]}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="small"
                    disabled={row.status !== 'submitted' || filterTab !== 'interview'}
                    onClick={() => {
                      setBtn('interview');
                    }}
                  >
                    Interview
                  </Button>
                </Stack>
                <Stack sx={[btn === 'placement' ? { borderBottom: 3, borderColor: 'text.secondary' } : {}, { p: 0.5 }]}>
                  <Button
                    variant="contained"
                    size="small"
                    color="custom"
                    disabled={row.status !== 'interview' || filterTab !== 'placement'}
                    // disabled={filterTab === "worksheet"}
                    onClick={() => {
                      setBtn('placement');
                    }}
                  >
                    Placement
                  </Button>
                </Stack>
                {/* <Stack
                  sx={[btn === 'backout' ? { borderBottom: 3, borderColor: 'text.secondary' } : {}, { p: 0.5 }]}
                  onClick={() => {
                    setBtn('backout');
                  }}
                >
                  <Button variant="outlined" disabled size="large">
                    Backout
                  </Button>
                </Stack> */}
                <Stack sx={[btn === 'start' ? { borderBottom: 3, borderColor: 'text.secondary' } : {}, { p: 0.5 }]}>
                  <Button
                    type="start"
                    variant="contained"
                    color="custom"
                    size="small"
                    // disabled={filterTab === "worksheet"}
                    disabled={row.status !== 'placement'}
                    onClick={() => {
                      setBtn('start');
                    }}
                  >
                    Start
                  </Button>
                </Stack>
              </Stack>
              {btn === 'submitted' ? (
                <Stack sx={{ width: '60%', mt: 2 }} gap={3}>
                  <FormControl>
                    <Typography fontWeight={500}>Submit to</Typography>
                    {/* <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="position"
                      name="radio-buttons-group"
                      value={subto}
                      onChange={(e) => setSubto(e.target.value)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="position" control={<Radio />} label="Position" />
                      <FormControlLabel value="client" control={<Radio />} label="Client" />
                    </RadioGroup> */}
                  </FormControl>
                  {/* {subto === 'position' && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jobid}
                        label="Job Id"
                        onChange={(e) => setJobid(e.target.value)}
                    
                      >
                       
                        {joblst.map((item) => {
                          return <MenuItem value={item.jobId}>{item.jobId} : {item.jobTitle}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  )} */}
                  {/* {subto === 'client' && (
                    <Stack>
                      <FormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={cname}
                          label="Select Client"
                          onChange={(e) => setCname(e.target.value)}
                        >
                          {clientlst.map((item, index) => {
                            return <MenuItem value={index}>{item.clientCompanyId} : {item.companyName}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>

                      <TextField
                        sx={{ mt: 2 }}
                        id="outlined-basic"
                        label="Position Name"
                        placeholder="Position Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={pname}
                        onChange={(e) => setPname(e.target.value)}
                      />
                    </Stack>
                  )} */}


                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Client Name"
                    placeholder="Client Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={val.submitTo}
                    // onChange={(e) => setPname(e.target.value)}
                    onChange={(e) => setVal({ ...val, submitTo: e.target.value })}
                  />
                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Position Name"
                    placeholder="Position Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={pname}
                    onChange={(e) => setPname(e.target.value)}
                  />

                  <FormControl variant="outlined" sx={{}}>
                    <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={country1}
                      // label="Select Country"
                      // onChange={(e) => setCountry1(e.target.value)}
                      value={box1.box1Country1}
                      // value={country}
                      label="Select Country"
                      // onChange={(e) => setCountry(e.target.value)}
                      onChange={(e) => setBox1({ ...box1, box1Country1: e.target.value })}
                    // setCountry(e.target.value)}}

                    >
                      <MenuItem value="USA">USA</MenuItem>
                      <MenuItem value="CANADA">CANADA</MenuItem>;

                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" size="small" sx={{}}>
                    <CustomComplete
                      placeholder={'City & Pincode'}
                      option={cityData}
                      propVal={cityy}
                      childFuc={childFuc}
                      fullVal={fullVal}
                      fetch={'state'}

                    />
                  </FormControl>

                  <FormControl size="small" sx={{ mt: -2.5, mb: -2.5 }}>
                    {cityandstate ? (
                      <Typography color={'text.textG'} variant="body">
                        {cityandstate?.city},{" "} {cityandstate?.state}
                      </Typography>
                    ) : null}
                  </FormControl>

                  <FormControl variant="outlined" size="small" sx={{}}>
                    <TextField
                      // size="small"
                      value={valueee?.zip}
                      onChange={(e) => setValueee({ ...valueee, zip: e.target.value })}
                    />
                  </FormControl>

                  <FormControl sx={{}}>
                    <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Job Type"
                      value={value2.jobType}
                      onChange={(e) => setValue2({ ...value2, jobType: e.target.value })}
                    >
                      <MenuItem value="remote">Remote Job</MenuItem>
                      <MenuItem value="onsite">Onsite Job</MenuItem>
                      <MenuItem value="hybrid">Hybrid Job</MenuItem>
                      {/* <MenuItem value="wfh">WFH</MenuItem> */}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comType}
                      label="Compensation Type"
                      onChange={(e) => { setComType(e.target.value); setTax('') }}
                    >
                      <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                      <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                    </Select>
                  </FormControl>

                  <TextField
                    id="outlined-basic"
                    label="Expected Compensation( In Dollars )*"
                    placeholder="Expected Compensation"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={expectedCom}
                    type='text'
                    // onWheel={(e)=>{e.target.blur()}}
                    //   onFocus={(e) =>
                    //     e.target.addEventListener(
                    //         "wheel",
                    //         (e) => e.preventDefault(),
                    //         { passive: false }
                    //     )
                    // }
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: comType && (
                        <InputAdornment position="end">{comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                      ),
                    }}
                    // onChange={(e) => setExpectedCom(e.target.value)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (/^\d*\.?\d*$/.test(newValue)) {
                        setExpectedCom(newValue);
                      }
                    }}
                  />
                  {/* <TextField
                    id="outlined-basic"
                    label="Rate / Salary in $"
                    placeholder="Rate / Salary in $"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={rate}
                    type="number"
                    onChange={(e) => setRate(e.target.value)}
                  /> */}
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Tax Term</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="c2c"

                      name="radio-buttons-group"
                      value={tax}
                      onChange={(e) => setTax(e.target.value)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >

                      <FormControlLabel value="c2c" disabled={comType !== 'hourly'} control={<Radio />} label="C2C" />
                      <FormControlLabel value="cth" disabled={comType !== 'hourly'} control={<Radio />} label="CTH" />
                      {/* <FormControlLabel value="w2" control={<Radio />} label="W2" /> */}
                      {/* <FormControlLabel value="1099" control={<Radio />} label="1099" />
                      <FormControlLabel value="T4" control={<Radio />} label="T4" />
                      <FormControlLabel value="INC" control={<Radio />} label="INC" /> */}
                      <FormControlLabel value="fulltime" disabled={comType !== 'Annually'} control={<Radio />} label="Full-Time" />
                    </RadioGroup>
                  </FormControl>

                  <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography fontWeight={500}>Need to send Automailer to client</Typography>
                    <Switch checked={checked} onChange={handleChangebtn} inputProps={{ 'aria-label': 'controlled' }} />
                  </Stack>
                  {checked === true && (
                    <Stack>
                      <TextField
                        id="outlined-basic"
                        label="Subject"
                        placeholder="Subject"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={emailsub}
                        onChange={(e) => setEmailsub(e.target.value)}
                      />
                      {/* <TextField
                        sx={{ mt: 2 }}
                        id="outlined-multiline-static"
                        label="Body"
                        placeholder="Body"
                        multiline
                        rows={4}
                        value={emailbody}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setEmailbody(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                    </Stack>
                  )}

                  {/* <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Recruiter Company Name"
                    placeholder="Recruiter Company Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    
                    value={val.recruitorCompany}
                    // onChange={(e) => {setVal({ ...val, recruitorCompany: e.target.value }); recAndCompList("", "")}}
                    onChange={handleInputChangeComp}
                  /> */}

                  {/* <Autocomplete
  options={filtered.length > 0 ? filtered : [val.recruitorCompany]}
  getOptionLabel={(option) => option || ''}
  value={filtered.find(city => city === val.recruitorCompany) || null} 
  onInputChange={handleInputChangeComp}
  onChange={handleChangeComp}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Recruiter Company Name"
      placeholder="Recruiter Company Name"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  )}
  freeSolo 
/> */}
                  {showHide && (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Recruiting Company Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Recruiting Company Name"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Recruiting Company Name"
                        value={val.recruitorCompany}
                        onChange={(e) => setVal({ ...val, recruitorCompany: e.target.value })}
                      >
                        <Button
                          sx={{
                            mb: 0.5,
                            width: "100%",
                            backgroundColor: 'text.secondary',
                            color: "white",
                            '&:hover': {
                              backgroundColor: 'text.secondary',
                            }
                          }}
                          onClick={() => {
                            setShowHide(false); setShowHide1(false);
                            setVal({ ...val, recruitorCompany: '', recruitorName: "", emailId: "", phoneNumber: "" })
                          }}
                        >
                          Add New
                        </Button>

                        {/* <MenuItem value="" disabled>
  Select an option
</MenuItem> */}
                        {dropComp
                          ?.filter(item => item !== '' && item !== null && item !== undefined)
                          .map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}

                      </Select>
                    </FormControl>
                  )}
                  {!showHide && (
                    <TextField
                      id="outlined-basic"
                      label="Recruiter Company Name"
                      placeholder="Recruiter Company Name"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={val.recruitorCompany}
                      onChange={(e) => setVal({ ...val, recruitorCompany: e.target.value })}
                    />
                  )}


                  {showHide1 && (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Recruiter Name</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Recruiter Name"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Recruiter Name"
                        value={val.recruitorName}
                        onChange={(e) => handleChangeRec(e)}
                      >

                        {dropRec
                          ?.filter(item => item !== '' && item !== null && item !== undefined)
                          .map((item, index) => (
                            <MenuItem key={index} value={item.recruitorName}>
                              {item.recruitorName}
                            </MenuItem>
                          ))}

                        <Button
                          sx={{
                            mb: 0.5,
                            width: "100%",
                            backgroundColor: 'text.secondary',
                            color: "white",
                            '&:hover': {
                              backgroundColor: 'text.secondary',
                            }
                          }}
                          onClick={() => {
                            setShowHide1(false);
                            setVal({ ...val, recruitorName: "", emailId: "", phoneNumber: "" })
                          }}
                        >
                          Add New
                        </Button>
                      </Select>
                    </FormControl>
                  )}
                  {!showHide1 && (
                    <TextField
                      id="outlined-basic"
                      label="Recruiter Name"
                      placeholder="Recruiter Name"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={val.recruitorName}
                      onChange={(e) => setVal({ ...val, recruitorName: e.target.value })}
                    />
                  )}
                  {/* <Autocomplete
  options={filtered.length > 0 ? filtered : dropRec} 
  getOptionLabel={(option) => option.recruitorName || ''}
  value={filtered.find(city => city.recruitorName === val.recruitorName) || null}
  onInputChange={handleInputChangeRec}
  onChange={handleChangeComp1}
  onFocus={recList}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Recruiter Name"
      placeholder="Recruiter Name"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  )}
  freeSolo 
/> */}

                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={val.emailId}
                    onChange={(e) => setVal({ ...val, emailId: e.target.value })}
                  />
                  <TextField
                    // sx={{ mt: 2 }}
                    id="outlined-basic"
                    label="Phone"
                    placeholder="Phone"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={val.phoneNumber}
                    onChange={(e) => setVal({ ...val, phoneNumber: e.target.value })}
                  />
                  <TextField
                    sx={{}}
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    value={actionnotes}
                    onChange={(e) => setActionnotes(e.target.value)}
                  // defaultValue="Default Value"
                  />
                  {/* <Stack sx={{ width: 500 }}>
                    <CKEditor
                      editor={ClassicEditor}
                      // data="<p>{value.notes}<br><br></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setActionnotes(data);
                        console.log('ckeditor------data', data);
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    />
                  </Stack> */}
                  {/* <Button
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="large"
                    sx={{ width: 230 }}
                    onClick={(e) => ConfirmSubmitted('Submit')}
                  >
                    Submitt
                  </Button> */}
                  <LoadingButton
                    loading={loadBtn}
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="medium"
                    sx={{ width: 230 }}
                    onClick={(e) => ConfirmSubmitted('Submit')}>
                    Submit
                  </LoadingButton>

                </Stack>
              ) : null}
              {btn === 'interview' ? (
                <Stack>
                  <Typography fontWeight={500} sx={{ mt: 1 }}>
                    Select Interview Date
                  </Typography>
                  <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                    <FormControl sx={{ width: '100%', mt: 1 }}>
                      <InputLabel id="demo-simple-select-label">Select Interview Level</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Interview Level"
                        value={leveltype}
                        onChange={(e) => setLeveltype(e.target.value)}
                      >
                        <MenuItem value={'l1'}>Level 1</MenuItem>
                        <MenuItem value={'l2'}>Level 2</MenuItem>
                        <MenuItem value={'l3'}>Level 3</MenuItem>
                        <MenuItem value={'l4'}>Level 4</MenuItem>
                        <MenuItem value={'l5'}>Level 5</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            // disablePast
                            sx={{ width: '100%' }}
                            label="MM/DD/YY"
                            value={valuedata}
                            onChange={(e) => {
                              setValuedata(e);
                              // setIdate({ ...idate, day: e.$D, month: e.$M + 1, year: e.$y });
                              // console.log('date----------', e.$D, '/', e.$M + 1, '/', e.$y);
                            }}
                            slotProps={{
                              textField: {
                                readOnly: true,
                              },
                            }}

                          // defaultValue={dayjs('2022-04-17')}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            sx={{ width: '100%' }}
                            label="Start Time"
                            value={startTime}
                            onChange={(e) => {
                              setStarttime(e);
                              // setItime({ ...itime, hrs: e.$H, min: e.$m });
                              // console.log('===============time===', e.$H, ':', e.$m);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            sx={{ width: '100%' }}
                            label="End Time"
                            value={endtime}
                            onChange={(e) => {
                              setEndtime(e);
                              // setItime({ ...itime, hrs: e.$H, min: e.$m });
                              // console.log('===============time===', e.$H, ':', e.$m);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                  <FormControl sx={{ width: '50%', mt: 1 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Time zone</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      label="Time Zone"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Time zone"
                      value={timez}
                      onChange={(e) => setTimez(e.target.value)}
                    >
                      <MenuItem value={'est'}>EST</MenuItem>
                      <MenuItem value={'pst'}>PST</MenuItem>
                      <MenuItem value={'mst'}>MST</MenuItem>
                      <MenuItem value={'cst'}>CST</MenuItem>
                    </Select>
                  </FormControl>

                  <Typography fontWeight={500} sx={{ mt: 2 }}>
                    Select Interview mode
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="c2c"
                      name="radio-buttons-group"
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="phoneic" control={<Radio />} label="Phone" />
                      <FormControlLabel value="video" control={<Radio />} label="Video Conference" />
                      <FormControlLabel value="onsite" control={<Radio />} label="Inperson" />
                    </RadioGroup>
                  </FormControl>
                  {mode === 'video' ? (
                    <Stack sx={{ mt: 1, width: '50%' }} gap={3}>
                      {/* <TextField
                      id="outlined-basic"
                      label="IMs / Video Platform"
                      placeholder="IMs / Video Platform"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={interdata.vPlatform}
                      onChange={(e) => setInterdata({ ...interdata, vPlatform: e.target.value })}
                    /> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IMs / Video Platform</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="IMs / Video Platform"
                          value={interdata.vPlatform}
                          onChange={(e) => setInterdata({ ...interdata, vPlatform: e.target.value })}
                        >
                          <MenuItem value="Microsoft Team">Microsoft Team</MenuItem>
                          <MenuItem value="Google Meet">Google Meet</MenuItem>
                          <MenuItem value="Zoom">Zoom</MenuItem>
                          <MenuItem value="Slack">Slack</MenuItem>
                          <MenuItem value="Dialpad Meetings">Dialpad Meetings</MenuItem>
                          <MenuItem value="TrueConf Online">TrueConf Online</MenuItem>
                          <MenuItem value="Free Conference">Free Conference</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        id="outlined-basic"
                        label="Meeting URL"
                        placeholder="Meeting URL"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={interdata.murl}
                        onChange={(e) => setInterdata({ ...interdata, murl: e.target.value })}
                      />
                    </Stack>
                  ) : null}

                  {mode === 'onsite' ? (
                    <Stack sx={{ mt: 3, width: '50%' }} gap={3}>
                      {/* <TextField
                        id="outlined-basic"
                        label="Company Name"
                        placeholder="Company Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={interdata.companyname}
                        onChange={(e) => setInterdata({ ...interdata, companyname: e.target.value })}
                      /> */}
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        placeholder="Address"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={interdata.caddress}
                        onChange={(e) => setInterdata({ ...interdata, caddress: e.target.value })}
                      />
                    </Stack>
                  ) : null}
                  <Stack sx={{ mt: 3, width: '50%' }} gap={3}>
                    <TextField
                      id="outlined-basic"
                      label="Client Name"
                      placeholder="Client Name"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={interdata.clientName}
                      onChange={(e) => setInterdata({ ...interdata, clientName: e.target.value })}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Hiring Manager Name"
                      placeholder="Hiring Manager Name"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={interdata.managerName}
                      onChange={(e) => setInterdata({ ...interdata, managerName: e.target.value })}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Contact Number (optional)"
                      placeholder="Contact Number (optional)"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={interdata.contactNo}
                      onChange={(e) => setInterdata({ ...interdata, contactNo: e.target.value })}
                    />
                    <TextField
                      id="outlined-multiline-static"
                      label="Notes"
                      multiline
                      rows={4}
                      value={interdata.inote}
                      onChange={(e) => setInterdata({ ...interdata, inote: e.target.value })}

                    // defaultValue="Default Value"
                    />
                    {/* <Stack sx={{ width: 500 }}>
                      <CKEditor
                        editor={ClassicEditor}
                        // data="<p>{value.notes}<br><br></p>"
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setInterdata({ ...interdata, inote: data });
                          console.log('ckeditor------data', { event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Stack> */}
                    {/* <Button
                      type="submit"
                      variant="contained"
                      color="custom"
                      size="large"
                      sx={{ width: 200 }}
                      onClick={(e) => setInterview()}
                    >
                      Schedule Now
                    </Button> */}
                    <LoadingButton
                      loading={loadBtn}
                      type="submit"
                      variant="contained"
                      color="custom"
                      size="medium"
                      sx={{ width: 200 }}
                      onClick={(e) => setInterview()}>
                      Schedule Now
                    </LoadingButton>
                  </Stack>
                </Stack>
              ) : null}
              {btn === 'placement' ? (
                <Stack fullWidth sx={{ ml: 2 }}>
                  <FormControl fullWidth>
                    <Typography fontWeight={500} sx={{ my: 2 }}>
                      Placement Status
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="c2c"
                      name="radio-buttons-group"
                      value={placestatus}
                      onChange={(e) => setPlacestatus(e.target.value)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="placed" control={<Radio />} label="Placed" />
                      <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                    </RadioGroup>
                  </FormControl>
                  {placestatus === 'placed' && (
                    <Grid container sx={{ mt: 1, width: '100%', ml: 0.2 }} gap={3}>
                      <FormControl sx={{ width: '41.5%' }}>
                        <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Job Type"
                          value={interdata.jobType}
                          onChange={(e) => setInterdata({ ...interdata, jobType: e.target.value })}
                        >
                          <MenuItem value="onsite">On Site</MenuItem>
                          <MenuItem value="hybrid">Hybrid</MenuItem>
                          <MenuItem value="wfh">WFH</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: '41.5%' }}>
                        <InputLabel id="demo-simple-select-label">Offered Job</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Offered Job"
                          value={interdata.offeredJob}
                          onChange={(e) => setInterdata({ ...interdata, offeredJob: e.target.value })}
                        >
                          <MenuItem value="fulltime">Full Time</MenuItem>
                          <MenuItem value="contract">Contract</MenuItem>
                          <MenuItem value="cth">CTH</MenuItem>
                        </Select>
                      </FormControl>
                      {interdata.offeredJob === 'fulltime' ? (
                        <>
                          <TextField
                            sx={{ width: '41.5%' }}
                            id="outlined-multiline-static"
                            label="Annual Salary"
                            value={interdata.AnnualSalary}
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">K</InputAdornment>,
                            }}
                            onChange={(e) => setInterdata({ ...interdata, AnnualSalary: e.target.value })}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Joining Bonus"
                            value={interdata.JoiningBonus}
                            onChange={(e) => setInterdata({ ...interdata, JoiningBonus: e.target.value })}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Annual Bonus"
                            value={interdata.AnnualBonus}
                            onChange={(e) => setInterdata({ ...interdata, AnnualBonus: e.target.value })}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Relocation Assistance"
                            value={interdata.ReloAssistance}
                            onChange={(e) => setInterdata({ ...interdata, ReloAssistance: e.target.value })}
                          />
                        </>
                      ) : null}

                      {interdata.offeredJob !== 'fulltime' ? (
                        <>
                          <TextField
                            sx={{ width: '41.5%' }}
                            id="outlined-multiline-static"
                            label="Hourly Rate Offered"
                            value={interdata.hro}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">per/hour</InputAdornment>,
                            }}
                            onChange={(e) => setInterdata({ ...interdata, hro: e.target.value })}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Tax Term"
                            value={interdata.TaxTerm}
                            onChange={(e) => setInterdata({ ...interdata, TaxTerm: e.target.value })}
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Overtime Expense"
                            value={interdata.OvertimeExpense}
                            onChange={(e) => setInterdata({ ...interdata, OvertimeExpense: e.target.value })}
                          />
                        </>
                      ) : null}

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer sx={{ width: '42%', mt: -0.8 }} components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            disablePast
                            label="Tentative Start Date"
                            value={tsd}
                            onChange={(newValue) => setTsd(newValue)}
                            slotProps={{
                              textField: {
                                readOnly: true,
                              },
                            }}
                          // console.log("uncontrol date",newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <TextField
                        id="outlined-multiline-static"
                        label="Additional"
                        value={interdata.Additional}
                        onChange={(e) => setInterdata({ ...interdata, Additional: e.target.value })}
                      />
                    </Grid>
                  )}

                  <TextField
                    sx={{ width: '50%', mt: 2 }}
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    value={placenote}
                    rows={4}
                    onChange={(e) => setPlacenote(e.target.value)}
                  // defaultValue="Default Value"
                  />
                  {/* <Stack sx={{ width: 500, mt: 2 }}>
                    <CKEditor
                      editor={ClassicEditor}
                      // data="<p>{value.notes}<br><br></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setPlacenote(data);
                        console.log('ckeditor------data', { event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    />
                  </Stack> */}
                  {/* <Button
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="large"
                    sx={{ width: 230, mt: 3 }}
                    onClick={(e) => setPlacement()}
                  >
                    Update Placement Status
                  </Button> */}
                  <LoadingButton
                    loading={loadBtn}
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="medium"
                    sx={{ width: 230, mt: 3 }}
                    onClick={(e) => setPlacement()}>
                    Update Placement Status
                  </LoadingButton>
                </Stack>
              ) : null}
              {btn === 'start' ? (
                <Stack>
                  {quit === 'quit' ? (
                    <Stack>
                      <FormControl>
                        <Typography fontWeight={500} sx={{ mt: 2 }}>
                          End Status
                        </Typography>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="started"
                          name="radio-buttons-group"
                          value={endstatus}
                          onChange={(e) => setEndstatus(e.target.value)}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            ml: 1,
                          }}
                        >
                          <FormControlLabel value="project ended" control={<Radio />} label="Project Ended" />
                          <FormControlLabel value="Job Quit" control={<Radio />} label="Quit" />
                          <FormControlLabel value="terminated" control={<Radio />} label="Terminated" />
                        </RadioGroup>
                      </FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            disablePast
                            label="Date of End"
                            // defaultValue={dayjs('2022-04-17')}
                            value={joindate}
                            onChange={(newValue) => setEnddate(newValue)}
                            slotProps={{
                              textField: {
                                readOnly: true,
                              },
                            }}
                          // console.log("uncontrol date",newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                      <TextField
                        sx={{ width: '50%', mt: 3 }}
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        value={endnote}
                        onChange={(e) => setEndnote(e.target.value)}
                      // defaultValue="Default Value"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="custom"
                        size="large"
                        sx={{ width: 230, mt: 3 }}
                        onClick={(e) => setEnd()}
                      >
                        Update End Status
                      </Button>
                    </Stack>
                  ) : (
                    <Stack>
                      <FormControl>
                        <Typography fontWeight={500} sx={{ my: 2 }}>
                          Joining Status
                        </Typography>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="started"
                          name="radio-buttons-group"
                          value={joinstatus}
                          onChange={(e) => setJoinstatus(e.target.value)}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            ml: 1,
                          }}
                        >
                          <FormControlLabel value="started" control={<Radio />} label="Started" />
                          <FormControlLabel value="backout" control={<Radio />} label="Backout" />
                        </RadioGroup>
                      </FormControl>

                      {joinstatus === 'started' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                              disablePast
                              label="Date of Joining"
                              // defaultValue={dayjs('2022-04-17')}
                              value={joindate}
                              onChange={(newValue) => setJoindate(newValue)}
                              slotProps={{
                                textField: {
                                  readOnly: true,
                                },
                              }}
                            // console.log("uncontrol date",newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                      <TextField
                        sx={{ width: '50%', mt: 3 }}
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        value={joinnote}
                        onChange={(e) => setJoinnote(e.target.value)}
                      // defaultValue="Default Value"
                      />
                      {/* <Stack sx={{ width: 500, mt: 3 }}>
                    <CKEditor
                      editor={ClassicEditor}
                      // data="<p>{value.notes}<br><br></p>"
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setJoinnote(data);
                        console.log('ckeditor------data', { event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    />
                  </Stack> */}
                      {/* <Button
                        type="submit"
                        variant="contained"
                        color="custom"
                        size="large"
                        sx={{ width: 230, mt: 3 }}
                        onClick={(e) => setStart()}
                      >
                        Update Status
                      </Button> */}
                      <LoadingButton
                        loading={loadBtn}
                        type="submit"
                        variant="contained"
                        color="custom"
                        size="medium"
                        sx={{ width: 230, mt: 3 }}
                        onClick={(e) => setStart()}>
                        Update Status
                      </LoadingButton>
                    </Stack>
                  )}
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          {value === 'History' ? (
            <Stack>
              <Stack sx={{ backgroundColor: '#fff', mb: 2 }}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: 'text.hover',
                  }}
                >
                  <Typography sx={{ p: 2, width: '25%' }}>Date</Typography>
                  <Typography sx={{ p: 2, width: '20%' }}>User</Typography>
                  <Typography sx={{ p: 2, width: '20%' }}>Status</Typography>
                  <Typography sx={{ p: 2, width: '20%' }}>Note</Typography>
                </Stack>
                {his.map((item) => {
                  return (
                    <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                      <Typography variant="body1" sx={{ p: 1, width: '25%' }}>
                        {moment(`${item.date}`).format('MMM Do YYYY')}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, width: '20%' }}>
                        {item.actionBy}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, width: '20%' }}>
                        {item.actionFrom} to {item.actionTo}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, width: '20%' }}>
                        {/* <div dangerouslySetInnerHTML={{ __html: item.note }} /> */}
                        {item.note}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ) : null}
        </Box>
      </Grid>
      {value === 'Detail' ? (
        <>
          <Stack container sx={{ mt: 2, gap: 1, flexDirection: 'row', width: '100%' }}>
            <Stack sx={{ backgroundColor: 'text.background', borderRadius: 3, width: '50%', boxShadow: 3, paddingTop: 3 }}>
              {/* {value1 === "Detail" && ( */}

              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>

                {edit1 === false ? (
                  <Iconify
                    icon="bx:edit"
                    sx={{ width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                    onClick={() => editSource()}
                  />
                ) : (
                  <Iconify
                    icon="carbon:save"
                    sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                    onClick={() => editSource()}
                  />
                )}

                {/* <Button sx={{ width: 100, mr: 3 }} variant="contained" onClick={() => editSource()}>
              {edit1 === false ? 'edit' : 'save'}
            </Button> */}
              </Stack>
              {/* )} */}

              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Date Added
                </Typography>

                <Typography fontWeight={500} sx={{ p: 1 }}>
                  {moment(row.createdAt).format('ll')}
                </Typography>

              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Candidate ID
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.candidateid}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    value={box1.candidateid}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, candidateid: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Candidate Name
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.candidateName}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    value={box1.candidateName}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, candidateName: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              {/* <Divider /> */}

              <Divider sx={{ mb: 1.5, mt: 0.1 }} />
              <Grid sx={{ flexDirection: "row" }}>
                <Typography color={'text.secondary'} sx={{ p: 1, width: '50%', ml: 1.3, mt: -0.9 }}>
                  Date of Birth
                </Typography>

                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1, ml: 25.5, mt: -4.5, pb: 3 }}>
                    {dayjs(row.DOB).format('DD/MM/YYYY')}
                  </Typography>
                ) : (

                  <FormControl size="small" sx={{ width: '58.5%', ml: 25.9, mt: -4.3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth"
                        slotProps={{ textField: { size: "small" } }}
                        value={formValue.fromDate ? dayjs(formValue.fromDate) : null}
                        onChange={(date) => handleDateChange1(date, 'fromDate')}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              </Grid>

              <Divider sx={{ mb: 0.1, mt: - 1.5 }} />

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Status
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.currentStatus}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    sx={{ py: 0.5, width: '60%' }}
                    value={box1.currentStatus}
                    onChange={(e) => setBox1({ ...box1, currentStatus: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />


              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Email
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.box1email}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    // disabled
                    // disabled={fistable}
                    // disabled={emailDiss === false}
                    value={box1.box1email}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, box1email: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Secondary Email
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.box1SeconEemail}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    value={box1.box1SeconEemail}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, box1SeconEemail: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Contact
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.box1phone}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    value={box1.box1phone}
                    onChange={(e) => setBox1({ ...box1, box1phone: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Secondary Contact
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.box1SecondPhone}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    value={box1.box1SecondPhone}
                    onChange={(e) => setBox1({ ...box1, box1SecondPhone: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Linkedin Link
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.linkedin}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    value={box1.linkedin}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, linkedin: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Source
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.source}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    value={box1.source}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox1({ ...box1, source: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg, mb: 2 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Address
                </Typography>
                {fistable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box1.box1address}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={fistable}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    value={box1.box1address}
                    onChange={(e) => setBox1({ ...box1, box1address: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
            </Stack>

            <Stack
              sx={{ backgroundColor: 'text.background', borderRadius: 2, width: '50%', boxShadow: 3, paddingTop: 3 }}
            >
              {/* {value1 === "Detail" && ( */}
              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                {edit2 === false ? (
                  <Iconify
                    icon="bx:edit"
                    sx={{ width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editCertificate()}
                  />
                ) : (
                  <Iconify
                    icon="carbon:save"
                    sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editCertificate()}
                  />
                )}
              </Stack>
              {/* )} */}

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, mt: 1, width: 200, color: 'text.secondary' }}>
                  Years of Experience
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box2.box2YofE}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box2.box2YofE}
                    onChange={(e) => setBox2({ ...box2, box2YofE: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  {/* Current Company */}
                  Previous Company 1

                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.companyName1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.companyName1}
                    onChange={(e) => setSbox({ ...sbox, companyName1: e.target.value })}
                  // value={box2.box2currentCompany}
                  // onChange={(e) => setBox2({ ...box2, box2currentCompany: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Duration
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.duration1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.duration1}
                    onChange={(e) => setSbox({ ...sbox, duration1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Job Title
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.jobTitle1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.jobTitle1}
                    onChange={(e) => setSbox({ ...sbox, jobTitle1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Previous Company 2
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.companyName2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.companyName2}
                    onChange={(e) => setSbox({ ...sbox, companyName2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Duration
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.duration2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.duration2}
                    onChange={(e) => setSbox({ ...sbox, duration2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Job Title
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {sbox.jobTitle2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={sbox.jobTitle2}
                    onChange={(e) => setSbox({ ...sbox, jobTitle2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Compensation Type
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.comType}
                  </Typography>
                ) : (
                  <FormControl size="small" sx={{ width: '60%', py: 0.5 }}>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={box3.comType}
                      label="Compensation Type"
                      onChange={(e) => setBox3({ ...box3, comType: e.target.value })}
                    >
                      <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                      <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <Divider /> */}
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Current Compensation
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.currentCom}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    sx={{ py: 0.5, width: '60%' }}
                    id="outlined-basic"
                    placeholder="Current Compensation"
                    variant="outlined"
                    value={box3.currentCom}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: box3.comType && (
                        <InputAdornment position="end">{box3.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                      ),
                    }}
                    onChange={(e) => setBox3({ ...box3, currentCom: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Desired Compensation
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.desiredCom}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    sx={{ py: 0.5, width: '60%' }}
                    id="outlined-basic"
                    placeholder="Desired Compensation"
                    variant="outlined"
                    value={box3.desiredCom}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: box3.comType && (
                        <InputAdornment position="end">{box3.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                      ),
                    }}
                    onChange={(e) => setBox3({ ...box3, desiredCom: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />

              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Desired Title
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.desiredJobType}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ py: 0.5, width: '60%' }}
                    value={box3.desiredJobType}
                    onChange={(e) => setBox3({ ...box3, desiredJobType: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />

              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Added By
                </Typography>
                {secondable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.addedBy}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={secondable}
                    sx={{ py: 0.5, width: '60%' }}
                    value={box3.addedBy}
                    onChange={(e) => setBox3({ ...box3, addedBy: e.target.value })}
                  />
                )}
              </Stack>

            </Stack>
          </Stack>
          <Stack container sx={{ flexDirection: 'row', width: '100%', mt: 1, gap: 1 }}>
            <Stack container sx={{
              backgroundColor: 'text.background', width: '50%', borderRadius: 2,
              boxShadow: 3, paddingTop: 3
            }}>
              {/* {value1 === "Detail" && ( */}
              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                {edit3 === false ? (
                  <Iconify
                    icon="bx:edit"
                    sx={{ width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editRef()}
                  />
                ) : (
                  <Iconify
                    icon="carbon:save"
                    sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editRef()}
                  />
                )}
              </Stack>
              {/* )} */}

              <Stack sx={{ backgroundColor: cardbg }}>
                <Typography fontWeight={600} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  Refrences
                </Typography>
                {/* {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Ref}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Ref}
                onChange={(e) => setBox4({ ...box4, b4Ref: e.target.value })}
              />
            )} */}
              </Stack>
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={600} sx={{ px: 1 }}>
                  Contact 1 :
                </Typography>
                {/* {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Con1}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Con1}
                onChange={(e) => setBox4({ ...box4, b4Con1: e.target.value })}
              />
            )} */}
              </Stack>
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Name
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Name1}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Name1}
                onChange={(e) => setBox4({ ...box4, b4Name1: e.target.value })}
              />
            )}
          </Stack> */}

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={600} sx={{ p: 1, width: 200 }}>
                  Name:
                </Typography>

                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Name1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Name1}
                    onChange={(e) => setBox4({ ...box4, b4Name1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Company Name
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4CName1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4CName1}
                    onChange={(e) => setBox4({ ...box4, b4CName1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Job title
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4JobT1}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4JobT1}
                onChange={(e) => setBox4({ ...box4, b4JobT1: e.target.value })}
              />
            )}
          </Stack> */}

              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Email id
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Email1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Email1}
                    onChange={(e) => setBox4({ ...box4, b4Email1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Phone no
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Phone1}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Phone1}
                    onChange={(e) => setBox4({ ...box4, b4Phone1: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />

              <Stack sx={{ flexDirection: 'row', p: 1 }}>
                <Typography fontWeight={600} sx={{ px: 1 }}>  Contact 2 :
                </Typography>
                {/* {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Con2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Con2}
                onChange={(e) => setBox4({ ...box4, b4Con2: e.target.value })}
              />
            )} */}
              </Stack>
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Name
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Name2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Name2}
                onChange={(e) => setBox4({ ...box4, b4Name2: e.target.value })}
              />
            )}
          </Stack> */}

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={600} sx={{ p: 1, width: 200 }}>
                  Name:
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Name2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Name2}
                    onChange={(e) => setBox4({ ...box4, b4Name2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Company Name
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4CName2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4CName2}
                    onChange={(e) => setBox4({ ...box4, b4CName2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Job Title
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4JobT2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4JobT2}
                onChange={(e) => setBox4({ ...box4, b4JobT2: e.target.value })}
              /> 
            )}
          </Stack> */}
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Email Id
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Email2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Email2}
                    onChange={(e) => setBox4({ ...box4, b4Email2: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Phone No
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Phone2}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Phone2}
                    onChange={(e) => setBox4({ ...box4, b4Phone2: e.target.value })}
                  />
                )}
              </Stack>
              {/* <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Comments
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Comment}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Comment}
                    onChange={(e) => setBox4({ ...box4, b4Comment: e.target.value })}
                  />
                )}
              </Stack> */}
              <Divider />

              <Stack sx={{ flexDirection: 'row', p: 1 }}>
                <Typography fontWeight={600} sx={{ px: 1 }}>  Contact 3 :
                </Typography>
                {/* {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Con2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Con2}
                onChange={(e) => setBox4({ ...box4, b4Con2: e.target.value })}
              />
            )} */}
              </Stack>
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Name
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4Name2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4Name2}
                onChange={(e) => setBox4({ ...box4, b4Name2: e.target.value })}
              />
            )}
          </Stack> */}

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={600} sx={{ p: 1, width: 200 }}>
                  Name:
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Name3}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Name3}
                    onChange={(e) => setBox4({ ...box4, b4Name3: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Company Name
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4CName3}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4CName3}
                    onChange={(e) => setBox4({ ...box4, b4CName3: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              {/* <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography  fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Job Title
            </Typography>
            {thirdable ? (
              <Typography  fontWeight={500} sx={{ p: 1 }}>
                {box4.b4JobT2}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled={thirdable}
                sx={{ width: '60%',py:0.5 }}
                value={box4.b4JobT2}
                onChange={(e) => setBox4({ ...box4, b4JobT2: e.target.value })}
              /> 
            )}
          </Stack> */}
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Email Id
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Email3}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Email3}
                    onChange={(e) => setBox4({ ...box4, b4Email3: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Phone No
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Phone3}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Phone3}
                    onChange={(e) => setBox4({ ...box4, b4Phone3: e.target.value })}
                  />
                )}
              </Stack>
              <Divider />
              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Comments
                </Typography>
                {thirdable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box4.b4Comment}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    disabled={thirdable}
                    sx={{ width: '60%', py: 0.5 }}
                    value={box4.b4Comment}
                    onChange={(e) => setBox4({ ...box4, b4Comment: e.target.value })}
                  />
                )}
              </Stack>

            </Stack>

            <Stack
              container
              sx={{ backgroundColor: 'text.background', width: '50%', borderRadius: 2, boxShadow: 3, paddingTop: 3 }}
            >
              {/* {value1 === "Detail" && ( */}
              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                {edit4 === false ? (
                  <Iconify
                    icon="bx:edit"
                    sx={{ width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editYear()}
                  />
                ) : (
                  <Iconify
                    icon="carbon:save"
                    sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1 }}
                    onClick={() => editYear()}
                  />
                )}
              </Stack>
              {/* )} */}
              <Typography fontWeight={600} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                Education & Certification
              </Typography>

              <div>

                <Stack sx={{ flexDirection: 'row', px: 1, mt: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                    Education
                  </Typography>
                  {fourable ? (
                    <Typography fontWeight={500}
                      sx={{
                        p: 1,
                        width: "57%",
                        maxHeight: "auto",
                        overflow: "auto",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {sections.box2education || ''}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      disabled={fourable}
                      multiline
                      rows={3}
                      sx={{ width: '60%', py: 0.5 }}
                      value={sections.box2education || ''}
                      onChange={(e) => setSections({ ...sections, box2education: e.target.value })}
                    />
                  )}
                </Stack>
                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                    College Name
                  </Typography>
                  {fourable ? (
                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {sections.box2collageName || ''}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      disabled={fourable}
                      sx={{ width: '60%', py: 0.5 }}
                      value={sections.box2collageName || ''}
                      onChange={(e) => setSections({ ...sections, box2collageName: e.target.value })}
                    />
                  )}
                </Stack>
                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                    Country
                  </Typography>
                  {fourable ? (
                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {sections.box2Country || ''}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      disabled={fourable}
                      sx={{ width: '60%', py: 0.5 }}
                      value={sections.box2Country || ''}
                      onChange={(e) => setSections({ ...sections, box2Country: e.target.value })}
                    />
                  )}
                </Stack>
                <Divider />
                <Stack sx={{ flexDirection: 'row', px: 1 }}>
                  <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                    Year
                  </Typography>
                  {fourable ? (
                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {sections.box2Year || ''}
                    </Typography>
                  ) : (
                    <TextField
                      size="small"
                      disabled={fourable}
                      sx={{ width: '60%', py: 0.5 }}
                      value={sections.box2Year || ''}
                      onChange={(e) => setSections({ ...sections, box2Year: e.target.value })}
                    />
                  )}
                </Stack>
                <Divider />
                {/* {!fourable && sections.length > 1 && (
            <Button onClick={() => handleRemoveSection(index)} variant="outlined" color="error" sx={{ ml: 62, mt: 1 }}>
              Less
            </Button>
          )} */}
              </div>

              {/* {!fourable && sections.length < 3 && (
        <Button onClick={handleAddSection} variant="outlined" sx={{ ml: 62, mt: 1, mb: 1 }}>
          More
        </Button>
      )} */}

              {!addMoreShow && !fourable && (
                <Button
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, padding: '0.8', maxWidth: '2.8px', ml: 62 }}
                  onClick={() => setAddMoreShow(true)}
                >
                  More
                </Button>
              )}

              {addMoreShow && (
                <Button
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, padding: '0.8', maxWidth: '2.8px', ml: 62 }}
                  onClick={() => setAddMoreShow(false)}
                >
                  Less
                </Button>
              )}

              {fourable && (
                <>
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Education Specialization 1
                    </Typography>

                    <Typography fontWeight={500}
                      sx={{
                        p: 1,
                        width: "57%",
                        maxHeight: "auto",
                        overflow: "auto",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {boxx4.spec1 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      College Name
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.collage1 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Country
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.country1 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Year
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.year1 || ''}
                    </Typography>

                  </Stack>
                </>
              )}

              {addMoreShow && (
                <>
                  <Stack sx={{ flexDirection: 'row', px: 1, mt: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Education Specialization 1
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500}
                        sx={{
                          p: 1,
                          width: "57%",
                          maxHeight: "auto",
                          overflow: "auto",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {boxx4.spec1 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        multiline
                        rows={3}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.spec1}
                        onChange={(e) => setBoxx4({ ...boxx4, spec1: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      College Name
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.collage1 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.collage1}
                        onChange={(e) => setBoxx4({ ...boxx4, collage1: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Country
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.country1 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.country1}
                        onChange={(e) => setBoxx4({ ...boxx4, country1: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Year
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.year1 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.year1}
                        onChange={(e) => setBoxx4({ ...boxx4, year1: e.target.value })}
                      />
                    )}
                  </Stack>
                </>
              )}
              <Divider />

              {addMoreShow && (
                <Button
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, padding: '0.8', maxWidth: '2.8px', ml: 62 }}
                  onClick={() => setAddMoreShow1(true)}
                >
                  More
                </Button>
              )}


              {fourable && (
                <>
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Education Specialization 2
                    </Typography>

                    <Typography fontWeight={500}
                      sx={{
                        p: 1,
                        width: "57%",
                        maxHeight: "auto",
                        overflow: "auto",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {boxx4.spec2 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      College Name
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.collage2 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Country
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.country2 || ''}
                    </Typography>

                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Year
                    </Typography>

                    <Typography fontWeight={500} sx={{ p: 1 }}>
                      {boxx4.year2 || ''}
                    </Typography>

                  </Stack>
                </>
              )}
              <Divider />
              {addMoreShow1 && (
                <>
                  <Stack sx={{ flexDirection: 'row', px: 1, mt: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Education Specialization 2
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500}
                        sx={{
                          p: 1,
                          width: "57%",
                          maxHeight: "auto",
                          overflow: "auto",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {boxx4.spec2 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        multiline
                        rows={3}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.spec2}
                        onChange={(e) => setBoxx4({ ...boxx4, spec2: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      College Name
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.collage2 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.collage2}
                        onChange={(e) => setBoxx4({ ...boxx4, collage2: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Country
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.country2 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.country2}
                        onChange={(e) => setBoxx4({ ...boxx4, country2: e.target.value })}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack sx={{ flexDirection: 'row', px: 1 }}>
                    <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                      Year
                    </Typography>
                    {fourable ? (
                      <Typography fontWeight={500} sx={{ p: 1 }}>
                        {boxx4.year2 || ''}
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        disabled={fourable}
                        sx={{ width: '60%', py: 0.5 }}
                        value={boxx4.year2}
                        onChange={(e) => setBoxx4({ ...boxx4, year2: e.target.value })}
                      />
                    )}
                  </Stack>
                </>
              )}

              {addMoreShow && (
                <Button
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, padding: '0.8', maxWidth: '2.8px', ml: 62 }}
                  onClick={() => setAddMoreShow1(false)}
                >
                  Less
                </Button>
              )}
              {/* <Divider sx={{mt:0.5}} /> */}

              <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Certification
                </Typography>
                {fourable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.b3special || ''}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    value={box3.b3special || ''}
                    disabled={fourable}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox3({ ...box3, b3special: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />

              <Stack sx={{ flexDirection: 'row', px: 1 }}>
                <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                  Year
                </Typography>
                {fourable ? (
                  <Typography fontWeight={500} sx={{ p: 1 }}>
                    {box3.b3Year || ''}
                  </Typography>
                ) : (
                  <TextField
                    size="small"
                    value={box3.b3Year || ''}
                    disabled={fourable}
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    onChange={(e) => setBox3({ ...box3, b3Year: e.target.value })}
                  // label='Source'
                  />
                )}
              </Stack>
              <Divider />
            </Stack>
          </Stack>
        </>
      ) : null}
      <Modal open={openHis} onClose={closeHistory} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            History of {row.fullName}
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}>
                <Stack
                  sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    User Name
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job ID
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Client
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job Title
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '40%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Notes
                  </Typography>
                </Stack>
              </Stack>

              {his
                ? his.map((item, index) => {
                  console.log('client list----------------------', item);
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                      ]}
                    >
                      <Typography sx={{ width: '10%' }}>
                        {moment(`${row.updatedAt}`).format('ll')}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}>
                        {item.actionBy}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.jobId ? item.jobId : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.clientName ? item.clientName : '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.jobTitle}
                      </Typography>
                      <Typography
                        sx={[
                          {
                            color:
                              item.actionTo === 'started' || item.actionTo === 'placement'
                                ? 'green'
                                : item.actionTo === 'Backout from Job offer'
                                  ? 'red'
                                  : '',
                          },
                          { textTransform: 'capitalize', width: '10%' },
                        ]}
                      >
                        {item.actionTo}
                      </Typography>
                      <Typography sx={{ width: '40%', textTransform: 'capitalize' }}>
                        {item.note}
                      </Typography>
                      {/* <Stack sx={{  width: '40%'}}>

              <div dangerouslySetInnerHTML={{ __html: item.note }} />
                        </Stack> */}
                    </Stack>
                  );
                })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}

export default BenResumeDetail;
