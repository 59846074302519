import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

function SalesManager() {
  const navigate = useNavigate();
  const location = useLocation();
  const [id , setId] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const role = localStorage.getItem('role');
  const [editTrue, setEditTrue] = React.useState(true);
  const token =  localStorage.getItem('token');
  const userId = localStorage.getItem('userid');


  useEffect(() => {
    console.log('dependency', location.state);
    if (location.state) {
      setId(location.state.id);
      // setRole(location.state.rolee);
      console.log('temp role location', location.state.id);
      getCompany(location.state.id);
    } else {
      // setRole(localStorage.getItem('role'));
      console.log('ghuchdu');
      getCompany(userId);
      setId(userId);
    }
  }, [location.state]);
  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal 1
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // modal 2
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [tableData, setTableData] = useState([]);
  const tableLabel = [
    // { id: 1, label: 'Company Name' },
    { id: 2, label: 'User Id' },
    { id: 3, label: 'Role' },
    { id: 4, label: 'Name' },
    { id: 5, label: 'Phone' },
    { id: 6, label: 'Email' },
    { id: 7, label: 'Password' },
    { id: 8, label: 'Action' },
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };

  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: 'salesManager',
    email: '',
    domain: '',
    ContactPersonName: '',
    contactNumber: '',
    password: '',
    address: '',
  });

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        })
      }
    } else{
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      })
    }
    
  }

  const getCompany = async (idd) => {
    Api(`company/get_users_list/${idd}`, 'GET', '', token)
      .then((response) => {
        console.log('=========getCompany======>>>>>>>', response.data.message);
        console.log('=========getCompany======>>>>>>>', response);
        if (response.status === 200) {
          // toast.success(response.data.message, +'!');
          setTableData(response.data.data);
        } else {
          // toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const addCompany = async (e) => {
    // alert('ashish')
    e.preventDefault();  
    const body = {
      parentId:id,
      email: formValuesAddComapnny.email,
      role: formValuesAddComapnny.role,
      password: formValuesAddComapnny.password,
      contactPersonName: formValuesAddComapnny.ContactPersonName,
      contactNo: formValuesAddComapnny.contactNumber,
      officialDomain: formValuesAddComapnny.domain,
      companyAddress: formValuesAddComapnny.address,
      companyName: formValuesAddComapnny.cName,
    };
    Api(`company/add_user`, 'POST', body, token)
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          handleClose2();
          toast.success(response.data.message, +'!');
          getCompany(id);
          setFormValuesAddCompany({
            ...formValuesAddComapnny,
            cName: '',
            // role: '',
            email: '',
            domain: '',
            ContactPersonName: '',
            contactNumber: '',
            password: '',
            address: '',
          });
          // setTableData(response.data.result)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })

      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const funclick = () => {
    getCompany(id);
  };
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'}  color={'text.secondary'}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer'}} onClick={()=>navigate(-1)} />
          <Typography variant="h5"  color={'text.secondary'}>
         User Management
        </Typography>
        </Stack>
        <Stack flexDirection={'row'} gap={1}>
        {role === 'admin'&&(
           <Button color="custom" variant="contained" size="small" onClick={handleOpen2}>
            Add
          </Button>
        )}
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor:'text.secondary'}}>
              {tableLabel.map((item) => {
                return <TableCell sx={{p:1,py:0.5,color:'white'}} key={item.id}>{item.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <ManagementRow key={row._id} data={row} fun={funclick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={(e) => addCompany(e)}>
            <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
              <Grid
                display={'grid'}
                py={1}
                pr={{ xs: 1 }}
                gap={2}
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    placeholder="Role"
                    value={formValuesAddComapnny.role}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        role: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="salesManager">Sales Manager</MenuItem>
                    <MenuItem value="salesTeamLead">Sales Team Lead</MenuItem>
                    <MenuItem value="salesExecutive">Sales Executive</MenuItem>
                    <MenuItem value="salesTrainee">Sales Trainee</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={formValuesAddComapnny.email}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      email: e.target.value,
                    })
                  }
                />
                {/* <TextField
                  id="outlined-basic"
                  // name="domain"
                  label="Company Name"
                  placeholder="Company Name"
                  variant="outlined"
                  value={formValuesAddComapnny.cName}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      cName: e.target.value,
                    })
                  }
                /> */}
                <TextField
                  id="outlined-basic"
                  name="ContactPersonName"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  variant="outlined"
                  value={formValuesAddComapnny.ContactPersonName}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      ContactPersonName: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  name="contactNumber"
                  label="Contact Number"
                  placeholder="Contact Number"
                  variant="outlined"
                  value={formValuesAddComapnny.contactNumber}
                  // type="number"
                  onChange={(e) =>setContact(e.target.value)}
                  // onChange={(e) =>
                  //   setFormValuesAddCompany({
                  //     ...formValuesAddComapnny,
                  //     contactNumber: e.target.value,
                  //   })
                  // }
                />
                <Stack className="passwordIcon">
                  <TextField
                    id="outlined-basic"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    value={formValuesAddComapnny.password}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        password: e.target.value,
                      })
                    }
                  />
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </Stack>
              </Grid>
            </Scrollbar>
            <Stack flexDirection={'row'} gap={1} mt={2}>
              <Button color="custom" type="submit" variant="contained">
                Add
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

function ManagementRow({ data, fun }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    border: '3px solid #3C6BA5',
    borderRadius: 3,
  };
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: data.role,
    email: data.email,
    domain: data.officialDomain,
    ContactPersonName: data.contactPersonName,
    contactNumber: data.contactNo,
    password: data.password,
    address: data.companyAddress,
  });
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();
  const role = localStorage.getItem('role')
  const [accesss,setAccesss]= useState(false)
  const  closeaccess =()=>{setAccesss(false)}
  const  openaccess =()=>{setAccesss(true)}
  const [accesss1,setAccesss1]= useState(false)
  const  closeaccess1 =()=>{setAccesss1(false)}
  const  openaccess1 =()=>{setAccesss1(true)}
  const [access,setAccess] = useState({
    rworksheet: '',rdatabase:"", client: '', lead: '', jobs: '', placement: '' 
  })

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        })
      }
    } else{
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      })
    }
    
  }
  async function editFun(data) {
    const token = await localStorage.getItem('token');
    if (edit === false) {
      setEdit(true);
    } else if (edit === true) {
      const body = {
        userId: data._id,
        parentId:data.parentId,
        email: formValuesAddComapnny.email ? formValuesAddComapnny.email : data.email,
        // role: formValuesAddComapnny.role ? formValuesAddComapnny.role : data.role,
        password: formValuesAddComapnny.password ? formValuesAddComapnny.password : data.password,
        contactPersonName: formValuesAddComapnny.ContactPersonName
          ? formValuesAddComapnny.ContactPersonName
          : data.contactPersonName,
        contactNo: formValuesAddComapnny.contactNumber ? formValuesAddComapnny.contactNumber : data.contactNo,
        // officialDomain: formValuesAddComapnny.domain ? formValuesAddComapnny.domain : data.domain,
        // companyAddress: formValuesAddComapnny.address ? formValuesAddComapnny.address : data.companyAddress,
      };
      Api(`company/edit_user`, 'POST', body, token).then((Response) => {
        console.log('======edit_company_details======>', JSON.stringify(Response));
        if (Response.status === 200) {
          if(Response.data.code === 200){
            toast.success(Response.data.message, +'!');
          setEdit(false);
          fun();
          }else{
          toast.warn(Response.data.message, +'!');
          }
        }
      });
    }
  }

  async function statuss(data) {
    const token = await localStorage.getItem('token');
    let sts = '';
    if (data.status === 'active') {
      sts = 'blocked';
    } else {
      sts = 'active';
    }
    const body = {
      companyId: data._id,
      status: sts,
    };
    Api(`company/company_status`, 'POST', body, token).then((Response) => {
      console.log('======getUser==response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          fun();
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
        }
      }
    });
  }

  const goto=(data)=>{
    if(data.role === 'recruiterHead'){
      navigate('/recuiterManager', { state: { id: data._id }}) 
    }else if(data.role === 'salesHead'){
      navigate('/salesManager', { state: { id: data._id }}) 
    }else if(data.role === 'recruiterManager'){
      navigate('/recruiterTeamLead', { state: { id: data._id }}) 
    }else if(data.role === 'salesManager'){
      navigate('/salesTeamLead', { state: { id: data._id }}) 
    }else if(data.role === 'recruiterTeamLead'){
      navigate('/recruiterManagement', { state: { id: data._id }})
    }else if(data.role === 'salesTeamLead'){
      navigate('/salesManagement', { state: { id: data._id }})
    }else if(data.role === 'recruiter'){
      navigate('/recruitertrainee', { state: { id: data._id }})
    }else if(data.role === 'salesExecutive'){
      navigate('/salestrainee', { state: { id: data._id }})
    }

  }
  const getRights = async (data) => {
    const token = await localStorage.getItem('token');
    Api(`company/getrights/${data._id}`, 'GET',"", token).then((Response) => {
      console.log('======getUser=getrights=response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setAccess({
            rworksheet: Response.data.data.worksheet,
            rdatabase: Response.data.data.resumes,
            client: Response.data.data.clients,
            lead: Response.data.data.leads,
            jobs: Response.data.data.jobs,
            placement: Response.data.data.placements,
          });
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
        }
      }
    }); 
  }
  const Rights = async (data) => {
    const token = await localStorage.getItem('token');
    const body ={
      "userId": data._id,
      "worksheet": access.rworksheet,
      "clients": access.client,
      "jobs": access.jobs,
      "resumes": access.rdatabase,
      "leads": access.lead,
      "placements": access.placement
    }
    Api(`company/access_rights`, 'POST',body, token).then((Response) => {
      console.log('======getUser=getrights=response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.message, +'!');
          closeaccess()
          setAccess({
            rworksheet: '',
            rdatabase: '',
            client: '',
            lead: '',
            jobs: '',
            placement: '',
          });
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          toast.error(Response.data.message, +'!');
          console.log('======getUser=======>', +Response);
        }
      }
    }); 
  }

  const [dropDownLst, setDropDownLst] = useState([]) 
  const [roleId, setRoleId] = useState('')
  const [roleParId, setRoleParId] = useState('')
  
    const dropDownList = (id) => {
      setRoleParId(id)
      const body = {
        "user_id":id
      };
      const token =  localStorage.getItem('token');
      Api(`company/userRoleDropDownList`, 'POST', body, token).then((Response) => {
        console.log('======get==response=====>', Response);
        if (Response.status === 200) {
          setDropDownLst(Response.data.result)
          if (Response.data.code === 200) {
            // toast.success(Response.data.message);
            // fun();
            console.log('======getUser=======>', Response.data.data);
          } else {
            console.log('======getUser====>', +Response);
          }
        }
      });
    }
  
    console.log("dropDownLst", dropDownLst)
    const [loading, setLoading] = useState(false);
  
    const hierarcyFun = () => {
      setLoading(true)
      const body = {
        "parentId":roleId,
        "user_id":roleParId
      };
      const token =  localStorage.getItem('token');
      Api(`company/changesHeirarchy`, 'POST', body, token).then((Response) => {
        console.log('======get==response=====>', Response);
        if (Response.status === 200) {
          fun()
          closeaccess1()
          setLoading(false)
          if (Response.data.code === 200) {
            fun()
            closeaccess1()
            setLoading(false)
            // setDropDownLst(Response.data.data)
            // toast.success(Response.data.message);
            // fun();
            console.log('======getUser=======>', Response.data.data);
          } else {
            console.log('======getUser====>', +Response);
          }
        }
      }); 
    }
    console.log("roleId", roleId)
    const [selectedValue, setSelectedValue] = useState('');
    const [roleId1, setRoleId1] = useState('')
    const [open2, setOpen2] = React.useState(false);
    const [roleDis, setRoleDis] = useState('')
    const handleOpen2 = (id, role) => {
      console.log("role", role)
      setRoleDis(role)
      setRoleId1(id)
      setOpen2(true);
    }
    const handleClose2 = () => setOpen2(false);
    const [formValuesAddComapnny1, setFormValuesAddCompany1] = useState({
      role:'',
      temprole:''
    });
  
    const [loading1, setLoading1] = useState(false);
  
    const ChangePosition = () => {
      setLoading1(true)
      const body = {
        "role":formValuesAddComapnny1.role,
        "user_id":roleId1
      };
      const token =  localStorage.getItem('token');
      Api(`company/changesRoleAPI`, 'POST', body, token).then((Response) => {
        console.log('======get==response=====>', Response);
        if (Response.status === 200) {
          fun()
          handleClose2()
          setLoading1(false)
          if (Response.data.code === 200) {
            fun()
            handleClose2()
            setLoading1(false)
            // setDropDownLst(Response.data.data)
            // toast.success(Response.data.message);
            // fun();
            console.log('======getUser=======>', Response.data.data);
          } else {
            console.log('======getUser====>', +Response);
          }
        }
      }); 
    }

    const capitalizeWords = (str) => {
      return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    };
    
    const getRoleDisplayName = (Role) => {
      switch (Role) {
        case 'recruiterHead': return 'Recruitment Head';
        case 'recruiterManager': return 'Recruitment Manager';
        case 'recruiterTeamLead': return 'Recruitment Lead';
        case 'recruiter': return 'Technical Recruiter';
        case 'recruiterTrainee': return 'Recruitment Trainee';
        case 'salesHead': return 'Sales Head';
        case 'salesManager': return 'Sales Manager';
        case 'salesTeamLead': return 'Sales Team Lead';
        case 'salesExecutive': return 'Sales Executive';
        case 'salesTrainee': return 'Sales Trainee';
        case 'benchSalesHead': return 'Bench Sales Head';
        case 'benchSalesManager': return 'Bench Sales Manager';
        case 'benchSalesTeamLead': return 'Bench Sales Lead';
        case 'benchSalesExecutive': return 'Bench Sales Executive';
        case 'admin': return 'Admin';
        case 'superadmin': return 'Super Admin';
        default: return Role.replace(/([A-Z])/g, ' $&') || '-';
      }
    }

  return (
    <>
    <TableRow key={data._id}>
      {/* <TableCell sx={{ pl: 1 }}><Typography>{data.companyName}</Typography></TableCell> */}
      <TableCell sx={{ pl: 1 }}>
        <Stack sx={{flexDirection:'row'}}>
        <Typography sx={{ maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.USERID}</Typography>
        <CopyToClipboard text={data.USERID}>
                <Iconify
                  icon="radix-icons:copy"
                  color={'text.textG'}
                  sx={{ height: 17, width: 17, cursor: 'pointer',ml:1 }}
                />
              </CopyToClipboard>
              </Stack>
        </TableCell>
      <TableCell sx={{ pl: 1 }}>
        <Typography>{data.role.replace(/([A-Z])/g, ' $&')}</Typography></TableCell>
      {/* <TableCell sx={{ pl: 1,textTransform:'lowercase'  }}><Typography>{data.email}</Typography></TableCell> */}
      {/* <TableCell sx={{ pl: 1 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Official Domain"
            placeholder="Official Domain"
            variant="outlined"
            value={formValuesAddComapnny.domain}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                domain: e.target.value,
              })
            }
          />
        ) : (
          <span>{data.officialDomain}</span>
        )}
      </TableCell> */}
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Name"
            placeholder="Name"
            variant="outlined"
            value={formValuesAddComapnny.ContactPersonName}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                ContactPersonName: e.target.value,
              })
            }
          />
        ) : (
          <span>{data.contactPersonName}</span>
        )}
      </TableCell>

      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Phone"
            placeholder="Phone"
            variant="outlined"
            value={formValuesAddComapnny.contactNumber}
            onChange={(e) => setContact(e.target.value)}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            // onChange={(e) =>
            //   setFormValuesAddCompany({
            //     ...formValuesAddComapnny,
            //     contactNumber: e.target.value,
            //   })
            // }
          />
        ) : (
          <span>{data.contactNo}</span>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Email"
            variant="outlined"
            value={formValuesAddComapnny.email}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                email: e.target.value,
              })
            }
          />
        ) : (
          <Typography sx={{textTransform:'lowercase'}}>{data.email}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Password"
            placeholder="Password"
            variant="outlined"
            value={formValuesAddComapnny.password}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                password: e.target.value,
              })
            }
          />
        ) : (
          <Typography sx={{maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{data.password}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        <Stack flexDirection={'row'} gap={1}>
        {/* {(data.role !== "recruiterHead" && data.role !== "salesHead" 
          ) && ( */}
        <Button color="custom" type="submit" variant="contained" size="small"
        onClick={()=>{openaccess1(); dropDownList(data._id)}}>
            Swap Team
          </Button>
          {/* )}   */}
 
        <Button color="custom" type="submit" variant="contained" size="small"
        onClick={()=>{handleOpen2(data._id, data.role)}}>
            Promote 
          </Button>
        
        {role === 'admin' &&(
           <Button color="custom" type="submit" variant="contained" size="small" onClick={() => {getRights(data); openaccess()}}>
            access
          </Button>
          )}
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => editFun(data)}>
            {edit === false ? 'edit' : 'save'}
          </Button>
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => statuss(data)}>
            {data.status}
          </Button>
          {data.role!== "salesTrainee" && data.role!== "recruiterTrainee"?(
            <Iconify
              icon="maki:arrow"
              sx={{ color: 'text.secondary', width: 30, height: 30 }}
              style={{ cursor: 'pointer' }}
              onClick={() => goto(data) } 
            />
        ):null}
        </Stack>
      </TableCell>
    </TableRow>
    <Modal
    open={open2}
    onClose={handleClose2}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Scrollbar sx={{ maxHeight: 400 }}>
        <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          Change Position
        </Typography>
        <Divider />
        <FormControl fullWidth sx={{mt:1}}>
  <InputLabel id="demo-simple-select-label">Recruitment / Sales</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Recruitment / Sales"
    value={formValuesAddComapnny1.temprole}
    onChange={(e) =>
      setFormValuesAddCompany1({
        ...formValuesAddComapnny1,
        temprole: e.target.value,
      })
    }
  >
    <MenuItem 
     disabled={
      roleDis === "salesHead" || 
       roleDis ==="salesManager" ||
       roleDis ==="salesTeamLead" ||
       roleDis === "salesExecutive" ||
       roleDis === "salesTrainee"
    } 
    value="recruiter">Recruitment</MenuItem>
    <MenuItem 
    disabled={
      roleDis === "recruiterHead" || 
       roleDis ==="recruiterManager" ||
       roleDis ==="recruiterTeamLead" ||
       roleDis === "recruiter" ||
       roleDis === "recruiterTrainee"
    } 
    value="sales">Sales</MenuItem>
  </Select>
</FormControl>

{formValuesAddComapnny1.temprole === 'recruiter' ? (
  <FormControl fullWidth sx={{mt:1}}>
    <InputLabel id="demo-simple-select-label-role">Role</InputLabel>
    <Select
      labelId="demo-simple-select-label-role"
      id="demo-simple-select-role"
      label="Role"
      value={formValuesAddComapnny1.role}
      onChange={(e) =>
        setFormValuesAddCompany1({
          ...formValuesAddComapnny1,
          role: e.target.value,
        })
      }
    >
      <MenuItem value="recruiterHead">Recruitment Head</MenuItem>
      <MenuItem value="recruiterManager">Recruitment Manager</MenuItem>
      <MenuItem value="recruiterTeamLead">Recruitment Lead</MenuItem>
      <MenuItem value="recruiter">Technical Recruiter</MenuItem>
      <MenuItem value="recruiterTrainee">Recruitment Trainee</MenuItem>
    </Select>
  </FormControl>
) :  (
  <FormControl fullWidth sx={{mt:1}}>
    <InputLabel id="demo-simple-select-label-role">Role</InputLabel>
    <Select
      labelId="demo-simple-select-label-role"
      id="demo-simple-select-role"
      label="Role"
      value={formValuesAddComapnny1.role}
      onChange={(e) =>
        setFormValuesAddCompany1({
          ...formValuesAddComapnny1,
          role: e.target.value,
        })
      }
    >
      <MenuItem value="salesHead">Sales Head</MenuItem>
      <MenuItem value="salesManager">Sales Manager</MenuItem>
      <MenuItem value="salesTeamLead">Sales Team Lead</MenuItem>
      <MenuItem value="salesExecutive">Sales Executive</MenuItem>
      <MenuItem value="salesTrainee">Sales Trainee</MenuItem>
    </Select>
  </FormControl>
) }

      </Scrollbar>
      <Stack sx={{ alignItems: 'center' }} gap={1} mt={2}>
        <Button color="custom" variant="contained" sx={{ width: 100 }} 
        onClick={()=>{ChangePosition()}}
        disabled={loading1}
        >
           {loading1 ? <CircularProgress size={24} /> : 'Submit'}
          {/* Submit */}
        </Button>
      </Stack>
    </Box>
  </Modal>

    <Modal
    open={accesss1}
    onClose={closeaccess1}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Scrollbar sx={{ maxHeight: 400 }}>
        <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          Change Role
        </Typography>
        <Divider />
        <Stack gap={2} sx={{ mt: 2 ,flexDirection:'row'}}>
          <Stack sx={{width:'100%',gap:2}}>
          
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Change Role</InputLabel>
            <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label="Change Role"
        onChange={(e) => {
          const newValue = e.target.value;
          const selectedValue = dropDownLst.find(item => 
            `${item.contactPersonName}-${item.role}` === newValue
          );
          if (selectedValue) {
            setRoleId(selectedValue._id)
            setSelectedValue(newValue);
          }
        }}
      >
        {dropDownLst?.map((row, index) => (
          <MenuItem 
          key={index} value={`${row.contactPersonName}-${row.role}`}>
            {`${capitalizeWords(row.contactPersonName)} - ${getRoleDisplayName(row.role)}`}
          </MenuItem>
        ))}
      </Select>
          </FormControl>
          </Stack>
        </Stack>
      </Scrollbar>
      <Stack sx={{ alignItems: 'center' }} gap={1} mt={2}>
        <Button color="custom" variant="contained" sx={{ width: 100 }} 
        onClick={()=>{hierarcyFun()}}
        disabled={loading}
        >
           {loading ? <CircularProgress size={24} /> : 'Submit'}
          {/* Submit */}
        </Button>
      </Stack>
    </Box>
  </Modal>
     <Modal
     open={accesss}
     onClose={closeaccess}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>
       <Scrollbar sx={{ maxHeight: 400 }}>
         <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
           Access Management
         </Typography>
         <Divider />
         <Stack gap={2} sx={{ mt: 2 ,flexDirection:'row'}}>
           <Stack sx={{width:'100%',gap:2}}>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Recruiter Worksheet</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.rworksheet}
               label="Recruiter Worksheet"
               onChange={(e) => setAccess({ ...access, rworksheet: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Client</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.client}
               label="Client"
               onChange={(e) => setAccess({ ...access, client: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Jobs</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.jobs}
               label="Jobs"
               onChange={(e) => setAccess({ ...access, jobs: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           
           </Stack>
           <Stack sx={{width:'100%',gap:2}}>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Resume Database</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.rdatabase}
               label="Lead"
               onChange={(e) => setAccess({ ...access, rdatabase: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Bench Worksheet</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.lead}
               label="Bench Worksheet"
               onChange={(e) => setAccess({ ...access, lead: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Placement</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={access.placement}
               label="Placement"
               onChange={(e) => setAccess({ ...access, placement: e.target.value })}
             >
               <MenuItem value={'noaccess'}>No Access</MenuItem>
               <MenuItem value={'limited'}>Limited Access</MenuItem>
               <MenuItem value={'fullaccess'}>Full Access</MenuItem>
             </Select>
           </FormControl>
           </Stack>
         </Stack>
       </Scrollbar>
       <Stack sx={{ alignItems: 'center' }} gap={1} mt={2}>
         <Button color="custom" variant="contained" sx={{ width: 100 }} onClick={()=>Rights(data)}>
           save
         </Button>
       </Stack>
     </Box>
   </Modal>
   </>
  );
}

export default SalesManager;
